import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { Router } from '@angular/router';
import {
  LoaderService,
  NotificationButtonComponent,
  UserInitialsComponent,
} from 'processdelight-angular-components';
import {
  BehaviorSubject,
  Subject,
  filter,
  first,
  forkJoin,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { CoreModule } from 'src/app/core/core.module';
import { lastRouteKey } from 'src/app/core/guards/initial-navigation.guard';
import { UserFacade } from 'src/app/core/store/user/user.facade';
import { ApplicationService } from 'src/app/core/services/application.service';
import { PortalService } from 'src/app/core/services/portal.service';
import { TranslationService } from 'processdelight-angular-components';
import { CommonModule } from '@angular/common';
import { SignupLinkService } from 'src/app/core/services/signup-link.service';
import { UserLicenseInfo } from 'src/app/core/domain/models/user-license-info.model';
import { ExternalUserInfo } from 'src/app/core/domain/models/external-user-info.model';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { AccountSettingsComponent } from 'src/app/user/account-settings/account-settings.component';

@Component({
  selector: 'app-top-bar',
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatExpansionModule,
    UserInitialsComponent,
    NotificationButtonComponent,
    MatDialogModule,
  ],
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit, OnDestroy {
  @Input() appName!: string;

  @Input() orgLogo?: string;
  @Input() userPhoto?: string;

  @Input() navColor = 'var(--processdelightBlue)';

  userLang = '';
  loggedInUser?: ExternalUserInfo;

  isMobile$ = new BehaviorSubject<boolean>(
    /Mobi/.test(window.navigator.userAgent)
  );

  signedIn = false;

  activeTenantIds: string[] = [];

  destroy$ = new Subject<void>();

  get username(): string | undefined {
    return this.applicationService.msal.name;
  }

  get userMail(): string | undefined {
    return this.applicationService.msal.username;
  }

  get inTeams() {
    return this.applicationService.msal.runningOnTeams;
  }

  get isAdmin$() {
    return this.userFacade.isAdmin$;
  }

  constructor(
    private breakpointObserver: BreakpointObserver,
    private readonly applicationService: ApplicationService,
    private readonly portalService: PortalService,
    private readonly translationService: TranslationService,
    private readonly loader: LoaderService,
    private readonly router: Router,
    private readonly userFacade: UserFacade,
    private readonly linkService: SignupLinkService,
    private readonly accountSettingsDialog: MatDialog
  ) {}

  getTranslation$(key: string) {
    return this.translationService.getTranslation$(key);
  }

  ngOnInit(): void {
    if (!this.isMobile$.value)
      this.breakpointObserver
        .observe([Breakpoints.XSmall])
        .pipe(takeUntil(this.destroy$))
        .subscribe((result) =>
          this.isMobile$.next(Object.values(result.breakpoints).some((b) => b))
        );
    this.applicationService.signedIn
      .pipe(
        filter((b) => !!b),
        first()
      )
      .subscribe(() => this.onSignedIn());
    // this.userFacade.userInfo$
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((userInfo) => {
    //     if (userInfo) this.userLang = userInfo.language ?? 'en';
    //     else this.userLang = '';
    //   });
    this.userFacade.externalUserInfo$
      .pipe(takeUntil(this.destroy$))
      .subscribe((userInfo) => {
        if (userInfo) this.loggedInUser = userInfo;
      });

    const queryParams = new URLSearchParams(window.location.search);
    const loginHint = queryParams.get('loginHint') ?? undefined;
    this.applicationService.msal.silentSignIn(loginHint);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  goToSettings() {
    this.router.navigate(['settings']);
  }

  languageChange(event: MatSelectChange) {
    this.userFacade.updateUserLanguage(event.value);
  }

  signIn() {
    this.applicationService.signIn();
  }
  signOut() {
    this.applicationService.signOut();
  }

  private onSignedIn() {
    this.signedIn = true;
    this.launch();
  }

  launch() {
    const loadId = this.loader.startLoading('Initializing data');
    this.applicationService
      .initAppData()
      .pipe(first())
      .subscribe(() => {
        this.loader.stopLoading(loadId);
      });
  }

  openAccountSettings() {
    this.accountSettingsDialog.open(AccountSettingsComponent, {
      width: '70%',
      height: '70%',
      disableClose: true,
    });
  }
}
