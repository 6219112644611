import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalPostLike } from 'src/app/core/domain/models/portal-post-like.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { PortalUserPipe } from '../../portal.user.pipe';

@Component({
  selector: 'app-like-list-popup',
  standalone: true,
  imports: [
    CommonModule,
    PortalUserPipe,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
  ],
  templateUrl: './like-list-popup.component.html',
  styleUrls: ['./like-list-popup.component.scss'],
})
export class LikeListPopupComponent {
  likes: PortalPostLike[] = [];

  // mat data constructor
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: { likes: PortalPostLike[] },
    public dialogRef: MatDialogRef<LikeListPopupComponent>
  ) {
    this.likes = dialogData.likes;
  }

  close() {
    this.dialogRef.close();
  }
}
