import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatestWith, mergeMap, of, switchMap, tap } from 'rxjs';
import { PortalService } from '../../services/portal.service';
import { AppState } from '../app.reducer';
import { BaseEffects } from '../base.effects';
import {
  createPortalClientUserGroup,
  createPortalClientUserGroupResolved,
  deletePortalClientUserGroup,
  deletePortalClientUserGroupResolved,
  getLicensesForUser,
  getLicensesForUserResolved,
  getUserSettings,
  getUserSettingsResolved,
  updateUserSettings,
  updateUserSettingsResolved,
} from './user.actions';

@Injectable({ providedIn: 'root' })
export class UserEffects extends BaseEffects {
  constructor(
    private portalService: PortalService,
    actions$: Actions,
    private _store: Store<AppState>
  ) {
    super(actions$);
  }

  getLicensesForUser = this.createDefaultEffect(
    getLicensesForUser,
    ({ email }) => this.portalService.getLicensesForUser(email),
    {
      valueMapper: (v) => ({ licenseInfo: v.result }),
      action: getLicensesForUserResolved,
    }
  );

  getUserSettings = this.createDefaultEffect(
    getUserSettings,
    () => this.portalService.getUserSettings(),
    {
      valueMapper: (v) => ({ settings: v.result }),
      action: getUserSettingsResolved,
    }
  );

  updateUserSettings = this.createDefaultEffect(
    updateUserSettings,
    ({ settings }) => this.portalService.updateUserSettings(settings),
    {
      valueMapper: (v) => ({ settings: v.result }),
      action: updateUserSettingsResolved,
    }
  );

  deletePortalClientUserGroup = createEffect(() =>
    this.actions$.pipe(
      ofType(deletePortalClientUserGroup),
      mergeMap(({ portalClientUserGroup, callback }) =>
        this.portalService
          .deletePortalClientUserGroup(portalClientUserGroup)
          .pipe(
            tap(() => (callback ? callback() : undefined)),
            switchMap(() =>
              of(deletePortalClientUserGroupResolved({ portalClientUserGroup }))
            )
          )
      )
    )
  );

  createPortalClientUserGroup = createEffect(() =>
    this.actions$.pipe(
      ofType(createPortalClientUserGroup),
      combineLatestWith(
        this._store.select((s) => s.portalFeature.group.groups)
      ),
      mergeMap(([{ portalClientUserGroup, callback }, groups]) =>
        this.portalService
          .createPortalClientUserGroup(portalClientUserGroup)
          .pipe(
            tap(() => (callback ? callback() : undefined)),
            switchMap(() =>
              of(
                createPortalClientUserGroupResolved({
                  portalClientUserGroup,
                  groups,
                })
              )
            )
          )
      )
    )
  );
}
