import { Injectable } from '@angular/core';
import { PortalService } from '../../services/portal.service';
import { BaseEffects } from '../base.effects';
import {
  getPages,
  getPagesResolved,
  getWebPartTypes,
  getWebPartTypesResolved,
  updatePageConfiguration,
} from './page.actions';
import { Actions } from '@ngrx/effects';

@Injectable({ providedIn: 'root' })
export class PageEffects extends BaseEffects {
  constructor(private portalService: PortalService, actions$: Actions) {
    super(actions$);
  }

  getPages = this.createDefaultEffect(
    getPages,
    () => this.portalService.getPages(),
    { valueMapper: (v) => ({ pages: v.result }), action: getPagesResolved }
  );

  updatePageConfiguration = this.createDefaultEffect(
    updatePageConfiguration,
    ({ configuration }) =>
      this.portalService.updatePageConfiguration(configuration),
    {
      valueMapper: (v) => ({ pages: v.result }),
      action: getPagesResolved,
    }
  );

  getWebPartTypes = this.createDefaultEffect(
    getWebPartTypes,
    () => this.portalService.getWebPartTypes(),
    {
      valueMapper: (v) => ({ webPartTypes: v.result }),
      action: getWebPartTypesResolved,
    }
  );
}
