import { CRMEntity } from 'processdelight-angular-components';

export class Group {
  id?: string;
  name!: string;
  clientId!: string;
  client!: CRMEntity;
  constructor(obj: Partial<Group>) {
    Object.assign(this, obj);
    if (obj?.client) this.client = new CRMEntity(obj.client);
  }
}
