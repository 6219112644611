import {
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { Subject, takeUntil, finalize, tap, map } from 'rxjs';
import * as uuid from 'uuid';
import {
  AbstractWebPartConfigComponent,
  GroupUser,
  Library,
  LibraryFacade,
  MetadataFacade,
  WebPartConfig,
} from 'processdelight-angular-components';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { AdaptableInputComponent } from '../adaptable-input/adaptable-input.component';
import { MetadataParam } from 'processdelight-angular-components';
import {
  DateStartEnd,
  MetadataFilterType,
} from '../domain/models/metadata.functions';
import { ColumnType } from '../domain/enums/column-type.enum';
import { DateTime } from 'luxon';
import { TranslationService } from 'processdelight-angular-components';
import {
  MatProgressSpinnerModule,
  MatSpinner,
} from '@angular/material/progress-spinner';

@Component({
  selector: 'app-dms-config',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,

    // filter
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatInputModule,
    MatButtonToggleModule,
    AdaptableInputComponent,
    MatProgressSpinnerModule,
  ],
  templateUrl: './dms-config.component.html',
  styleUrls: ['./dms-config.component.scss'],
})
export class DmsConfigComponent
  extends AbstractWebPartConfigComponent
  implements OnInit, OnDestroy
{
  // filter
  @ViewChild('filterValueElement') filterValueElement?: AdaptableInputComponent;
  filterParamControl = new FormControl<MetadataParam | undefined>(undefined);
  filterValueControl = new FormControl<MetadataFilterType | undefined>(
    undefined
  );

  private metaDataFacade: MetadataFacade = inject(MetadataFacade);
  private translationService: TranslationService = inject(TranslationService);
  private libraryFacade: LibraryFacade = inject(LibraryFacade);

  loadingLibraries = true;
  libraries$ = this.libraryFacade.getLibraries$().pipe(
    map((libraries) =>
      [...libraries].sort((a, b) => a.title.localeCompare(b.title))
    ),
    tap((libraries) => {
      this.setInitialLibrarySelection(libraries);
    }),
    finalize(() => {
      this.loadingLibraries = false;
    })
  );

  selectedLibraryControl = new FormControl<Library | undefined>(undefined);

  metadataParameters$ = this.metaDataFacade.metadataParams$;

  selectedView?: string;

  paramTrackBy = (_i: number, param: MetadataParam) => param.id;

  filterTrackBy = (_i: number, param: { paramId: string }) => param.paramId;

  getParamValue(
    params: MetadataParam[],
    id: string,
    value: MetadataFilterType
  ) {
    const param = this.getParamById(params, id);
    if (param?.type == ColumnType.Choice)
      return Array.isArray(value)
        ? value
            .map((v) => param.choices?.find((c) => c.id == v)?.value)
            .join(', ')
        : param.choices?.find((c) => c.id == value)?.value;
    else if (param?.type == ColumnType.ConsolidatedChoice) {
      if (Array.isArray(value)) {
        return value
          .map((v) => {
            const [pId, cId] = (v as string).split('.');
            const p = this.getParamById(params, pId);
            return p?.choices?.find((c) => c.id == cId)?.value;
          })
          .join(', ');
      } else {
        const [pId, cId] = (value as string).split('.');
        const p = this.getParamById(params, pId);
        return p?.choices?.find((c) => c.id == cId)?.value;
      }
    } else if (param?.type == ColumnType.GroupUser)
      return Array.isArray(value)
        ? value.map((v) => (v as GroupUser).displayName).join(', ')
        : (value as GroupUser).displayName;
    else if (param?.type == ColumnType.DateTime) {
      if (typeof value == 'string') return value;
      else
        return `${
          this.getDateStartEndStart(value as DateStartEnd)?.toFormat(
            param.format ?? 'dd/MM/yyyy'
          ) ?? '...'
        } - ${
          this.getDateStartEndEnd(value as DateStartEnd)?.toFormat(
            param.format ?? 'dd/MM/yyyy'
          ) ?? '...'
        }`;
    } else if (Array.isArray(value)) return value.join(', ');
    else return value;
  }

  getFilteredParams(params: MetadataParam[]) {
    return this.library
      ? params
          .filter(
            (p) =>
              p.createdByParam ||
              p.createdOnParam ||
              p.modifiedByParam ||
              p.modifiedOnParam ||
              p.fileNameParam ||
              this.library?.configuredParams.some((c) => c.paramId == p.id)
          )
          .sort((a, b) => (a.title < b.title ? -1 : 1))
      : params.sort((a, b) => (a.title < b.title ? -1 : 1));
  }

  getDateStartEndStart(value: DateStartEnd) {
    if (!value.start) return undefined;
    return value.start instanceof DateTime
      ? value.start
      : DateTime.fromJSDate(value.start);
  }
  getDateStartEndEnd(value: DateStartEnd) {
    if (!value.end) return undefined;
    return value.end instanceof DateTime
      ? value.end
      : DateTime.fromJSDate(value.end);
  }

  addFilter() {
    if (this.selectedLibraryControl.value) {
      this.updateFilter({
        ...this.filterValue,
      });
    }
    const param = this.filterParamControl.value;
    if (!param) return;
    if (this.filterValueControl.value)
      this.updateFilter({
        ...this.filterValue,
        [param.id]: this.filterValueControl.value,
      });
    else if (this.filterValue[param.id]) {
      delete this.filterValue[param.id];
      this.updateFilter({ ...this.filterValue });
    }
  }

  removeFilter(paramId: string) {
    delete this.filterValue[paramId];
    this.updateFilter({ ...this.filterValue });
  }

  getParamById(params: MetadataParam[], id: string) {
    return params.find((p) => p.id == id);
  }

  updateFilter(value: { [key: string]: any }) {
    this.filterValue = value;
    const prefiltersConfig =
      this.configurations?.find((c) => c.name === 'prefilter') ??
      new WebPartConfig({
        id: uuid.v4(),
        name: 'prefilter',
        configuration: '',
      });
    const libraryConfig =
      this.configurations?.find((c) => c.name === 'library') ??
      new WebPartConfig({
        id: uuid.v4(),
        name: 'library',
        configuration: '',
      });
    this.valueChanges.emit([
      new WebPartConfig({
        id: prefiltersConfig.id,
        name: prefiltersConfig.name,
        configuration: JSON.stringify(value),
      }),
      new WebPartConfig({
        id: libraryConfig.id,
        name: libraryConfig.name,
        configuration: this.selectedLibraryControl.value?.id ?? '',
      }),
    ]);
  }

  get filterValueArray() {
    return Object.entries(this.filterValue).map(([key, value]) => ({
      paramId: key,
      value,
    }));
  }

  focusInput(input: HTMLInputElement) {
    setTimeout(() => {
      input.focus();
    }, 0);
  }

  getTranslation(label: string): string {
    return this.translationService.getTranslation(label);
  }
  getTranslation$(label: string) {
    return this.translationService.getTranslation$(label);
  }

  @Input() library?: Library;
  @Input() filterValue: { [key: string]: string } = {};

  form = new FormGroup({
    prefilter: new FormControl<string>(''),
  });

  destroy$ = new Subject<void>();

  ngOnInit(): void {
    const prefiltersConfig = this.configurations?.find(
      (c) => c.name === 'prefilter'
    );

    if (prefiltersConfig?.configuration) {
      try {
        const prefilters = JSON.parse(prefiltersConfig.configuration);
        this.filterValue = prefilters;
      } catch (error) {
        console.error('Failed to parse prefiltersConfig', error);
      }
    }

    this.selectedLibraryControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((library) => {
        this.library = library ?? undefined;
        this.filterParamControl.setValue(undefined);
        this.removeInvalidFilters();
        this.updateFilter({ ...this.filterValue });
      });
    this.filterParamControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.filterValueControl.setValue(undefined);
      });
  }

  setInitialLibrarySelection(libraries: Library[]) {
    const libraryConfig = this.configurations?.find(
      (c) => c.name === 'library'
    );
    if (libraryConfig?.configuration) {
      const selectedLibrary = libraries.find(
        (library) => library.id === libraryConfig.configuration
      );
      this.selectedLibraryControl.setValue(selectedLibrary, {
        emitEvent: false,
      });
    }
  }

  removeInvalidFilters() {
    if (!this.library) return;

    const validFilterIds = new Set(
      this.library.configuredParams.map((c) => c.paramId)
    );
    const newFilterValue = Object.fromEntries(
      Object.entries(this.filterValue).filter(
        ([key, value]) =>
          validFilterIds.has(key) &&
          (!this.library!.configuredParams.find((c) => c.paramId == key)!
            .choices.length ||
            this.library!.configuredParams.find(
              (c) => c.paramId == key
            )!.choices.some((c) => c.metadataChoiceId == value))
      )
    );

    if (
      Object.keys(newFilterValue).length !==
      Object.keys(this.filterValue).length
    ) {
      this.updateFilter(newFilterValue);
    }
  }

  isSearchDisabled() {
    return !(
      this.selectedLibraryControl.value ||
      (this.filterParamControl.value && this.filterValueControl.value)
    );
  }

  clearLibrarySelection(event: Event) {
    event.stopPropagation();
    this.selectedLibraryControl.reset();
    this.updateFilter({ ...this.filterValue });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
