import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormGroup,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  AbstractWebPartConfigComponent,
  WebPartConfig,
} from 'processdelight-angular-components';
import * as uuid from 'uuid';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-verhofste-offer-config',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  templateUrl: './verhofste-offer-config.component.html',
  styleUrls: ['./verhofste-offer-config.component.scss'],
})
export class VerhofsteOfferConfigComponent
  extends AbstractWebPartConfigComponent
  implements OnInit, OnDestroy
{
  form = new FormGroup({
    enableSearch: new FormControl<boolean>(false),
    validityDate: new FormControl<number>(5, [
      Validators.required,
      Validators.min(1),
    ]),
    deliveryDate: new FormControl<number>(5, [
      Validators.required,
      Validators.min(0),
    ]),
    contact: new FormControl<string>('', [
      Validators.required,
      Validators.email,
    ]),
    disabled: new FormControl<boolean>(false),
    ralLabel: new FormControl<string>('Powder coated'),
  });

  destroy$ = new Subject<void>();

  ngOnInit(): void {
    const enableSearchConfig = this.configurations?.find(
      (c) => c.name === 'enableSearch'
    );
    const validityDateConfig = this.configurations?.find(
      (c) => c.name === 'validityDate'
    );
    const deliveryDateConfig = this.configurations?.find(
      (c) => c.name === 'deliveryDate'
    );
    const contactConfig = this.configurations?.find(
      (c) => c.name === 'contact'
    );
    const disabledConfig = this.configurations?.find(
      (c) => c.name === 'disabled'
    );
    const ralLabelConfig = this.configurations?.find(
      (c) => c.name === 'ralLabel'
    );

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      const enableSearchConfig =
        this.configurations?.find((c) => c.name === 'enableSearch') ??
        new WebPartConfig({
          id: uuid.v4(),
          name: 'enableSearch',
          configuration: 'false',
        });
      const validityDateConfig =
        this.configurations?.find((c) => c.name === 'validityDate') ??
        new WebPartConfig({
          id: uuid.v4(),
          name: 'validityDate',
          configuration: value.validityDate?.toString(),
        });
      const deliveryDateConfig =
        this.configurations?.find((c) => c.name === 'deliveryDate') ??
        new WebPartConfig({
          id: uuid.v4(),
          name: 'deliveryDate',
          configuration: value.deliveryDate?.toString(),
        });
      const contactConfig =
        this.configurations?.find((c) => c.name === 'contact') ??
        new WebPartConfig({
          id: uuid.v4(),
          name: 'contact',
          configuration: value.contact ?? undefined,
        });
      const disabledConfig =
        this.configurations?.find((c) => c.name === 'disabled') ??
        new WebPartConfig({
          id: uuid.v4(),
          name: 'disabled',
          configuration: 'false',
        });
      const ralLabelConfig =
        this.configurations?.find((c) => c.name === 'ralLabel') ??
        new WebPartConfig({
          id: uuid.v4(),
          name: 'ralLabel',
          configuration: value.ralLabel ?? undefined,
        });
      this.valueChanges.emit([
        new WebPartConfig({
          id: enableSearchConfig.id,
          name: enableSearchConfig.name,
          configuration: value.enableSearch ? 'true' : 'false',
        }),
        new WebPartConfig({
          id: validityDateConfig.id,
          name: validityDateConfig.name,
          configuration: value.validityDate?.toString(),
        }),
        new WebPartConfig({
          id: deliveryDateConfig.id,
          name: deliveryDateConfig.name,
          configuration: value.deliveryDate?.toString(),
        }),
        new WebPartConfig({
          id: contactConfig.id,
          name: contactConfig.name,
          configuration: value.contact ?? undefined,
        }),
        new WebPartConfig({
          id: disabledConfig.id,
          name: disabledConfig.name,
          configuration: value.disabled ? 'true' : 'false',
        }),
        new WebPartConfig({
          id: ralLabelConfig.id,
          name: ralLabelConfig.name,
          configuration: value.ralLabel ?? undefined,
        }),
      ]);
    });
    this.form.patchValue({
      enableSearch: enableSearchConfig?.configuration === 'true' ?? false,
      validityDate: validityDateConfig?.configuration
        ? +validityDateConfig.configuration
        : 5,
      deliveryDate: deliveryDateConfig?.configuration
        ? +deliveryDateConfig.configuration
        : 5,
      contact: contactConfig?.configuration ?? '',
      disabled: disabledConfig?.configuration === 'true' ?? false,
      ralLabel: ralLabelConfig?.configuration ?? 'Powder coated',
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
