import { Pipe, PipeTransform } from '@angular/core';
import { GroupUser } from 'processdelight-angular-components';
import { GroupUserEntity } from 'processdelight-angular-components/lib/domain/models/groupUserEntity';
import { PortalPostComment } from 'src/app/core/domain/models/portal-post-comment.model';
import { PortalPostLike } from 'src/app/core/domain/models/portal-post-like.model';
import { PortalUser } from 'src/app/core/domain/models/portal-user.model';

@Pipe({
  standalone: true,
  name: 'portalUser',
})
export class PortalUserPipe implements PipeTransform {
  transform(like: {
    portalUser?: PortalUser;
    groupUser?: GroupUserEntity;
    portalUserAuthor?: PortalUser;
    groupUserAuthor?: GroupUserEntity;
  }): string {
 
    if (like.portalUser) return like.portalUser.displayName ?? '';
    if (like.groupUser) return like.groupUser.displayName + ' (internal)';
    if (like.portalUserAuthor) return like.portalUserAuthor.displayName ?? '';
    if (like.groupUserAuthor)
      return like.groupUserAuthor.displayName + ' (internal)';

    return '';
  }
}
