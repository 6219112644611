<div #webPartContainer class="h-100 w-100">
  <processdelight-entity-default-cells></processdelight-entity-default-cells>
  <processdelight-entity-default-filters></processdelight-entity-default-filters>
  <mat-card class="h-100 d-flex flex-column list-background p-0">
    <mat-card-header class="d-flex flex-row align-items-center w-100" [style.display]="shouldDisplayCardHeader ? 'flex' : 'none !important'">
      <div class="align-self-start me-2">
        <mat-card-title>{{ getTranslation$("tasks") | async }}</mat-card-title>
      </div>
      <app-tasks-switcher class="tasksSwitcher" [control]="assignedToMe">
      </app-tasks-switcher>
      <div class="flex-grow-1"></div>
      <mat-spinner *ngIf="loading" diameter="40" class="me-2"></mat-spinner>
    </mat-card-header>
    <mat-card-content class="h-100 w-100 d-flex flex-column min-height-0">
      <div class="d-flex flex-column h-100 min-height-0" *ngIf="initialized">
        <div class="flex-grow-1 pt-2 min-height-0" #dashboardWrapper>
          <processdelight-entity-dashboard
            [style.display]="shouldDisplayTable ? '' : 'none !important'"
            class="hide-paginator hide-scrollbar h-100 d-flex flex-column"
            [data]="pagedEntities"
            [maxSizeItems]="totalRecordCount"
            [pageSize]="pageSize"
            [possibleColumns]="possibleColumns"
            [selectedColumns]="selectedColumns"
            (rowExpanded)="openDetailDialog($event)"
            [sortColumn]="orderBy"
            [sortDirection]="orderByDirection"
            (sortChange)="sortChanged($event)"
            (filterChange)="filterChanged($event)"
            (columnChange)="columnChanged($event)"
            [emptyTableText]="noItems"
            [itemTrackByFn]="itemTrackByFn"
          ></processdelight-entity-dashboard>
        </div>
        <ng-template #noItems>
          <div class="w-100 h-100 d-flex justify-content-center align-items-center">
            <h1 *ngIf="!loading">{{ getTranslation$("noResults") | async }}</h1>
          </div>
        </ng-template>
        <div class="text-center" [style.display]="shouldDisplayButton ? '' : 'none !important'">
          <button mat-button (click)="openListDialog()">{{ getTranslation$("showMore") | async }}</button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>