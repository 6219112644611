<div class="h-100 d-flex flex-column">
  <processdelight-action-bar
    [navColor]="navColor"
    [navContrast]="navContrast"
    [title]="title"
    [iconActions]="iconActions"
    [searchEnabled]="searchEnabled"
    [sideBarButtonEnabled]="sideBarButtonEnabled"
    [buttonActions]="buttonActions"
    class="flex-shrink-0"
  ></processdelight-action-bar>
  <mat-dialog-content
    class="quote-summary-dialog mh-100 h-100 d-flex flex-column"
  >
    <div class="d-flex flex-row justify-content-between ms-2 me-2 mb-2">
      <div class="d-flex flex-column">
        <div>Client reference:</div>
        <div>
          {{ quoteSummaryData.clientReference }}
        </div>
      </div>
      <mat-form-field appearance="outline" [style.width.rem]="20">
        <mat-label>Extra information</mat-label>
        <input matInput [(ngModel)]="description" />
      </mat-form-field>
    </div>
    <div class="flex-grow-1 overflow-auto scrollbar">
      <app-verhofste-product-table
        [data]="data"
        [displayedColumns]="displayedColumns"
        [possibleColumns]="possibleColumns"
        [actualDisplayedColumns]="actualDisplayedColumns"
        [amountTableDef]="amountTableDef"
        [ralTableDef]="ralTableDef"
        [ralLabel]="ralLabel"
        [filtersEnabled]="filtersEnabled"
        [sortSettings]="sortSettings"
        (inputsChanged)="validate()"
      ></app-verhofste-product-table>
    </div>
    <div class="total-price text-end mt-2">
      <h2 class="h6">
        <strong>Total Price All:</strong> € {{ getTotalPrice() }}
      </h2>
    </div>
  </mat-dialog-content>
</div>
