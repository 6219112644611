import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ActionCreator } from '@ngrx/store';
import { ActionBase } from './action-base.interface';
import { TypedAction } from '@ngrx/store/src/models';
import { Observable, catchError, switchMap, tap } from 'rxjs';

export type ResolvedAction<P, PR, R> = {
  valueMapper: (v: P & { result: R }) => PR;
  action: ActionCreator<string, (props: PR) => PR & TypedAction<string>>;
};

export class BaseEffects {
  constructor(protected actions$: Actions) {}

  createDefaultEffect<P, RA extends ResolvedAction<P, any, R>, R>(
    action: ActionCreator<
      string,
      (props: P & ActionBase<R>) => (P & ActionBase<R>) & TypedAction<string>
    >,
    apiObs: (props: P) => Observable<R>,
    resolvedActions: RA | RA[]
  ) {
    return createEffect(() =>
      this.actions$.pipe(
        ofType(action),
        switchMap((props) =>
          apiObs(props).pipe(
            tap((value) =>
              props.callback ? props.callback(value) : undefined
            ),
            switchMap((value) =>
              Array.isArray(resolvedActions)
                ? resolvedActions.map((ra) =>
                    ra.action(ra.valueMapper({ result: value, ...props }))
                  )
                : ([
                    resolvedActions.action(
                      resolvedActions.valueMapper({ result: value, ...props })
                    ),
                  ] as TypedAction<string>[])
            ),
            catchError((e) => {
              if (props.error) props.error(e);
              return [];
            })
          )
        )
      )
    );
  }
}
