import { Injectable } from '@angular/core';
import { select } from '@ngrx/store';
import { BaseFacade } from '../../base.facade';
import {
  createComment,
  createPost,
  deleteComment,
  deletePost,
  editComment,
  getPosts,
  likePost,
  removeLike,
  resetSlice,
  UpdatePost,
} from './feed.actions';
import { feedSelectors } from './feed.selectors';
import { PortalPost } from 'src/app/core/domain/models/portal-post.model';
import { PortalPostLike } from 'src/app/core/domain/models/portal-post-like.model';
import { first, of } from 'rxjs';
import { PortalPostComment } from 'src/app/core/domain/models/portal-post-comment.model';

@Injectable({
  providedIn: 'root',
})
export class FeedFacade extends BaseFacade {
  posts$ = this._store.pipe(select(feedSelectors.getFeed));
  hasLoadedAllPosts$ = this._store.pipe(
    select(feedSelectors.hasLoadedAllPosts)
  );

  getFeed$(
    filteredInterestGroups: string[] | undefined,
    portalUserId: string,
    orderByDir: 'asc' | 'desc',
    pageSize: number,
    pageIndex: number,
    filter?: string,
    resetPaging = false
  ) {
    // let posts: PortalPost[] | undefined;
    // this.posts$.pipe(first()).subscribe((p) => (posts = p));
    // if (posts) return of(posts);
    return this.dispatchAction$(getPosts, {
      filteredInterestGroups,
      portalUserId,
      orderByDir,
      filter,
      pageSize,
      pageIndex,
      resetPaging,
    });
  }

  createPost(post: PortalPost) {
    return this.dispatchAction$(createPost, { post });
  }

  updatePost(post: PortalPost) {
    return this.dispatchAction$(UpdatePost, { post });
  }

  removePost(post: PortalPost) {
    return this.dispatchAction$(deletePost, { post });
  }

  createLike(like: PortalPostLike) {
    return this.dispatchAction$(likePost, { like });
  }

  removeLike(like: PortalPostLike) {
    return this.dispatchAction$(removeLike, { like });
  }

  createComment(comment: PortalPostComment) {
    return this.dispatchAction$(createComment, { comment });
  }

  editComment(comment: PortalPostComment) {
    return this.dispatchAction$(editComment, { comment });
  }

  removeComment(comment: PortalPostComment) {
    return this.dispatchAction$(deleteComment, { comment });
  }

  resetSlice() {
    this._store.dispatch(resetSlice());
  }
}
