import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { combineLatest, map, Observable } from 'rxjs';
import { ClientFacade } from '../store/client/client.facade';
import { UserFacade } from '../store/user/user.facade';

@Injectable({ providedIn: 'root' })
export class CanActivateHome implements CanActivate {
  private router = inject(Router);
  private userFacade = inject(UserFacade);
  private clientFacade = inject(ClientFacade);

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return combineLatest([
      this.clientFacade.currentClient$,
      this.userFacade.externalUserInfo$,
    ]).pipe(
      map(([currentclient, userInfo]) => {
        if (currentclient) return true;
        if (userInfo?.clientUserRequests.length)
          this.router.navigate(['wait-for-approval']);
        return false;
      })
    );
  }
}
