import { createAction, props } from '@ngrx/store';
import { GroupUser } from 'processdelight-angular-components';
import { IshtarOrganization } from '../../domain/models/ishtar-organization.model';
import { PortalUserVM } from '../../domain/models/portal-user.model';
import { ActionBase } from '../action-base.interface';

export const getOrganizationInfo = createAction(
  '[Organization] Get organization info',
  props<{ tenantId: string; callback?: () => void }>()
);
export const getOrganizationInfoResolved = createAction(
  '[Organization] Get organization info resolved',
  props<{ organizationInfo?: IshtarOrganization }>()
);
export const getGroupUsers = createAction(
  '[Organization] Get groups and users',
  props<{ callback?: (groupUsers: GroupUser[]) => void }>()
);
export const getGroupUsersResolved = createAction(
  '[Organization] Get groups and users resolved',
  props<{ groupUsers: GroupUser[] }>()
);

export const getPortalUsers = createAction(
  '[Organization] Get portal users',
  props<ActionBase<PortalUserVM[]>>()
);
export const getPortalUsersResolved = createAction(
  '[Organization] Get portal users resolved',
  props<{ portalUsers: PortalUserVM[] }>()
);

export const resetSlice = createAction('[Organization] Reset slice');
