import { AADUser } from 'processdelight-angular-components';

export class PortalUser {
  id?: string;
  user!: string;
  displayName?: string;

  constructor(obj: Partial<PortalUser>) {
    Object.assign(this, obj);
  }
}

export class PortalUserVM {
  id?: string;
  user!: AADUser;

  constructor(obj: Partial<PortalUserVM>) {
    Object.assign(this, obj);
    if (obj?.user) this.user = new AADUser(obj.user);
  }
}
