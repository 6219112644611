import { Entity } from 'processdelight-angular-components';
import { GroupUserEntity } from 'processdelight-angular-components/lib/domain/models/groupUserEntity';
import { PortalUser } from './portal-user.model';

export class PortalPostLike extends Entity {
  public id!: string;
  public postId?: string;
  public commentId?: string;

  public portalUserId!: string;
  public groupUserId!: string;

  public portalUser?: PortalUser;
  public groupUser?: GroupUserEntity;

  constructor(obj: Partial<PortalPostLike>) {
    super(obj);
  }
}
