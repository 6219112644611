import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, combineLatestWith, of, switchMap, tap } from 'rxjs';
import { DataService } from 'src/app/webparts/dms-component/services/data.service';
import {
  createComment,
  createCommentResolved,
  createPost,
  createPostResolved,
  deleteComment,
  deleteCommentResolved,
  deletePost,
  deletePostResolved,
  editComment,
  editCommentResolved,
  getPosts,
  getPostsResolved,
  likePost,
  likePostResolved,
  removeLike,
  removeLikeResolved,
  UpdatePost,
  UpdatePostResolved,
} from './feed.actions';
import { PortalService } from 'src/app/core/services/portal.service';
import { pages } from '@microsoft/teams-js';

@Injectable({ providedIn: 'root' })
export class FeedEffects {
  constructor(private actions$: Actions, private api: DataService) {}

  getPosts = createEffect(() =>
    this.actions$.pipe(
      ofType(getPosts),
      switchMap(
        ({
          callback,
          error,
          filteredInterestGroups,
          portalUserId,
          orderByDir,
          filter,
          pageSize,
          pageIndex,
          resetPaging,
        }) =>
          this.api
            .getPosts(
              filteredInterestGroups,
              portalUserId,
              orderByDir,
              filter,
              pageSize,
              pageIndex
            )
            .pipe(
              tap((posts) => (callback ? callback(posts) : undefined)),
              switchMap((posts) =>
                of(
                  getPostsResolved({
                    feed: posts,
                    resetPaging,
                    requestPageSize: pageSize,
                  })
                )
              ),
              catchError((e) => {
                if (error) error(e);

                return [];
              })
            )
      )
    )
  );

  createPost = createEffect(() =>
    this.actions$.pipe(
      ofType(createPost),
      switchMap(({ callback, error, post }) =>
        this.api.createPost(post).pipe(
          tap((post) => (callback ? callback(post) : undefined)),
          switchMap((post) => of(createPostResolved({ post }))),
          catchError((e) => {
            if (error) error(e);

            return [];
          })
        )
      )
    )
  );

  updatePost = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdatePost),
      switchMap(({ callback, error, post }) =>
        this.api.updatePost(post).pipe(
          tap((post) => (callback ? callback(post) : undefined)),
          switchMap((post) => of(UpdatePostResolved({ post }))),
          catchError((e) => {
            if (error) error(e);

            return [];
          })
        )
      )
    )
  );

  deletePost = createEffect(() =>
    this.actions$.pipe(
      ofType(deletePost),
      switchMap(({ callback, error, post }) =>
        this.api.deletePost(post.id).pipe(
          tap((id) => (callback ? callback(id) : undefined)),
          switchMap((id) => of(deletePostResolved({ id }))),
          catchError((e) => {
            if (error) error(e);

            return [];
          })
        )
      )
    )
  );

  likePost = createEffect(() =>
    this.actions$.pipe(
      ofType(likePost),
      switchMap(({ callback, error, like }) =>
        this.api.createLike(like).pipe(
          tap((like) => (callback ? callback(like) : undefined)),
          switchMap((like) => of(likePostResolved({ like }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  removeLike = createEffect(() =>
    this.actions$.pipe(
      ofType(removeLike),
      switchMap(({ callback, error, like }) =>
        this.api.removeLike(like.id).pipe(
          tap((id) => (callback ? callback(id) : undefined)),
          switchMap((id) =>
            of(
              removeLikeResolved({
                likeId: id,
                postId: like.postId,
                commentId: like.commentId,
              })
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  createComment = createEffect(() =>
    this.actions$.pipe(
      ofType(createComment),
      switchMap(({ callback, error, comment }) =>
        this.api.createComment(comment).pipe(
          tap((comment) => (callback ? callback(comment) : undefined)),
          switchMap((comment) => of(createCommentResolved({ comment }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  editComment = createEffect(() =>
    this.actions$.pipe(
      ofType(editComment),
      switchMap(({ callback, error, comment }) =>
        this.api.editComment(comment).pipe(
          tap((comment) => (callback ? callback(comment) : undefined)),
          switchMap((comment) => of(editCommentResolved({ comment }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  removeComment = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteComment),
      switchMap(({ callback, error, comment }) =>
        this.api.removeComment(comment.id).pipe(
          tap((commentId) => (callback ? callback(commentId) : undefined)),
          switchMap((commentId) =>
            of(deleteCommentResolved({ commentId, postId: comment.postId }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );
}
