import { Entity } from 'processdelight-angular-components';
import { GroupUserEntity } from 'processdelight-angular-components/lib/domain/models/groupUserEntity';
import { PortalPostLike } from './portal-post-like.model';
import { PortalUser } from './portal-user.model';

export class PortalPostComment extends Entity {
  public postId!: string;
  public message!: string;
  public portalUserId!: string;
  public groupUserId!: string;

  public portalUser?: PortalUser;
  public groupUser?: GroupUserEntity;

  public likes!: PortalPostLike[];

  constructor(obj: Partial<PortalPostComment>) {
    super(obj);
    if (obj?.likes)
      this.likes = obj.likes.map((like) => new PortalPostLike(like));
  }
}
