import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { GroupUser } from 'processdelight-angular-components';
import { Subject } from 'rxjs';
import { AppState } from 'src/app/core/store/app.reducer';
import { BaseFacade } from '../base.facade';
import {
  getGroupUsers,
  getOrganizationInfo,
  getOrganizationInfoResolved,
  getPortalUsers,
  resetSlice,
} from './organization.actions';
import { organizationSelectors } from './organization.selectors';
import { IshtarOrganization } from '../../domain/models/ishtar-organization.model';

@Injectable({
  providedIn: 'root',
})
export class OrganizationFacade extends BaseFacade {
  organizationInfo$ = this._store.pipe(
    select(organizationSelectors.getOrganizationInfo)
  );
  groupUsers$ = this._store.pipe(select(organizationSelectors.getGroupUsers));
  portalUsers$ = this._store.pipe(select(organizationSelectors.getPortalUsers));

  constructor(_store: Store<AppState>) {
    super(_store);
  }

  getOrganizationInfo(tenantId: string, callback?: () => void) {
    this._store.dispatch(getOrganizationInfo({ tenantId, callback }));
  }
  getGroupUsers(callback?: (groupUsers: GroupUser[]) => void) {
    this._store.dispatch(getGroupUsers({ callback }));
  }
  getOrganizationInfo$(tenantId: string) {
    const subject = new Subject<void>();
    this._store.dispatch(
      getOrganizationInfo({
        tenantId,
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }
  getOrganizationInfoResolved(organizationInfo: IshtarOrganization) {
    this._store.dispatch(getOrganizationInfoResolved({ organizationInfo }));
  }
  getGroupUsers$() {
    const subject = new Subject<GroupUser[]>();
    this._store.dispatch(
      getGroupUsers({
        callback: (groupUsers) => {
          subject.next(groupUsers);
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  getPortalUsers$() {
    return this.dispatchAction$(getPortalUsers);
  }

  resetSlice() {
    this._store.dispatch(resetSlice());
  }
}
