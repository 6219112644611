<button class="close-button" mat-icon-button (click)="onClose()">
  <mat-icon>close</mat-icon>
</button>
<processdelight-entity-default-cells></processdelight-entity-default-cells>
<processdelight-entity-default-filters></processdelight-entity-default-filters>
<mat-card class="h-100 d-flex flex-column list-background ql-editor scrollbar p-0">
  <mat-card-content class="p-0 h-100">
    <div class="w-100 h-100 d-flex flex-column position-relative min-height-0 min-width-0">
      <app-tasks-switcher class="tasksSwitcher" [control]="assignedToMe">
      </app-tasks-switcher>
      <div class="h-100 min-height-0 flex-grow-1 pt-2 text-center">
        <processdelight-entity-dashboard
          class="w-100 h-100"
          [data]="pagedEntities"
          [maxSizeItems]="totalRecordCount"
          [pageSize]="pageSize"
          [possibleColumns]="possibleColumns"
          [selectedColumns]="selectedColumns"
          (rowExpanded)="openDetailDialog($event)"
          [sortColumn]="orderBy"
          [sortDirection]="orderByDirection"
          (sortChange)="sortChanged($event)"
          (filterChange)="filterChanged($event)"
          (columnChange)="columnChanged($event)"
          [emptyTableText]="noItems"
          [itemTrackByFn]="itemTrackByFn"
        ></processdelight-entity-dashboard>
        <ng-template #noItems>
          <div class="w-100 h-100 d-flex justify-content-center align-items-center">
            <h1 *ngIf="!loading">
              {{ getTranslation$("noResults") | async }}
            </h1>
          </div>
        </ng-template>
      </div>
    </div>
  </mat-card-content>
</mat-card>
