<h3>Likes</h3>
<div *ngFor="let like of likes" class="like">
  <p class="m-0">{{ like | portalUser }}</p>
  <p class="date m-0">{{ like.createdOn?.toJSDate() | date : "dd/MM/yyyy" }}</p>
</div>
<div *ngIf="!likes || likes.length === 0">
  <p>No Likes yes</p>
</div>
<div style="margin-bottom: 50px"></div>

<button mat-fab class="closebtn" (click)="close()">
  <mat-icon>close</mat-icon>
</button>
