<div class="content">
  <ng-container *ngIf="userInfo$ | async as userinfo; else noAccess">
    <mat-card class="card" *ngIf="userinfo.clientUserRequests.length">
      <h3>Please wait for admin approval, you will be notified via email.</h3>
      <hr />
      <p>Reload the page to check if you have been approved.</p>
    </mat-card>
  </ng-container>

  <ng-template #noAccess>
    <mat-card class="card">
      <h3>You do not have access to this page.</h3>
      <hr />
      <p>Please contact an administrator for a signup link</p>
    </mat-card>
  </ng-template>
</div>
