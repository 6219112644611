import { Injectable } from '@angular/core';

export class InterestGroupWebpartUserSettings {
  selectedInterestGroupIds: string[] = [];

  constructor(data?: Partial<InterestGroupWebpartUserSettings>) {
    Object.assign(this, data);
  }
}

@Injectable({
  providedIn: 'root',
})
export class InterestGroupWebpartUserSettingsService {
  private static LOCAL_STORAGE_KEY_MODIFIER =
    'interest_group_webpart_user_settings_';

  settings: { [webpartId: string]: InterestGroupWebpartUserSettings } = {};

  private getLocalStorageKey(webpartId: string): string {
    return (
      InterestGroupWebpartUserSettingsService.LOCAL_STORAGE_KEY_MODIFIER +
      webpartId
    );
  }

  getSettings(webpartId: string): InterestGroupWebpartUserSettings {
    if (!this.settings[webpartId]) {
      const val = localStorage.getItem(this.getLocalStorageKey(webpartId));
      if (val)
        this.settings[webpartId] = new InterestGroupWebpartUserSettings(
          JSON.parse(val)
        );
      else this.settings[webpartId] = new InterestGroupWebpartUserSettings();
    }
    return this.settings[webpartId];
  }
  setSettings(webpartId: string, settings: InterestGroupWebpartUserSettings) {
    this.settings[webpartId] = settings;
    localStorage.setItem(
      this.getLocalStorageKey(webpartId),
      JSON.stringify(settings)
    );
  }
}
