import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractWebPartConfigComponent } from 'processdelight-angular-components';

@Component({
  selector: 'app-tasks-config',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './tasks-config.component.html',
  styleUrls: ['./tasks-config.component.scss'],
})
export class TasksConfigComponent extends AbstractWebPartConfigComponent {
}
