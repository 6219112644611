import { Lookup } from 'processdelight-angular-components';

export class MetadataParameter {
  id!: string;
  title!: string;
  type!: string;
  showGroups = true;
  showUsers = true;
  multi = false;
  format?: string;
  maxLength?: number;

  choices: MetadataParameterChoice[] = [];

  constructor(obj: Partial<MetadataParameter>) {
    Object.assign(this, obj);
  }
}

export class MetadataParameterChoice {
  id!: string;
  value!: string;
  metadataParameterId!: string;
  position?: number;
  translations?: Lookup[];

  constructor(obj: Partial<MetadataParameterChoice>) {
    Object.assign(this, obj);
  }
}
