import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_NATIVE_DATE_FORMATS,
  MatNativeDateModule,
  NativeDateAdapter,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import {
  ActionBarComponent,
  ContextMenuAction,
  DatePickerFormatDirective,
  MY_FORMATS,
} from 'processdelight-angular-components';
import { BehaviorSubject } from 'rxjs';
import { VerhofsteOffer } from '../domain/models/offer.model';
import { VerhofsteProduct } from '../domain/models/product.model';
import { VerhofsteService } from '../services/verhofste.service';
import {
  VerhofsteProductLineVM,
  VerhofsteProductTableComponent,
} from '../verhofste-product-table/verhofste-product-table.component';
import { DateTime } from 'luxon';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';

export interface DialogData {
  quotation: VerhofsteOffer;
  deliveryDateOffset: number;
  updateAmount: (item: VerhofsteProduct, amount: number) => void;
  webpartId: string;
  businessPartner: string;
}

@Component({
  standalone: true,
  selector: 'app-verhofste-quote-details-dialog',
  templateUrl: 'verhofste-quote-details-dialog.component.html',
  styleUrls: ['verhofste-quote-details-dialog.component.scss'],
  imports: [
    ActionBarComponent,
    CommonModule,
    FormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    VerhofsteProductTableComponent,
    DatePickerFormatDirective,
  ],
  providers: [
    { provide: DateAdapter, useClass: LuxonDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class VerhofsteQuoteDetailsDialogComponent implements AfterViewInit {
  filtersEnabled = false;
  data: VerhofsteProductLineVM[] = [];
  displayedColumns = [
    'ItemId',
    'ItemName',
    'PriceUnitPrice',
    'Order',
    'TotalPrice',
  ];
  possibleColumns: {
    [id: string]: {
      internalName: string;
      displayName: string;
      sortable: boolean;
      fixed: boolean;
      filterValue: string;
      valueAccessor: (item: VerhofsteProductLineVM) => any;
      sortValueAccessor: (item: VerhofsteProductLineVM) => string | number;
    };
  } = {
    ItemId: {
      internalName: 'ItemId',
      displayName: 'Item ID',
      sortable: true,
      fixed: false,
      filterValue: '',
      valueAccessor: (item: VerhofsteProductLineVM) => item.product.itemId,
      sortValueAccessor: (item: VerhofsteProductLineVM) => item.product.itemId,
    },
    ItemName: {
      internalName: 'ItemName',
      displayName: 'Name',
      sortable: true,
      fixed: false,
      filterValue: '',
      valueAccessor: (item: VerhofsteProductLineVM) => item.product.itemName,
      sortValueAccessor: (item: VerhofsteProductLineVM) =>
        item.product.itemName,
    },
    PriceUnitPrice: {
      internalName: 'PriceUnitPrice',
      displayName: 'Price',
      sortable: true,
      fixed: false,
      filterValue: '',
      valueAccessor: (item: VerhofsteProductLineVM) =>
        '€ ' + Math.round(Number(item.product.priceUnitPrice) * 100) / 100,
      sortValueAccessor: (item: VerhofsteProductLineVM) =>
        +item.product.priceUnitPrice,
    },
    TotalPrice: {
      internalName: 'TotalPrice',
      displayName: 'Total Price',
      sortable: true,
      fixed: false,
      filterValue: '',
      valueAccessor: (item: VerhofsteProductLineVM) =>
        '€ ' +
        Math.round(
          Number(item.product.priceUnitPrice) * (item?.amount ?? 0) * 100
        ) /
          100,
      sortValueAccessor: (item: VerhofsteProductLineVM) =>
        +item.product.priceUnitPrice * (item?.amount ?? 0),
    },
    Order: {
      internalName: 'Order',
      displayName: 'Amount',
      sortable: true,
      fixed: false,
      filterValue: '',
      valueAccessor: (item: VerhofsteProductLineVM) => item.amount,
      sortValueAccessor: (item: VerhofsteProductLineVM) => item.amount ?? 0,
    },
    RAL: {
      internalName: 'RAL',
      displayName: 'RAL',
      sortable: true,
      fixed: false,
      filterValue: '',
      valueAccessor: (item: VerhofsteProductLineVM) => item.ral,
      sortValueAccessor: (item: VerhofsteProductLineVM) => item.ral ?? '',
    },
  };

  amountTableDef = {
    internalName: 'Amount',
    displayName: 'Amount',
    sortable: true,
    fixed: false,
    filterValue: '',
    valueAccessor: (item: VerhofsteProductLineVM) => item.amount,
  };

  sortSettings = {
    active: 'ItemId',
    direction: 'asc',
  };

  navColor = '#124464';
  navContrast = '#fff';
  searchEnabled = false;
  sideBarButtonEnabled = false;
  title = 'Quotation';

  buttonActions: ContextMenuAction<unknown>[] = [];
  iconActions = [];

  earliestDeliveryDate: DateTime = DateTime.now();

  orderDisabled$ = new BehaviorSubject<boolean>(true);

  constructor(
    private verhofsteService: VerhofsteService,
    private _snackbar: MatSnackBar,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<VerhofsteQuoteDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public quoteDetailsData: DialogData
  ) {
    this.data = this.quoteDetailsData.quotation.offerLines.map(
      (l) =>
        new VerhofsteProductLineVM(
          new VerhofsteProduct({
            ...l.product,
            priceUnitPrice: l.price.toString(),
          }),
          l.order,
          l.ral
        )
    );
    if (this.data.some((item) => item.ral))
      this.displayedColumns = [
        'ItemId',
        'ItemName',
        'PriceUnitPrice',
        'Order',
        'RAL',
        'TotalPrice',
      ];
    this.title +=
      ' #' + (this.quoteDetailsData?.quotation.quoteNumber ?? 'details');
    this.earliestDeliveryDate = DateTime.now().plus({
      days: this.quoteDetailsData.deliveryDateOffset,
    });
    this.quoteDetailsData.quotation.deliveryDate = this.earliestDeliveryDate;
    this.validate();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.buttonActions = [
        new ContextMenuAction({
          id: 'order',
          label: 'Order',
          icon: 'check',
          action: () => this.onGenerateOrder(),
          disabled: this.orderDisabled$.asObservable(),
          index: 0,
        }),
        new ContextMenuAction({
          id: 'close',
          label: 'Cancel',
          icon: 'close',
          iconOutline: true,
          action: () => this.onClose(),
          index: 2,
        }),
      ];
    }, 0);
  }

  validate() {
    this.orderDisabled$.next(
      !this.quoteDetailsData.quotation.deliveryDate ||
        this.quoteDetailsData.quotation.validityDate.toJSDate() < new Date()
    );
  }

  getTotalPrice(): number {
    const total = this.data.reduce(
      (acc, item) => acc + +item.product.priceUnitPrice * (item?.amount ?? 0),
      0
    );
    return Math.round((total + Number.EPSILON) * 100) / 100;
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onNewQuotation(): void {
    // const dialogRef = this.dialog.open(VerhofsteQuoteSummaryDialogComponent, {
    //   maxWidth: '1100px',
    //   height: '90%',
    //   data: {
    //     clientReference: this.quoteDetailsData.quotation.agent.businessPartnerName,
    //     items: this.selectedItems.map(
    //       (item) =>
    //         new VerhofsteProductLineVM(
    //           new VerhofsteProduct(item.product),
    //           item.amount
    //         )
    //     ),
    //     updateAmount: (item: VerhofsteProductLineVM, amount: number) => {
    //       item.amount = amount;
    //     },
    //   },
    // });
    // dialogRef
    //   .afterClosed()
    //   .subscribe(
    //     (
    //       result:
    //         | { action: string; items: VerhofsteProductLineVM[] }
    //         | undefined
    //     ) => {
    //       if (result?.action === 'confirm') {
    //         const offerId = uuid.v4();
    //         const offer = new VerhofsteOffer({
    //           id: offerId,
    //           validityDate: this.getValidityDate(
    //             this.businessPartner!.businessPartnerId
    //           ),
    //           agentId: +this.businessPartner!.businessPartnerId,
    //           agent: this.businessPartner!,
    //           offerLines: result.items.map(
    //             (i: VerhofsteProductLineVM) =>
    //               new VerhofsteOfferLine({
    //                 id: uuid.v4(),
    //                 name: i.product.itemName,
    //                 price: +i.product.priceUnitPrice,
    //                 discount: i.product.priceUserDiscount
    //                   ? +i.product.priceUserDiscount
    //                   : 0,
    //                 productId: +i.product.itemId,
    //                 order: i.amount,
    //                 offer: { id: offerId, name: '' },
    //                 product: i.product,
    //               })
    //           ),
    //         });
    //         this.verhofsteService.createOffer(offer).subscribe(() => {
    //           this._snackBar.open('Quotation created', 'X', {
    //             duration: 3000,
    //           });
    //         });
    //         this.selectedItems = [];
    //         this.resetSelectedItems = true;
    //         setTimeout(() => {
    //           this.resetSelectedItems = false;
    //         }, 0);
    //       }
    //     }
    //   );
  }

  onGenerateOrder(): void {
    const offset = DateTime.now().offset;
    this.verhofsteService
      .updateOffer(
        new VerhofsteOffer({
          ...this.quoteDetailsData.quotation,
          orderDate: DateTime.now().toUTC(-offset),
          deliveryDate: this.quoteDetailsData.quotation.deliveryDate?.toUTC(
            -offset
          ),
        })
      )
      .subscribe(() => {
        this.verhofsteService
          .createOrderXml(
            this.quoteDetailsData.quotation.id,
            this.quoteDetailsData.webpartId,
            this.quoteDetailsData.businessPartner
          )
          .subscribe();
        this._snackbar.open('Order created', 'X', {
          duration: 3000,
        });
      });
    this.dialogRef.close();
  }
}
