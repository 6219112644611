import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BreadCrumbService,
  CMSPage,
  ContentPageComponent,
  SideBarAction,
  WebPartComponentMapper,
} from 'processdelight-angular-components';
import {
  Subject,
  combineLatestWith,
  first,
  map,
  takeUntil,
  takeWhile,
  withLatestFrom,
} from 'rxjs';
import { navColor$ } from 'src/app/app.observables';
import { CoreModule } from 'src/app/core/core.module';
import { isB2B } from 'src/app/core/extensions/b2x.extensions';
import { CanActivateHome } from 'src/app/core/guards/external-navigation.guard';
import { PortalActionsService } from 'src/app/core/services/portal-actions.service';
import { ClientFacade } from 'src/app/core/store/client/client.facade';
import { PageFacade } from 'src/app/core/store/page/page.facade';
import { RoleFacade } from 'src/app/core/store/role/role.facade';
import { UserFacade } from 'src/app/core/store/user/user.facade';
import { RefreshService } from 'src/app/webparts/communities-of-interest/services/refresh.service';
import { gridGap, webPartComponentMapper } from 'src/app/webparts/webpart.data';
import * as uuid from 'uuid';

@Component({
  selector: 'app-page',
  standalone: true,
  imports: [CoreModule, ContentPageComponent],
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  providers: [CanActivateHome],
})
export class PageComponent implements OnInit, OnDestroy {
  roles$ = this.roleFacade.roles$;
  webPartTypes$ = this.pageFacade.webPartTypes$;
  userRoles$ = isB2B()
    ? this.roles$
    : this.userFacade.externalUserInfo$.pipe(
        map((info) => info?.clientUserInfo.flatMap((cui) => cui.roles) ?? [])
      );

  webPartComponentMapper: WebPartComponentMapper[] = [];
  gridGap = gridGap;

  page?: CMSPage;
  private destroy$ = new Subject<void>();
  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly clientFacade: ClientFacade,
    private readonly roleFacade: RoleFacade,
    private readonly userFacade: UserFacade,
    private readonly pageFacade: PageFacade,
    private readonly portalActions: PortalActionsService,
    private readonly refreshService: RefreshService,
    private readonly breadCrumbService: BreadCrumbService
  ) {}

  ngOnInit(): void {
    this.refreshService.refreshPage$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.refreshPage();
      });

    this.route.data
      .pipe(first(), combineLatestWith(this.pageFacade.pages$))
      .subscribe(([data, pages]) => {
        this.page = data.page;
        const homePage = pages.find((p) => p.isHomePage);
        this.portalActions.sideBarActions = [
          new SideBarAction({
            icon: homePage?.icon ?? 'home',
            title: homePage?.title ?? 'Home',
            route: ['home'],
          }),
        ];
        this.page?.navigation.forEach((nav) => {
          const to = pages.find((p) => p.id === nav.toId);
          if (nav.title && to && to.title)
            this.portalActions.sideBarActions.push(
              new SideBarAction({
                icon: to.icon,
                title: to.title,
                route: ['home', to.title],
              })
            );
        });
        if (this.breadCrumbService.breadcrumbs.value.length == 0) {
          this.breadCrumbService.addBreadcrumb({
            id: uuid.v4(),
            label: homePage?.title ?? 'Home',
            action: () => this.router.navigate(['home']),
          });
        } else {
          const existingPage = this.breadCrumbService.breadcrumbs.value.find(
            (b) => b.label == this.page?.title
          );
          if (existingPage) {
            const index =
              this.breadCrumbService.breadcrumbs.value.indexOf(existingPage);
            this.breadCrumbService.breadcrumbs.next(
              this.breadCrumbService.breadcrumbs.value.slice(0, index + 1)
            );
          } else {
            this.breadCrumbService.addBreadcrumb({
              id: this.page?.id ?? uuid.v4(),
              label: this.page?.title ?? 'Page',
              action: () => this.router.navigate(['home', this.page?.title]),
            });
          }
        }
      });
    this.webPartTypes$
      .pipe(
        withLatestFrom(this.pageFacade.loadingWebPartTypes$),
        takeWhile(([_, loading]) => loading, true),
        map(([types]) => types)
      )
      .subscribe((types) => {
        this.webPartComponentMapper = webPartComponentMapper(types);
      });
  }

  refreshPage() {
    if (this.page) {
      const currentPage = this.page;
      this.page = undefined;
      setTimeout(() => {
        this.page = new CMSPage(currentPage);
      }, 0);
    }
  }

  ngOnDestroy(): void {
    this.portalActions.sideBarActions = [];

    this.destroy$.next();
    this.destroy$.complete();
  }
}
