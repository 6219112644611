import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import * as fromOrganization from './organization/organization.reducer';
import * as fromUser from './user/user.reducer';
import * as fromClient from './client/client.reducer';
import * as fromRole from './role/role.reducer';
import * as fromGroup from './group/group.reducer';
import * as fromPage from './page/page.reducer';
import * as fromApp from './app/app.reducer';
import * as fromInterestGroup from './interestGroup/interestGroup.reducer';
import * as fromFeed from './custom/feed/feed.reducer';

export const featureKey = 'portalFeature';

export interface CoreState {
  [fromUser.featureSlice]: fromUser.State;
  [fromOrganization.featureSlice]: fromOrganization.State;
  [fromClient.featureSlice]: fromClient.State;
  [fromRole.featureSlice]: fromRole.State;
  [fromGroup.featureSlice]: fromGroup.State;
  [fromPage.featureSlice]: fromPage.State;
  [fromApp.featureSlice]: fromApp.State;
  [fromInterestGroup.featureSlice]: fromInterestGroup.State;
}

export const reducers = new InjectionToken<ActionReducerMap<CoreState>>(
  featureKey,
  {
    factory: () => ({
      [fromUser.featureSlice]: fromUser.Reducer,
      [fromOrganization.featureSlice]: fromOrganization.Reducer,
      [fromClient.featureSlice]: fromClient.Reducer,
      [fromRole.featureSlice]: fromRole.Reducer,
      [fromGroup.featureSlice]: fromGroup.Reducer,
      [fromPage.featureSlice]: fromPage.Reducer,
      [fromApp.featureSlice]: fromApp.appReducer,
      [fromInterestGroup.featureSlice]: fromInterestGroup.InterestGroupReducer,
    }),
  }
);
