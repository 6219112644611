import { CRMEntity } from 'processdelight-angular-components';
import { ClientConfig } from './client-config.model';
import { ClientData } from './client-data.model';

export class ClientWithConfig {
  client!: CRMEntity;
  config?: ClientConfig;
  data?: ClientData[];

  constructor(obj: Partial<ClientWithConfig>) {
    if (obj?.client) this.client = new CRMEntity(obj.client);
    if (obj?.config) this.config = new ClientConfig(obj.config);
    if (obj?.data) this.data = obj.data.map((d) => new ClientData(d));
  }
}
