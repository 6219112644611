import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as microsoftTeams from '@microsoft/teams-js';
import { MicrosoftAuthenticationService } from '../../injectables/microsoft-authentication.service';

@Component({
  selector: 'app-sign-in-callback',
  templateUrl: './sign-in-callback.component.html',
})
export class SignInCallbackComponent implements OnInit {
  private adminConsent?: string;
  error?: string;
  errorDescription?: string;
  tenantId?: string;
  get hasErrors(): boolean {
    return !!this.error && !!this.errorDescription;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private readonly msalService: MicrosoftAuthenticationService
  ) {
    this.route.queryParams.subscribe((params: any) => {
      this.adminConsent = params['admin_consent'];
      this.error = params['error'];
      this.errorDescription = params['error_description'];
      this.tenantId = params['tenant'];
    });
  }

  //https://docs.microsoft.com/en-us/microsoftteams/platform/tabs/how-to/authentication/auth-tab-aad#the-callback-page
  ngOnInit() {
    this.msalService.signedIn.subscribe(() => this.complete());
    this.msalService.completeSignIn();
  }

  private complete(): void {
    if (this.msalService.runningOnTeams)
      microsoftTeams.authentication.notifySuccess();
    else if (
      window.opener &&
      window.opener !== window &&
      window.opener.location.origin !== window.location.origin
    ) {
      window.opener.postMessage('Consented', location.origin);
      window.close();
    } else this.router.navigate([]);
  }
}
