import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/core/store/app.reducer';

const groupState = (state: AppState) => state.coreFeature.group;
const loadingGroups = createSelector(
  groupState,
  (state) => state.loadingGroups
);
const getGroups = createSelector(groupState, (state) => state.groups);
const getGroupById = createSelector(
  getGroups,
  (groups) => (id: string) => groups.find((group) => group.id === id)
);
const getGroupsByClientId = createSelector(
  getGroups,
  (groups) => (clientId: string) =>
    groups.filter((group) => !group.clientId || group.clientId === clientId)
);
export const groupSelectors = {
  loadingGroups,
  getGroups,
  getGroupById,
  getGroupsByClientId,
};
