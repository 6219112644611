import { CRMEntity } from 'processdelight-angular-components';
import { PortalUserVM } from './portal-user.model';

export class ClientUserRequest {
  id!: string;
  clientId!: string;
  client!: CRMEntity;
  user!: PortalUserVM;
  userId!: string;
  requestStatus!: string;
  requestDate!: Date;

  constructor(obj?: Partial<ClientUserRequest>) {
    Object.assign(this, obj);
    if (obj?.client) this.client = new CRMEntity(obj.client);
    if (obj?.user) this.user = new PortalUserVM(obj.user);
  }
}
