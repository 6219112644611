import { AADUser, CRMEntity } from 'processdelight-angular-components';
import { ClientUserInfo } from './client-user-info.model';
import { ClientUserRequest } from './client-user-request.model';
import { IshtarOrganization } from './ishtar-organization.model';

export class ExternalUserInfo {
  user!: AADUser;
  clientUserInfo!: ClientUserInfo[];
  clientUserRequests!: ClientUserRequest[];
  linkedCRMEntityId?: string;
  portalUserId?: string;
  organization!: IshtarOrganization;

  constructor(obj: Partial<ExternalUserInfo>) {
    Object.assign(this, obj);
    if (obj?.user) this.user = new AADUser(obj.user);
    if (obj?.clientUserInfo)
      this.clientUserInfo = obj.clientUserInfo.map(
        (c) => new ClientUserInfo(c)
      );
    if (obj?.clientUserRequests)
      this.clientUserRequests = obj.clientUserRequests.map(
        (c) => new ClientUserRequest(c)
      );
    if (obj?.organization)
      this.organization = new IshtarOrganization(obj.organization);
  }
}
