import { BaseEntity, ResourceFunction } from 'processdelight-angular-components';

export class ResourceThing extends BaseEntity {
  name!: string;
  resourceFunctions?: ResourceFunction[] = [];

  constructor(obj: Partial<ResourceThing>) {
    super(obj);
    Object.assign(this, obj);
  }
}
