import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  Application,
  Attendee,
  DataObject,
  DataObjectService,
  EntityFormComponent,
  EntityObject,
  FormBuilderComponent,
  GroupUser,
  StateEntity,
  TranslationService,
} from 'processdelight-angular-components';
import {
  Observable,
  of,
} from 'rxjs';
import { Task } from '../../domain/models/task.model';
import { TaskService } from '../../services/task.service';
import { MicrosoftAuthenticationService } from 'src/app/msal/injectables/microsoft-authentication.service';
import { QuillEditorComponent, QuillModule } from 'ngx-quill';
import { MatIconModule } from '@angular/material/icon';
import { DateTime } from 'luxon';
import { log } from 'console';

@Component({
  standalone: true,
  selector: 'app-meeting-task-info',
  templateUrl: './meeting-task-info.component.html',
  styleUrls: ['./meeting-task-info.component.scss'],
  imports: [
    CommonModule,
    FormBuilderComponent,
    FormsModule,
    ReactiveFormsModule,
    QuillModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule
  ],
  providers: [
    DataObjectService,
  ],
})
export class MeetingTaskInfoComponent extends EntityFormComponent<Task> implements OnInit, AfterViewInit {
  nameChanger(name: string): void {
    throw new Error('Method not implemented.');
  }
  organizerId = '';
  hasMeeting = false;
  currentResponseSelection = '';

  taskService = inject(TaskService);
  translationService = inject(TranslationService);
  msal = inject(MicrosoftAuthenticationService);
  dataObjectService = inject(DataObjectService);

  translations$ = this.translationService.translations;

  override type = StateEntity.Task;
  override templates: {
    configurationName: string;
    template?: TemplateRef<{ config: DataObject }>;
    form?: FormControl;
  }[] = [];
  override getUsers(): Observable<GroupUser[]> {
    return of([]);
  }
  override getGroups(): Observable<GroupUser[]> {
    return of([]);
  }
  override getCurrentApplication(): Application {
    return new Application({});
  }

  override initForm(entityObject?: EntityObject<Task> | undefined): void {
    if (entityObject?.entity) this.initMeetingTaskForm(entityObject!.entity!);
    else this.initMeetingTaskForm(new Task({}));
  }
  override nameAccessor(entity: Task): string {
    return entity.title;
  }
  override initTemplates(): {
    configurationName: string;
    template?: TemplateRef<{ config: DataObject }>;
    form?: FormControl;
  }[] {
    return [
      this.getTemplate('TaskName', this.taskTitle),
      this.getTemplate('TaskNumber', this.taskNumber),
      this.getTemplate('TaskDescription', this.taskDescription),
      this.getTemplate('TaskType', this.taskType),
      this.getTemplate('TaskStartDate', this.taskStartTime),
      this.getTemplate('TaskEndDate', this.taskEndTime),
      this.getTemplate('TaskProject', this.taskProject),
      this.getTemplate('MeetingTaskLocation', this.taskLocation),
      this.getTemplate('MeetingTaskTeams', this.tasklinkTeamsMeeting),
      this.getTemplate('MeetingTaskAttendees', this.taskAttendees),
      this.getTemplate('MeetingLink', this.taskMeetingLink),
      this.getTemplate('MeetingTaskAnswer', this.taskMeetingAnswer),
    ];
  }

  @ViewChild('title') taskTitle!: TemplateRef<{
    config: DataObject;
  }>;
  @ViewChild('number') taskNumber!: TemplateRef<{
    config: DataObject;
  }>;
  @ViewChild('description') taskDescription!: TemplateRef<{
    config: DataObject;
  }>;
  @ViewChild('taskType') taskType!: TemplateRef<{ config: DataObject }>;
  @ViewChild('startTime') taskStartTime!: TemplateRef<{
    config: DataObject;
  }>;
  @ViewChild('endTime') taskEndTime!: TemplateRef<{
    config: DataObject;
  }>;
  @ViewChild('project') taskProject!: TemplateRef<{ config: DataObject }>;
  @ViewChild('location') taskLocation!: TemplateRef<{ config: DataObject }>;
  @ViewChild('linkTeamsMeeting') tasklinkTeamsMeeting!: TemplateRef<{
    config: DataObject;
  }>;
  @ViewChild('attendees') taskAttendees!: TemplateRef<{ config: DataObject }>;
  @ViewChild('meetingLink') taskMeetingLink!: TemplateRef<{
    config: DataObject;
  }>;
  @ViewChild('meetingAnswer') taskMeetingAnswer!: TemplateRef<{
    config: DataObject;
  }>;

  override buildBaseObject(form: FormGroup, id: string | undefined): Task {
    return new Task({
      ...this.data.entityObject?.entity
    });
  }

  currentUserId = '';

  override ngOnInit(): void {
    super.ngOnInit();
    this.checkHasMeeting();
    this.currentUserId = this.msal.id;
  }

  override ngAfterViewInit(): void {
    this.GetLinkedRecordsNotVisible();
    this.templates = this.initTemplates();
    this.fieldConfigurations = this.data?.entityObject?.dataObjects ?? [];
    this.objService.setOriginalData(
      this.fieldConfigurations,
      this.templates
    );
  }

  private checkHasMeeting(): void {
    const task = this.data.entityObject?.entity;
    this.organizerId = task?.createdById ?? '';

    if (this.organizerId) {
      this.taskService.getEventStatus(this.organizerId, task?.id)
        .subscribe((eventStatus) => {
          if (eventStatus.meetingLink) {
            this.entityFormGroup.get('meetingLink')?.setValue(eventStatus.meetingLink);
            this.entityFormGroup.get('linkTeamsMeeting')?.setValue('Yes');
          }

          const attendees = task?.attendees ?? [];
          this.hasMeeting = attendees.some((a) => a.userId == this.currentUserId);

          if (this.hasMeeting) {
            const response = eventStatus.attendeeStatusses
              ? eventStatus.attendeeStatusses.find((as) => as.userId == this.currentUserId)
              : undefined;    

            if (response) {
              const responseType = response.responseType.toLowerCase();   
              switch (responseType) {
                case 'declined':
                  this.currentResponseSelection = 'no';
                  break;
                case 'accepted':
                  this.currentResponseSelection = 'yes';
                  break;
                case 'tentativelyaccepted':
                  this.currentResponseSelection = 'maybe';
                  break;
                default:
                  this.currentResponseSelection = ''; // Optional default case if needed
              }
            }
          }
        });
    }
  }

  initMeetingTaskForm(task: Task): void {
    this.entityFormGroup = new FormGroup({
      id: new FormControl({
        value: task.id,
        disabled: true,
      }),
      title: new FormControl({
        value: task.title,
        disabled: true,
      }, Validators.required),
      number: new FormControl({
        value: task.number,
        disabled: true,
      }, [Validators.required, Validators.pattern('^[A-z0-9s.-]*$')]),
      description: new FormControl({
        value: task.description ?? '',
        disabled: true,
      }),
      taskType: new FormControl({
        value: task.type?.type ?? '',
        disabled: true,
      }, Validators.required),
      project: new FormControl({
        value: task.project
          ? `${task.project.projectId} ${task.project.projectName}`
          : '',
        disabled: true,
      }),
      startTime: new FormControl({
        value: task.startTime
          ? task.startTime.toFormat('HH:mm')
          : '',
        disabled: true,
      }),
      startDate: new FormControl({
        value: task.startTime
          ? task.startTime.toFormat('dd/MM/yyyy')
          : '',
        disabled: true,
      }),
      endTime: new FormControl({
        value: task.endTime
          ? task.endTime.toFormat('HH:mm')
          : '',
        disabled: true,
      }),
      endDate: new FormControl({
        value: task.endTime
          ? task.endTime.toFormat('dd/MM/yyyy')
          : '',
        disabled: true,
      }),
      duration: new FormControl({
        value: task.startTime && task.endTime
          ? this.getDuration(task.startTime, task.endTime)
          : '',
        disabled: true,
      }),
      linkTeamsMeeting: new FormControl({
        value: task.isTeamsMeeting 
          ? 'Yes'
          : 'No',
        disabled: true,
      }),
      location: new FormControl({
        value: task.location ?? '',
        disabled: true,
      }),
      attendees: new FormControl({
        value: this.getAttendees(task.attendees ?? []),
        disabled: true,
      }, Validators.required),
      meetingLink: new FormControl({
        value: task.meetingId ?? 'Meeting details will be here.',
        disabled: true,
      }),
    });
  }

  private getDuration(startDate: DateTime, endDate: DateTime): string {
    const diff = endDate.valueOf() - startDate.valueOf();
    const durationHrs = Math.floor(diff / 1000 / 60 / 60);
    const durationMins = Math.floor((diff / 1000 / 60) % 60);

    return `${('0' + durationHrs).slice(-2)}:${('0' + durationMins).slice(-2)}`;
  }

  private getAttendees(attendees: Attendee[]): string {
    const users: string[] = [];

    attendees.forEach((attendee) => {   
      if (attendee.externalUserMail) {
        users.push(attendee.externalUserMail);
      }

      if (attendee.user?.displayName) {
        users.push(attendee.user.displayName);
      }
    });

    return users.join(', ');
  }
}
