import {
  BaseEntity,
  GroupUser,
  Lookup,
} from 'processdelight-angular-components';
import { MetadataParam } from './metadata-param.model';
import { GroupUserEntity } from './group-user-entity.model';

export class Metadata extends BaseEntity {
  ishtarDMSMetadataId?: string;
  value?: string;
  numberValue?: number;
  dateTimeValue?: string;
  groupUserValue?: GroupUser;
  groupUserValueId?: string;
  groupUserValueEntity?: GroupUserEntity;
  itemId?: string;
  item?: Lookup;
  metadataParameterId?: string;
  metadataParameter?: MetadataParam;

  constructor(obj: Partial<Metadata>) {
    super(obj);
    Object.assign(this, obj);
    if (obj.groupUserValue)
      this.groupUserValue = new GroupUser(obj.groupUserValue);
  }
}
