import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/core/store/app.reducer';

const userState = (state: AppState) => state.portalFeature.user;
const coreState = (state: AppState) => state.portalFeature;
const getUserLicenseInfo = createSelector(
  userState,
  (state) => state.licenseInfo
);
const getExternalUserInfo = createSelector(
  userState,
  (state) => state.externalUserInfo
);
const getUserSettings = createSelector(userState, (state) => state.settings);
const getExternalUserRolesByClientId = createSelector(
  getExternalUserInfo,
  (externalUserInfo) => (clientId: string) =>
    externalUserInfo?.clientUserInfo?.find((c) => c.client?.id === clientId)
      ?.roles
);
const getExternalUserClientUser = createSelector(coreState, (state) =>
  state.user.externalUserInfo?.clientUserInfo.find(
    (i) => i.client.id == state.client.currentClient?.id
  )
);

export const userSelectors = {
  getUserLicenseInfo,
  getExternalUserInfo,
  getUserSettings,
  getExternalUserRolesByClientId,
  getExternalUserClientUser,
};
