import { ColumnType } from '../enums/column-type.enum';
import { MetadataChoice } from './metadata-choice.model';
import { MetadataConsolidatedChoice } from './metadata-consolidated-choice.model';
import { Lookup } from './lookup.model';
import { BaseEntity } from 'processdelight-angular-components';

export class MetadataParam extends BaseEntity {
  title!: string;
  tooltip?: string;
  type!: ColumnType;
  required = false;
  fileNameParam? = false;
  modifiedByParam? = false;
  modifiedOnParam? = false;
  createdOnParam? = false;
  createdByParam? = false;
  showGroups? = true;
  showUsers? = true;
  multi? = false;
  format?: string;
  maxLength?: number;
  consolidatedChoices: MetadataConsolidatedChoice[] = [];
  hasTranslations? = false;
  languages: Lookup[] = [];

  choices: MetadataChoice[] = [];

  constructor(obj: Partial<MetadataParam>) {
    super(obj);
    Object.assign(this, obj);
    if (obj.choices)
      this.choices = obj.choices
        .map((c) => new MetadataChoice(c))
        .sort((a, b) => a.position - b.position);
    if (typeof obj.type == 'string')
      this.type = ColumnType[obj.type as keyof typeof ColumnType];
  }
}
