import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, forkJoin, of, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { OrganizationFacade } from '../organization/organization.facade';
import {
  getExternalUserInfoResolved,
  getLicensesForUser,
  getLicensesForUserResolved,
  getUserSettings,
  resetSlice,
  updateUserLanguage,
  updateUserSettings,
  deletePortalClientUserGroup,
  deletePortalClientUserGroupResolved,
  createPortalClientUserGroup,
  createPortalClientUserGroupResolved,
} from './user.actions';
import { userSelectors } from './user.selectors';
import { AppState } from 'src/app/core/store/app.reducer';
import { UserSettings } from '../../domain/models/user-settings.model';
import { ExternalUserInfo } from '../../domain/models/external-user-info.model';
import { UserLicenseInfo } from '../../domain/models/user-license-info.model';
import { ClientFacade } from '../client/client.facade';
import { PortalClientUserGroup } from 'src/app/webparts/domain/models/portal-client-user-group.model';

@Injectable({
  providedIn: 'root',
})
export class UserFacade {
  userLicenseInfo$ = this._store.pipe(select(userSelectors.getUserLicenseInfo));
  externalUserInfo$ = this._store.pipe(
    select(userSelectors.getExternalUserInfo)
  );
  ExternalUserClientUser$ = this._store.pipe(
    select(userSelectors.getExternalUserClientUser)
  );

  isAdmin$ = combineLatest([
    this.userLicenseInfo$,
    this.externalUserInfo$,
  ]).pipe(
    switchMap(([licenseInfo, userInfo]) => {
      if (licenseInfo)
        return of(
          !!licenseInfo.licenses.find((l) => l.productName == 'Ishtar.Portal')
            ?.isAdmin
        );
      else if (userInfo)
        return this.clientFacade.currentClient$.pipe(
          switchMap((c) => {
            if (c)
              return this.getExternalUserRolesByClientId$(c.id!).pipe(
                map((roles) => roles?.some((r) => r.admin))
              );
            else return of(false);
          })
        );
      else return of(false);
    })
  );

  userSettings$ = this._store.pipe(select(userSelectors.getUserSettings));

  getExternalUserRolesByClientId$ = (clientId: string) =>
    this._store.pipe(
      select(userSelectors.getExternalUserRolesByClientId),
      map((fn) => fn(clientId))
    );

  constructor(
    private _store: Store<AppState>,
    private organizationFacade: OrganizationFacade,
    private clientFacade: ClientFacade
  ) {}

  getUserLicenses(email: string, callback?: () => void) {
    this._store.dispatch(getLicensesForUser({ email, callback }));
  }

  getUserLicenses$(email: string) {
    const subject = new Subject<void>();
    this._store.dispatch(
      getLicensesForUser({
        email,
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  getUserLicenseInfoResolved(licenseInfo: UserLicenseInfo) {
    this._store.dispatch(getLicensesForUserResolved({ licenseInfo }));
  }

  getExternalUserInfoResolved(externalUserInfo: ExternalUserInfo) {
    this._store.dispatch(getExternalUserInfoResolved({ externalUserInfo }));
  }

  updateUserLanguage(lang: string, callback?: () => void) {
    this._store.dispatch(updateUserLanguage({ lang, callback }));
  }

  updateUserLanguage$(lang: string) {
    const subject = new Subject<void>();
    this._store.dispatch(
      updateUserLanguage({
        lang,
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  getUserSettings$() {
    const subject = new Subject<void>();
    this._store.dispatch(
      getUserSettings({
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  updateUserSettings$(settings: UserSettings) {
    const subject = new Subject<void>();
    this._store.dispatch(
      updateUserSettings({
        settings,
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  deletePortalClientUserGroup$(portalClientUserGroup: PortalClientUserGroup) {
    const subject = new Subject<void>();
    this._store.dispatch(
      deletePortalClientUserGroup({
        portalClientUserGroup,
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  createPortalClientUserGroup$(portalClientUserGroup: PortalClientUserGroup) {
    const subject = new Subject<void>();
    this._store.dispatch(
      createPortalClientUserGroup({
        portalClientUserGroup,
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  resetSlice() {
    this._store.dispatch(resetSlice());
  }
}
