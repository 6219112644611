<mat-tab-group
  class="h-100"
  [mat-stretch-tabs]="false"
  [selectedIndex]="canAssignMetadata ? 1 : 0"
>
  <mat-tab
    disabled
    labelClass="p-0 customer-select-tab"
    *ngIf="canAssignMetadata"
  >
    <ng-template mat-tab-label>
      <mat-form-field class="no-subscript-wrapper" style="max-width: 300px">
        <mat-label>Customer</mat-label>
        <mat-select
          [(ngModel)]="businessPartner"
          (opened)="focusInput(customerFilter)"
          (closed)="customerFilter.value = ''"
          (valueChange)="onCustomerSelected($event)"
        >
          <div class="h-100 d-flex flex-column">
            <mat-form-field class="no-subscript-wrapper flex-shrink-0">
              <mat-label>Search</mat-label>
              <input matInput placeholder="Search" #customerFilter />
            </mat-form-field>
            <div class="h-100 overflow-auto scrollbar">
              <mat-option
                *ngFor="let customer of customerList"
                [value]="customer"
                [hidden]="
                  !customer.businessPartnerName
                    .toLowerCase()
                    .includes(customerFilter.value.toLowerCase())
                "
              >
                {{ customer.businessPartnerName }}
              </mat-option>
            </div>
          </div>
        </mat-select>
      </mat-form-field>
    </ng-template>
  </mat-tab>
  <mat-tab label="Price list" class="h-100">
    <app-verhofste-product-list-component
      [canAssignMetadata]="canAssignMetadata"
      [filtersEnabled]="filtersEnabled"
      [businessPartner]="businessPartner"
      [validityDateOffset]="validityDateOffset"
      [disabled]="disabled"
      [ralLabel]="ralLabel"
    ></app-verhofste-product-list-component>
  </mat-tab>
  <mat-tab label="Quotations" class="h-100">
    <app-verhofste-quotations-dashboard
      [deliveryDateOffset]="deliveryDateOffset"
      [businessPartner]="businessPartner"
      [webpartId]="id"
    ></app-verhofste-quotations-dashboard>
  </mat-tab>
  <mat-tab label="Orders">
    <app-verhofste-orders-dashboard></app-verhofste-orders-dashboard>
  </mat-tab>
</mat-tab-group>
