import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/core/store/app.reducer';

const organizationState = (state: AppState) => state.coreFeature.organization;
const getOrganizationInfo = createSelector(
  organizationState,
  (state) => state.organizationInfo
);
const getGroupUsers = createSelector(
  organizationState,
  (state) => state.groupUsers
);
const getPortalUsers = createSelector(
  organizationState,
  (state) => state.portalUsers
);
export const organizationSelectors = {
  getOrganizationInfo,
  getGroupUsers,
  getPortalUsers,
};
