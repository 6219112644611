<div class="h-100 d-flex flex-column list-background">
  <div class="d-flex flex-row mb-2 align-items-center">
    <div class="flex-grow-1"></div>
    <button
      [matMenuTriggerFor]="menu"
      mat-mini-fab
      class="btn"
      style="right: 0px"
      [style.background]="navColor"
      [style.color]="navContrast"
    >
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu" xPosition="before" class="scrollbar">
      <div
        class="pe-2"
        (click)="$event.stopPropagation()"
        *ngFor="
          let column of possibleColumns | keyvalue;
          trackBy: columnTrackByFn
        "
      >
        <mat-checkbox
          (change)="addOrRemoveColumn(column.value)"
          [checked]="displayedColumns.includes(column.value.internalName)"
        >
          {{ column.value.displayName }}</mat-checkbox
        >
      </div>
    </mat-menu>
  </div>

  <div class="h-100 mainTable scrollbar">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      multiTemplateDataRows
      (matSortChange)="sortData($event)"
      class="mat-elevation-z8"
      style="table-layout: fixed"
    >
      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <ng-container
        *ngFor="let column of displayedColumns; trackBy: stringTrackByFn"
        [matColumnDef]="column"
      >
        <ng-container [ngSwitch]="possibleColumns[column].internalName">
          <!-- Default -->
          <ng-container *ngSwitchDefault>
            <th
              mat-header-cell
              *matHeaderCellDef
              [mat-sort-header]="column"
              class="buttonTableHeader"
            >
              <div class="d-flex flex-column position-relative">
                <span class="colTitle">
                  {{ possibleColumns[column].displayName }}
                </span>
              </div>
            </th>

            <td mat-cell *matCellDef="let element">
              <div class="tableCell">
                {{ possibleColumns[column].valueAccessor(element) }}
              </div>
            </td>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions">
          &nbsp;
        </th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            aria-label="expand row"
            (click)="
              expandedElement = expandedElement === element ? null : element;
              $event.stopPropagation()
            "
          >
            <ng-container *ngIf="expandedElement === element; else elseBlock">
              <mat-icon>keyboard_arrow_up</mat-icon>
            </ng-container>
            <ng-template #elseBlock>
              <mat-icon>keyboard_arrow_down</mat-icon>
            </ng-template>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.colspan]="actualDisplayedColumns.length"
        >
          <div
            class="element-detail w-100"
            [@detailExpand]="
              element === expandedElement ? 'expanded' : 'collapsed'
            "
            *ngIf="element === expandedElement"
          >
            <div class="expanded-section w-100 pe-1">
              <div class="row">
                <div class="col-9 me-3">
                  <div
                    *ngFor="let offerLine of element.offerLines"
                    class="offer-line-row"
                  >
                    <div class="row product-details">
                      <div class="col">
                        <strong class="offer-line-title">Name: </strong>
                        <span class="offer-line-value">{{
                          offerLine.product.itemName
                        }}</span>
                      </div>
                      <div class="col">
                        <strong class="offer-line-title">Amount: </strong>
                        <span class="offer-line-value">{{
                          offerLine.order
                        }}</span>
                      </div>
                      <div class="col">
                        <ng-container *ngIf="offerLine.ral">
                          <strong class="offer-line-title">RAL: </strong>
                          <span class="offer-line-value">{{
                            offerLine.ral
                          }}</span>
                        </ng-container>
                      </div>
                      <div class="col">
                        <strong class="offer-line-title">Price: </strong>
                        <span class="offer-line-value"
                          >€
                          {{
                            offerLine.price - (offerLine.discount ?? 0)
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 total-price">
                  <strong class="offer-line-title">Total price: </strong> €
                  {{ getTotalPriceAll(element) }}
                </div>
              </div>
              <div class="row mt-3 justify-content-end">
                <button
                  mat-raised-button
                  color="primary"
                  class="col-2"
                  (click)="downloadQuote(element)"
                >
                  <mat-icon>file_download</mat-icon>
                  DOWNLOAD
                </button>
                <button
                  mat-raised-button
                  color="primary"
                  class="col-2 ms-2 me-2"
                  (click)="showQuoteDetails(element)"
                >
                  <mat-icon>info</mat-icon>
                  DETAILS
                </button>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="actualDisplayedColumns"
        class="position-sticky top-0"
      ></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: actualDisplayedColumns"
        class="element-row"
        [class.expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="detail-row"
        [ngStyle]="{ display: expandedElement ? 'initial' : 'none' }"
      ></tr>
    </table>
    <mat-paginator
      [length]="data.length"
      [pageSize]="10"
      [pageSizeOptions]="[3, 10, 25, 100]"
      [pageIndex]="pageSettings.pageIndex"
      (page)="pageData($event)"
      aria-label="Select page"
      class="position-sticky bottom-0"
      [style.z-index]="10"
    >
    </mat-paginator>
  </div>
</div>
