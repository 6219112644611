import { Lookup } from 'processdelight-angular-components';
import { VerhofsteProduct } from './product.model';

export class VerhofsteOfferLine {
  id!: string;
  name?: string;
  productId!: number;
  offerId!: string;
  order!: number;
  price!: number;
  discount!: number;
  ral?: string;

  product!: VerhofsteProduct;

  constructor(init?: Partial<VerhofsteOfferLine>) {
    Object.assign(this, init);
  }
}
