import { Injectable } from '@angular/core';
import { PortalService } from '../../services/portal.service';
import { BaseEffects } from '../base.effects';
import {
  acceptRequest,
  acceptRequestResolved,
  getClientUserRequests,
  getClientUserRequestsResolved,
  getClientUsers,
  getClientUsersResolved,
  getClients,
  getClientsResolved,
  newRequest,
  newRequestResolved,
  rejectRequest,
  rejectRequestResolved,
  updateUserConfiguration,
} from './client.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap, of, catchError, mergeMap, forkJoin } from 'rxjs';
import { addClientUserRequest } from '../user/user.actions';

@Injectable({ providedIn: 'root' })
export class ClientEffects extends BaseEffects {
  constructor(private portalService: PortalService, actions$: Actions) {
    super(actions$);
  }
  getClients = this.createDefaultEffect(
    getClients,
    () => this.portalService.getClients(),
    {
      valueMapper: (v) => ({
        clients: v.result,
      }),
      action: getClientsResolved,
    }
  );

  getClientUsers = this.createDefaultEffect(
    getClientUsers,
    ({ clientId }) => this.portalService.getClientUsers(clientId),
    {
      valueMapper: (v) => ({ clientUsers: v.result }),
      action: getClientUsersResolved,
    }
  );

  getClientUserRequests = this.createDefaultEffect(
    getClientUserRequests,
    ({ clientId }) => this.portalService.getClientUserRequests(clientId),
    {
      valueMapper: (v) => ({ requests: v.result }),
      action: getClientUserRequestsResolved,
    }
  );

  acceptRequest = this.createDefaultEffect(
    acceptRequest,
    ({ requestId }) => this.portalService.acceptRequest(requestId),
    {
      valueMapper: (v) => ({
        requestId: v.requestId,
        clientUserInfo: v.result,
      }),
      action: acceptRequestResolved,
    }
  );

  rejectRequest = this.createDefaultEffect(
    rejectRequest,
    ({ requestId }) => this.portalService.rejectRequest(requestId),
    {
      valueMapper: (v) => ({
        requestId: v.requestId,
        clientUserInfo: v.result,
      }),
      action: rejectRequestResolved,
    }
  );

  newRequest = createEffect(() =>
    this.actions$.pipe(
      ofType(newRequest),
      switchMap(({ client, callback, error }) =>
        this.portalService.newRequest(client).pipe(
          tap((request) => (callback ? callback(request) : undefined)),
          switchMap((request) =>
            of(addClientUserRequest({ request })).pipe(
              switchMap(() => of(addClientUserRequest({ request })))
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  updateUserConfiguration = this.createDefaultEffect(
    updateUserConfiguration,
    ({ userConfiguration }) =>
      this.portalService.updateUserConfiguration(userConfiguration),
    [
      {
        valueMapper: (v) => ({
          clients: v.result.clientConfigs,
        }),
        action: getClientsResolved,
      },
      {
        valueMapper: (v) => ({
          clientUsers: v.result.users,
        }),
        action: getClientUsersResolved,
      },
    ]
  );
}
