import { Component, Input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

@Component({
  selector: 'app-tasks-switcher',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, 
    ReactiveFormsModule,
    MatButtonToggleModule,
  ],
  templateUrl: './tasks-switcher.component.html',
  styleUrls: ['./tasks-switcher.component.scss'],
  animations: [
    trigger('selected', [
      state(
        'true',
        style({
          scale: '1',
        })
      ),
      state(
        'false',
        style({
          scale: '0',
        })
      ),
      transition('true => false', [animate('0.2s')]),
      transition('false => true', [animate('0.2s')]),
    ]),
  ],
})
export class TasksSwitcherComponent {
  @Input() control: FormControl = new FormControl();
}
