import { Routes } from '@angular/router';
import { InitialNavigationGuard } from './core/guards/initial-navigation.guard';
import { EmptyComponent } from './layout/empty/empty.component';
import { AppSigninComponent } from './msal/components/app-signin/app-signin.component';
import { AppSilentSigninComponent } from './msal/components/app-silent-signin/app-silent-signin.component';
import { SignInCallbackComponent } from './msal/components/sign-in-callback/sign-in-callback.component';
import { SignOutCallbackComponent } from './msal/components/sign-out-callback/sign-out-callback.component';
import { TeamsAuthenticationComponent } from './msal/components/teams-authentication/teams-authentication.component';
import { TeamsSignOutComponent } from './msal/components/teams-sign-out/teams-sign-out.component';
import { ClientSelectorComponent } from './layout/client-selector/client-selector.component';
import { WaitForApprovalComponent } from './layout/wait-for-approval/wait-for-approval.component';

export const appRoutes: Routes = [
  {
    path: 'admin/settings',
    loadComponent: () =>
      import('./admin/components/settings/settings.component'),
    loadChildren: () =>
      import('./admin/components/settings/settings.component').then(
        (settings) => settings.settingsRoutes
      ),
  },
];
export const specialroutes: Routes = [
  { path: 'client-select', component: ClientSelectorComponent },
  { path: 'wait-for-approval', component: WaitForApprovalComponent },
];
export const routes: Routes = [
  { path: 'app-signin', component: AppSigninComponent },
  { path: 'app-silent-signin', component: AppSilentSigninComponent },
  {
    path: 'signin',
    component: SignInCallbackComponent,
    data: { breadcrumbTitle: 'Sign in' },
  },
  {
    path: 'silent-signin',
    component: EmptyComponent,
  },
  {
    path: 'silent-signin-callback',
    component: EmptyComponent,
  },
  { path: 'signout', component: SignOutCallbackComponent },
  { path: 'teams-authentication', component: TeamsAuthenticationComponent },
  { path: 'teams-sign-out', component: TeamsSignOutComponent },
  // { path: 'acceptinvite', component: AcceptInviteComponent },
  // { path: 'redirect', component: RedirectComponent },
  // { path: 'privacy', component: PrivacyComponent },
  // { path: 'support', component: SupportComponent }
  {
    path: '',
    component: EmptyComponent,
    canActivate: [InitialNavigationGuard],
  },
  { path: '', redirectTo: '', pathMatch: 'full' },
  { path: '**', redirectTo: '' },
];
