import { Injectable } from '@angular/core';
import { DMSWebpartUserSettings } from '../domain/models/dms-webpart-user-settings.model';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  private static LOCAL_STORAGE_KEY_MODIFIER = 'dms_webpart_user_settings_';

  settings: { [webpartId: string]: DMSWebpartUserSettings } = {};

  private getLocalStorageKey(webpartId: string): string {
    return UserSettingsService.LOCAL_STORAGE_KEY_MODIFIER + webpartId;
  }

  getSettings(webpartId: string): DMSWebpartUserSettings {
    if (!this.settings[webpartId]) {
      const val = localStorage.getItem(this.getLocalStorageKey(webpartId));
      if (val)
        this.settings[webpartId] = new DMSWebpartUserSettings(JSON.parse(val));
      else this.settings[webpartId] = new DMSWebpartUserSettings();
    }
    return this.settings[webpartId];
  }
  setSettings(webpartId: string, settings: DMSWebpartUserSettings) {
    this.settings[webpartId] = settings;
    localStorage.setItem(
      this.getLocalStorageKey(webpartId),
      JSON.stringify(settings)
    );
  }
}
