export class IshtarOrganization {
  organizationId!: number;
  tenantId!: string;
  name!: string;
  joined?: Date;
  dataverseEnvironmentUrl?: string;
  sharepointUrl?: string;
  adminConsented?: Date;
  logo?: string;
  navColor?: string;
  navContrast?: string;
  domain?: string;

  constructor(obj: Partial<IshtarOrganization>) {
    Object.assign(this, obj);
  }
}
