import { createSelector } from '@ngrx/store';
import { MetadataParam } from 'src/app/webparts/dms-component/domain/models/metadata-param.model';
import { AppState } from '../../app.reducer';

const feedState = (state: AppState) => state.customFeature.feed;
const getFeed = createSelector(feedState, (state) => state.posts);
const getLoadedAllPosts = createSelector(
  feedState,
  (state) => state.loadedAllPosts
);

export const feedSelectors = {
  getFeed,
  hasLoadedAllPosts: getLoadedAllPosts,
};
