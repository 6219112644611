import {
  AbstractWebPartComponent,
  HtmlWebpartComponent,
  WebPartComponentMapper,
  WebPartType,
} from 'processdelight-angular-components';
import { VerhofsteOrderProductsTabsComponent } from './verhofste-offer/verhofste-order-products-tabs.component';
import { CommunitiesOfInterestComponent } from './communities-of-interest/communities-of-interest.component';
import { DmsComponent } from './dms-component/dms.component';
import { FeedComponent } from './CMS/feed/feed.component';
import { TaskCalendarComponent } from './calendar/task-calendar.component';

export const gridGap = 8;
interface CompWithType {
  type: string;
  component: new (...params: any[]) => AbstractWebPartComponent;
}
const webPartComponents: CompWithType[] = [
  {
    type: HtmlWebpartComponent.TYPE,
    component: HtmlWebpartComponent,
  },
  {
    type: VerhofsteOrderProductsTabsComponent.TYPE,
    component: VerhofsteOrderProductsTabsComponent,
  },
  {
    type: CommunitiesOfInterestComponent.TYPE,
    component: CommunitiesOfInterestComponent,
  },
  {
    type: DmsComponent.TYPE,
    component: DmsComponent,
  },
  {
    type: FeedComponent.TYPE,
    component: FeedComponent,
  },
  {
    type: TaskCalendarComponent.TYPE,
    component: TaskCalendarComponent,
  },
];
export const webPartComponentMapper: (
  types: WebPartType[]
) => WebPartComponentMapper[] = (types) => {
  const webPartComponentMapper: WebPartComponentMapper[] = [];
  webPartComponents.forEach((comp) => {
    const type = types.find((t) => t.name === comp.type);
    if (type) {
      webPartComponentMapper.push({
        type,
        component: comp.component,
      });
    }
  });
  return webPartComponentMapper;
};
