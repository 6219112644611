import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import {
  ActionBarComponent,
  ContextMenuAction,
} from 'processdelight-angular-components';
import {
  VerhofsteProductLineVM,
  VerhofsteProductTableComponent,
} from '../verhofste-product-table/verhofste-product-table.component';
import { BehaviorSubject } from 'rxjs';
import { Memoize } from 'typescript-memoize';

export interface DialogData {
  quotationId: string;
  clientReference: string;
  ralLabel?: string;
  items: VerhofsteProductLineVM[];
  updateAmount: (item: VerhofsteProductLineVM, amount: number) => void;
}

@Component({
  standalone: true,
  selector: 'app-verhofste-quote-summary-dialog',
  templateUrl: 'verhofste-quote-summary-dialog.component.html',
  styleUrls: ['verhofste-quote-summary-dialog.component.scss'],
  imports: [
    ActionBarComponent,
    CommonModule,
    FormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatButtonModule,
    VerhofsteProductTableComponent,
  ],
})
export class VerhofsteQuoteSummaryDialogComponent implements AfterViewInit {
  filtersEnabled = false;
  ralLabel?: string;
  data: VerhofsteProductLineVM[] = [];
  displayedColumns = ['ItemId', 'ItemName', 'PriceUnitPrice', 'TotalPrice'];
  possibleColumns: {
    [id: string]: {
      internalName: string;
      displayName: string;
      sortable: boolean;
      fixed: boolean;
      filterValue: string;
      valueAccessor: (item: VerhofsteProductLineVM) => any;
      sortValueAccessor: (item: VerhofsteProductLineVM) => string | number;
    };
  } = {
    ItemId: {
      internalName: 'ItemId',
      displayName: 'Item ID',
      sortable: true,
      fixed: false,
      filterValue: '',
      valueAccessor: (item: VerhofsteProductLineVM) => item.product.itemId,
      sortValueAccessor: (item: VerhofsteProductLineVM) => item.product.itemId,
    },
    ItemName: {
      internalName: 'ItemName',
      displayName: 'Name',
      sortable: true,
      fixed: false,
      filterValue: '',
      valueAccessor: (item: VerhofsteProductLineVM) => item.product.itemName,
      sortValueAccessor: (item: VerhofsteProductLineVM) =>
        item.product.itemName,
    },
    PriceUnitPrice: {
      internalName: 'PriceUnitPrice',
      displayName: 'Price',
      sortable: true,
      fixed: false,
      filterValue: '',
      valueAccessor: (item: VerhofsteProductLineVM) =>
        '€ ' + Math.round(+item.product.priceUnitPrice * 100) / 100,
      sortValueAccessor: (item: VerhofsteProductLineVM) =>
        +item.product.priceUnitPrice,
    },
    TotalPrice: {
      internalName: 'TotalPrice',
      displayName: 'Total Price',
      sortable: true,
      fixed: false,
      filterValue: '',
      valueAccessor: (item: VerhofsteProductLineVM) =>
        '€ ' +
        Math.round(
          Number(item.product.priceUnitPrice) * (item?.amount ?? 0) * 100
        ) /
          100,
      sortValueAccessor: (item: VerhofsteProductLineVM) =>
        +item.product.priceUnitPrice * (item?.amount ?? 0),
    },
  };

  amountTableDef = {
    internalName: 'Amount',
    displayName: 'Amount',
    sortable: true,
    fixed: false,
    filterValue: '',
    valueAccessor: (item: VerhofsteProductLineVM) => item.amount,
    sortValueAccessor: (item: VerhofsteProductLineVM) => item.amount ?? 0,
  };
  ralTableDef = {
    internalName: 'RAL',
    displayName: 'RAL',
    sortable: true,
    fixed: false,
    filterValue: '',
    valueAccessor: (item: VerhofsteProductLineVM) => item.ral,
    sortValueAccessor: (item: VerhofsteProductLineVM) => item.ral ?? '',
  };

  sortSettings = {
    active: 'ItemId',
    direction: 'asc',
  };

  navColor = '#124464';
  navContrast = '#fff';
  searchEnabled = false;
  sideBarButtonEnabled = false;
  title = 'Quotation';

  confirmDisabled$ = new BehaviorSubject<boolean>(true);

  buttonActions: ContextMenuAction<unknown>[] = [];
  iconActions = [];

  description = '';

  actualDisplayedColumns = [
    'ItemId',
    'ItemName',
    'PriceUnitPrice',
    'ItemAmount',
    'TotalPrice',
  ];

  private wholeNumberRegex = new RegExp('^[0-9]*$');

  constructor(
    public dialogRef: MatDialogRef<VerhofsteQuoteSummaryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public quoteSummaryData: DialogData
  ) {
    this.data = this.quoteSummaryData.items;
    this.ralLabel = this.quoteSummaryData.ralLabel;
    this.title += ' ' + (this.quoteSummaryData?.quotationId ?? 'summary');
    if (this.data.some((item) => item.ral)) {
      this.actualDisplayedColumns = [
        'ItemId',
        'ItemName',
        'PriceUnitPrice',
        'ItemAmount',
        'RAL',
        'TotalPrice',
      ];
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.validate();
      this.buttonActions = [
        new ContextMenuAction({
          id: 'confirm',
          label: 'Confirm',
          icon: 'check',
          iconOutline: true,
          action: () => this.onConfirm(),
          index: 0,
          disabled: this.confirmDisabled$,
        }),
        new ContextMenuAction({
          id: 'close',
          label: 'Cancel',
          icon: 'close',
          iconOutline: true,
          action: () => this.onClose(),
          index: 1,
        }),
      ];
    }, 0);
  }

  getTotalPrice(): number {
    const total = this.data.reduce(
      (acc, item) => acc + +item.product.priceUnitPrice * (item?.amount ?? 0),
      0
    );
    return Math.round((total + Number.EPSILON) * 100) / 100;
  }

  @Memoize({
    tags: ['ral'],
    hashFunction: (item: VerhofsteProductLineVM) => item.product.itemId,
  })
  needsRal(item: VerhofsteProductLineVM): boolean {
    return (
      !!this.ralLabel &&
      item.product.itemName.toLowerCase().includes(this.ralLabel.toLowerCase())
    );
  }

  validate() {
    this.confirmDisabled$.next(
      this.data.reduce((acc, item) => acc + (item.amount ?? 0), 0) === 0 ||
        this.data.some(
          (item) =>
            item.amount === undefined ||
            item.amount < 0 ||
            !this.wholeNumberRegex.test(item.amount?.toString()) ||
            (this.needsRal(item) && !item.ral)
        )
    );
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.dialogRef.close({
      action: 'confirm',
      items: this.quoteSummaryData.items,
      description: this.description,
    });
  }
}
