import { Injectable } from '@angular/core';
import {
  Notification,
  NotificationService,
  NotificationType,
} from 'processdelight-angular-components';
import {
  buffer,
  debounceTime,
  delay,
  filter,
  first,
  of,
  Subject,
  tap,
} from 'rxjs';
import { SendNotification } from '../domain/models/send-notification.model';
import { PortalService } from './portal.service';
import { UserFacade } from '../store/user/user.facade';

@Injectable({
  providedIn: 'root',
})
export class PortalNotificationsService {
  constructor(
    private notifications: NotificationService,
    private portal: PortalService,
    private userFac: UserFacade
  ) {}

  notificationClose = new Subject<string>();
  private readonly pollingDelay = 30000;
  failedAttempts = 0;

  Init(): void {
    this.userFac.externalUserInfo$
      .pipe(
        filter((u) => !!u),
        first()
      )
      .subscribe((e) => {
        this.GetNotifications(e!.portalUserId!);
      });

    this.notificationClose
      .pipe(buffer(this.notificationClose.pipe(debounceTime(500))))
      .subscribe((ids) => {
        this.portal.markNotificationsAsRead(ids).subscribe(() => {
          ids.forEach((id) => {
            const notif = this.notifications.notifications.find(
              (n) => n.id === id
            );
            if (notif) this.notifications.removeNotification(notif);
          });
        });
      });
  }

  GetNotifications(portalUserId: string) {
    this.portal
      .getNotifications(portalUserId)
      .pipe(
        tap(
          (notifications) => {
            notifications
              .filter(
                (n) =>
                  !this.notifications.notifications.some((en) => en.id === n.id)
              )
              .forEach((notification) => {
                this.notifications.addNotification(
                  this.mapSendNotification(notification)
                );
              });
          },
          (err) => {
            if (this.failedAttempts >= 4) {
              console.error(
                'Attempted 5 times to get notifications, but failed.'
              );
              return;
            }
            return of(true)
              .pipe(delay(this.pollingDelay))
              .subscribe(() => {
                this.failedAttempts++;
                this.GetNotifications(portalUserId);
              });
          }
        ),
        delay(this.pollingDelay)
      )
      .subscribe(() => {
        this.GetNotifications(portalUserId);
        this.failedAttempts = 0;
      });
  }

  mapSendNotification(notification: SendNotification) {
    return new Notification({
      id: notification.id,
      label: notification.title,
      message: notification.content,
      type: NotificationType.Info,
      closeAction: () => this.notificationClose.next(notification.id),
      // clickAction: () => TODO ADD CLICK ACTION
    });
  }
}
