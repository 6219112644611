<div class="h-100 w-100">
  <processdelight-content-page
    *ngIf="page"
    [webPartComponentMapper]="webPartComponentMapper"
    [types]="(webPartTypes$ | async) ?? []"
    [roles]="(roles$ | async) ?? []"
    [userRoles]="(userRoles$ | async) ?? []"
    [gridGap]="gridGap"
    [page]="page"
  >
  </processdelight-content-page>
</div>
