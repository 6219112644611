import { Injectable } from '@angular/core';
import { MicrosoftAuthenticationService } from 'src/app/msal/injectables/microsoft-authentication.service';
import { environment } from 'src/environments/environment';
import { OrganizationFacade } from '../store/organization/organization.facade';
import { first } from 'rxjs';
import { UserFacade } from '../store/user/user.facade';

@Injectable({
  providedIn: 'root',
})
export class SignupLinkService {
  constructor(
    public msal: MicrosoftAuthenticationService,
    private userFacade: UserFacade
  ) {}

  public getSignupLink(clientId: string) {
    let domain: string | undefined = undefined;
    this.userFacade.userLicenseInfo$.pipe(first()).subscribe((o) => {
      domain = o?.domain;
    });
    if (domain) domain = domain + '.';
    return `https://${
      domain + environment.ishtar365.substring(8)
    }/organization-select/${clientId}?tenantId=${this.msal.tenantId}`;
  }
}
