import { Action, createReducer, on } from '@ngrx/store';
import { getMetadataParamsResolved, resetSlice } from './metadata.actions';
import { MetadataParam } from 'src/app/webparts/dms-component/domain/models/metadata-param.model';

export const featureSlice = 'metadata';

export interface State {
  metadata: MetadataParam[];
}

const defaultState: State = {
  metadata: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return metadataReducer(state, action);
}

export const initialState: State = defaultState;
export const metadataReducer = createReducer(
  initialState,
  on(getMetadataParamsResolved, (state, { metadataParams }) => ({
    ...state,
    metadata: metadataParams,
  })),
  on(resetSlice, () => initialState)
);
