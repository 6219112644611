<div class="group-popup h-100 w-100">
  <div class="group-header">
    <div
      class="grid-header d-flex justify-content-center"
      *ngIf="getTranslation$('filterGroups') | async as filterGroupsLabel"
    >
      {{ filterGroupsLabel }}
    </div>
    <div
      class="align-items-center d-flex justify-content-between p-1 pt-4 w-100"
    >
      <div>
        <button
          *ngIf="getTranslation$('save') | async as saveLabel"
          mat-stroked-button
          class="addButton me-3"
          type="button"
          (click)="saveFilters()"
        >
          {{ saveLabel }}
        </button>
      </div>
      <mat-icon (click)="filterDialogRef.close()" class="closeButtonIcon"
        >close</mat-icon
      >
    </div>
  </div>
  <div class="scrollbar w-100 h-100 overflow-auto min-height-0">
    <mat-form-field
      class="search_input_wrapper"
      appearance="outline"
      floatLabel="always"
    >
      <mat-label>
        {{ getTranslation$("search") | async }}
        {{ getTranslation$("groups") | async }}
      </mat-label>
      <input
        matInput
        (keydown)="$event.stopPropagation()"
        (input)="filterGroups($event)"
      />
    </mat-form-field>
    <mat-tree
      [dataSource]="dataSource"
      [treeControl]="treeControl"
      class="example-tree w-100 h-100"
    >
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        <div class="d-flex flex-row align-items-center w-100 h-100">
          <mat-checkbox
            (change)="checkBoxChange($event, node)"
            [checked]="node.isSelected"
          >
          </mat-checkbox>
          <mat-card
            [style.borderColor]="node.color"
            class="position-relative w-100 d-flex flex-column justify-content-center"
          >
            <div
              class="position-absolute start-0 top-0 h-100"
              style="width: 5px"
              [style.backgroundColor]="node.color"
            ></div>
            <div class="d-flex align-items-center w-100">
              <ng-container *ngIf="node.icon">
                <img
                  class="list-icon m-2"
                  [src]="transform(node.icon)"
                  alt="Interest group icon"
                />
              </ng-container>
              <div class="d-flex flex-column ps-3">
                <div class="bold">{{ node.title }}</div>
                <div class="italic">{{ node.description }}</div>
              </div>
            </div>
          </mat-card>
        </div>
      </mat-tree-node>
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <div class="mat-tree-node">
          <div class="d-flex flex-row align-items-center w-100 h-100">
            <button
              mat-icon-button
              matTreeNodeToggle
              [attr.aria-label]="'Toggle ' + node.name"
            >
              <mat-icon class="mat-icon-rtl-mirror">
                {{
                  treeControl.isExpanded(node) ? "expand_more" : "chevron_right"
                }}
              </mat-icon>
            </button>
            <mat-checkbox
              (change)="checkBoxChange($event, node)"
              [checked]="node.isSelected"
            >
            </mat-checkbox>
            <mat-card
              [style.borderColor]="node.color"
              class="position-relative w-100 d-flex flex-column justify-content-center"
            >
              <div
                class="position-absolute start-0 top-0 h-100"
                style="width: 5px"
                [style.backgroundColor]="node.color"
              ></div>
              <div class="d-flex align-items-center w-100">
                <ng-container *ngIf="node.icon">
                  <img
                    class="list-icon m-2"
                    [src]="transform(node.icon)"
                    alt="Interest group icon"
                  />
                </ng-container>
                <div class="d-flex flex-column ps-3">
                  <div class="bold">{{ node.title }}</div>
                  <div class="italic">{{ node.description }}</div>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
        <div
          [class.example-tree-invisible]="!treeControl.isExpanded(node)"
          role="group"
        >
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>
    </mat-tree>
  </div>
</div>
