import { Injectable } from '@angular/core';
import { select } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { BaseFacade } from '../base.facade';
import {
  getLibraries,
  getLibrary,
  getLibraryItems,
  getSharedItems,
  resetSlice,
} from './library.actions';
import { librarySelectors } from './library.selectors';

@Injectable({
  providedIn: 'root',
})
export class LibraryFacade extends BaseFacade {
  libraryItems$ = this._store.pipe(select(librarySelectors.getLibraryItems));
  sharedItems$ = this._store.pipe(select(librarySelectors.getSharedItems));
  libraries$ = this._store.pipe(select(librarySelectors.getLibraries));

  libraryById$ = (id: string) =>
    this._store.pipe(
      select(librarySelectors.getLibraryById),
      map((selector) => selector(id))
    );

  getLibraries$() {
    return this.dispatchAction$(getLibraries);
  }
  getLibrary$(id: string) {
    return this.dispatchAction$(getLibrary, { id });
  }

  getSharedItems$(
    orderBy: string | undefined,
    orderByDirection: 'asc' | 'desc' | undefined,
    filters: { [key: string]: string },
    pageSize: number,
    page: number
  ) {
    return this.dispatchAction$(getSharedItems, {
      orderBy,
      orderByDirection,
      filters,
      pageSize,
      page,
    });
  }
  getLibraryItems$(
    orderBy: string | undefined,
    orderByDirection: 'asc' | 'desc' | undefined,
    filters: { [key: string]: string },
    pageSize: number,
    page: number,
    filterActive: boolean,
    libraryId: string | undefined = undefined,
    filteredInterestGroupIds: string[] | undefined = undefined,
    reset$: (() => Observable<void>) | undefined = undefined
  ) {
    return this.dispatchAction$(getLibraryItems, {
      orderBy,
      orderByDirection,
      filters,
      pageSize,
      page,
      filterActive,
      libraryId,
      filteredInterestGroupIds,
      reset$,
    });
  }

  resetSlice() {
    this._store.dispatch(resetSlice());
  }
}
