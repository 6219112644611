import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/core/store/app.reducer';

const clientState = (state: AppState) => state.portalFeature.client;
const loadingClients = createSelector(
  clientState,
  (state) => state.loadingClients
);
const loadingClientUsers = createSelector(
  clientState,
  (state) => state.loadingClientUsers
);
const getClients = createSelector(clientState, (state) => state.clients);
const getClientUsers = createSelector(
  clientState,
  (state) => state.clientUsers
);
const getClientUserRequests = createSelector(
  clientState,
  (state) => state.requests
);
const getClientById = createSelector(
  getClients,
  (clients) => (id: string) => clients.find((client) => client.client.id === id)
);
const getClientUsersByClientId = createSelector(
  getClientUsers,
  (users) => (clientId: string) =>
    users.filter((user) => user.client?.id === clientId)
);
const getClientUserRequestsByClientId = createSelector(
  getClientUserRequests,
  (requests) => (clientId: string) =>
    requests.filter((request) => request.client?.id === clientId)
);
const getCurrentClient = createSelector(
  clientState,
  (state) => state.currentClient
);
const getCurrentClientWithConfig = createSelector(clientState, (state) =>
  state.clients.find((c) => c.client.id === state.currentClient?.id)
);
export const clientSelectors = {
  loadingClients,
  loadingClientUsers,
  getClients,
  getClientUsers,
  getClientUserRequests,
  getClientById,
  getClientUsersByClientId,
  getClientUserRequestsByClientId,
  getCurrentClient,
  getCurrentClientWithConfig,
};
