import { Application, GroupUser } from 'processdelight-angular-components';
import { GroupUserEntity } from 'src/app/webparts/dms-component/domain/models/group-user-entity.model';

export class SendNotification {
  id!: string;
  tenantId?: string;

  groupUserId?: string;
  portalUserId?: string;
  email?: string;

  title!: string;
  content!: string;

  navigationKey!: string;
  navigationProperties!: { [key: string]: string };
  applicationId!: string;

  sendDate?: Date;
  scheduledSendDate?: Date;
  dismissedDate?: Date;

  groupUser?: GroupUserEntity;
  portalUser?: any; // not important for i365
  application!: Application;

  constructor(obj?: Partial<SendNotification>) {
    Object.assign(this, obj);
  }
}
