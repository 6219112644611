import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LibraryIconService {
  private libraryIconMapper: { [key: string]: string } = {
    docx: environment.ishtar_word_icon,
    pptx: environment.ishtar_powerpoint_icon,
    'xlsx;csv;xls;xlsm': environment.ishtar_excel_icon,
    pdf: environment.ishtar_pdf_icon,
    'png;jpeg;jpg;tiff': environment.ishtar_picture_icon,
    'mp4;3gp;ogg;wmv;webm;flv;avi;hdv;wav;mov': environment.ishtar_movie_icon,
    'msg;eml': environment.ishtar_email_icon,
  };

  provideIcon(fileName: string) {
    if (!fileName) return;

    const extension = fileName.split('.').slice(-1)[0];
    const iconCase = Object.keys(this.libraryIconMapper).find(
      (c) => c.toLowerCase().indexOf(extension.toLowerCase()) != -1
    );
    if (!iconCase) return environment.ishtar_default_icon;
    const icon = this.libraryIconMapper[iconCase];
    if (icon == null) return environment.ishtar_default_icon;

    return icon;
  }
}
