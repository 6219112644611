import {
    Entity,
    GroupUserEntity,
    RegistrationType,
    Priority,
    TaskType,
    Log,
    Checklist,
    Dependency,
    DependencyValue,
    ApprovalType,
    DMSItem,
    ApprovalFlow,
    Project,
    Status,
    Skill,
    Attendee,
} from "processdelight-angular-components";
import { DateTime } from "luxon";
import { Resource } from "./resource.model";

export class Task extends Entity {
    title!: string;
    number?: string;
    description?: string;
    startTime?: DateTime;
    endTime?: DateTime;
    deadline?: DateTime;
    actualStartTime?: DateTime;
    actualEndTime?: DateTime;
    progress?: number;
    progressRegistrationTypeId?: string;
    progressRegistrationType?: RegistrationType;
    parentTaskId?: string;
    parentTask?: Task;
    projectId?: string;
    project?: Project;
    statusId?: string;
    status?: Status;
    priorityId?: string;
    priority?: Priority;
    skillId?: string;
    skill?: Skill;
    typeId?: string;
    type?: TaskType;
    isTaskTemplate!: boolean;
    subTasks?: Task[];
    logs?: Log[];
    checklists?: Checklist[];
    dependencies?: Dependency[];
    dependencyValues?: DependencyValue[];
    owners?: GroupUserEntity[];
    estimatedTime?: number;
    completedTime?: number;
    isDeleted!: boolean;
    closeTaskWhenSubtasksDone?: boolean;
    approvalTypeId?: string;
    approvalType?: ApprovalType;
    approvalLinkId?: string;
    approvalLink?: DMSItem;
    documents?: DMSItem[];
    approvalFlows?: ApprovalFlow[];
    followers?: GroupUserEntity[];
    resources?: Resource[];

    // meeting task
    location?: string;
    isTeamsMeeting?: boolean;
    meetingId?: string;
    attendees?: Attendee[];

    isLoadedFromTemplate = false;
    taskTemplateSourceId?: string;

    public constructor(obj: Partial<Task>) {
        super(obj);
        if (typeof obj.startTime == "string")
            this.startTime = DateTime.fromISO(obj.startTime, {
                zone: "utc",
            }).toLocal();
        if (typeof obj.endTime == "string")
            this.endTime = DateTime.fromISO(obj.endTime, {
                zone: "utc",
            }).toLocal();
        if (typeof obj.deadline == "string")
            this.deadline = DateTime.fromISO(obj.deadline, {
                zone: "utc",
            }).toLocal();
        if (obj.startTime instanceof DateTime)
            this.startTime = DateTime.fromMillis(obj.startTime.valueOf(), {
                zone: "utc",
            });
        if (obj.endTime instanceof DateTime)
            this.endTime = DateTime.fromMillis(obj.endTime.valueOf(), {
                zone: "utc",
            });
        if (obj.deadline instanceof DateTime)
            this.deadline = DateTime.fromMillis(obj.deadline.valueOf(), {
                zone: "utc",
            });
        if (obj.progressRegistrationType)
            this.progressRegistrationType = new RegistrationType(
                obj.progressRegistrationType
            );
        if (obj.resources)
            this.resources = obj.resources.map((x: any) => new Resource(x));
        if (obj.logs) this.logs = obj.logs.map((x: any) => new Log(x));
        if (obj.checklists)
            this.checklists = obj.checklists.map((x: any) => new Checklist(x));
        if (obj.approvalFlows)
            this.approvalFlows = obj.approvalFlows.map(
                (x: ApprovalFlow) => new ApprovalFlow(x)
            );
        if (obj.documents)
            this.documents = obj.documents.map((x: any) => new DMSItem(x));
        if (obj.attendees)
            this.attendees = obj.attendees.map((x: any) => new Attendee(x));
    }
}