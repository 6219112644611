<div [formGroup]="form" class="h-100 w-100 d-flex flex-column pt-2 pb-2">
  <div class="h-100 ms-2 me-2 d-flex flex-column">
    <div class="mb-2">
      <mat-checkbox formControlName="enableSearch"
        >Enable search in columns</mat-checkbox
      >
    </div>
    <div>
      <mat-checkbox formControlName="disabled"
        >Temporarily disable the tool for price updates</mat-checkbox
      >
    </div>
    <mat-form-field appearance="outline">
      <mat-label>Validity (in days)</mat-label>
      <input matInput formControlName="validityDate" type="number" min="1" />
    </mat-form-field>
    <mat-form-field appearance="outline" class="mt-3">
      <mat-label>Earliest delivery (in days)</mat-label>
      <input matInput formControlName="deliveryDate" type="number" min="0" />
    </mat-form-field>
    <mat-form-field appearance="outline" class="mt-3">
      <mat-label>Contact</mat-label>
      <input matInput formControlName="contact" />
    </mat-form-field>
    <mat-form-field appearance="outline" class="mt-3">
      <mat-label>RAL label</mat-label>
      <input matInput formControlName="ralLabel" />
    </mat-form-field>
  </div>
</div>
