<div class="w-100 h-100 p-2 overflow-hidden d-flex flex-column">
  <button class="close-button" mat-icon-button (click)="onClose()">
    <mat-icon>close</mat-icon>
  </button>
  <div
    *ngIf="previewData"
    class="p-1 border w-100 h-100 d-flex justify-content-center align-items-center min-height-0"
    #previewDiv
  >
    <ng-container [ngSwitch]="previewType">
      <ng-container *ngSwitchCase="PreviewTypes.Iframe">
        <iframe
          [src]="previewData"
          frameborder="0"
          class="w-100 h-100"
          role="presentation"
        ></iframe>
      </ng-container>
      <ng-container *ngSwitchCase="PreviewTypes.Image">
        <img
          [src]="previewData"
          alt="Image preview"
          class="p-1"
          [style.max-width.px]="previewDiv.clientWidth"
          [style.max-height.px]="previewDiv.clientHeight"
        />
      </ng-container>
      <ng-container *ngSwitchCase="PreviewTypes.Video">
        <video class="w-100 h-100" controls>
          <source [src]="previewData" />
        </video>
      </ng-container>
      <ng-container *ngSwitchCase="PreviewTypes.Audio">
        <audio controls>
          <source [src]="previewData" />
        </audio>
      </ng-container>
      <ng-container *ngSwitchCase="PreviewTypes.Email">
        <div
          class="w-100 h-100 d-flex flex-column justify-content-center align-items-center overflow-auto scrollbar horizontal-scrollbar"
          [innerHTML]="previewData"
        ></div>
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{ getTranslation$("previewUnavailable") | async }}
      </ng-container>
    </ng-container>
  </div>
</div>
