import { GroupUser } from 'processdelight-angular-components';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { UserLicenseInfo } from '../core/domain/models/user-license-info.model';

export const license$ = new BehaviorSubject<UserLicenseInfo | undefined>(
  undefined
);
export const users$ = new BehaviorSubject<GroupUser[] | undefined>(undefined);
export const groups$ = new BehaviorSubject<GroupUser[] | undefined>(undefined);
export const groupUsers$ = combineLatest([groups$, users$]).pipe(
  map(([groups, users]) => (groups ?? []).concat(users ?? []))
);

export const isAppAdmin$ = (appName: string) =>
  license$.pipe(
    map(
      (l) =>
        l?.licenses.some((l) => l.productName == appName && l.isAdmin) ?? false
    )
  );
