<div class="d-flex flex-column overflow-hidden choice-select-container">
  <mat-form-field
    class="no-subscript-wrapper"
    (click)="$event.stopPropagation()"
  >
    <mat-label>{{ getTranslation$("search") | async }}...</mat-label>
    <input
      matInput
      type="text"
      #searchInput
      (keydown.enter)="selectFirstValue(searchInput.value)"
    />
  </mat-form-field>
  <ng-container *ngIf="!isConsolidatedChoice; else consolidated">
    <mat-action-list
      *ngIf="!choiceParam.hasTranslations; else withTranslations"
      class="overflow-auto scrollbar p-0"
    >
      <mat-list-item
        *ngFor="
          let choice of filterChoices(choiceParam);
          trackBy: choiceTrackBy
        "
        [hidden]="!matchChoiceFilter(searchInput.value, choice)"
        (click)="
          multiple ? $event.stopPropagation() : undefined; selectChoice(choice)
        "
      >
        <div class="d-flex flex-row align-items-center">
          <mat-checkbox
            *ngIf="multiple"
            [checked]="valueChecked(choice)"
            color="primary"
          ></mat-checkbox>
          <div class="ms-1">
            {{ choice.value }}
          </div>
        </div>
      </mat-list-item>
    </mat-action-list>
    <ng-template #withTranslations>
      <mat-table [dataSource]="datasource" class="overflow-auto scrollbar">
        <ng-container matColumnDef="checkbox">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox
              [checked]="valueChecked(element)"
              color="primary"
            ></mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            {{ element.value }}
          </td>
        </ng-container>
        <ng-container
          *ngFor="let language of choiceParam.languages"
          [matColumnDef]="language.id"
        >
          <th mat-header-cell *matHeaderCellDef>
            {{ language.name }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ getChoiceTranslation(language.id, element)?.translation }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
        <tr
          mat-row
          *matRowDef="let element; columns: tableColumns"
          [hidden]="!matchChoiceFilter(searchInput.value, element)"
          class="hover"
          (click)="
            multiple ? $event.stopPropagation() : undefined;
            selectChoice(element)
          "
        ></tr>
      </mat-table>
    </ng-template>
  </ng-container>
  <ng-template #consolidated>
    <div class="overflow-auto scrollbar">
      <ng-container *ngFor="let choiceParam of consolidatedChoices">
        <ng-container
          *ngIf="matchConsolidatedChoiceFilter(searchInput.value, choiceParam)"
        >
          <div class="p-2 fw-bold">{{ choiceParam.title }}</div>
          <mat-action-list
            *ngIf="!choiceParam.hasTranslations; else withTranslations"
            class="p-0"
          >
            <mat-list-item
              *ngFor="
                let choice of filterChoices(choiceParam);
                trackBy: choiceTrackBy
              "
              [hidden]="!matchChoiceFilter(searchInput.value, choice)"
              (click)="
                multiple ? $event.stopPropagation() : undefined;
                selectChoice(choice, choiceParam)
              "
            >
              <div class="d-flex flex-row align-items-center">
                <mat-checkbox
                  *ngIf="multiple"
                  [checked]="valueChecked(choice, choiceParam)"
                  color="primary"
                ></mat-checkbox>
                <div class="ms-1">
                  {{ choice.value }}
                </div>
              </div>
            </mat-list-item>
          </mat-action-list>
          <ng-template #withTranslations>
            <mat-table
              [dataSource]="consolidatedChoicesDatasources[choiceParam.id]"
              class="overflow-auto scrollbar"
            >
              <ng-container matColumnDef="checkbox">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                  <mat-checkbox
                    [checked]="valueChecked(element, choiceParam)"
                    color="primary"
                  ></mat-checkbox>
                </td>
              </ng-container>
              <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                  {{ element.value }}
                </td>
              </ng-container>
              <ng-container
                *ngFor="let language of choiceParam.languages"
                [matColumnDef]="language.id"
              >
                <th mat-header-cell *matHeaderCellDef>
                  <div class="d-flex align-items-center">
                    {{ language.name }}
                  </div>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ getChoiceTranslation(language.id, element)?.translation }}
                </td>
              </ng-container>
              <tr
                mat-header-row
                *matHeaderRowDef="
                  consolidatedChoicesTableColumns[choiceParam.id]
                "
              ></tr>
              <tr
                mat-row
                *matRowDef="
                  let element;
                  columns: consolidatedChoicesTableColumns[choiceParam.id]
                "
                [hidden]="!matchChoiceFilter(searchInput.value, element)"
                class="hover"
                (click)="
                  multiple ? $event.stopPropagation() : undefined;
                  selectChoice(element, choiceParam)
                "
              ></tr>
            </mat-table>
          </ng-template>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>
</div>
