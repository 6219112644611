import { Action, createReducer, on } from '@ngrx/store';

import {
  getInterestGroupsResolved,
  getInterestGroupsForUserResolved,
  addPortalUserInterestGroupResolved,
  deletePortalUserInterestGroupResolved,
  resetSlice,
} from './interestGroup.actions';
import { InterestGroup } from 'processdelight-angular-components';

export const featureSlice = 'interestGroup';

export interface State {
  interestGroups: InterestGroup[];
  interestGroupsForUser: InterestGroup[];
}

const defaultState: State = {
  interestGroups: [],
  interestGroupsForUser: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return InterestGroupReducer(state, action);
}

export const initialState: State = defaultState;
export const InterestGroupReducer = createReducer(
  initialState,
  on(getInterestGroupsResolved, (state, { interestGroups }) => ({
    ...state,
    interestGroups: [...interestGroups],
  })),
  on(getInterestGroupsForUserResolved, (state, { interestGroups }) => {
    return {
      ...state,
      interestGroupsForUser: [...interestGroups],
    };
  }),
  on(addPortalUserInterestGroupResolved, (state, { response }) => ({
    ...state,
    interestGroupsForUser: [
      ...state.interestGroupsForUser,
      ...(response.joinedGroups || []),
    ],
  })),
  on(
    deletePortalUserInterestGroupResolved,
    (state, { deletedInterestGroup }) => ({
      ...state,
      interestGroupsForUser: state.interestGroupsForUser.filter(
        (ig) => ig.id !== deletedInterestGroup.id
      ),
    })
  ),
  on(resetSlice, () => initialState)
);
