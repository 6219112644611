import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeModule, MatTreeNestedDataSource } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatCardModule } from '@angular/material/card';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TranslationService } from 'processdelight-angular-components';
import { InterestGroupVM as InterestGroup } from '../interest-group.model';
import { Memoize } from 'typescript-memoize';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

interface Groups {
  id: string;
  title: string;
  color: string;
  description?: string;
  icon?: string;
  isSelected: boolean;
  children?: Groups[];
}

@Component({
  selector: 'app-coi-filter-popup',
  templateUrl: './coi-filter-popup.component.html',
  styleUrls: ['./coi-filter-popup.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    MatTreeModule,
    MatCardModule,
    MatCheckboxModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
  ],
})
export class CoiFilterPopupComponent implements OnInit, OnDestroy {
  treeControl = new NestedTreeControl<Groups>((node) => node.children ?? []);
  dataSource = new MatTreeNestedDataSource<Groups>();
  searchText = '';
  filteredInterestGroups: InterestGroup[] = [];

  hasChild = (_: number, node: Groups) =>
    !!node.children && node.children.length > 0;

  getTranslation$(label: string) {
    return this.translations.getTranslation$(label);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      interestGroups: InterestGroup[];
      dataSource: Groups[];
    },
    private readonly sanitizer: DomSanitizer,
    private readonly translations: TranslationService,
    public filterDialogRef: MatDialogRef<CoiFilterPopupComponent>
  ) {}

  ngOnInit(): void {
    this.dataSource.data = this.data.dataSource;
    this.filteredInterestGroups = this.data.interestGroups;
  }

  buildTree(interestGroups: InterestGroup[]): Groups[] {
    if (!interestGroups) {
      return [];
    }
    const parentGroups = interestGroups.filter(
      (g) => g.subGroups && g.subGroups.length > 0
    );
    const standaloneGroups = interestGroups.filter(
      // groups without a parent and not a subgroup of a group
      (g) =>
        !parentGroups.some((p) =>
          p.subGroups?.some((sg) => sg.subGroupId === g.id)
        ) && !parentGroups.map((p) => p.id).includes(g.id)
    );
    const parentGroupsThatAreNotSubGroups = parentGroups.filter(
      (pg) =>
        !parentGroups.some((p) =>
          p.subGroups?.some((sg) => sg.subGroupId === pg.id)
        )
    );

    const groupsToShow =
      parentGroupsThatAreNotSubGroups.concat(standaloneGroups);
    const r = groupsToShow.map((g) => ({
      id: g.id,
      title: g.title,
      color: g.color,
      description: g.description,
      icon: g.icon,
      isSelected: g.isSelected,
      children: this.getConvertedNodes(g),
    }));
    return this.sortGroups(r);
  }

  sortGroups(groups: Groups[]): Groups[] {
    return groups.sort((a, b) => {
      if (
        a.children &&
        a.children.length > 0 &&
        b.children &&
        b.children.length > 0
      ) {
        return a.title.localeCompare(b.title);
      } else if (a.children && a.children.length > 0) {
        return -1;
      } else if (b.children && b.children.length > 0) {
        return 1;
      } else {
        return a.title.localeCompare(b.title);
      }
    });
  }

  getConvertedNodes(interestGroup: InterestGroup): Groups[] {
    if (interestGroup.subGroups && interestGroup.subGroups.length === 0) {
      return [];
    }
    const r = interestGroup.subGroups!.flatMap((group) => {
      const subGroup = this.data.interestGroups?.find(
        (g) => g.id === group.subGroupId
      );
      if (!subGroup) return [];
      return [
        {
          id: subGroup.id,
          title: subGroup.title,
          color: subGroup.color,
          description: subGroup.description,
          icon: subGroup.icon,
          isSelected: subGroup.isSelected,
          children: this.getConvertedNodes(subGroup),
        },
      ];
    });
    return r;
  }

  checkBoxChange(event: MatCheckboxChange, node: Groups) {
    let groupsToCheck = this.data.interestGroups;
    let allGroupsToJoin = [node.id];
    if (event.checked) {
      let newSubGroups = allGroupsToJoin;
      do {
        newSubGroups = groupsToCheck
          .filter(
            (g) =>
              !allGroupsToJoin.includes(g.id) &&
              g.subGroups?.some((s) => newSubGroups.includes(s.subGroupId))
          )
          .map((g) => g.id);
        allGroupsToJoin = allGroupsToJoin.concat(newSubGroups);
      } while (newSubGroups.length != 0);
    }
    groupsToCheck = groupsToCheck.map((g) => {
      if (allGroupsToJoin.includes(g.id)) {
        g.isSelected = event.checked;
      }
      return g;
    });
    this.dataSource.data = this.buildTree(groupsToCheck);
  }

  @Memoize()
  transform(value: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }

  saveFilters() {
    this.filterDialogRef.close(this.data.interestGroups);
  }

  private applySearchFilter(): void {
    if (!this.data.interestGroups) return;

    const text = this.searchText.toLowerCase();
    // Filter all
    this.filteredInterestGroups = this.data.interestGroups.filter(
      (g) =>
        g.title?.toLowerCase().includes(text) ||
        g.description?.toLowerCase().includes(text)
    );
  }

  protected filterGroups(event: Event): void {
    const value = (event.target as HTMLInputElement).value || '';
    this.searchText = value;
    this.applySearchFilter();

    const sourceArray = this.filteredInterestGroups;

    this.dataSource.data = this.buildTree(sourceArray);
  }

  destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
