import { createSelector } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { MetadataParam } from 'src/app/webparts/dms-component/domain/models/metadata-param.model';

const metadataState = (state: AppState) => state.coreFeature.metadata;
const getMetadataParams = createSelector(
  metadataState,
  (state) => state.metadata
);
const getFixedParams = createSelector(
  getMetadataParams,
  (params) =>
    [
      params.find((p) => p.fileNameParam),
      params.find((p) => p.modifiedOnParam),
      params.find((p) => p.modifiedByParam),
      params.find((p) => p.createdOnParam),
      params.find((p) => p.createdByParam),
    ].filter((p) => !!p) as MetadataParam[]
);
const getFileNameParam = createSelector(getMetadataParams, (params) =>
  params.find((p) => p.fileNameParam)
);
export const metadataSelectors = {
  getMetadataParams,
  getFixedParams,
  getFileNameParam,
};
