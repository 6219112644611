import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/core/store/app.reducer';
import {
  acceptRequest,
  getClientUserRequests,
  getClientUsers,
  getClients,
  newRequest,
  rejectRequest,
  resetSlice,
  setCurrentClient,
  updateUserConfiguration,
} from './client.actions';
import { BaseFacade } from '../base.facade';
import { clientSelectors } from './client.selectors';
import { map } from 'rxjs';
import { UserConfigurationDTO } from '../../domain/dto/user-configuration.dto';
import { ClientWithConfig } from '../../domain/models/client-with-config.model';
import { CRMEntity } from 'processdelight-angular-components';
import { ClientUserRequest } from '../../domain/models/client-user-request.model';

@Injectable({
  providedIn: 'root',
})
export class ClientFacade extends BaseFacade {
  loadingClients$ = this._store.pipe(select(clientSelectors.loadingClients));
  loadingClientUsers$ = this._store.pipe(
    select(clientSelectors.loadingClientUsers)
  );
  clients$ = this._store.pipe(select(clientSelectors.getClients));
  clientUsers$ = this._store.pipe(select(clientSelectors.getClientUsers));
  clientUserRequests$ = this._store.pipe(
    select(clientSelectors.getClientUserRequests)
  );
  currentClient$ = this._store.pipe(select(clientSelectors.getCurrentClient));
  currentClientWithConfig$ = this._store.pipe(
    select(clientSelectors.getCurrentClientWithConfig)
  );
  getClientById$ = (id: string) =>
    this._store.pipe(
      select(clientSelectors.getClientById),
      map((fn) => fn(id))
    );
  getClientUsersByClientId$ = (clientId: string) =>
    this._store.pipe(
      select(clientSelectors.getClientUsersByClientId),
      map((fn) => fn(clientId))
    );

  getClientUserRequestsByClientId$ = (clientId: string) =>
    this._store.pipe(
      select(clientSelectors.getClientUserRequestsByClientId),
      map((fn) => fn(clientId))
    );

  constructor(_store: Store<AppState>) {
    super(_store);
  }

  getClients$() {
    return this.dispatchAction$(getClients);
  }

  getClientUsers$(clientId?: string) {
    return this.dispatchAction$(getClientUsers, { clientId });
  }

  getClientUserRequests$(clientId?: string) {
    return this.dispatchAction$(getClientUserRequests, { clientId });
  }

  acceptRequest$(requestId: string) {
    return this.dispatchAction$(acceptRequest, { requestId });
  }
  rejectRequest$(requestId: string) {
    return this.dispatchAction$(rejectRequest, { requestId });
  }
  newRequest$(client: CRMEntity) {
    return this.dispatchAction$(newRequest, { client });
  }
  updateUserConfiguration$(userConfiguration: UserConfigurationDTO[]) {
    return this.dispatchAction$(updateUserConfiguration, { userConfiguration });
  }

  setCurrentClient(client: CRMEntity) {
    this._store.dispatch(setCurrentClient({ client }));
  }

  resetSlice() {
    this._store.dispatch(resetSlice());
  }
}
