import { DateTime } from 'luxon';
import { BaseEntity } from 'processdelight-angular-components';

export class TaskCalendarItem extends BaseEntity {
  title?: string;
  startDate?: DateTime;
  endDate?: DateTime;
  taskType?: string;

  constructor(obj: Partial<TaskCalendarItem>) {
    super(obj);
    Object.assign(this, obj);
    if (typeof obj.startDate == 'string')
      this.startDate = DateTime.fromISO(obj.startDate, { zone: 'utc' });
    if (typeof obj.endDate == 'string')
      this.endDate = DateTime.fromISO(obj.endDate, { zone: 'utc' });
    if (obj.startDate instanceof DateTime)
      this.startDate = DateTime.fromMillis(obj.startDate.valueOf(), {
        zone: 'utc',
      });
    if (obj.endDate instanceof DateTime)
      this.endDate = DateTime.fromMillis(obj.endDate.valueOf(), {
        zone: 'utc',
      });
  }
}
