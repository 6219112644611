<div class="dialogContainer h-100 w-100">
  <h5 class="property-header">
    <div class="formType">[{{ data.entityObject?.form?.name }}]</div>
    {{ data.entityObject?.entity?.title }}
  </h5>
  <div class="align-items-center d-flex justify-content-end buttons-header p-2 w-100">
    <mat-icon class="closeButtonIcon" (click)="onCloseDialog()">close</mat-icon>
  </div>
  <div class="w-100 h-100 client-dialog-container scrollbar min-height-0">
    <form [formGroup]="entityFormGroup" class="h-100 min-height-0">
      <div class="d-flex flex-column h-100">
        <processdelight-form-builder #formBuilder
          *ngIf="fieldConfigurations"
          [currentUserId]="currentUserId"
          [formgroup]="entityFormGroup"
          [debug]="true"
          [isNewForm]="false"
          [disabled]="true"
          [objService]="dataObjectService"></processdelight-form-builder>
        <ng-template #number let-element="config">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{ translations.meetingTaskNr }}*</mat-label>
            <input type="text"
              formControlName="number"
              matInput
              readonly="readonly" />
          </mat-form-field>
        </ng-template>
        <ng-template #title let-element="config">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{ translations.meetingTaskName }}</mat-label>
            <input formControlName="title" matInput />
          </mat-form-field>
        </ng-template>
        <ng-template #description let-element="config">
          <div class="d-flex flex-column w-100" style="height: 300px; padding: 5px">
            <quill-view-html class="w-100 h-100" [content]="entityFormGroup.get('description')?.value"></quill-view-html>
          </div>
        </ng-template>
        <ng-template #taskType let-element="config">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{ translations.type }}</mat-label>
            <input formControlName="taskType" matInput />
          </mat-form-field>
        </ng-template>
        <ng-template #project let-element="config">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{ translations.project }}</mat-label>
            <input
              formControlName="project"
              matInput
              placeholder="{{ translations.project }}"
            />
          </mat-form-field>
        </ng-template>
        <ng-template #meetingAnswer let-element="config">
          <div *ngIf="hasMeeting">
            <h3>{{ translations.answer }}</h3>
            <div>
              <button 
                color="primary"
                mat-raised-button
                class="menuButton mx-1"
                [style.background-color]="
                  currentResponseSelection === 'yes'
                    ? 'green'
                    : 'rgb(20, 36, 54)'
                "
                [style.color]="'rgb(255, 255, 255)'"
                [disabled]="true">
                <mat-icon style="color: white">check</mat-icon>
                {{ translations.yes }}
              </button>
              <button 
                color="accent"
                mat-raised-button
                class="menuButton mx-1"
                [style.background-color]="
                  currentResponseSelection === 'maybe'
                    ? 'green'
                    : 'rgb(20, 36, 54)'
                "
                [style.color]="'rgb(255, 255, 255)'"
                [disabled]="true">
                <mat-icon style="color: orange">access_time</mat-icon>
                {{ translations.maybe }}
              </button>
              <button 
                color="warn"
                mat-raised-button
                class="menuButton mx-1"
                [style.background-color]="
                  currentResponseSelection === 'no'
                    ? 'green'
                    : 'rgb(20, 36, 54)'
                "
                [style.color]="'rgb(255, 255, 255)'"
                [disabled]="true">
                <mat-icon style="color: red">close</mat-icon>
                {{ translations.no }}
              </button>
            </div>
          </div>
        </ng-template>
        <ng-template #location let-element="config">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>{{ translations.location }}</mat-label>
            <input formControlName="location"
              matInput
              placeholder="{{ translations.location }}" />
          </mat-form-field>
        </ng-template>
        <ng-template #linkTeamsMeeting let-element="config">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>{{ translations.teamsMeeting }}?*</mat-label>
            <input formControlName="linkTeamsMeeting" matInput />
          </mat-form-field>
        </ng-template>
        <ng-template #meetingLink let-lement="config">
          <div class="w-100">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>{{ translations.meetingDescription }}</mat-label>
              <textarea matInput
                formControlName="meetingLink"
                placeholder="{{ translations.meetingDescription }}"
                rows="4"
                [disabled]="true"></textarea>
            </mat-form-field>
          </div>
        </ng-template>
        <ng-template #attendees let-element="config">
          <div class="d-flex flex-column w-100 h-100">
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>{{ translations.attendees }}</mat-label>
              <input formControlName="attendees" matInput />
            </mat-form-field>
          </div>
        </ng-template>
        <ng-template #startTime let-element="config">
          <div class="d-flex align-items-center w-100">
            <div class="col-md-5">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>{{ translations.startDate }}*</mat-label>
                <input
                  formControlName="startDate"
                  matInput
                />
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>{{ translations.startTime }}*</mat-label>
                <input formControlName="startTime" matInput />
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="outline" class="hide-subscript w-100">
                <mat-label>{{ translations.duration }}*</mat-label>
                <input matInput
                  name="duration"
                  formControlName="duration"
                  processdelightTimeInput />
              </mat-form-field>
            </div>
          </div>
        </ng-template>
        <ng-template #endTime let-element="config">
          <div class="d-flex align-items-center w-100">
            <div class="col-md-5">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>{{ translations.endDate }}*</mat-label>
                <input formControlName="endDate" matInput />
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>{{ translations.endTime }}*</mat-label>
                <input
                  formControlName="endTime"
                  matInput
                />
              </mat-form-field>
            </div>
          </div>
        </ng-template>
      </div>
    </form>
  </div>
</div>
