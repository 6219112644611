import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DateTime } from 'luxon';
import {
  CalendarComponent,
  CalendarItem,
  getTodayInUTC,
  LoaderService,
  TranslationService,
} from 'processdelight-angular-components';
import { Subject } from 'rxjs';
import { PortalService } from 'src/app/core/services/portal.service';

@Component({
  selector: 'app-calendar-popup',
  templateUrl: './calendar-popup.component.html',
  styleUrls: ['./calendar-popup.component.scss'],
  standalone: true,
  imports: [CalendarComponent, MatIconModule],
})
export class CalendarPopupComponent {
  selectedDate = getTodayInUTC();
  calendarHeight = 1440;
  hourBlocks = 2;

  isLoading = new Subject<string>();

  getTranslations(input: string) {
    return this.translationService.getTranslation(input);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      calendarItems: CalendarItem[];
      loadedWeeks: DateTime[];
    },
    private popDialogRef: MatDialogRef<CalendarPopupComponent>,
    private portalService: PortalService,
    private loaderService: LoaderService,
    private translationService: TranslationService
  ) {}

  onDateChange(event: { startDate: DateTime; endDate: DateTime }) {
    this.loaderService.startLoading(
      this.getTranslations('loadingData'),
      () => this.isLoading
    );
    let year = 0;
    let month = 0;
    if (event.startDate < this.data.loadedWeeks[0]) {
      year = event.startDate.year;
      month = event.startDate.month;
    } else {
      year = event.endDate.year;
      month = event.endDate.month;
    }

    const startOfMonth = DateTime.utc().set({
      year: year,
      month: month,
      day: 1,
    });
    const endOfMonth = startOfMonth.endOf('month');

    let weekStartStartOfMonth = startOfMonth.startOf('week').set({
      hour: 0,
      minute: 0,
    });
    if (startOfMonth.weekday == 1) {
      weekStartStartOfMonth = weekStartStartOfMonth.minus({ days: 7 });
    }
    const weekStartEndOfMonth = endOfMonth.startOf('week');
    const weekEndEndOfMonth = endOfMonth.endOf('week').set({
      hour: 23,
      minute: 59,
    });

    const newWeeks = [];
    for (
      let index = 0;
      DateTime.fromMillis(weekStartStartOfMonth.valueOf() + index * 86400000, {
        zone: 'utc',
      }) <= weekStartEndOfMonth;
      index += 7
    ) {
      if (
        !this.data.loadedWeeks.some(
          (l) =>
            l.valueOf() ==
            new Date(
              weekStartStartOfMonth.valueOf() + index * 86400000
            ).valueOf()
        )
      ) {
        newWeeks.push(
          DateTime.fromMillis(
            weekStartStartOfMonth.valueOf() + index * 86400000,
            { zone: 'utc' }
          )
        );
        this.data.loadedWeeks.push(
          DateTime.fromMillis(
            weekStartStartOfMonth.valueOf() + index * 86400000,
            { zone: 'utc' }
          )
        );
      }
    }
    this.data.loadedWeeks.sort((a, b) => a.valueOf() - b.valueOf());
    if (newWeeks.length > 0) {
      newWeeks.sort((a, b) => a.valueOf() - b.valueOf());
      const startDate = newWeeks[0];
      const endDate = newWeeks[newWeeks.length - 1]
        .plus({
          days: 7,
        })
        .set({
          hour: 23,
          minute: 59,
        });

      this.portalService
        .getTaskCalendarItems(startDate, endDate)
        .subscribe((tasks) => {
          this.isLoading.next('');
          if (tasks) {
            const mappedTasks = tasks.map(
              (x) =>
                new CalendarItem({
                  id: x.id,
                  startDate: x.startDate,
                  endDate: x.endDate,
                  title: x.title,
                  description: x.taskType,
                  accentColor:
                    x.taskType == 'Meeting'
                      ? '#FF0000'
                      : x.taskType == 'Execution'
                      ? '#0000FF'
                      : '#00FF00',
                  isDraggable: false,
                })
            );
            const dinstinctTasks = mappedTasks.filter(
              (x) => !this.data.calendarItems.some((c) => c.id == x.id)
            );
            this.data.calendarItems = [
              ...this.data.calendarItems,
              ...dinstinctTasks,
            ];
          }
        });
    }
    this.isLoading.next('');
  }

  closeDialog() {
    this.popDialogRef.close({
      calendarItems: this.data.calendarItems,
      loadedWeeks: this.data.loadedWeeks,
    });
  }
}
