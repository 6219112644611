import { createSelector } from '@ngrx/store';
import { AppState } from '../app.reducer';

const appState = (state: AppState) => state.portalFeature.app;
const getAppConfig = createSelector(appState, (state) => state.appSettings);
const forms = createSelector(appState, (state) => state.forms);

export const appSelectors = {
  getAppConfig,
  getForms: forms,
};
