import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { tasks } from '@microsoft/teams-js';
import { DateTime } from 'luxon';
import {
  AbstractWebPartComponent,
  CalendarComponent,
  CalendarItem,
  CalendarWebpartViewComponent,
  getTodayInUTC,
  VIEW,
  WebpartViewBox,
} from 'processdelight-angular-components';
import { Subject } from 'rxjs';
import { PortalService } from 'src/app/core/services/portal.service';
import { CalendarPopupComponent } from './calendar-popup/calendar-popup.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-calendar',
  templateUrl: './task-calendar.component.html',
  styleUrls: ['./task-calendar.component.scss'],
  standalone: true,
  imports: [
    CalendarWebpartViewComponent,
    CalendarComponent,
    MatDialogModule,
    CommonModule,
  ],
})
export class TaskCalendarComponent
  extends AbstractWebPartComponent
  implements OnInit, OnDestroy
{
  static override TYPE = 'TaskCalendar';
  static override FEATURES = [VIEW];
  override set webpartViewbox(value: WebpartViewBox | undefined) {
    this._webpartViewbox = value;
  }
  calendarItems: CalendarItem[] = [];
  loadedWeeks: DateTime[] = [];
  isLoading = false;
  selectedDate = getTodayInUTC();
  calendarHeight = 1440;
  hourBlocks = 2;

  constructor(
    @Inject(MAT_DATE_LOCALE) protected locale: string,
    private portalService: PortalService,
    private calendarDialog: MatDialog
  ) {
    super();
  }

  ngOnInit(): void {
    this.isLoading = true;
    const startOfMonth = getTodayInUTC().startOf('month');
    const endOfMonth = getTodayInUTC().endOf('month');
    let weekStartStartOfMonth = startOfMonth.startOf('week').set({
      hour: 0,
      minute: 0,
    });
    if (startOfMonth.weekday == 1) {
      weekStartStartOfMonth = weekStartStartOfMonth.minus({ days: 7 });
    }
    const weekStartEndOfMonth = endOfMonth.startOf('week');
    const weekEndEndOfMonth = endOfMonth.endOf('week').set({
      hour: 23,
      minute: 59,
    });
    this.portalService
      .getTaskCalendarItems(weekStartStartOfMonth, weekEndEndOfMonth)
      .subscribe((tasks) => {
        this.isLoading = false;
        if (tasks) {
          this.calendarItems = tasks.map(
            (x) =>
              new CalendarItem({
                id: x.id,
                startDate: x.startDate,
                endDate: x.endDate,
                title: x.title,
                description: x.taskType,
                accentColor: x.taskType == 'Duty' ? '#FF0000' : '#0000FF',
                isDraggable: false,
              })
          );
        }
      });

    for (
      let index = 0;
      DateTime.fromMillis(weekStartStartOfMonth.valueOf() + index * 86400000, {
        zone: 'utc',
      }) <= weekStartEndOfMonth;
      index += 7
    ) {
      this.loadedWeeks.push(
        DateTime.fromMillis(
          weekStartStartOfMonth.valueOf() + index * 86400000,
          { zone: 'utc' }
        )
      );
    }
  }

  onDateChange(event: { startDate: DateTime; endDate: DateTime }) {
    this.isLoading = true;
    let year = 0;
    let month = 0;
    if (event.startDate < this.loadedWeeks[0]) {
      year = event.startDate.year;
      month = event.startDate.month;
    } else {
      year = event.endDate.year;
      month = event.endDate.month;
    }

    const startOfMonth = DateTime.utc().set({
      year: year,
      month: month,
      day: 1,
    });
    const endOfMonth = startOfMonth.endOf('month');

    let weekStartStartOfMonth = startOfMonth.startOf('week').set({
      hour: 0,
      minute: 0,
    });
    if (startOfMonth.weekday == 1) {
      weekStartStartOfMonth = weekStartStartOfMonth.minus({ days: 7 });
    }
    const weekStartEndOfMonth = endOfMonth.startOf('week');
    const weekEndEndOfMonth = endOfMonth.endOf('week').set({
      hour: 23,
      minute: 59,
    });

    const newWeeks = [];
    for (
      let index = 0;
      DateTime.fromMillis(weekStartStartOfMonth.valueOf() + index * 86400000, {
        zone: 'utc',
      }) <= weekStartEndOfMonth;
      index += 7
    ) {
      if (
        !this.loadedWeeks.some(
          (l) =>
            l.valueOf() ==
            new Date(
              weekStartStartOfMonth.valueOf() + index * 86400000
            ).valueOf()
        )
      ) {
        newWeeks.push(
          DateTime.fromMillis(
            weekStartStartOfMonth.valueOf() + index * 86400000,
            { zone: 'utc' }
          )
        );
        this.loadedWeeks.push(
          DateTime.fromMillis(
            weekStartStartOfMonth.valueOf() + index * 86400000,
            { zone: 'utc' }
          )
        );
      }
    }
    this.loadedWeeks.sort((a, b) => a.valueOf() - b.valueOf());
    if (newWeeks.length > 0) {
      newWeeks.sort((a, b) => a.valueOf() - b.valueOf());
      const startDate = newWeeks[0];
      const endDate = newWeeks[newWeeks.length - 1]
        .plus({
          days: 7,
        })
        .set({
          hour: 23,
          minute: 59,
        });

      this.portalService
        .getTaskCalendarItems(startDate, endDate)
        .subscribe((tasks) => {
          this.isLoading = false;
          if (tasks) {
            const mappedTasks = tasks.map(
              (x) =>
                new CalendarItem({
                  id: x.id,
                  startDate: x.startDate,
                  endDate: x.endDate,
                  title: x.title,
                  description: x.taskType,
                  accentColor:
                    x.taskType == 'Meeting'
                      ? '#FF0000'
                      : x.taskType == 'Execution'
                      ? '#0000FF'
                      : '#00FF00',
                  isDraggable: false,
                })
            );
            const dinstinctTasks = mappedTasks.filter(
              (x) => !this.calendarItems.some((c) => c.id == x.id)
            );
            this.calendarItems = [...this.calendarItems, ...dinstinctTasks];
          }
        });
    }
    this.isLoading = false;
  }

  openCalendarPopUp(event: boolean) {
    this.calendarDialog
      .open(CalendarPopupComponent, {
        width: '85%',
        height: '70%',
        data: {
          calendarItems: this.calendarItems,
          loadedWeek: this.loadedWeeks,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        this.calendarItems = result.calendarItems;
        this.loadedWeeks = result.loadedWeeks;
      });
  }

  destroy$ = new Subject<void>();

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
