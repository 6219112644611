import { CommonModule, LocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';

import { AppSigninComponent } from './components/app-signin/app-signin.component';
import { AppSilentSigninComponent } from './components/app-silent-signin/app-silent-signin.component';
import { SignInCallbackComponent } from './components/sign-in-callback/sign-in-callback.component';
import { SignOutCallbackComponent } from './components/sign-out-callback/sign-out-callback.component';
import { TeamsAuthenticationComponent } from './components/teams-authentication/teams-authentication.component';
import { TeamsSignOutComponent } from './components/teams-sign-out/teams-sign-out.component';
import { QueryParamLocationStrategy } from './query-param.location-strategy';

@NgModule({
  declarations: [
    SignInCallbackComponent,
    SignOutCallbackComponent,
    TeamsAuthenticationComponent,
    TeamsSignOutComponent,
    AppSigninComponent,
    AppSilentSigninComponent,
  ],
  imports: [CommonModule],
  providers: [
    { provide: LocationStrategy, useClass: QueryParamLocationStrategy },
  ],
})
export class MSALModule {}
