<div class="d-flex flex-row justify-content-center mt-1">
  <h3>Please select a client:</h3>
</div>
<div class="clientGallery">
  <mat-card *ngFor="let client of clients$ | async" class="gap-2 clientcard">
    <span>{{ client.client.name }}</span>
    <button mat-raised-button (click)="selectClient(client.client)">
      Select Client
    </button>
  </mat-card>
</div>
