import { Action, createReducer, on } from '@ngrx/store';
import { Group } from '../../domain/models/group.model';
import { getGroups, getGroupsResolved, resetSlice } from './group.actions';

export const featureSlice = 'group';

export interface State {
  loadingGroups: boolean;
  groups: Group[];
}

const defaultState: State = {
  loadingGroups: false,
  groups: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return groupReducer(state, action);
}

export const initialState: State = defaultState;
export const groupReducer = createReducer(
  initialState,
  on(getGroups, (state) => ({ ...state, loadingGroups: true })),
  on(getGroupsResolved, (state, { groups }) => ({
    ...state,
    groups,
    loadingGroups: false,
  })),
  on(resetSlice, () => initialState)
);
