import { MetadataParameter } from './metadata-parameter.model';

export class VerhofsteProductMetadata {
  id!: string;
  productId!: string;
  value!: string;
  paramId!: string;
  param!: MetadataParameter;

  constructor(init?: Partial<VerhofsteProductMetadata>) {
    Object.assign(this, init);
  }
}
