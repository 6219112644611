import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {
  DomSanitizer,
  SafeHtml,
  SafeResourceUrl,
} from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { DateTime } from 'luxon';
import {
  DashboardComponent,
  GroupUser,
  MicrosoftAuthenticationService,
  TranslationService,
} from 'processdelight-angular-components';
import { BehaviorSubject, combineLatest, first } from 'rxjs';
import { MetadataFacade } from 'src/app/core/store/metadata/metadata.facade';
import { users$ } from 'src/app/data/data.observables';
import { ColumnType } from '../domain/enums/column-type.enum';
import { PermissionType } from '../domain/enums/permission-type.enum';
import { LibraryItem } from '../domain/models/item.model';
import { Library } from '../domain/models/library.model';
import { MetadataFilterType } from '../domain/models/metadata.functions';
import { Metadata } from '../domain/models/metadata.model';
import { Permission } from '../domain/models/permission.model';
import {
  LibraryDocumentService,
  PreviewType,
} from '../services/library-document.service';

@Component({
  selector: 'app-dms-preview-dialog',
  templateUrl: './dms-preview-dialog.component.html',
  styleUrls: ['./dms-preview-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    DashboardComponent,
    MatIconModule,
    MatDialogModule,
    MatSnackBarModule,
    RouterModule,
    MatCardModule,
    MatButtonModule,
  ],
})
export class DmsPreviewDialogComponent implements OnInit {
  item!: LibraryItem;

  @Output() updated = new EventEmitter<LibraryItem>();

  initialValue: {
    [key: string]: MetadataFilterType;
  } = {};
  itemValue: {
    [key: string]: MetadataFilterType;
  } = {};

  previewType?: PreviewType;
  previewData?: SafeResourceUrl | SafeHtml;

  PreviewTypes = PreviewType;

  updating = false;

  redrawing = false;

  library?: Library;

  formValid = new BehaviorSubject<boolean>(false);
  formDisabled = false;

  permissions: Permission[] = [];

  get canEdit() {
    return (
      !this.permissions.length ||
      this.permissions.some(
        (p) =>
          (p.groupUser?.user?.id == this.msal.userId ||
            p.groupUser?.group?.members?.some(
              (m) => m.id == this.msal.userId
            )) &&
          p.permissionType != PermissionType.Read
      )
    );
  }

  constructor(
    private readonly msal: MicrosoftAuthenticationService,
    private readonly metadataFacade: MetadataFacade,
    private readonly sanitizer: DomSanitizer,
    private readonly libraryDocumentService: LibraryDocumentService,
    private readonly translations: TranslationService,
    public dialogRef: MatDialogRef<DmsPreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { item: LibraryItem }
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }

  getTranslation(label: string): string {
    return this.translations.getTranslation(label);
  }
  getTranslation$(label: string) {
    return this.translations.getTranslation$(label);
  }

  transform(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  sanitizeHtml(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  public ngOnInit(): void {
    this.item = this.data.item;
    if (this.item == null) {
      return;
    }

    this.libraryDocumentService
      .providePreviewUrl(
        this.item.sharepointId,
        this.item.fileLocation.split('.').pop()!
      )
      .subscribe(({ data, type }) => {
        this.previewData =
          type == PreviewType.Email
            ? this.sanitizeHtml(data)
            : this.transform(data);
        this.previewType = type;
      });

    this.redrawing = true;
    this.itemValue = {};
    this.initialValue = {};
    this.metadataFacade.metadataParams$.pipe(first()).subscribe((params) => {
      this.item.metadata.forEach((m) => {
        const param = params.find((p) => p.id == m.metadataParameter!.id);
        if (param?.multi)
          if (this.itemValue[m.metadataParameter!.id]) {
            if (param.type == ColumnType.GroupUser)
              this.itemValue[m.metadataParameter!.id] = [
                ...(this.itemValue[m.metadataParameter!.id] as GroupUser[]),
                m.groupUserValue!,
              ];
            else
              this.itemValue[m.metadataParameter!.id] = [
                ...(this.itemValue[m.metadataParameter!.id] as string[]),
                m.value!,
              ];
          } else {
            if (param.type == ColumnType.GroupUser)
              this.itemValue[m.metadataParameter!.id] = [m.groupUserValue!];
            else this.itemValue[m.metadataParameter!.id] = [m.value!];
          }
        else
          this.itemValue[m.metadataParameter!.id] = (m.value ??
            m.dateTimeValue ??
            m.numberValue ??
            m.groupUserValue)!;
      });
      this.initialValue = { ...this.itemValue };
      setTimeout(() => {
        this.redrawing = false;
      }, 0);
    });
    // if (this.item.library)
    //   this.libraryFacade
    //     .libraryById$(this.item.library.id)
    //     .pipe(first())
    //     .subscribe((l) => {
    //       this.library = new Library({
    //         ...l,
    //         configuredParams: [],
    //         // l?.configuredParams.map(
    //         //   (p) =>
    //         //     new LibraryParamConfig({ ...p, choices: [...p.choices] })
    //         // ) ?? [],
    //       });
    //       //this.permissions = l?.permissions ?? [];
    //       this.formDisabled = !this.canEdit;
    //     });
    // else
    //   this.tilePageFacade.homePage$.pipe(first()).subscribe((p) => {
    //     this.library = undefined;
    //     //this.permissions = p?.permissions ?? [];
    //     this.formDisabled = !this.canEdit;
    //   });
  }

  updateFormValid(valid: boolean) {
    setTimeout(() => {
      this.formValid.next(valid);
    }, 0);
  }

  dirty = false;

  metadataChanges(value: { [key: string]: MetadataFilterType }) {
    this.itemValue = value;
    this.dirty = true;
  }

  updateItem() {
    this.updating = true;
    const existing = this.item.metadata;
    combineLatest([this.metadataFacade.metadataParams$, users$])
      .pipe(first())
      .subscribe(([params, users]) => {
        const newMetadata = Object.entries(this.itemValue)
          .filter(([_, v]) => v != undefined)
          .reduce((acc, [k, v]) => {
            const param = params.find((p) => p.id == k);
            if (Array.isArray(v)) {
              if (param?.type == ColumnType.GroupUser)
                return [
                  ...acc,
                  ...v.map(
                    (v) =>
                      new Metadata({
                        //metadataParameter: { id: k },
                        item: { id: this.item.id },
                        groupUserValue: v as any,
                      })
                  ),
                ];
              return [
                ...acc,
                ...v.map(
                  (v) =>
                    new Metadata({
                      //metadataParameter: { id: k },
                      item: { id: this.item.id },
                      value: v as any,
                    })
                ),
              ];
            }
            if (param?.type == ColumnType.DateTime) {
              if (param.modifiedOnParam)
                return [
                  ...acc,
                  new Metadata({
                    ishtarDMSMetadataId: existing.find(
                      (e) => e.metadataParameter!.id == k
                    )?.ishtarDMSMetadataId,
                    //metadataParameter: { id: k },
                    item: { id: this.item.id },
                    dateTimeValue: DateTime.fromJSDate(new Date()).toFormat(
                      'dd/MM/yyyy HH:mm'
                    ),
                  }),
                ];
              else
                return [
                  ...acc,
                  new Metadata({
                    ishtarDMSMetadataId: existing.find(
                      (e) => e.metadataParameter!.id == k
                    )?.ishtarDMSMetadataId,
                    //metadataParameter: { id: k },
                    item: { id: this.item.id },
                    dateTimeValue:
                      typeof v == 'string'
                        ? v
                        : DateTime.fromJSDate(v as Date).toFormat(
                            'dd/MM/yyyy HH:mm'
                          ),
                  }),
                ];
            } else if (param?.type == ColumnType.Number)
              return [
                ...acc,
                new Metadata({
                  ishtarDMSMetadataId: existing.find(
                    (e) => e.metadataParameter!.id == k
                  )?.ishtarDMSMetadataId,
                  // metadataParameter: { id: k },
                  item: { id: this.item.id },
                  numberValue: v as any,
                }),
              ];
            else if (param?.type == ColumnType.GroupUser)
              return [
                ...acc,
                new Metadata({
                  ishtarDMSMetadataId: existing.find(
                    (e) => e.metadataParameter!.id == k
                  )?.ishtarDMSMetadataId,
                  // metadataParameter: { id: k },
                  item: { id: this.item.id },
                  groupUserValue: param.modifiedByParam
                    ? users?.find((u) => u.user?.mail == this.msal.email) ??
                      (v as any)
                    : (v as any),
                }),
              ];
            else
              return [
                ...acc,
                new Metadata({
                  ishtarDMSMetadataId: existing.find(
                    (e) => e.metadataParameter!.id == k
                  )?.ishtarDMSMetadataId,
                  // metadataParameter: { id: k },
                  item: { id: this.item.id },
                  value: v as any,
                }),
              ];
          }, [] as Metadata[]);
      });
  }
}
