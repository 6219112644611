import { Injectable } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { LoaderService } from 'processdelight-angular-components';
import { first, of, switchMap } from 'rxjs';
import { appRoutes, routes, specialroutes } from 'src/app/app.routes';
import { PageComponent } from 'src/app/user/page/page.component';
import { isB2B } from '../extensions/b2x.extensions';
import { CanActivateHome } from '../guards/external-navigation.guard';
import { lastRouteKey } from '../guards/initial-navigation.guard';
import { PageFacade } from '../store/page/page.facade';

@Injectable({
  providedIn: 'root',
})
export class RouteBuilderService {
  constructor(
    private router: Router,
    private loader: LoaderService,
    private pageFacade: PageFacade
  ) {}
  private backToLastRoute() {
    const lastRoute = localStorage.getItem(lastRouteKey);
    if (lastRoute && lastRoute != '/' && !lastRoute.startsWith('/?')) {
      const loadId = this.loader.startLoading('Getting you back into action');
      const [route, query] = lastRoute.split('?');
      const params = Object.fromEntries(new URLSearchParams(query));
      setTimeout(() => {
        this.router.navigate(
          route
            .split('/')
            .filter((v) => !!v)
            .map((v) => decodeURIComponent(v)),
          { queryParams: params }
        );
        setTimeout(() => {
          this.loader.stopLoading(loadId);
        }, 1000);
      }, 0);
    } else {
      if (isB2B()) {
        this.router.navigate(['admin/settings', 'roles']);
      } else {
        this.router.navigate(['home']);
      }
    }
  }

  signupLink() {
    const lastRoute = localStorage.getItem(lastRouteKey);
    if (lastRoute == null) return null;
    const match = lastRoute.match('/organization-select/([a-zA-Z0-9-]*)?');
    if (match?.length !== 2) return null;
    return match[1];
  }

  initRoutes(navigate = true) {
    return this.pageFacade.pages$.pipe(
      first(),
      switchMap((pages) => {
        const homepage = pages.find((p) => p.isHomePage);
        const pageRoutes: Routes = pages.map((p) => ({
          path: 'home/' + p.title,
          component: PageComponent,
          data: {
            page: p,
          },
        }));
        pageRoutes.unshift({
          path: 'home',
          component: PageComponent,
          data: {
            page: homepage,
          },
          canActivate: [CanActivateHome],
        });
        const r = [...pageRoutes, ...appRoutes, ...routes];
        this.router.resetConfig(r);
        if (navigate) this.backToLastRoute();
        return of(true);
      })
    );
  }

  resetRoutes() {
    this.router.resetConfig(routes);
  }

  initSpecialRoute(path: string, navigateToRoute = false) {
    const r = [...routes];
    const specificRoute = specialroutes.find((r) => r.path == path);
    if (specificRoute) r.unshift(specificRoute);
    this.router.resetConfig(r);
    if (navigateToRoute) this.router.navigate([path]);
  }
}
