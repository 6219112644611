import { Action, createReducer, on } from '@ngrx/store';
import { PortalPost } from 'src/app/core/domain/models/portal-post.model';
import {
  createCommentResolved,
  createPostResolved,
  deleteCommentResolved,
  deletePostResolved,
  editCommentResolved,
  getPostsResolved,
  likePostResolved,
  removeLikeResolved,
  resetSlice,
  UpdatePostResolved,
} from './feed.actions';
import { PortalPostComment } from 'src/app/core/domain/models/portal-post-comment.model';

export const featureSlice = 'feed';

export interface State {
  posts?: PortalPost[];
  loadedAllPosts: boolean;
}

const defaultState: State = {
  posts: undefined,
  loadedAllPosts: false,
};

export function Reducer(state: State | undefined, action: Action) {
  return feedReducer(state, action);
}

export const initialState: State = defaultState;
export const feedReducer = createReducer(
  initialState,
  on(getPostsResolved, (state, { feed, resetPaging, requestPageSize }) => ({
    ...state,
    posts: resetPaging ? feed : (state.posts ?? []).concat(feed),
    loadedAllPosts: feed.length < requestPageSize,
  })),
  on(createPostResolved, (state, { post }) => ({
    ...state,
    posts: [post].concat(state.posts ?? []),
  })),
  on(UpdatePostResolved, (state, { post }) => ({
    ...state,
    posts: (state.posts ?? []).map((p) => (p.id === post.id ? post : p)),
  })),
  on(deletePostResolved, (state, { id }) => ({
    ...state,
    posts: state.posts?.filter((p) => p.id !== id),
  })),
  on(likePostResolved, (state, { like }) => ({
    ...state,
    posts: state.posts?.map((p) =>
      p.id === like.postId
        ? new PortalPost({ ...p, likes: p.likes.concat(like) })
        : p.comments.some((c) => c.id === like.commentId)
        ? new PortalPost({
            ...p,
            comments: p.comments.map((c) =>
              c.id === like.commentId
                ? new PortalPostComment({ ...c, likes: c.likes.concat(like) })
                : c
            ),
          })
        : p
    ),
  })),
  on(removeLikeResolved, (state, { likeId, postId, commentId }) => ({
    ...state,
    posts: state.posts?.map((p) =>
      p.id === postId
        ? new PortalPost({
            ...p,
            likes: p.likes.filter((l) => l.id !== likeId),
          })
        : p.comments.some((c) => c.id === commentId)
        ? new PortalPost({
            ...p,
            comments: p.comments.map((c) =>
              c.id === commentId
                ? new PortalPostComment({
                    ...c,
                    likes: c.likes.filter((l) => l.id !== likeId),
                  })
                : c
            ),
          })
        : p
    ),
  })),
  on(createCommentResolved, (state, { comment }) => ({
    ...state,
    posts: state.posts?.map((p) =>
      p.id === comment.postId
        ? new PortalPost({ ...p, comments: p.comments.concat(comment) })
        : p
    ),
  })),
  on(editCommentResolved, (state, { comment }) => ({
    ...state,
    posts: state.posts?.map((p) =>
      p.id === comment.postId
        ? new PortalPost({
            ...p,
            comments: p.comments.map((c) =>
              c.id === comment.id ? comment : c
            ),
          })
        : p
    ),
  })),
  on(deleteCommentResolved, (state, { commentId, postId }) => ({
    ...state,
    posts: state.posts?.map((p) =>
      p.id === postId
        ? new PortalPost({
            ...p,
            comments: p.comments.filter((l) => l.id !== commentId),
          })
        : p
    ),
  })),
  on(resetSlice, () => initialState)
);
