import {
  HttpEvent,
  HttpEventType,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { map, mergeMap } from 'rxjs/operators';
import { MicrosoftAuthenticationService } from './microsoft-authentication.service';

export const microsoftAuthenticationInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
) => {
  const msalService = inject(MicrosoftAuthenticationService);
  return msalService
    .getAccessToken(req.url)
    .pipe(
      mergeMap((token) => {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
        return next(req);
      })
    )
    .pipe(
      map((res) => {
        if (res.type === HttpEventType.Response) {
          const newResponse: HttpEvent<any> = new HttpResponse({
            body: res.body,
            headers: res.headers,
            status: res.status,
            statusText: res.statusText,
            url: res.url ?? undefined,
          });
          return newResponse;
        } else {
          return res;
        }
      })
    );
};
