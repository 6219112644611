import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  ContextMenuAction,
  CRMEntity,
  DataObject,
  DataObjectService,
  DataProperty,
  DataSourceService,
  EntityObject,
  FormBuilderComponent,
  GroupUser,
  RelationFieldTable,
} from 'processdelight-angular-components';
import { PortalService } from 'src/app/core/services/portal.service';
import { TranslationService } from 'processdelight-angular-components';
import { ChangeRequestPopupComponent } from './change-request-popup/change-request-popup.component';
import { BehaviorSubject, first, Subject, take, takeUntil } from 'rxjs';
import { UserFacade } from 'src/app/core/store/user/user.facade';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule,
    FormBuilderComponent,
    MatCheckboxModule,
  ],
  providers: [DataObjectService],
})
export class ProfileComponent implements OnInit, OnDestroy {
  @ViewChild('clientName') clientName!: TemplateRef<{
    config: DataObject;
  }>;
  @ViewChild('clientTeams') clientTeams!: TemplateRef<{
    config: DataObject;
  }>;

  relationFieldTables: RelationFieldTable[] = [];
  peopleAndGroups = new BehaviorSubject<GroupUser[]>([]);
  loadingProfileData = false;
  profileData?: EntityObject<CRMEntity>;
  noProfile = false;
  templates: {
    configurationName: string;
    template?: TemplateRef<{
      config: DataObject;
    }>;
    form?: FormControl;
  }[] = [];
  clientForm: FormGroup = new FormGroup({});

  getTranslation$(key: string) {
    return this.translationService.getTranslation$(key);
  }
  GetValueOfObject: (
    dataObject: DataObject,
    dataProperty: DataProperty,
    joinString?: string
  ) => string = this.dataSourceService.GetValueOfObject;

  @Output() buttonActions = new EventEmitter<ContextMenuAction<unknown>[]>();

  constructor(
    private portalService: PortalService,
    private translationService: TranslationService,
    public dataObjectService: DataObjectService,
    private requestChangeDialog: MatDialog,
    private dataSourceService: DataSourceService,
    private userFacade: UserFacade
  ) {}

  ngOnInit(): void {
    this.buttonActions.next([
      new ContextMenuAction({
        icon: 'sync',
        label: this.translationService.getTranslation('requestChanges'),
        action: () => {
          this.requestChanges();
        },
      }),
    ]);

    this.userFacade.externalUserInfo$.pipe(take(1)).subscribe((user) => {
      if (user && user.linkedCRMEntityId) {
        this.loadingProfileData = true;
        this.portalService
          .getProfileData(user.linkedCRMEntityId)
          .subscribe((data) => {
            this.loadingProfileData = false;
            if (data) this.profileData = data;
            this.initForm(this.profileData?.entity);
            this.dataObjectService.setOriginalData(
              this.profileData?.dataObjects ?? [],
              this.templates
            );
          });
      } else {
        this.noProfile = true;
      }
    });
  }

  initForm(entity?: CRMEntity) {
    this.clientForm = new FormGroup({
      clientName: new FormControl(entity?.name ?? ''),
      teams: new FormControl({
        value: entity?.teamId ? true : false,
        disabled: true,
      }),
    });
    this.templates = [
      this.getTemplateForm(
        'Name',
        this.clientForm.controls.clientName as FormControl
      ),
      this.getTemplate('TeamId', this.clientTeams),
    ];
  }

  getTemplate(
    configurationName: string,
    template: TemplateRef<{ config: DataObject }>
  ): {
    configurationName: string;
    template: TemplateRef<{ config: DataObject }>;
  } {
    return { configurationName, template };
  }

  getDataObjects() {
    return this.profileData?.dataObjects
      ? this.profileData.dataObjects.filter((d) => !d.isStandardObject)
      : [];
  }
  requestChanges() {
    this.requestChangeDialog.open(ChangeRequestPopupComponent, {
      width: '50%',
      height: '250px',
      disableClose: true,
      data: {
        entity: this.profileData?.entity,
      },
    });
  }

  private getTemplateForm(
    configurationName: string,
    form: FormControl
  ): {
    configurationName: string;
    template?: TemplateRef<{ config: DataObject }>;
    form?: FormControl;
  } {
    return { configurationName, form };
  }
  destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
