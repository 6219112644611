import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  EntityDashboardComponent,
  EntityFilter,
  EntityObject,
  Project,
  Skill,
  Status,
  TaskType,
} from 'processdelight-angular-components';
import {
  catchError,
  first,
  forkJoin,
  map,
  Observable,
  switchMap,
  throwError,
} from 'rxjs';
import { isB2C } from 'src/app/core/extensions/b2x.extensions';
import { camelcaseKeys } from 'src/app/core/extensions/object.extensions';
import { ClientFacade } from 'src/app/core/store/client/client.facade';
import { UserFacade } from 'src/app/core/store/user/user.facade';
import { ResourceThing } from '../domain/models/resource-thing.model';
import { ResourceUser } from '../domain/models/resource-user.model';
import { Task } from '../domain/models/task.model';
import { MicrosoftAuthenticationService } from 'src/app/msal/injectables/microsoft-authentication.service';

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  apiBase = `${location.origin}/web`;
  static tenantId = '93e3ed2e-e22f-4a14-801c-53d1c4279e2a';

  constructor(
    private httpClient: HttpClient,
    private userFacade: UserFacade,
    private clientFacade: ClientFacade,
    private msal: MicrosoftAuthenticationService,
  ) {}

  private b2x() {
    return isB2C() ? 'b2c' : 'b2b';
  }

  getSkills() {
    return this.httpClient.get<Skill[]>(`${this.apiBase}/${this.b2x()}/tasks/types/skills`)
      .pipe(
        map((skills) => skills.map((s) => new Skill(camelcaseKeys(s))))
      );
  }

  getProjects() {
    return this.httpClient.get<Project[]>(`${this.apiBase}/${this.b2x()}/tasks/types/projects`)
      .pipe(
        map((projects) => projects.map((p) => new Project(camelcaseKeys(p))))
      );
  }

  getStatusses() {
    return this.httpClient.get<Status[]>(`${this.apiBase}/${this.b2x()}/tasks/types/statusses`)
      .pipe(
        map((statusses) => statusses.map((s) => new Status(camelcaseKeys(s))))
      );
  }

  getTaskTypes() {
    return this.httpClient.get<TaskType[]>(`${this.apiBase}/${this.b2x()}/tasks/types/task-types`)
      .pipe(
        map((taskTypes) => taskTypes.map((tt) => new TaskType(camelcaseKeys(tt))))
      );
  }

  getResourceThings() {
    return this.httpClient.get<ResourceThing[]>(`${this.apiBase}/${this.b2x()}/tasks/types/resource-things`)
      .pipe(
        map((resourceThings) => resourceThings.map((rt) => new ResourceThing(camelcaseKeys(rt))))
      );
  }

  getResourceUsers() {
    return this.httpClient.get<ResourceUser[]>(`${this.apiBase}/${this.b2x()}/tasks/types/resource-users`)
      .pipe(
        map((resourceUsers) => resourceUsers.map((ru) => new ResourceUser(camelcaseKeys(ru))))
      );
  }

  getAllTasks() {
    return this.httpClient.get<Task[]>(`${this.apiBase}/${this.b2x()}/tasks/all`)
      .pipe(
        map((tasks) => tasks.map((t) => new Task(camelcaseKeys(t))))
      );
  }

  public getEventStatus(organizerId?: string, taskId?: string): Observable<{ attendeeStatusses?: { email: string; responseType: string; userId: string; }[]; meetingLink?: string; }> {
    const body = {
      organizerId: organizerId ?? "",
      taskId: taskId ?? ""
    };

    return this.httpClient.post<{ attendeeStatusses?: { email: string; responseType: string; userId: string; }[]; meetingLink?: string; }>(`${this.apiBase}/${this.b2x()}/tasks/event-status`, body)
      .pipe(
        map((r) => (r)),
        catchError((error) => {
          console.error('An error occurred on get event status:', error);
          return throwError(error);
        })
      );
  }

  getTaskEntities(
    assignedToMe: boolean,
    internalSort: boolean,
    orderBy: string | undefined,
    orderByDirection: 'asc' | 'desc' | '',
    filters: EntityFilter<EntityObject<Task>>[],
    pageSize?: number,
    page?: number,
    filterActive?: boolean,
    filteredInterestGroupIds?: string[]
  ) {
    if (!pageSize || pageSize <= 0) pageSize = 100;
    const getFilter = (internal: boolean) => filters.filter((f) => f.column.internal == internal);
    const internalFilters = getFilter(true);
    const externalFilters = getFilter(false);
    let internalFilterString = EntityDashboardComponent.createFilterString(internalFilters);
    const dataFilterString = EntityDashboardComponent.createFilterString(externalFilters);
    
    if (assignedToMe) {
      internalFilterString += internalFilterString.length > 0
        ? '&'
        : '' + `assignedToMe eq ${this.msal.id}`;
    }

    const parseQueryString = (items: {
      [key: string]: string | number | boolean | undefined;
    }) => {
      return Object.keys(items)
        .filter((key) => !!items[key])
        .map((key) => `${key}=${items[key]}`)
        .join('&');
    };
    return (
      isB2C()
        ? forkJoin([
            this.clientFacade.currentClient$.pipe(first()),
            this.userFacade.externalUserInfo$.pipe(first()),
          ]).pipe(
            first(),
            switchMap(([client, user]) =>
              this.httpClient.post<{
                result: EntityObject<Task>[];
                totalRecordCount: number;
              }>(
                `${this.apiBase}/${this.b2x()}/tasks/entities?${parseQueryString({
                  pageSize,
                  page,
                  internalOrderBy: internalSort ? orderBy : undefined,
                  dataOrderBy: internalSort ? undefined : orderBy,
                  orderByDirection,
                  internalFilter: internalFilterString,
                  dataFilter: dataFilterString,
                  filterActive,
                  clientId: client?.id,
                  userId: user?.user.id,
                })}`,
                filteredInterestGroupIds ?? []
              )
            )
          )
        : this.httpClient.post<{
            result: EntityObject<Task>[];
            totalRecordCount: number;
          }>(
            `${this.apiBase}/${this.b2x()}/tasks/entities?${parseQueryString({
              pageSize,
              page,
              internalOrderBy: internalSort ? orderBy : undefined,
              dataOrderBy: internalSort ? undefined : orderBy,
              orderByDirection,
              internalFilter: internalFilterString,
              dataFilter: dataFilterString
            })}`,
            undefined
          )
    ).pipe(
      map(({ result, totalRecordCount }) => ({
        result: result.map(
          (c) =>
            new EntityObject<Task>({
              ...c,
              entity: new Task(c.entity ?? {}),
            })
        ),
        totalRecordCount,
      }))
    );
  }
}
