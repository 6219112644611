import {
  BaseEntity,
  GroupUser,
  GroupUserEntity,
  Lookup,
  MetadataParam,
} from 'processdelight-angular-components';

export class Metadata extends BaseEntity {
  value?: string;
  numberValue?: number;
  dateTimeValue?: string;
  groupUserValue?: GroupUser;
  groupUserValueId?: string;
  groupUserValueEntity?: GroupUserEntity;
  itemId?: string;
  item?: Lookup;
  metadataParameterId?: string;
  metadataParameter?: MetadataParam;

  constructor(obj: Partial<Metadata>) {
    super(obj);
    Object.assign(this, obj);
    if (obj.groupUserValue)
      this.groupUserValue = new GroupUser(obj.groupUserValue);
  }
}
