import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AccountInfo,
  Configuration,
  IPublicClientApplication,
  PopupRequest,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';
import { AuthenticationResult } from '@azure/msal-common';
import * as microsoftTeams from '@microsoft/teams-js';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';
import { getSubDomain, isB2B } from 'src/app/core/extensions/b2x.extensions';
import { environment } from 'src/environments/environment';

export const PERSONAL_ACCOUNT_TENANT_ID =
  '9188040d-6c67-4c5b-b112-36a304b66dad';

@Injectable({
  providedIn: 'root',
})
export class MicrosoftAuthenticationService {
  get isAuthenticated(): boolean {
    return this.accountInfo !== undefined;
  }

  get username(): string {
    return this.isAuthenticated ? this.accountInfo.username : '';
  }

  get name(): string | undefined {
    return this.isAuthenticated ? this.accountInfo.name : undefined;
  }

  get id(): string {
    return this.isAuthenticated
      ? this.accountInfo.homeAccountId.split('.')[0]
      : '';
  }

  get tenantId(): string {
    return this.isAuthenticated ? this.accountInfo.tenantId : '';
  }

  get isPersonalAccount(): boolean {
    return this.tenantId == PERSONAL_ACCOUNT_TENANT_ID;
  }

  get accounts(): AccountInfo[] {
    return this.client?.getAllAccounts() ?? [];
  }

  private scopes: string[] = ['openid', 'offline_access', 'profile'];

  get accountInfo(): AccountInfo {
    return this.accounts[0];
  }

  signedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  needsSignBackIn = false;
  runningOnTeams = false;

  authError = '';

  private isInternal = false;

  private specificAuthority?: string;

  private config?: Configuration;
  private client?: IPublicClientApplication;
  private interceptors: Map<string, string[]> = new Map();

  private get silentSigninRedirectUri() {
    const redirectUri = this.client?.getConfiguration().auth.redirectUri;
    return redirectUri?.replace('signin', 'silent-signin');
  }

  constructor(private readonly router: Router) {
    microsoftTeams.app
      .initialize()
      .then(() => (this.runningOnTeams = true))
      .catch(() => console.log('Not running in Teams'));
  }

  configure(
    c: Configuration,
    interceptors?: Map<string, InterceptorScope>,
    internal = false
  ): Promise<void> {
    this.isInternal = internal;
    this.config = c;
    this.specificAuthority = c.auth.authority;
    this.interceptors.set('default', this.scopes);
    // this.interceptors.set(environment.ishtarFunctions, [
    //   environment.ishtarFunctionsScope,
    // ]);
    if (internal)
      this.interceptors.set(location.origin, [
        environment.ishtarFunctionsScope,
      ]);
    else this.interceptors.set(location.origin, [environment.b2cScope]);
    if (interceptors) {
      interceptors.forEach((i, k) => {
        if (i.interactive) this.scopes = this.scopes.concat(i.scopes);
        this.interceptors.set(k, i.scopes);
      });
    }
    this.client = new PublicClientApplication(this.config);
    return this.client.initialize();
  }

  addInterceptor(url: string, scopes: string[]): void {
    this.interceptors.set(url, scopes);
  }

  /**
   * Initializes the authentication process
   * based on the information of IshtarContext
   */
  authenticate(state = ''): void {
    if (this.runningOnTeams) {
      microsoftTeams.authentication
        .authenticate({
          url: window.location.origin + '/teams-authentication',
          width: 600,
          height: 535,
        })
        .then(() => this.silentSignIn())
        .catch((reason: string) => console.error(reason));
    } else {
      this.signIn(state);
    }
  }

  /**
   * Checks the cache for an account and acquires
   * the access tokens if set.
   */
  silentSignIn(loginHint?: string): void {
    const accounts = this.client?.getAllAccounts() ?? [];
    if (accounts.length) this.signedIn.next(true);
    else if (this.isInternal)
      loginHint
        ? this.client
            ?.ssoSilent({
              scopes: this.scopes,
              authority: this.specificAuthority,
              redirectUri: this.silentSigninRedirectUri,
              state: location.origin,
              loginHint,
            })
            .then(() => {
              const accounts = this.client?.getAllAccounts() ?? [];
              if (accounts.length) this.signedIn.next(true);
              else this.router.navigate(['signin-failed']);
            })
            .catch(() => this.redirect(''))
        : this.redirect('');
  }

  /**
   * Initializes the sign-in process.
   * @param state current state of the router.
   * @param popup whether to use the redirect or popup flow.
   */
  signIn(state = '', options: any = {}, navigate = true, popup = false): void {
    const accounts = this.client?.getAllAccounts() ?? [];
    if (accounts.length && !this.needsSignBackIn) {
      this.signedIn.next(true);
    } else {
      popup
        ? this.popup(state, options, navigate)
        : this.redirect(state, options);
    }
  }

  /**
   * Completes the sign-in process after the user
   * is redirected to the application.
   */
  completeSignIn(): void {
    this.client
      ?.handleRedirectPromise()
      .then((r) => {
        this.onCompleteSignIn(r);
      })
      .catch((e) => {
        console.error(e);
        this.navigateToAuthErrorPage(e);
      });
  }

  signOut(): Promise<void> {
    if (this.runningOnTeams) {
      return microsoftTeams.authentication
        .authenticate({
          url: `${location.origin}/teams-sign-out`,
          width: 535,
          height: 600,
        })
        .then(() => this.completeSignout());
    } else return this.signOutRedirect().then(() => this.completeSignout());
  }

  signOutRedirect(): Promise<void> {
    return (
      this.client?.logoutRedirect({ account: this.accountInfo }) ??
      new Promise<void>((_, reject) => reject('No client available'))
    );
  }

  completeSignout(): void {
    this.client?.handleRedirectPromise().then(() => {
      this.signedIn.next(false);
      setTimeout(() => {
        location.assign(`${location.origin}/?teams_reload=true`);
      }, 100);
    });
  }

  async changeAuthority(authority: string) {
    this.specificAuthority = authority;
  }

  runningAccessTokenGets: { [key: string]: Observable<string> } = {};

  getAccessToken(url = 'default'): Observable<string> {
    if (this.runningAccessTokenGets[url])
      return this.runningAccessTokenGets[url];
    let scopes: string[] = this.scopes;
    this.interceptors.forEach((i: string[], key: string) =>
      url.includes(key) ? (scopes = i) : undefined
    );
    const sub = new Subject<string>();
    new Observable<string>((o) => {
      const complete = (v: string) => {
        delete this.runningAccessTokenGets[url];
        o.next(v);
        o.complete();
      };
      const reject = (v: string) => {
        delete this.runningAccessTokenGets[url];
        o.error(v);
        o.complete();
      };
      if (this.client) {
        const request: SilentRequest = {
          account: this.accountInfo,
          scopes: scopes,
          authority: this.specificAuthority,
          state: this.isInternal ? location.origin : 'default',
          redirectUri: this.silentSigninRedirectUri,
        } as SilentRequest;
        this.client
          .acquireTokenSilent(request)
          .then((result) => {
            this.needsSignBackIn = false;
            complete(result.accessToken);
          })
          .catch((err) => {
            switch (err.errorCode) {
              case 'user_cancelled':
              case 'interaction_required':
              case 'invalid_grant':
                if (/not consented/.test(err.errorMessage)) {
                  console.error(err);
                  this.navigateToAuthErrorPage(err);
                } else {
                  this.needsSignBackIn = true;
                  delete this.runningAccessTokenGets[url];
                  if ((err as any).claims)
                    request.claims = JSON.stringify((err as any).claims);
                  this.redirect(getSubDomain()!);
                }
                break;
              default:
                this.needsSignBackIn = true;
                delete this.runningAccessTokenGets[url];
                if ((err as any).claims)
                  request.claims = JSON.stringify((err as any).claims);
                this.redirect(getSubDomain()!);
                break;
            }
            reject(err.errorMessage);
          });
      } else {
        reject('No auth client available');
      }
    })
      .pipe(
        first(),
        catchError((e) => {
          sub.error(e);
          sub.complete();
          return of(e);
        })
      )
      .subscribe((s: string) => {
        sub.next(s);
        sub.complete();
      });
    this.runningAccessTokenGets[url] = sub.asObservable();
    return sub.asObservable();
  }

  denyAccess(): void {
    this.router.navigate(['/NoAccess']);
  }

  private redirect(state: string, options: any = {}): void {
    const request: RedirectRequest = {
      scopes: this.scopes,
      state: this.isInternal ? location.origin : state,
      authority: this.specificAuthority,
      ...options,
    };
    this.client?.loginRedirect(request).catch((e) => {
      this.client
        ?.handleRedirectPromise()
        .then((r) => {
          if (!this.client?.getActiveAccount()) {
            this.navigateToAuthErrorPage('No active account found');
          }
        })
        .catch((e) => {
          console.error(e);
          this.navigateToAuthErrorPage(e);
        });
    });
  }

  private popup(state: string, options: any = {}, navigate = true): void {
    const request: PopupRequest = {
      scopes: this.scopes,
      state: this.isInternal ? location.origin : state,
      authority: this.specificAuthority,
      ...options,
    };
    this.client
      ?.loginPopup(request)
      .then((r: AuthenticationResult) => this.onCompleteSignIn(r, navigate))
      .catch((e) => {
        console.error(e);
        this.navigateToAuthErrorPage(e);
      });
  }

  private onCompleteSignIn(resp: AuthenticationResult | null, navigate = true) {
    if (resp) {
      this.signedIn.next(true);
      if (navigate) this.navigateToReturnUrl(resp.state || '');
    }
  }

  private async navigateToReturnUrl(returnUrl: string) {
    // It's important that we do a replace here so that we remove the callback uri with the
    // fragment containing the tokens from the browser history.
    await this.router.navigateByUrl(returnUrl, {
      replaceUrl: true,
    });
  }

  private navigateToAuthErrorPage(error: string) {
    this.authError = error;
    this.router.navigate(['/auth-error']);
  }
}

export interface InterceptorScope {
  interactive?: boolean;
  scopes: string[];
}
