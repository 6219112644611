import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/core/store/app.reducer';
import { BaseFacade } from '../base.facade';
import {
  getPages,
  getWebPartTypes,
  resetSlice,
  updatePageConfiguration,
} from './page.actions';
import { pageSelectors } from './page.selectors';
import { PageConfigurationDTO } from '../../domain/dto/page-configuration.dto';

@Injectable({
  providedIn: 'root',
})
export class PageFacade extends BaseFacade {
  loadingPages$ = this._store.pipe(select(pageSelectors.loadingPages));
  pages$ = this._store.pipe(select(pageSelectors.getPages));

  loadingWebPartTypes$ = this._store.pipe(
    select(pageSelectors.loadingWebPartTypes)
  );
  webPartTypes$ = this._store.pipe(select(pageSelectors.getWebPartTypes));

  constructor(_store: Store<AppState>) {
    super(_store);
  }

  getPages$() {
    return this.dispatchAction$(getPages);
  }
  updatePageConfiguration$(configuration: PageConfigurationDTO) {
    return this.dispatchAction$(updatePageConfiguration, { configuration });
  }
  getWebPartTypes$() {
    return this.dispatchAction$(getWebPartTypes);
  }

  resetSlice() {
    this._store.dispatch(resetSlice());
  }
}
