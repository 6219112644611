import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/core/store/app.reducer';
import { BaseFacade } from '../base.facade';
import {
  getGroups,
  resetSlice,
  updateGroupConfiguration,
} from './group.actions';
import { groupSelectors } from './group.selectors';
import { GroupConfigurationDTO } from '../../domain/dto/group-configuration.dto';
import { first, map, withLatestFrom } from 'rxjs';
import { ClientFacade } from '../client/client.facade';

@Injectable({
  providedIn: 'root',
})
export class GroupFacade extends BaseFacade {
  loadingGroups$ = this._store.pipe(select(groupSelectors.loadingGroups));
  groups$ = this._store.pipe(select(groupSelectors.getGroups));
  getGroupById$ = (id: string) =>
    this._store.pipe(
      select(groupSelectors.getGroupById),
      map((fn) => fn(id))
    );

  getGroupsByClientId$ = (clientId: string) =>
    this._store.pipe(
      select(groupSelectors.getGroupsByClientId),
      map((fn) => fn(clientId)),
      withLatestFrom(this.clientFacade.getClientById$(clientId)),
      map(([groups, client]) =>
        client?.config?.useGeneralRoles
          ? groups
          : groups.filter((g) => g.clientId === clientId)
      )
    );

  constructor(_store: Store<AppState>, private clientFacade: ClientFacade) {
    super(_store);
  }

  getGroups(clientId?: string) {
    let groups = [];
    this.groups$.pipe(first()).subscribe((g) => {
      groups = g;
    });
    if (groups.length > 0) return;
    this.dispatchAction$(getGroups, { clientId }).subscribe();
  }

  updateGroupConfiguration$(groupConfiguration: GroupConfigurationDTO[]) {
    return this.dispatchAction$(updateGroupConfiguration, {
      groupConfiguration,
    });
  }

  resetSlice() {
    this._store.dispatch(resetSlice());
  }
}
