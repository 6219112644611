import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Memoize, clear as MemoizeClear } from 'typescript-memoize';
import { VerhofsteProduct } from '../domain/models/product.model';
import { WholeNumbersValidatorDirective } from './whole-number.validator.directive';

export class VerhofsteProductLineVM {
  constructor(
    public product: VerhofsteProduct,
    public amount: number | undefined,
    public ral: string | undefined
  ) {}
}

@Component({
  standalone: true,
  selector: 'app-verhofste-product-table',
  templateUrl: './verhofste-product-table.component.html',
  styleUrls: ['./verhofste-product-table.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatTableModule,
    MatCheckboxModule,
    MatButtonModule,
    MatMenuModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatOptionModule,
    WholeNumbersValidatorDirective,
  ],
})
export class VerhofsteProductTableComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() resetSelectedItems = false;
  @Input() showTotalPrice = false;
  @Input() data!: VerhofsteProductLineVM[];
  @Input() displayedColumns!: string[];
  @Input() actualDisplayedColumns!: string[];
  @Input() possibleColumns!: {
    [id: string]: {
      internalName: string;
      displayName: string;
      sortable: boolean;
      fixed: boolean;
      filterValue: string;
      valueAccessor: (item: VerhofsteProductLineVM) => any;
      sortValueAccessor: (item: VerhofsteProductLineVM) => string | number;
    };
  };
  @Input() amountTableDef!: {
    internalName: string;
    displayName: string;
    sortable: boolean;
    fixed: boolean;
    filterValue: string;
    valueAccessor: (item: VerhofsteProductLineVM) => number | undefined;
    sortValueAccessor: (item: VerhofsteProductLineVM) => string | number;
  };
  @Input() ralTableDef!: {
    internalName: string;
    displayName: string;
    sortable: boolean;
    fixed: boolean;
    filterValue: string;
    valueAccessor: (item: VerhofsteProductLineVM) => string | undefined;
    sortValueAccessor: (item: VerhofsteProductLineVM) => string | number;
  };
  @Input() filtersEnabled = false;
  @Input() sortSettings = {
    direction: '',
    active: '',
  };
  @Input() ralLabel?: string;

  @Output() selectedItemsChange = new EventEmitter<VerhofsteProductLineVM[]>();
  @Output() inputsChanged = new EventEmitter<void>();
  selectedItems: VerhofsteProductLineVM[] = [];

  dataSource = new MatTableDataSource<VerhofsteProductLineVM>([]);

  stringTrackByFn = (_index: number, item: string) => item;

  @ViewChild(MatSort, { static: true }) sort: MatSort = new MatSort();

  pageSettings = {
    pageIndex: 0,
    pageSize: 10,
    length: 0,
  };
  filterSettings = {
    value: '',
    column: '',
  };

  ngOnInit(): void {
    this.dataChange();
  }

  ngOnChanges(): void {
    this.dataChange();
    if (this.resetSelectedItems) {
      this.selectedItems = [];
      this.selectedItemsChange.emit(this.selectedItems);
      MemoizeClear(['checked']);
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item, sortHeaderId) => {
        if (sortHeaderId === 'Amount')
          return this.amountTableDef.sortValueAccessor(item);
        if (sortHeaderId === 'RAL')
          return this.ralTableDef.sortValueAccessor(item);
        const column = this.possibleColumns[sortHeaderId];
        return column.sortValueAccessor(item);
      };
      this.sort.sort({
        disableClear: true,
        id: this.sortSettings.active,
        start: this.sortSettings.direction as 'asc' | 'desc',
      });
    }, 0);
  }

  sortData($event: Sort): void {
    this.sortSettings = $event;
    this.pageSettings.pageIndex = 0;
    this.dataChange();
  }

  pageData($event: PageEvent): void {
    this.pageSettings = $event;
    this.dataChange();
  }

  filterData(e: string, column: string): void {
    this.filterSettings = {
      value: e,
      column,
    };
    this.dataChange();
  }

  collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: 'base',
  });

  onCheckChange(itemId: string) {
    this.resetSelectedItems = false;
    const item = this.data.find((i) => i.product.itemId === itemId)!;
    if (this.selectedItems.some((i) => i.product.itemId === itemId))
      this.selectedItems = this.selectedItems.filter(
        (i) => i.product.itemId !== itemId
      );
    else this.selectedItems.push(item);
    this.selectedItemsChange.emit(this.selectedItems);
    MemoizeClear(['checked']);
  }

  selectAll($event: MatCheckboxChange) {
    this.selectedItems = $event.checked ? this.data : [];
    this.selectedItemsChange.emit(this.selectedItems);
    MemoizeClear(['checked']);
  }

  @Memoize({ tags: ['checked'] })
  isChecked(itemId: string): boolean {
    return this.selectedItems.some((i) => i.product.itemId === itemId);
  }

  @Memoize({
    tags: ['ral'],
    hashFunction: (item: VerhofsteProductLineVM) => item.product.itemId,
  })
  needsRal(item: VerhofsteProductLineVM): boolean {
    return (
      !!this.ralLabel &&
      item.product.itemName.toLowerCase().includes(this.ralLabel.toLowerCase())
    );
  }

  onAmountChange(e: VerhofsteProductLineVM): void {
    this.resetSelectedItems = false;
    this.inputsChanged.emit();
    const amount = e.amount;
    if (amount === undefined || Number.isNaN(amount) || amount < 0) {
      e.amount = 0;
    }
  }
  onRalChange(e: VerhofsteProductLineVM): void {
    this.resetSelectedItems = false;
    this.inputsChanged.emit();
    if (e.ral === undefined) {
      e.ral = '';
    }
  }

  dataChange(): void {
    const onlyUnique = (
      value: VerhofsteProductLineVM,
      index: number,
      array: VerhofsteProductLineVM[]
    ) =>
      array.findIndex((p) => p.product.itemId === value.product.itemId) ===
      index;

    const data = this.data.filter(onlyUnique);

    const filtered = data.filter((item) => {
      if (this.filterSettings.column === '') return true;
      const val =
        this.possibleColumns[this.filterSettings.column].valueAccessor(item);
      return val
        ?.toString()
        .toLowerCase()
        .includes(this.filterSettings.value.toLowerCase());
    });

    let sortOrder = 0;
    switch (this.sortSettings.direction) {
      // Reverse sort based on direction in sortDirection
      case 'asc':
        sortOrder = 1;
        break;
      case 'desc':
        sortOrder = -1;
        break;
    }
    const sorted =
      this.sortSettings.active !== ''
        ? filtered.sort((a, b) => {
            const valA = this.possibleColumns[
              this.sortSettings.active
            ].valueAccessor(a) as string;
            const valB = this.possibleColumns[
              this.sortSettings.active
            ].valueAccessor(b) as string;
            const result = this.collator.compare(valA, valB);
            return result * sortOrder;
          })
        : filtered;

    const pagedData = sorted.slice(
      this.pageSettings.pageIndex * this.pageSettings.pageSize,
      this.pageSettings.pageIndex * this.pageSettings.pageSize +
        this.pageSettings.pageSize
    );

    this.dataSource.data = pagedData;
    MemoizeClear(['checked', 'ral']);
  }
}
