import { createAction, props } from '@ngrx/store';
import { ActionBase } from '../action-base.interface';
import { Library } from 'src/app/webparts/dms-component/domain/models/library.model';
import { LibraryItem } from 'src/app/webparts/dms-component/domain/models/item.model';
import { Observable } from 'rxjs';

export const getLibraries = createAction(
  '[Library] Get Libraries',
  props<ActionBase<Library[]>>()
);
export const getLibrariesResolved = createAction(
  '[Library] Get Libraries resolved',
  props<{ libraries: Library[] }>()
);
export const getLibrary = createAction(
  '[Library] Get Library',
  props<{ id: string } & ActionBase<Library>>()
);
export const getLibraryResolved = createAction(
  '[Library] Get Library resolved',
  props<{ library: Library }>()
);
export const getSharedItems = createAction(
  '[Library] Get shared items',
  props<
    {
      orderBy: string | undefined;
      orderByDirection: 'asc' | 'desc' | undefined;
      filters: { [key: string]: string };
      pageSize: number;
      page: number;
    } & ActionBase<{
      result: LibraryItem[];
      totalRecordCount: number;
    }>
  >()
);
export const getSharedItemsResolved = createAction(
  '[Library] Get shared items resolved',
  props<{
    result: LibraryItem[];
    totalRecordCount: number;
  }>()
);
export const getLibraryItems = createAction(
  '[Library] Get Library items',
  props<
    {
      orderBy: string | undefined;
      orderByDirection: 'asc' | 'desc' | undefined;
      filters: { [key: string]: string };
      pageSize: number;
      page: number;
      filterActive: boolean;
      libraryId: string | undefined;
      filteredInterestGroupIds: string[] | undefined;
      reset$: (() => Observable<void>) | undefined;
    } & ActionBase<{
      result: LibraryItem[];
      totalRecordCount: number;
    }>
  >()
);
export const getLibraryItemsResolved = createAction(
  '[Library] Get Library items resolved',
  props<{
    result: LibraryItem[];
    totalRecordCount: number;
  }>()
);
export const createLibraryItems = createAction(
  '[Library] Create Library items',
  props<
    {
      items: LibraryItem[];
    } & ActionBase<LibraryItem[]>
  >()
);
export const createLibraryItemsResolved = createAction(
  '[Library] Create Library items resolved',
  props<{
    items: LibraryItem[];
  }>()
);

export const resetSlice = createAction('[Library] Reset slice');
