import { createAction, props } from '@ngrx/store';
import { InterestGroup } from 'processdelight-angular-components';
import { ActionBase } from '../action-base.interface';
import { UserJoinInterestGroupDTO } from '../../domain/dto/user-join-interest-group.dto';

export const getInterestGroups = createAction(
  '[InterestGroup] Get interest groups',
  props<ActionBase<InterestGroup[]>>()
);
export const getInterestGroupsResolved = createAction(
  '[InterestGroup] Get interest groups resolved',
  props<{ interestGroups: InterestGroup[] }>()
);

export const getInterestGroupsForUser = createAction(
  '[InterestGroup] Get interest groups for user',
  props<{ portalUserId: string } & ActionBase<InterestGroup[]>>()
);
export const getInterestGroupsForUserResolved = createAction(
  '[InterestGroup] Get interest groups for user resolved',
  props<{ interestGroups: InterestGroup[] }>()
);
export const addPortalUserInterestGroup = createAction(
  '[InterestGroup] Add portal user interest group',
  props<
    {
      portalUserId: string;
      interestGroupId: string;
    } & ActionBase<UserJoinInterestGroupDTO>
  >()
);
export const addPortalUserInterestGroupResolved = createAction(
  '[InterestGroup] Add portal user interest group resolved',
  props<{ response: UserJoinInterestGroupDTO }>()
);

export const deletePortalUserInterestGroup = createAction(
  '[InterestGroup] Delete portal user interest group',
  props<
    {
      portalUserId: string;
      interestGroup: InterestGroup;
    } & ActionBase<InterestGroup>
  >()
);
export const deletePortalUserInterestGroupResolved = createAction(
  '[InterestGroup] Delete portal user interest group resolved',
  props<{ deletedInterestGroup: InterestGroup }>()
);

export const resetSlice = createAction('[InterestGroup] Reset slice');
