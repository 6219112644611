import { createAction, props } from '@ngrx/store';
import { ActionBase } from '../action-base.interface';
import { MetadataParam } from 'src/app/webparts/dms-component/domain/models/metadata-param.model';

export const getMetadataParams = createAction(
  '[Metadata] Get metadata parameters',
  props<ActionBase<MetadataParam[]>>()
);
export const getMetadataParamsResolved = createAction(
  '[Metadata] Get metadata parameters resolved',
  props<{
    metadataParams: MetadataParam[];
  }>()
);

export const resetSlice = createAction('[Metadata] Reset slice');
