import { Injectable } from '@angular/core';
import { MicrosoftAuthenticationService } from 'processdelight-angular-components';
import { OrganizationFacade } from 'src/app/core/store/organization/organization.facade';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FunctionsService {
  apiBase = `${environment.ishtarFunctions}/api`;
  tenantId?: string;

  constructor(
    private msal: MicrosoftAuthenticationService,
    private organizationFacade: OrganizationFacade
  ) {
    // Can't really do takeuntil destroy, since services don't have a destroy lifecycle, but since it is root provided we know this will just live as long as the app
    this.organizationFacade.organizationInfo$
      .subscribe((o) => {
        this.tenantId = o?.tenantId;
      });
  }
  private createApiEndpointUrl(path: string) {
    const url = new URL(`${this.apiBase}/${path}`);
    if (environment.ishtarFunctionsKey.trim() !== '')
      url.searchParams.append('code', environment.ishtarFunctionsKey);
    return url.toString();
  }
}
