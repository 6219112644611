import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { QuillModule } from 'ngx-quill';
import {
  ContextMenuAction,
  DropZoneComponent,
  InterestGroup,
  LoaderService,
  SelectComponent,
  TopLayoutComponent,
  TranslationService,
} from 'processdelight-angular-components';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  first,
  map,
  Subject,
  switchMap,
  takeUntil,
  tap,
  zip,
} from 'rxjs';
import {
  navColor$,
  navContrast$,
  orgLogo$,
  userName$,
} from 'src/app/app.observables';
import { CoreModule } from 'src/app/core/core.module';
import { PortalPost } from 'src/app/core/domain/models/portal-post.model';
import { FeedFacade } from 'src/app/core/store/custom/feed/feed.facade';
import { InterestGroupFacade } from 'src/app/core/store/interestGroup/interestGroup.facade';
import { UserFacade } from 'src/app/core/store/user/user.facade';
import { DataService } from '../../../../core/services/data.service';

@Component({
  selector: 'app-post-popup',
  standalone: true,
  imports: [
    CommonModule,
    CoreModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    QuillModule,
    TopLayoutComponent,
    DropZoneComponent,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    SelectComponent,
    MatDialogModule,
  ],
  templateUrl: './post-popup.component.html',
  styleUrls: ['./post-popup.component.scss'],
})
export class PostPopupComponent implements OnInit, OnDestroy {
  constructor(
    private dialogRef: DialogRef,
    private igFac: InterestGroupFacade,
    private feedFac: FeedFacade,
    private dataService: DataService,
    private userFacade: UserFacade,
    private loader: LoaderService,
    public readonly translations: TranslationService,
    @Inject(MAT_DIALOG_DATA) public data: { post?: PortalPost }
  ) {}

  formats = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
    'formula',
    // 'image'
    // 'video'
  ];
  modules = [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block'],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ direction: 'rtl' }], // text direction

    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ['clean'],
  ];

  orgLogo$ = orgLogo$;
  userName$ = userName$;
  navColor$ = navColor$;
  navContrast$ = navContrast$;

  groups$ = new BehaviorSubject<InterestGroup[]>([]);

  postFormField!: FormGroup;

  newFileToUpload: File | null = null;
  portalUserId: string | undefined;
  groupUserId: string | undefined;

  isSaving$ = new BehaviorSubject<boolean>(false);
  isFormValid$ = new BehaviorSubject<boolean>(true);

  buttonActions = [
    new ContextMenuAction({
      icon: 'save',
      label: 'Save',
      action: () => this.submitPost(),
      disabled: combineLatest([
        this.isSaving$.asObservable(),
        this.isFormValid$.asObservable(),
      ]).pipe(map(([saving, dirty]) => saving || !dirty)),
    }),
  ];
  iconActions: ContextMenuAction<unknown>[] = [
    new ContextMenuAction({
      icon: 'close',
      label: 'Close',
      action: () => this.close(),
    }),
  ];

  get selectedImgUrl() {
    return this.postFormField.controls.image.value;
  }

  get groupsRestrictionEnabled() {
    return this.postFormField.controls.groupsRestrictionEnabled.value;
  }

  get selectedGroupIds(): string[] {
    return this.postFormField.controls.groups.value ?? [];
  }

  ngOnInit(): void {
    this.igFac.interestGroups$.pipe(takeUntil(this.destroy$)).subscribe((g) => {
      this.groups$.next(g);
    });

    zip([this.userFacade.externalUserInfo$, this.userFacade.userLicenseInfo$])
      .pipe(
        filter((e) => !!e),
        first()
      )
      .subscribe(([external, internal]) => {
        if (internal) this.groupUserId = internal.userId;
        if (external) this.portalUserId = external?.portalUserId;
      });

    this.postFormField = new FormGroup({
      title: new FormControl(this.data.post?.name ?? '', Validators.required),
      description: new FormControl(
        this.data.post?.description ?? '',
        Validators.required
      ),
      image: new FormControl(this.data.post?.pictureUrl ?? ''),
      groupsRestrictionEnabled: new FormControl(
        this.data.post?.groupsRestrictionEnabled ?? false
      ),
      groups: new FormControl(this.data.post?.groups?.map((g) => g.id) ?? []),
    });

    this.postFormField.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.isFormValid$.next(
          this.postFormField.valid && this.postFormField.dirty
        );
      });
  }

  submitPost() {
    this.isSaving$.next(true);
    const loadingsubj = new Subject<void>();
    this.loader.startLoading('Saving post...', () => loadingsubj);
    const savePost = () => {
      const post = new PortalPost({
        id: this.data?.post?.id,
        name: this.postFormField.controls.title.value,
        pictureUrl: this.postFormField.controls.image.value,
        description: this.postFormField.controls.description.value,
        isDeleted: false,
        groupsRestrictionEnabled: this.groupsRestrictionEnabled,
        groups: this.groupsRestrictionEnabled
          ? this.selectedGroupIds.map(
              (id) => this.groups$.value.find((g) => g.id === id)!
            )
          : [],
        portalUserAuthorId: this.portalUserId,
        groupUserAuthorId: this.groupUserId,
      });
      if (post.id) return this.feedFac.updatePost(post);
      else return this.feedFac.createPost(post);
    };
    this.close();
    if (this.newFileToUpload)
      this.uploadFile(this.newFileToUpload)
        .pipe(switchMap(() => savePost()))
        .subscribe(() => {
          loadingsubj.next();
          this.isSaving$.next(false);
        });
    else
      savePost().subscribe(() => {
        loadingsubj.next();
        this.isSaving$.next(false);
      });
  }

  saveFile(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      if (event.target?.result)
        this.postFormField.controls.image.setValue(event.target.result);
      console.log(event);
    };
    this.newFileToUpload = file;
  }

  uploadFile(file: File) {
    return this.dataService.blobToBase64(file).pipe(
      switchMap((base64) => {
        return this.dataService.uploadImage(
          'IshtarPortalPostImages',
          file.name,
          file.type,
          base64
        );
      }),
      tap((url) => this.postFormField.controls.image.setValue(url))
    );
  }

  resetFile() {
    this.newFileToUpload = null;
    this.postFormField.controls.image.setValue('');
  }

  close() {
    this.dialogRef.close();
  }

  destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
