<div class="d-flex flex-column h-100">
  <h1 mat-dialog-title class="dialogTitle">Request changes</h1>
  <mat-icon class="closeButtonIcon" mat-dialog-close>close</mat-icon>
  <div
    class="h-100 mh-100 min-height-0 d-flex flex-column"
    style="padding: 0px"
  >
    <mat-form-field class="subScript h-100" appearance="outline">
      <mat-label *ngIf="getTranslation$('email') | async as emailLabel">{{
        emailLabel
      }}</mat-label>
      <textarea
        #textArea
        class="fontSize h-100"
        style="resize: none"
        matInput
        [(ngModel)]="emailTobeSent"
        [maxlength]="1000"
      ></textarea>
      <mat-hint align="end">{{ textArea.value.length }}/1000</mat-hint>
    </mat-form-field>

    <div class="rowbuttons">
      <button
        mat-raised-button
        mat-dialog-close
        class="addButton fontSize"
        (click)="sendEmail()"
        [disabled]="textArea.value.length === 0 && !emailTobeSent"
      >
        <mat-icon>check</mat-icon>
        {{ getTranslation$("confirm") | async }}
      </button>
      <button mat-raised-button mat-dialog-close class="addButton fontSize">
        <mat-icon>cancel</mat-icon>
        {{ getTranslation$("cancel") | async }}
      </button>
    </div>
  </div>
</div>
