import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import {
  AbstractWebPartComponent,
  AbstractWebPartConfigComponent,
  SelectComponent,
  VIEW,
} from 'processdelight-angular-components';
import { VerhofsteService } from './services/verhofste.service';
import { VerhofsteOfferConfigComponent } from './verhofste-offer-config/verhofste-offer-config.component';
import { VerhofsteProductListComponent } from './verhofste-product-list/verhofste-product-list.component';
import { VerhofsteQuotationsDashboardComponent } from './verhofste-quotations-dashboard/verhofste-quotations-dashboard.component';
import { first, withLatestFrom } from 'rxjs';
import { VerhofsteAgent } from './domain/models/agent.model';
import { FormsModule } from '@angular/forms';
import { VerhofsteOrdersDashboardComponent } from './verhofste-orders-dashboard/verhofste-orders-dashboard.component';
import { ClientFacade } from 'src/app/core/store/client/client.facade';

export const ASSIGN_METADATA = 'AssignMetadata';

@Component({
  standalone: true,
  selector: 'app-verhofste-order-products-tabs-component',
  templateUrl: './verhofste-order-products-tabs.component.html',
  styleUrls: ['./verhofste-order-products-tabs.component.scss'],
  imports: [
    CommonModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    VerhofsteProductListComponent,
    VerhofsteQuotationsDashboardComponent,
    VerhofsteOrdersDashboardComponent,
    SelectComponent,
  ],
})
export class VerhofsteOrderProductsTabsComponent
  extends AbstractWebPartComponent
  implements OnInit
{
  static override TYPE = 'VerhofsteOffers';
  static override FEATURES = [VIEW, ASSIGN_METADATA];
  static override CONFIG_COMPONENT: new () => AbstractWebPartConfigComponent =
    VerhofsteOfferConfigComponent;

  customerList: VerhofsteAgent[] = [];
  businessPartner?: VerhofsteAgent;

  customerValueAccessor = (customer: VerhofsteAgent) => customer;
  customerDisplayValueAccessor = (customer: VerhofsteAgent) =>
    customer.businessPartnerName;

  get filtersEnabled() {
    return (
      this.configurations?.find((c) => c.name === 'enableSearch')
        ?.configuration == 'true'
    );
  }

  get validityDateOffset() {
    return +(
      this.configurations?.find((c) => c.name === 'validityDate')
        ?.configuration ?? 5
    );
  }

  get deliveryDateOffset() {
    return +(
      this.configurations?.find((c) => c.name === 'deliveryDate')
        ?.configuration ?? 5
    );
  }

  get disabled() {
    return (
      this.configurations?.find((c) => c.name === 'disabled')?.configuration ==
      'true'
    );
  }

  get ralLabel() {
    return this.configurations?.find((c) => c.name === 'ralLabel')
      ?.configuration;
  }

  get canAssignMetadata() {
    return this.canAccessFeature(ASSIGN_METADATA);
  }

  constructor(
    private verhofsteService: VerhofsteService,
    private clientFacade: ClientFacade
  ) {
    super();
  }

  ngOnInit(): void {
    this.verhofsteService.products$.next([]);
    this.fetchCustomerList();
    this.fetchProductMetadata();
    if (this.canAssignMetadata) this.fetchMetadataParameters();
  }

  onCustomerSelected(customer: VerhofsteAgent) {
    this.verhofsteService.products$.next([]);
    this.verhofsteService.offers$.next([]);

    this.businessPartner = customer;
    this.verhofsteService.getOffers(+customer.businessPartnerId).subscribe();
    this.verhofsteService
      .getProductList(customer.businessPartnerId)
      .subscribe();
  }

  fetchCustomerList() {
    this.verhofsteService
      .getCustomerList()
      .pipe(first(), withLatestFrom(this.clientFacade.currentClientWithConfig$))
      .subscribe(([data, currentClient]) => {
        this.customerList = data;
        this.customerList.sort((a, b) =>
          a.businessPartnerName.localeCompare(b.businessPartnerName)
        );
        if (currentClient?.data?.some((d) => d.propertyName == 'AgentNumber')) {
          const agentNumber =
            currentClient.data.find((d) => d.propertyName == 'AgentNumber')
              ?.value ?? 0;
          const agent = this.customerList.find(
            (c) => +c.businessPartnerId == +agentNumber
          );
          if (agent) {
            this.onCustomerSelected(agent);
          }
        }
      });
  }
  fetchProductMetadata() {
    this.verhofsteService.getProductMetadata().pipe(first()).subscribe();
  }
  fetchMetadataParameters() {
    this.verhofsteService.getMetadataParameters().pipe(first()).subscribe();
  }

  focusInput(input: HTMLInputElement) {
    setTimeout(() => {
      input.focus();
    }, 0);
  }
}
