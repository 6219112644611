import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import {
  ActionBarComponent,
  ContextMenuAction,
} from 'processdelight-angular-components';
import { TranslationService } from 'processdelight-angular-components';
import { ProfileComponent } from './profile/profile.component';
import { GroupSettingsComponent } from './group-settings/group-settings.component';
import { NotificationSettingsComponent } from './notification-settings/notification-settings.component';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    MatDialogModule,
    ActionBarComponent,
    ProfileComponent,
    GroupSettingsComponent,
    NotificationSettingsComponent,
  ],
})
export class AccountSettingsComponent implements OnInit {
  iconActions: ContextMenuAction<unknown>[] = [];
  buttonActions: ContextMenuAction<unknown>[] = [];

  getTranslation$(key: string) {
    return this.translationService.getTranslation$(key);
  }

  constructor(
    private translationService: TranslationService,
    private settingsDialogRef: MatDialogRef<AccountSettingsComponent>
  ) {}

  ngOnInit(): void {
    this.iconActions.push(
      new ContextMenuAction({
        icon: 'close',
        label: this.getTranslation$('close'),
        action: () => this.onCloseDialog(),
      })
    );
  }
  onCloseDialog() {
    this.settingsDialogRef.close();
  }
}
