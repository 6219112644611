<mat-tree
  [dataSource]="dataSource"
  [treeControl]="treeControl"
  class="tree scrollbar h-100 w-100 min-height-0 overflow-auto"
>
  <!-- This is the tree node template for leaf nodes -->
  <!-- There is inline padding applied to this node using styles.
      This padding value depends on the mat-icon-button width. -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
    <mat-card class="position-relative">
      <div
        class="colorStripe position-absolute start-0 top-0 h-100"
        style="width: 5px"
        [style.backgroundColor]="node.color"
      ></div>
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center w-100">
          <div class="d-flex flex-column ps-3">
            <div class="bold">{{ node.name }}</div>
            <div class="italic">{{ node.description }}</div>
          </div>
          <ng-container *ngIf="node.icon">
            <img
              class="list-icon m-2"
              [src]="node.icon"
              alt="Interest group icon"
            />
          </ng-container>
        </div>

        <ng-container
          *ngTemplateOutlet="button; context: { group: node }"
        ></ng-container>
      </div>
    </mat-card>
  </mat-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
    <div class="mat-tree-node">
      <div class="d-flex align-items-center w-100">
        <button
          mat-icon-button
          matTreeNodeToggle
          [attr.aria-label]="'Toggle ' + node.name"
        >
          <mat-icon class="mat-icon-rtl-mirror">
            {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
          </mat-icon>
        </button>
        <mat-card class="position-relative">
          <div
            class="colorStripe position-absolute start-0 top-0 h-100"
            style="width: 5px"
            [style.backgroundColor]="node.color"
          ></div>
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center w-100">
              <div class="d-flex flex-column ps-3">
                <div class="bold">{{ node.name }}</div>
                <div class="italic">{{ node.description }}</div>
              </div>
              <ng-container *ngIf="node.icon">
                <img
                  class="list-icon m-2"
                  [src]="node.icon"
                  alt="Interest group icon"
                />
              </ng-container>
            </div>
            <ng-container
              *ngTemplateOutlet="button; context: { group: node }"
            ></ng-container>
          </div>
        </mat-card>
      </div>
    </div>
    <!-- There is inline padding applied to this div using styles.
            This padding value depends on the mat-icon-button width.  -->
    <div [class.tree-invisible]="!treeControl.isExpanded(node)" role="group">
      <ng-container matTreeNodeOutlet></ng-container>
    </div>
  </mat-nested-tree-node>
</mat-tree>

<ng-template #button let-group="group">
  <ng-container [ngSwitch]="group.status">
    <ng-container *ngSwitchCase="'joined'">
      <button
        mat-raised-button
        class="buttons flex-shrink-0"
        color="warn"
        (click)="leaveGroup(group.id)"
        [disabled]="!canJoinCommunityGroups"
      >
        {{ getTranslation("leave") }}
        <mat-icon class="material-icons-outlined"> logout </mat-icon>
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'none'">
      <button
        mat-raised-button
        class="buttons flex-shrink-0"
        color="primary"
        (click)="joinGroup(group.id)"
        [disabled]="!canJoinCommunityGroups"
      >
        {{ getTranslation("join") }}
        <mat-icon class="material-icons-outlined"> add </mat-icon>
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'requestSent'">
      <div class="join-requested pe-2 d-flex align-items-center">Requested</div>
    </ng-container>
    <ng-container *ngSwitchDefault></ng-container>
  </ng-container>
</ng-template>
