import { CMSRole, CRMEntity } from 'processdelight-angular-components';

export class Role extends CMSRole {
  client?: CRMEntity;
  clientId?: string;

  constructor(data?: Partial<Role>) {
    super(data);
    Object.assign(this, data);
    if (data?.client) this.client = new CRMEntity(data.client);
  }
}
