<mat-card class="m-0 h-100">
  <div class="titlebar" [class.smalltitlebar]="isSmallView">
    <div class="d-flex flex-row gap-1 align-items-center">
      <h2>{{ translations.getTranslation$("updates") | async }}</h2>
      <button
        mat-stroked-button
        class="ms-2 orderbymenu"
        [matMenuTriggerFor]="menu"
      >
        {{
          translations.getTranslation$(orderBy === "desc" ? "recent" : "oldest")
            | async
        }}
      </button>
      <mat-menu #menu="matMenu">
        <button
          mat-menu-item
          class="orderbymenubtn"
          (click)="orderBy = 'desc'; searchPosts(true)"
        >
          {{ translations.getTranslation$("recent") | async }}
        </button>
        <button
          mat-menu-item
          class="orderbymenubtn"
          (click)="orderBy = 'asc'; searchPosts(true)"
        >
          {{ translations.getTranslation$("oldest") | async }}
        </button>
      </mat-menu>
    </div>
    <div class="d-flex flex-row gap-1 align-items-center min-width-0">
      <mat-form-field
        class="no-subscript-wrapper searchbar me-1"
        appearance="fill"
        placeholder="Search"
      >
        <input
          matInput
          type="text"
          [placeholder]="translations.getTranslation('search')"
          [(ngModel)]="searchTerm"
          (ngModelChange)="searchPosts(true)"
        />
        <button
          matIconSuffix
          mat-icon-button
          class="clearbtn"
          *ngIf="searchTerm"
          (click)="searchTerm = ''; searchPosts(true)"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <button
        *ngIf="canCreatePosts"
        class="createpost"
        mat-stroked-button
        (click)="createNewPost()"
      >
        <span class="text-nowrap">
          {{ translations.getTranslation$("create") | async }} &nbsp;
          {{ translations.getTranslation$("post") | async }}
        </span>
      </button>
    </div>
  </div>

  <div
    id="feed"
    class="d-flex flex-column gap-3 ms-3 me-2 pe-1 mb-3 scrollbar feed"
    appScrollNearEnd
    (nearEnd)="nextBatch()"
  >
    <div
      *ngFor="let post of feed; trackBy: postTrackBy"
      class="d-flex flex-column gap-3"
    >
      <app-feed-post
        [post]="post"
        [isSmallView]="isSmallView"
        [groupUserId]="groupUserId"
        [portalUserId]="portalUserId"
        [canCreatePosts]="canCreatePosts"
        [canCreateComment]="canCreateComment"
        [canCreateLike]="canCreateLike"
        [canDeleteAnything]="canDeleteAnything"
        [canEditAllPosts]="canEditAllPosts"
      >
      </app-feed-post>
    </div>

    <div
      class="d-flex flex-column align-items-center justify-content-between h-100"
      *ngIf="loading"
    >
      <mat-spinner></mat-spinner>
    </div>
  </div>
</mat-card>
