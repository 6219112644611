import { Injectable } from '@angular/core';
import { PortalService } from '../../services/portal.service';
import { BaseEffects } from '../base.effects';
import {
  getOrganizationInfo,
  getOrganizationInfoResolved,
  getPortalUsers,
  getPortalUsersResolved,
} from './organization.actions';
import { Actions } from '@ngrx/effects';

@Injectable({ providedIn: 'root' })
export class OrganizationEffects extends BaseEffects {
  constructor(private portalService: PortalService, actions$: Actions) {
    super(actions$);
  }

  getOrganizationInfo = this.createDefaultEffect(
    getOrganizationInfo,
    ({ tenantId }) => this.portalService.getOrganization(tenantId),
    {
      valueMapper: (v) => ({ organizationInfo: v.result }),
      action: getOrganizationInfoResolved,
    }
  );

  // getGroupUsers = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(getGroupUsers),
  //     switchMap(({ callback }) =>
  //       combineLatest([
  //         this.portalService.getGroups(),
  //         this.portalService.getUsers(),
  //       ]).pipe(
  //         map(([groups, users]) => [...groups, ...users]),
  //         tap((groupUsers) => (callback ? callback(groupUsers) : undefined)),
  //         switchMap((groupUsers) => of(getGroupUsersResolved({ groupUsers })))
  //       )
  //     )
  //   )
  // );

  getPortalUsers = this.createDefaultEffect(
    getPortalUsers,
    () => this.portalService.getPortalUsers(),
    {
      valueMapper: (v) => ({ portalUsers: v.result }),
      action: getPortalUsersResolved,
    }
  );
}
