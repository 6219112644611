import { createSelector } from '@ngrx/store';
import { AppState } from '../app.reducer';

const libraryState = (state: AppState) => state.coreFeature.library;
const getLibraryItems = createSelector(libraryState, (state) => state.items);
const getSharedItems = createSelector(
  libraryState,
  (state) => state.sharedItems
);
const getLibraries = createSelector(libraryState, (state) => state.libraries);
const getLibraryById = createSelector(
  libraryState,
  (state) => (id: string) => state.libraries.find((x) => x.id === id)
);

export const librarySelectors = {
  getLibraryItems,
  getSharedItems,
  getLibraries,
  getLibraryById,
};
