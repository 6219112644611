<div class="d-flex flex-column mainTable">
  <div class="w-100 h-100 overflow-auto scrollbar">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      (matSortChange)="sortData($event)"
      class="mat-elevation-z8"
      style="table-layout: fixed"
    >
      <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

      <!-- Position Column -->
      <ng-container matColumnDef="ItemSelected">
        <th mat-header-cell *matHeaderCellDef class="hover-left">
          <mat-checkbox
            (change)="selectAll($event)"
            [indeterminate]="
              selectedItems.length > 0 &&
              selectedItems.length !== dataSource.data.length
            "
          ></mat-checkbox>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          class="hover-left"
        >
          <mat-checkbox
            [checked]="isChecked(element.product.itemId)"
            (change)="onCheckChange(element.product.itemId)"
          ></mat-checkbox>
        </td>
      </ng-container>

      <!-- Discount  Column -->

      <ng-container *ngIf="amountTableDef" matColumnDef="ItemAmount">
        <th mat-header-cell *matHeaderCellDef>
          <div class="d-flex flex-column position-relative">
            <span class="colTitle">
              {{ amountTableDef.displayName }}
            </span>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          <div
            class="discountInput example-right-align dense position-relative"
          >
            <mat-form-field
              class="w-100 no-subscript-wrapper discountformfield dense"
              appearance="fill"
            >
              <input
                matInput
                [(ngModel)]="element.amount"
                type="number"
                inputmode="numeric"
                (input)="onAmountChange(element)"
                appWholeNumbers
                min="0"
              />
            </mat-form-field>
          </div>
        </td>
      </ng-container>
      <ng-container *ngIf="ralTableDef" matColumnDef="RAL">
        <th mat-header-cell *matHeaderCellDef>
          <div class="d-flex flex-column position-relative">
            <span class="colTitle">
              {{ ralTableDef.displayName }}
            </span>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          <div
            *ngIf="needsRal(element)"
            class="discountInput example-right-align dense position-relative"
          >
            <mat-form-field
              class="w-100 no-subscript-wrapper discountformfield dense"
              appearance="fill"
            >
              <input
                matInput
                [(ngModel)]="element.ral"
                type="text"
                (input)="onRalChange(element)"
              />
            </mat-form-field>
          </div>
        </td>
      </ng-container>

      <ng-container
        *ngFor="let column of displayedColumns; trackBy: stringTrackByFn"
        [matColumnDef]="column"
      >
        <ng-container [ngSwitch]="possibleColumns[column].internalName">
          <!-- Default -->
          <ng-container *ngSwitchDefault>
            <th
              mat-header-cell
              *matHeaderCellDef
              [mat-sort-header]="column"
              class="buttonTableHeader"
            >
              <div class="d-flex flex-column position-relative">
                <span class="colTitle">
                  {{ possibleColumns[column].displayName }}
                </span>
                <ng-container *ngIf="filtersEnabled">
                  <input
                    type="text"
                    #search
                    (click)="$event.stopPropagation()"
                    [(ngModel)]="possibleColumns[column].filterValue"
                    (ngModelChange)="filterData($event, column)"
                    class="rowFilter w-100"
                    placeholder="Filter..."
                  />
                </ng-container>
              </div>
            </th>

            <td mat-cell *matCellDef="let element">
              <div class="tableCell">
                {{ possibleColumns[column].valueAccessor(element) }}
              </div>
            </td>
          </ng-container>
        </ng-container>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="actualDisplayedColumns"
        class="position-sticky top-0"
        [style.z-index]="10"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: actualDisplayedColumns"></tr>
    </table>
  </div>
  <mat-paginator
    [length]="data.length"
    [pageSize]="10"
    [pageSizeOptions]="[3, 10, 25, 100]"
    [pageIndex]="pageSettings.pageIndex"
    (page)="pageData($event)"
    aria-label="Select page"
    class="position-sticky bottom-0"
    [style.z-index]="10"
    [style.border-radius.px]="8"
  >
  </mat-paginator>
</div>
