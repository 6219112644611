import { BehaviorSubject } from 'rxjs';
import { PortalUserInterestGroupJoinRequest } from './core/domain/models/portal-user-interest-group-join-request.model';

export const orgLogo$ = new BehaviorSubject<string | undefined>(undefined);
export const userName$ = new BehaviorSubject<string | undefined>(undefined);
export const navColor$ = new BehaviorSubject<string | undefined>(undefined);
export const navContrast$ = new BehaviorSubject<string | undefined>(undefined);

export const interestGroupJoinRequests$ = new BehaviorSubject<
  PortalUserInterestGroupJoinRequest[]
>([]);
