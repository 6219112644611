import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { catchError, combineLatest, Subject, takeUntil, tap } from 'rxjs';
import { ExternalUserInfo } from 'src/app/core/domain/models/external-user-info.model';
import { InterestGroup } from 'src/app/core/domain/models/interestGroup';
import { TranslationService } from 'processdelight-angular-components';
import { InterestGroupFacade } from 'src/app/core/store/interestGroup/interestGroup.facade';
import { UserFacade } from 'src/app/core/store/user/user.facade';
import { MatTreeModule, MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatChipsModule } from '@angular/material/chips';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

interface Groups {
  id: string;
  name: string;
  description?: string;
  icon?: string;
  color: string;
  joinable: boolean;
  children?: Groups[];
}

@Component({
  selector: 'app-group-settings',
  templateUrl: './group-settings.component.html',
  styleUrls: ['./group-settings.component.scss'],
  standalone: true,
  imports: [
    MatCardModule,
    CommonModule,
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule,
    MatTreeModule,
    MatCardModule,
    MatChipsModule,
  ],
})
export class GroupSettingsComponent implements OnInit, OnDestroy {
  interestGroups: InterestGroup[] = [];
  joinedGroups: InterestGroup[] = [];
  externalUser: ExternalUserInfo | undefined;

  treeControl = new NestedTreeControl<Groups>((node) => node.children ?? []);
  dataSource = new MatTreeNestedDataSource<Groups>();

  hasChild = (_: number, node: Groups) =>
    !!node.children && node.children.length > 0;

  getTranslation$(key: string) {
    return this.translationService.getTranslation$(key);
  }
  getTranslation(label: string): string {
    return this.translationService.getTranslation(label);
  }

  constructor(
    private translationService: TranslationService,
    private interestGroupFacade: InterestGroupFacade,
    private userFacade: UserFacade,
    private snackbar: MatSnackBar,
    private readonly sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.userFacade.externalUserInfo$
      .pipe(takeUntil(this.destroy$))
      .subscribe((external) => {
        if (external) this.externalUser = external;
      });

    combineLatest([
      this.interestGroupFacade.interestGroups$,
      this.interestGroupFacade.interestGroupsForUser$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([interestGroups, joinedGroups]) => {
        if (joinedGroups) this.joinedGroups = joinedGroups;
        if (interestGroups) {
          this.interestGroups = interestGroups;
          this.dataSource.data = [...interestGroups].map((g) => ({
            id: g.id,
            name: g.title,
            description: g.description,
            icon: g.icon,
            color: g.color,
            joinable: !this.joinedGroups.some((jg) => jg.id === g.id),
            children: this.getConvertedNodes(g),
          }));
        }
      });
  }

  getConvertedNodes(interestGroup: InterestGroup): Groups[] {
    if (interestGroup.subGroups && interestGroup.subGroups.length === 0) {
      return [];
    }
    const r = interestGroup.subGroups!.map((group) => {
      const subGroup = this.interestGroups?.find(
        (g) => g.id === group.subGroupId
      );
      return {
        id: subGroup!.id,
        name: subGroup!.title,
        description: subGroup!.description,
        icon: subGroup!.icon,
        color: subGroup!.color,
        joinable: !this.joinedGroups.some((jg) => jg.id === subGroup!.id),
        children: this.getConvertedNodes(subGroup!),
      };
    });
    return r;
  }

  joinGroup(groupId: string) {
    const interestGroup = this.interestGroups.find((g) => g.id === groupId);
    const portalUserId = this.externalUser?.portalUserId;
    if (!portalUserId) {
      this.snackbar.open('No portalUserId. Contact administrator', 'X', {
        duration: 3000,
        panelClass: 'app-notification-error',
      });
      return;
    }
    this.interestGroupFacade
      .addPortalUserInterestGroup$(portalUserId, interestGroup!)
      .pipe(
        tap((x) => {
          this.snackbar.open(this.getTranslation('joinGroup'), 'X', {
            duration: 3000,
            panelClass: 'app-notification-success',
          });
        }),
        catchError((err) => {
          this.snackbar.open(this.getTranslation('savingError'), 'X', {
            duration: 3000,
            panelClass: 'app-notification-error',
          });
          return err;
        })
      )
      .subscribe();
  }

  leaveGroup(groupId: string) {
    const interestGroup = this.interestGroups.find((g) => g.id === groupId);
    const portalUserId = this.externalUser?.portalUserId;
    if (!portalUserId) {
      this.snackbar.open('No portalUserId. Contact administrator', 'X', {
        duration: 3000,
        panelClass: 'app-notification-error',
      });
      return;
    }
    this.interestGroupFacade
      .deletePortalUserInterestGroup$(portalUserId, interestGroup!)
      .pipe(
        tap((x) => {
          this.snackbar.open(this.getTranslation('leftGroup'), 'X', {
            duration: 3000,
            panelClass: 'app-notification-success',
          });
        }),
        catchError((err) => {
          this.snackbar.open(this.getTranslation('savingError'), 'X', {
            duration: 3000,
            panelClass: 'app-notification-error',
          });
          return err;
        })
      )
      .subscribe();
  }
  transform(value: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }

  destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
