import { Action, createReducer, on } from '@ngrx/store';
import { ClientWithConfig } from '../../domain/models/client-with-config.model';
import {
  acceptRequestResolved,
  getClientUserRequestsResolved,
  getClientUsers,
  getClientUsersResolved,
  getClients,
  getClientsResolved,
  newRequestResolved,
  rejectRequestResolved,
  resetSlice,
  setCurrentClient,
} from './client.actions';
import { ClientUserInfo } from '../../domain/models/client-user-info.model';
import { ClientUserRequest } from '../../domain/models/client-user-request.model';
import {
  CRMEntity,
  DataObject,
  DataRecord,
} from 'processdelight-angular-components';

export const featureSlice = 'client';

export interface State {
  loadingClients: boolean;
  loadingClientUsers: boolean;
  clients: ClientWithConfig[];
  clientUsers: ClientUserInfo[];
  requests: ClientUserRequest[];
  currentClient?: CRMEntity;
  currentClientRecord?: DataRecord;
}

const defaultState: State = {
  loadingClients: false,
  loadingClientUsers: false,
  clients: [],
  clientUsers: [],
  requests: [],
  currentClient: undefined,
  currentClientRecord: undefined,
};

export function Reducer(state: State | undefined, action: Action) {
  return clientReducer(state, action);
}

export const initialState: State = defaultState;
export const clientReducer = createReducer(
  initialState,
  on(getClients, (state) => ({ ...state, loadingClients: true })),
  on(getClientsResolved, (state, { clients }) => ({
    ...state,
    clients: [...clients].sort(
      (c1, c2) => c1.client.name?.localeCompare(c2.client.name ?? '') ?? 1
    ),
    loadingClients: false,
  })),
  on(getClientUsers, (state) => ({ ...state, loadingClientUsers: true })),
  on(getClientUsersResolved, (state, { clientUsers }) => ({
    ...state,
    clientUsers: clientUsers,
    loadingClientUsers: false,
  })),
  on(getClientUserRequestsResolved, (state, { requests }) => ({
    ...state,
    requests: requests,
  })),
  on(acceptRequestResolved, (state, { requestId, clientUserInfo }) => ({
    ...state,
    requests: state.requests.filter((request) => request.id !== requestId),
    clientUsers: clientUserInfo
      ? [...state.clientUsers, clientUserInfo]
      : state.clientUsers,
  })),
  on(rejectRequestResolved, (state, { requestId, clientUserInfo }) => ({
    ...state,
    requests: state.requests.filter((request) => request.id !== requestId),
    clientUsers: clientUserInfo
      ? [...state.clientUsers, clientUserInfo]
      : state.clientUsers,
  })),
  on(newRequestResolved, (state, { clientUserInfo }) => ({
    ...state,
    requests: [...state.requests, clientUserInfo],
  })),
  on(setCurrentClient, (state, { client }) => ({
    ...state,
    currentClient: client,
  })),
  on(resetSlice, () => initialState)
);
