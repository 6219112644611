import { Injectable } from '@angular/core';
import { select } from '@ngrx/store';
import { InterestGroup } from 'processdelight-angular-components';
import { BaseFacade } from '../base.facade';
import {
  addPortalUserInterestGroup,
  deletePortalUserInterestGroup,
  getInterestGroups,
  getInterestGroupsForUser,
  resetSlice,
} from './interestGroup.actions';
import { interestGroupSelectors } from './interestGroup.selectors';

@Injectable({
  providedIn: 'root',
})
export class InterestGroupFacade extends BaseFacade {
  interestGroups$ = this._store.pipe(
    select(interestGroupSelectors.getInterestGroups)
  );
  interestGroupsForUser$ = this._store.pipe(
    select(interestGroupSelectors.getInterestGroupsForUser)
  );

  getInterestGroups$() {
    return this.dispatchAction$(getInterestGroups);
  }
  getInterestGroupsForUser$(portalUserId: string) {
    return this.dispatchAction$(getInterestGroupsForUser, { portalUserId });
  }
  addPortalUserInterestGroup$(portalUserId: string, interestGroupId: string) {
    return this.dispatchAction$(addPortalUserInterestGroup, {
      portalUserId,
      interestGroupId,
    });
  }
  deletePortalUserInterestGroup$(
    portalUserId: string,
    interestGroup: InterestGroup
  ) {
    return this.dispatchAction$(deletePortalUserInterestGroup, {
      portalUserId,
      interestGroup,
    });
  }

  resetSlice() {
    this._store.dispatch(resetSlice());
  }
}
