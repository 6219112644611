export class VerhofsteProduct {
  itemId!: string;
  itemCode!: string;
  itemName!: string;
  itemGroepId!: string;
  itemGroepCode!: string;
  itemGroepName!: string;
  itemSubGroepId!: string;
  itemSubGroepCode!: string;
  itemSubGroepName!: string;
  itemSubGroep2Id!: string;
  itemSubGroep2Code!: string;
  itemSubGroep2Name!: string;
  orderUnitId!: string;
  orderUnitCode!: string;
  priceUnitId!: string;
  priceUnitCode!: string;
  priceUnitPrice!: string;
  priceUnitDiscount!: string;
  priceUnitDiscountPerc!: string;
  weight?: string;

  constructor(init?: Partial<VerhofsteProduct>) {
    Object.assign(this, init);
  }
}
