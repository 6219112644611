import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { CommonModule, KeyValue } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  SecurityContext,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { DateTime } from 'luxon';
import { Subject, catchError, map, takeUntil } from 'rxjs';
import { VerhofsteOfferLine } from '../domain/models/offer-line.model';
import { VerhofsteOffer } from '../domain/models/offer.model';
import { VerhofsteService } from '../services/verhofste.service';
import { VerhofsteQuoteDetailsDialogComponent } from '../verhofste-quote-details-dialog/verhofste-quote-details-dialog.component';
import { VerhofsteAgent } from '../domain/models/agent.model';
import { ClientWithConfig } from 'src/app/core/domain/models/client-with-config.model';
import { ClientFacade } from 'src/app/core/store/client/client.facade';

@Component({
  standalone: true,
  selector: 'app-verhofste-quotations-dashboard',
  templateUrl: './verhofste-quotations-dashboard.component.html',
  styleUrls: ['./verhofste-quotations-dashboard.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatTableModule,
    MatCheckboxModule,
    MatButtonModule,
    MatMenuModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatOptionModule,
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class VerhofsteQuotationsDashboardComponent
  implements AfterViewInit, OnDestroy
{
  @Input() navColor = 'steelblue';
  @Input() navContrast = 'white';

  @Input() webpartId?: string;
  @Input() businessPartner?: VerhofsteAgent;
  @Input() deliveryDateOffset!: number;

  data: VerhofsteOffer[] = [];
  dataSource = new MatTableDataSource<VerhofsteOffer>([]);
  expandedElement!: VerhofsteOffer | null;

  possibleColumns: {
    [id: string]: {
      internalName: string;
      displayName: string;
      sortable: boolean;
      fixed: boolean;
      filterValue: string;
      valueAccessor: (item: VerhofsteOffer) => any;
      sortValueAccessor: (item: VerhofsteOffer) => string | number;
    };
  } = {
    QuotationNumber: {
      internalName: 'QuotationNumber',
      displayName: 'Quotation Number',
      sortable: true,
      fixed: false,
      filterValue: '',
      valueAccessor: (item: VerhofsteOffer) => item.quoteNumber,
      sortValueAccessor: (item: VerhofsteOffer) => item.quoteNumber,
    },
    Description: {
      internalName: 'Description',
      displayName: 'Extra information',
      sortable: true,
      fixed: false,
      filterValue: '',
      valueAccessor: (item: VerhofsteOffer) => item.description,
      sortValueAccessor: (item: VerhofsteOffer) => item.description ?? '',
    },
    AgentRef: {
      internalName: 'AgentRef',
      displayName: 'Client Reference',
      sortable: true,
      fixed: false,
      filterValue: '',
      valueAccessor: (item: VerhofsteOffer) => item.agentRef,
      sortValueAccessor: (item: VerhofsteOffer) => item.agentRef ?? '',
    },
    PriceValidityDate: {
      internalName: 'PriceValidityDate',
      displayName: 'Price Validity Date',
      sortable: true,
      fixed: false,
      filterValue: '',
      valueAccessor: (item: VerhofsteOffer) =>
        item.validityDate.toFormat('dd/MM/yyyy'),
      sortValueAccessor: (item: VerhofsteOffer) => item.validityDate.valueOf(),
    },
    Status: {
      internalName: 'Status',
      displayName: 'Status',
      sortable: true,
      fixed: false,
      filterValue: '',
      valueAccessor: (item: VerhofsteOffer) => {
        return this.getStatus(item.validityDate.toJSDate());
      },
      sortValueAccessor: (item: VerhofsteOffer) =>
        this.getStatus(item.validityDate.toJSDate()),
    },
    NrOfProducts: {
      internalName: 'NrOfProducts',
      displayName: 'Nr of Products',
      sortable: true,
      fixed: false,
      filterValue: '',
      valueAccessor: (item: VerhofsteOffer) => item.offerLines.length,
      sortValueAccessor: (item: VerhofsteOffer) => item.offerLines.length,
    },
    CreatedOn: {
      internalName: 'CreatedOn',
      displayName: 'Created On',
      sortable: true,
      fixed: false,
      filterValue: '',
      valueAccessor: (item: VerhofsteOffer) =>
        item.createdOn.toFormat('dd/MM/yyyy HH:mm'),
      sortValueAccessor: (item: VerhofsteOffer) => item.createdOn.valueOf(),
    },
  };

  displayedColumns = [
    'QuotationNumber',
    'PriceValidityDate',
    'Status',
    'Description',
    'NrOfProducts',
    'CreatedOn',
  ];

  @ViewChild(MatSort, { static: true }) sort: MatSort = new MatSort();

  sortSettings = {
    direction: 'asc',
    active: 'QuotationNumber',
  };
  pageSettings = {
    pageIndex: 0,
    pageSize: 10,
    length: 0,
  };
  filterSettings = {
    value: '',
    column: '',
  };

  collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: 'base',
  });

  columnTrackByFn = (
    _index: number,
    item: KeyValue<
      string,
      {
        internalName: string;
        displayName: string;
        sortable: boolean;
        fixed: boolean;
        filterValue: string;
        valueAccessor: (item: VerhofsteOffer) => any;
      }
    >
  ) => item;
  stringTrackByFn = (_index: number, item: string) => item;

  clients: ClientWithConfig[] = [];

  destroy$ = new Subject<void>();

  constructor(
    private clientFacade: ClientFacade,
    private verhofsteService: VerhofsteService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog
  ) {
    this.verhofsteService.offers$
      .pipe(
        takeUntil(this.destroy$),
        map((offers) => offers.filter((o) => !o.deliveryDate || !o.orderDate))
      )
      .subscribe((quotations) => {
        this.dataSource.data = quotations;
        this.data = quotations;
        this.dataChange();
      });
    this.clientFacade.clients$
      .pipe(takeUntil(this.destroy$))
      .subscribe((clients) => {
        this.clients = clients;
      });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item, sortHeaderId) => {
        return this.possibleColumns[sortHeaderId].sortValueAccessor(item);
      };
      this.sort.sort({
        disableClear: true,
        id: this.sortSettings.active,
        start: this.sortSettings.direction as 'asc' | 'desc',
      });
    }, 0);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get actualDisplayedColumns() {
    return [...this.displayedColumns, 'expand'];
  }

  addOrRemoveColumn(column: {
    internalName: string;
    displayName: string;
    sortable: boolean;
    fixed: boolean;
    filterValue: string;
    valueAccessor: (item: VerhofsteOffer) => any;
  }): void {
    if (this.displayedColumns.includes(column.internalName)) {
      this.displayedColumns = this.displayedColumns.filter(
        (c) => c !== column.internalName
      );
    } else {
      this.displayedColumns.push(column.internalName);
    }
  }

  sortData($event: Sort): void {
    this.sortSettings = $event;
    this.pageSettings.pageIndex = 0;
    this.dataChange();
  }

  pageData($event: PageEvent): void {
    this.pageSettings = $event;
    this.dataChange();
  }

  getStatus(priceValidityDate: Date): string {
    const currentDate = DateTime.now().toJSDate();
    return priceValidityDate >= currentDate ? 'Active' : 'Non-active';
  }

  dataChange(): void {
    const onlyUnique = (
      value: VerhofsteOffer,
      index: number,
      array: VerhofsteOffer[]
    ) => array.findIndex((p) => p.id === value.id) === index;

    const data = this.data.filter(onlyUnique);

    const filtered = data.filter((item) => {
      if (this.filterSettings.column === '') return true;
      const val =
        this.possibleColumns[this.filterSettings.column].valueAccessor(item);
      return val
        ?.toString()
        .toLowerCase()
        .includes(this.filterSettings.value.toLowerCase());
    });

    let sortOrder = 0;
    switch (this.sortSettings.direction) {
      // Reverse sort based on direction in sortDirection
      case 'asc':
        sortOrder = 1;
        break;
      case 'desc':
        sortOrder = -1;
        break;
    }
    const sorted =
      this.sortSettings.active !== ''
        ? filtered.sort((a, b) => {
            const valA = this.possibleColumns[
              this.sortSettings.active
            ].valueAccessor(a) as string;
            const valB = this.possibleColumns[
              this.sortSettings.active
            ].valueAccessor(b) as string;
            const result = this.collator.compare(valA, valB);
            return result * sortOrder;
          })
        : filtered;

    const pagedData = sorted.slice(
      this.pageSettings.pageIndex * this.pageSettings.pageSize,
      this.pageSettings.pageIndex * this.pageSettings.pageSize +
        this.pageSettings.pageSize
    );

    this.dataSource.data = pagedData;
  }

  showQuoteDetails(quote: VerhofsteOffer): void {
    this.dialog.open(VerhofsteQuoteDetailsDialogComponent, {
      width: '100%',
      maxWidth: '1100px',
      height: '90%',
      data: {
        quotation: quote,
        deliveryDateOffset: this.deliveryDateOffset,
        updateAmount: (item: VerhofsteOfferLine, amount: number) => {
          item.order = amount;
        },
        webpartId: this.webpartId,
        businessPartner: this.businessPartner?.businessPartnerName,
      },
    });
  }

  downloadQuote(quote: VerhofsteOffer): void {
    const client = this.clients.find((c) =>
      c.data?.some(
        (d) =>
          d.propertyName == 'AgentNumber' &&
          +(d.value ?? 0) === +(this.businessPartner?.businessPartnerCode ?? -1)
      )
    );
    const showDiscount =
      client?.data?.some(
        (d) =>
          d.propertyName === 'ShowDiscounts' &&
          d.value &&
          JSON.parse(d.value).selectedValues.includes('Yes')
      ) ?? false;
    this.verhofsteService
      .downloadQuoteUrl(quote.id, quote.agent.businessPartnerName, showDiscount)
      .pipe(
        catchError((e) => {
          console.error(e);
          throw e;
        })
      )
      .subscribe(({ downloadUrl }) => {
        const quotePDFUrl =
          this.sanitizer.bypassSecurityTrustResourceUrl(downloadUrl);

        const pdfUrl: string =
          this.sanitizer.sanitize(SecurityContext.URL, quotePDFUrl) || '';

        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = `${quote.agent.businessPartnerName} ${quote.quoteNumber}.pdf`;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }

  getTotalPriceAll(element: VerhofsteOffer): number {
    const total = element.offerLines.reduce(
      (acc2, item2) => acc2 + (+item2.price - item2.discount) * item2.order,
      0
    );
    return Math.round((total + Number.EPSILON) * 100) / 100;
  }
}
