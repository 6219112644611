import { Action, createReducer, on } from '@ngrx/store';
import { Form } from 'processdelight-angular-components';
import { PortalAppConfig } from '../../domain/models/portal-app-config';
import {
  getAppConfigResolved,
  getFormsResolved,
  resetSlice,
  updateAppConfigResolved,
} from './app.actions';
export const featureSlice = 'app';

export interface State {
  appSettings?: PortalAppConfig;
  forms?: Form[];
}

const defaultState: State = {
  appSettings: undefined,
  forms: undefined,
};

export function Reducer(state: State | undefined, action: Action) {
  return appReducer(state, action);
}

export const initialState: State = defaultState;
export const appReducer = createReducer(
  initialState,
  on(getAppConfigResolved, (state, { config }) => ({
    ...state,
    appSettings: config,
  })),
  on(updateAppConfigResolved, (state, { config }) => ({
    ...state,
    appSettings: config,
  })),
  on(getFormsResolved, (state, { forms }) => ({
    ...state,
    forms,
  })),
  on(resetSlice, () => initialState)
);
