import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import * as fromCoreFeature from './store/core.feature';
import * as fromCustomFeature from './store/custom/custom.feature';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { OrganizationEffects } from './store/organization/organization.effects';
import { UserEffects } from './store/user/user.effects';
import { ClientEffects } from './store/client/client.effects';
import { RoleEffects } from './store/role/role.effects';
import { GroupEffects } from './store/group/group.effects';
import { PageEffects } from './store/page/page.effects';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { AppEffects } from './store/app/app.effects';
import { CanActivateHome } from './guards/external-navigation.guard';
import { HttpClientModule } from '@angular/common/http';
import { InterestGroupEffects } from './store/interestGroup/interestGroup.effects';
import { FeedEffects } from './store/custom/feed/feed.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    StoreModule.forFeature(
      fromCoreFeature.featureKey,
      fromCoreFeature.reducers
    ),
    StoreModule.forFeature(
      fromCustomFeature.featureKey,
      fromCustomFeature.reducers
    ),
    EffectsModule.forFeature([
      UserEffects,
      OrganizationEffects,
      ClientEffects,
      RoleEffects,
      GroupEffects,
      PageEffects,
      AppEffects,
      InterestGroupEffects,
      FeedEffects,
    ]),
    MatSnackBarModule,
    HttpClientModule,
  ],
  exports: [CommonModule, RouterModule, MatIconModule, MatSnackBarModule],
  providers: [CanActivateHome],
})
export class CoreModule {}
