import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';

import * as fromFeed from './feed/feed.reducer';

export const featureKey = 'customFeature';

export interface CustomState {
  [fromFeed.featureSlice]: fromFeed.State;
}

export const reducers = new InjectionToken<ActionReducerMap<CustomState>>(
  featureKey,
  {
    factory: () => ({
      [fromFeed.featureSlice]: fromFeed.Reducer,
    }),
  }
);
