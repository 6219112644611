export class DMSWebpartUserSettings {
  columnOrder: string[] = [];
  columnSizes: { [key: string]: number } = {};
  sortColumn?: string;
  sortDirection?: 'asc' | 'desc';
  pageSize = 100;

  constructor(init?: Partial<DMSWebpartUserSettings>) {
    Object.assign(this, init);
  }
}
