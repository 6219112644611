<div
  class="d-flex flex-column w-100 p-3"
  *ngIf="metadataParameters$ | async as params"
>
  <h2 class="ps-3 pt-3">{{ getTranslation$("setUpPrefilter") | async }}</h2>

  <div class="d-flex flex-column w-100 p-3">
    <div class="d-flex flex-row w-100 align-items-center mb-3">
      <mat-form-field
        appearance="outline"
        class="ms-2 me-2 no-subscript-wrapper"
      >
        <mat-label>{{ getTranslation$("selectLibrary") | async }}</mat-label>
        <mat-select [formControl]="selectedLibraryControl">
          <mat-option
            *ngFor="let library of libraries$ | async"
            [value]="library"
          >
            {{ library.title }}
          </mat-option>
        </mat-select>
        <mat-spinner
          matSuffix
          *ngIf="loadingLibraries"
          class="m-1"
          diameter="26"
        ></mat-spinner>
        <button
          mat-icon-button
          matSuffix
          *ngIf="selectedLibraryControl.value"
          (click)="clearLibrarySelection($event)"
          class="cancel-button"
        >
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div class="d-flex flex-row w-100 align-items-center mb-3">
      <ng-container>
        <mat-form-field
          appearance="outline"
          class="ms-2 me-2 no-subscript-wrapper"
        >
          <mat-label>{{ getTranslation$("filter") | async }}</mat-label>
          <mat-select
            (openedChange)="$event ? focusInput(searchInput) : undefined"
            panelClass="p-0"
            [formControl]="filterParamControl"
          >
            <div class="d-flex flex-column h-100">
              <mat-form-field class="no-subscript-wrapper">
                <mat-label>{{ getTranslation$("search") | async }}</mat-label>
                <input matInput type="text" #searchInput />
              </mat-form-field>
              <div class="overflow-auto scrollbar">
                <mat-option
                  *ngFor="
                    let param of getFilteredParams(params);
                    trackBy: paramTrackBy
                  "
                  [value]="param"
                  [hidden]="
                    param.title.toLowerCase().indexOf(searchInput.value) === -1
                  "
                >
                  {{ param.title }}
                </mat-option>
              </div>
            </div>
          </mat-select>
        </mat-form-field>

        <ng-container *ngIf="filterParamControl.value">
          <div class="d-flex flex-row align-items-center ms-2 me-2 w-40">
            <app-adaptable-input
              class="no-subscript-wrapper me-2"
              [param]="filterParamControl.value"
              [params]="params"
              [library]="library"
              [filter]="true"
              [formControl]="filterValueControl"
              #filterValueElement
            ></app-adaptable-input>
          </div>
        </ng-container>
        <button
          mat-mini-fab
          color="primary"
          (click)="addFilter()"
          [disabled]="isSearchDisabled()"
        >
          <mat-icon>add</mat-icon>
        </button>
      </ng-container>
    </div>
    <mat-chip-listbox>
      <mat-chip *ngFor="let filter of filterValueArray; trackBy: filterTrackBy">
        {{ getParamById(params, filter.paramId)?.title }}:
        {{ getParamValue(params, filter.paramId, filter.value) }}
        <button matChipRemove (click)="removeFilter(filter.paramId)">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
    </mat-chip-listbox>
  </div>
</div>
