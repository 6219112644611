import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-app-silent-signin',
  templateUrl: './app-silent-signin.component.html',
})
export class AppSilentSigninComponent implements OnInit {
  window = window;
  error = false;
  constructor(private route: ActivatedRoute) {}
  ngOnInit(): void {
    if (window.opener && !window.opener.location.origin.includes('portal')) {
      window.close();
    } else {
      this.route.fragment.pipe(first()).subscribe((fragment) => {
        if (fragment) {
          const params = new URLSearchParams(fragment);
          const state = params.get('state');
          if (state) {
            const decoded = decodeURI(state);
            const appId = decoded.split('|').pop();
            if (!environment.production && appId?.includes('localhost'))
              location.assign(
                `http://${appId}/silent-signin-callback#${fragment}`
              );
            else
              location.assign(
                `https://${appId}.ishtar365.com/silent-signin-callback#${fragment}`
              );
          } else this.error = true;
        } else this.error = true;
      });
    }
  }
}
