import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  TranslationService,
  DataObjectService,
  EntityFormComponent,
  StateEntity,
  DataObject,
  GroupUser,
  Application,
  EntityObject,
  FormBuilderComponent,
  ResourceFunction,
} from 'processdelight-angular-components';
import { Task } from '../../domain/models/task.model';
import { MicrosoftAuthenticationService } from 'src/app/msal/injectables/microsoft-authentication.service';
import { Observable, of } from 'rxjs';
import { QuillModule } from 'ngx-quill';
import { Resource } from '../../domain/models/resource.model';
import { ResourceUser } from '../../domain/models/resource-user.model';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

@Component({
  standalone: true,
  selector: 'app-task-info',
  templateUrl: './task-info.component.html',
  styleUrls: ['./task-info.component.scss'],
  imports: [
    CommonModule,
    FormBuilderComponent,
    FormsModule,
    ReactiveFormsModule,
    QuillModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule
  ],
  providers: [
    DataObjectService,
  ],
})
export class TaskInfoComponent
  extends EntityFormComponent<Task>
  implements OnInit, AfterViewInit, OnDestroy
{
  nameChanger(name: string): void {
    throw new Error('Method not implemented.');
  }
  msal = inject(MicrosoftAuthenticationService);
  translationService = inject(TranslationService);
  dataObjectService = inject(DataObjectService);
  override type = StateEntity.Task;
  override templates: {
    configurationName: string;
    template?: TemplateRef<{ config: DataObject }>;
    form?: FormControl;
  }[] = [];
  override getUsers(): Observable<GroupUser[]> {
    return of([]);
  }
  override getGroups(): Observable<GroupUser[]> {
    return of([]);
  }
  override getCurrentApplication(): Application {
    return new Application({});
  }
  override initForm(entityObject?: EntityObject<Task> | undefined): void {
    if (entityObject?.entity) this.initTaskForm(entityObject.entity);
    else this.initTaskForm(new Task({}));
  }
  override nameAccessor(entity: Task): string {
    return entity.title;
  }
  override initTemplates(): {
    configurationName: string;
    template?: TemplateRef<{ config: DataObject }>;
    form?: FormControl;
  }[] {
    return [
      this.getTemplate('TaskName', this.taskTitle),
      this.getTemplate('TaskNumber', this.taskNumber),
      this.getTemplate('TaskDescription', this.taskDescription),
      this.getTemplate('TaskStartDate', this.taskStartTime),
      this.getTemplate('TaskEndDate', this.taskEndTime),
      this.getTemplate('TaskDeadline', this.taskDeadline),
      this.getTemplate('TaskStatus', this.taskStatus),
      this.getTemplate('TaskSkill', this.taskSkill),
      this.getTemplate('TaskProject', this.taskProject),
      this.getTemplate('TaskResourcePeople', this.taskResourceUsers),
      this.getTemplate('TaskResourceMachines', this.taskResourceFunctions),
      this.getTemplate('TaskparentTask', this.parentTask),
      this.getTemplate('TaskType', this.taskType),
    ];
  }

  @ViewChild('title') taskTitle!: TemplateRef<{
    config: DataObject;
  }>;
  @ViewChild('number') taskNumber!: TemplateRef<{
    config: DataObject;
  }>;
  @ViewChild('description') taskDescription!: TemplateRef<{
    config: DataObject;
  }>;
  @ViewChild('startTime') taskStartTime!: TemplateRef<{
    config: DataObject;
  }>;
  @ViewChild('endTime') taskEndTime!: TemplateRef<{
    config: DataObject;
  }>;
  @ViewChild('deadline') taskDeadline!: TemplateRef<{
    config: DataObject;
  }>;
  @ViewChild('status') taskStatus!: TemplateRef<{ config: DataObject }>;
  @ViewChild('taskSkill') taskSkill!: TemplateRef<{ config: DataObject }>;
  @ViewChild('project') taskProject!: TemplateRef<{ config: DataObject }>;
  @ViewChild('taskType') taskType!: TemplateRef<{ config: DataObject }>;
  @ViewChild('resourceUsers') taskResourceUsers!: TemplateRef<{
    config: DataObject;
  }>;
  @ViewChild('resourceFunctions') taskResourceFunctions!: TemplateRef<{
    config: DataObject;
  }>;
  @ViewChild('parentTask') parentTask!: TemplateRef<{ config: DataObject }>; // TODO add default dataproperty in DB

  override buildBaseObject(form: FormGroup, id: string | undefined): Task {
    return new Task({
      ...this.data.entityObject?.entity
    });
  }

  currentUserId = '';

  override ngOnInit(): void {
    super.ngOnInit();
    this.currentUserId = this.msal.id;
  }

  override ngAfterViewInit(): void {
    this.GetLinkedRecordsNotVisible();
    this.templates = this.initTemplates();
    this.fieldConfigurations = this.data?.entityObject?.dataObjects ?? [];
    this.objService.setOriginalData(
      this.fieldConfigurations,
      this.templates
    );
  }

  initTaskForm(task: Task) {
    this.entityFormGroup = new FormGroup({
      title: new FormControl({
        value: task.title,
        disabled: true,
      }, Validators.required),
      number: new FormControl({
        value: task.number,
        disabled: true,
      }, [Validators.required, Validators.pattern('^[A-z0-9s.-]*$')]),
      description: new FormControl({
        value: task.description ?? '',
        disabled: true,
      }),
      taskType: new FormControl({
        value: task.type?.type ?? '',
        disabled: true,
      }, Validators.required),
      skill: new FormControl({
        value: task.skill?.title ?? '',
        disabled: true,
      }),
      project: new FormControl({
        value: task.project?.projectName ?? '',
        disabled: true,
      }),
      // closeTaskWhenSubtasksDone: new FormControl({
      //   value: task?.closeTaskWhenSubtasksDone ?? false,
      //   disabled: false,
      // }),
      status: new FormControl({
        value: task.status?.status ?? '',
        disabled: true,
      }, Validators.required),
      startDate: new FormControl({
        value: task.startTime
          ? task.startTime.toFormat('dd/MM/yyyy')
          : '',
        disabled: true,
      }),
      startTime: new FormControl({
        value: task.startTime
          ? task.startTime.toFormat('HH:mm')
          : '',
        disabled: true,
      }),
      deadlineDate: new FormControl({
        value: task.deadline
          ? task.deadline.toFormat('dd/MM/yyyy')
          : '',
        disabled: true,
      }),
      deadlineTime: new FormControl({
        value: task.deadline
          ? task.deadline.toFormat('HH:mm')
          : '',
        disabled: true,
      }),
      endTime: new FormControl({
        value: task.endTime
          ? task.endTime.toFormat('HH:mm')
          : '',
        disabled: true,
      }),
      endDate: new FormControl({
        value: task.endTime
          ? task.endTime.toFormat('dd/MM/yyyy')
          : '',
        disabled: true,
      }),
      resourceUsers: new FormControl({
        value: this.getAssignedUsers(task.resources ?? []),
        disabled: true,
      }),
      resourceFunctions: new FormControl({
        value: this.getAssignedMachines(task.resources ?? []),
        disabled: true,
      }),
      parentTask: new FormControl({
        value: task.parentTask?.title ?? '',
        disabled: true,
      }),
    });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private getAssignedUsers(resources: Resource[]): string {
    const users: string[] = [];
    const resourceUsers = resources.map((r) => new ResourceUser({ ...r.resourceUser }));

    resourceUsers.forEach((resourceUser) => {   
      if (resourceUser.user?.displayName) {
        users.push(resourceUser.user.displayName);
      }
    });

    return users.join(', ');
  }

  private getAssignedMachines(resources: Resource[]): string {
    const machines: string[] = [];
    const resourceFunctions = resources.map((r) => new ResourceFunction({ ...r.function }));

    resourceFunctions.forEach((resourceFunction) => {   
      if (resourceFunction.name) {
        machines.push(resourceFunction.name);
      }
    });

    return machines.join(', ');
  }
}
