import { LicenseInfo } from './license-info.model';
export class UserLicenseInfo {
  public userId!: string;
  public organization!: string;
  public tenantId!: string;
  public email!: string;
  public name!: string;
  public language?: string;
  public dataverseEnvironmentUrl!: string;
  public sharepointUrl!: string;
  public navColor?: string;
  public navContrast?: string;
  public firstName?: string;
  public lastName?: string;
  public domain?: string;
  public licenses!: LicenseInfo[];
  constructor(obj: Partial<UserLicenseInfo>) {
    Object.assign(this, obj);
  }
}
