import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientFacade } from 'src/app/core/store/client/client.facade';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { PortalActionsService } from 'src/app/core/services/portal-actions.service';
import { isB2C } from 'src/app/core/extensions/b2x.extensions';
import { Router } from '@angular/router';
import { ClientUserRequest } from 'src/app/core/domain/models/client-user-request.model';
import { CRMEntity, LoaderService } from 'processdelight-angular-components';
import { UserFacade } from 'src/app/core/store/user/user.facade';
import { first, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-client-selector',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatButtonModule],
  templateUrl: './client-selector.component.html',
  styleUrls: ['./client-selector.component.scss'],
})
export class ClientSelectorComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private loader: LoaderService,
    private userFacade: UserFacade,
    private clientFacade: ClientFacade,
    private portalActions: PortalActionsService
  ) {}

  clients$ = this.clientFacade.clients$;

  ngOnInit(): void {
    this.portalActions.buttonActions = [];
    if (isB2C()) {
      this.clientFacade.currentClient$
        .pipe(takeUntil(this.destroy$))
        .subscribe((client) => {
          if (client != null) this.router.navigate(['/']);
        });
      this.userFacade.externalUserInfo$
        .pipe(takeUntil(this.destroy$))
        .subscribe((user) => {
          if (user != null && user.clientUserRequests?.length > 0)
            this.router.navigate(['wait-for-approval']);
        });
    }
  }

  selectClient(client: CRMEntity): void {
    const loadersubj = new Subject<void>();
    this.loader.startLoading('Creating request', () => loadersubj);
    this.clientFacade.newRequest$(client).subscribe(() => {
      loadersubj.next();
      loadersubj.complete();
    });
  }

  destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
