import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import {
  AbstractWebPartComponent,
  AbstractWebPartConfigComponent,
  DataSourceService,
  MicrosoftAuthenticationService,
  TranslationService,
  VIEW,
} from 'processdelight-angular-components';
import { filter, first, Subject, takeUntil, zip } from 'rxjs';
import { CoreModule } from 'src/app/core/core.module';
import { PortalPost } from 'src/app/core/domain/models/portal-post.model';
import { FeedFacade } from 'src/app/core/store/custom/feed/feed.facade';
import { UserFacade } from 'src/app/core/store/user/user.facade';
import { clear } from 'typescript-memoize';
import { FeedConfigComponent } from './feed-config/feed-config.component';
import { FeedPostComponent } from './feed-post/feed-post.component';
import { PostPopupComponent } from './post-popup/post-popup.component';
import { ScrollNearEndDirective } from './scroll.directive';

export const CREATEPOST = 'Create Post';
export const CREATECOMMENT = 'Create Comment';
export const CANLIKE = 'Can Like';
export const DELETEANYTHING = 'Can delete anything';
export const EDITALLPOSTS = 'Can edit all posts';

@Component({
  selector: 'app-feed',
  standalone: true,
  imports: [
    CommonModule,
    CoreModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatSnackBarModule,
    DatePipe,
    MatFormFieldModule,
    MatMenuModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatDialogModule,
    FeedPostComponent,
    MatProgressSpinnerModule,
    ScrollingModule,
    ScrollNearEndDirective,
  ],
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.scss'],
})
export class FeedComponent
  extends AbstractWebPartComponent
  implements OnInit, OnDestroy
{
  static override TYPE = 'CMSFeed';
  static override FEATURES = [
    VIEW,
    CREATEPOST,
    CREATECOMMENT,
    CANLIKE,
    DELETEANYTHING,
    EDITALLPOSTS,
  ];
  static override CONFIG_COMPONENT: new () => AbstractWebPartConfigComponent =
    FeedConfigComponent;

  constructor(
    public readonly translations: TranslationService,
    private readonly matDialog: MatDialog,
    private readonly feedFac: FeedFacade,
    private readonly userFacade: UserFacade,
    private readonly snackBar: MatSnackBar,
    private readonly msal: MicrosoftAuthenticationService,
    private readonly dataService: DataSourceService
  ) {
    super();
  }
  readonly pageSize = 5;
  page = 0;

  loading = false;
  hasLoadedAllPosts = false;
  savingLike = false;
  searchTerm = '';
  orderBy: 'asc' | 'desc' = 'desc';

  filteredInterestGroups: string[] | undefined;

  canCreatePosts = false;
  canCreateComment = false;
  canCreateLike = false;
  canDeleteAnything = false;
  canEditAllPosts=false;
  
  portalUserId: string | undefined;
  groupUserId: string | undefined;

  feed: PortalPost[] = [];

  postTrackBy = (index: number, post: PortalPost) => post.id;

  get isSmallView() {
    return this._webpartViewbox!.width! <= 2;
  }

  ngOnInit(): void {
    this.canCreatePosts = this.canAccessFeature(CREATEPOST);
    this.canCreateComment = this.canAccessFeature(CREATECOMMENT);
    this.canCreateLike = this.canAccessFeature(CANLIKE);
    this.canDeleteAnything = this.canAccessFeature(DELETEANYTHING);
    this.canEditAllPosts = this.canAccessFeature(EDITALLPOSTS);

    this.dataService
      .getData$<string[]>('filteredInterestGroups')
      .pipe(takeUntil(this.destroy$))
      .subscribe((groups) => {
        const reloadInterestGroups =
          this.filteredInterestGroups?.some((ig) => groups?.includes(ig)) ||
          this.filteredInterestGroups?.length !== groups?.length;
        this.filteredInterestGroups = groups;
        if (reloadInterestGroups) {
          this.searchPosts(true);
        }
      });

    zip([this.userFacade.externalUserInfo$, this.userFacade.userLicenseInfo$])
      .pipe(
        filter((e) => !!e),
        first()
      )
      .subscribe(([external, internal]) => {
        if (internal) this.groupUserId = internal.userId;
        if (external) this.portalUserId = external?.portalUserId;
      });

    this.feedFac.posts$.pipe(takeUntil(this.destroy$)).subscribe((posts) => {
      this.feed = posts ?? [];
      clear(['posts']);
    });

    this.feedFac.hasLoadedAllPosts$
      .pipe(
        filter((l) => !!l),
        first()
      )
      .subscribe(() => (this.hasLoadedAllPosts = true));

    this.searchPosts(true);
  }

  searchPosts(resetPaging: boolean) {
    this.loading = true;

    if (resetPaging) {
      this.page = 0;
      this.hasLoadedAllPosts = false;
    }

    this.feedFac
      .getFeed$(
        this.filteredInterestGroups,
        this.portalUserId ?? this.groupUserId!,
        this.orderBy,
        this.pageSize,
        this.page,
        this.searchTerm,
        resetPaging
      )
      .subscribe((p) => {
        p.length < this.pageSize;
        this.loading = false;
      });

    if (resetPaging) this.feed = [];
  }

  createNewPost() {
    this.matDialog.open(PostPopupComponent, {
      width: '100%',
      maxWidth: '750px',
      height: '100%',
      panelClass: 'p-4',
      data: {},
    });
  }

  nextBatch() {
    if (this.loading || this.hasLoadedAllPosts) return;
    this.page += 1;
    this.searchPosts(false);
  }

  destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
