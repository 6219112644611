<div class="container-fluid">
  <div class="d-flex justify-content-between mt-2 mb-4 px-1">
  <span class="title fs-3 fw-bold">{{getTranslation('likeOverview')}}</span>
    <mat-icon [style.cursor]="'pointer'" (click)="close()">close</mat-icon>
  </div>

  <processdelight-dashboard
  *ngIf="likes"
  [data]="likes"
  [possibleColumns]="possibleColumns"
  [selectedColumns]="selectedColumns"
  [showExpandedDetails]="false"
  [emptyTableText]="noItems"
  [highlightOnHover]="true"
  [horizontalScroll]="true"
  [columnsResizable]="true"
>
</processdelight-dashboard>
<ng-template #noItems>
  <div class="w-100 h-100 d-flex justify-content-center align-items-center">
    <h1 >{{ getTranslation("noLikes") }}</h1>
  </div>
</ng-template>
</div>
