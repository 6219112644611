import { Entity, InterestGroup } from 'processdelight-angular-components';
import { GroupUserEntity } from 'processdelight-angular-components/lib/domain/models/groupUserEntity';
import { PortalPostComment } from './portal-post-comment.model';
import { PortalPostLike } from './portal-post-like.model';
import { PortalUser } from './portal-user.model';

export class PortalPost extends Entity {
  public name!: string;
  public pictureUrl!: string;
  public description!: string;
  public isDeleted!: boolean;
  public groupsRestrictionEnabled!: boolean;

  public portalUserAuthorId?: string;
  public groupUserAuthorId?: string;
  public portalUserAuthor?: PortalUser;
  public groupUserAuthor?: GroupUserEntity;

  public likes!: PortalPostLike[];
  public comments!: PortalPostComment[];
  public groups!: InterestGroup[];

  constructor(obj: Partial<PortalPost>) {
    super(obj);
    if (obj?.likes)
      this.likes = obj.likes.map((like) => new PortalPostLike(like));
    if (obj?.comments)
      this.comments = obj.comments.map(
        (comment) => new PortalPostComment(comment)
      );
    if (obj?.groups)
      this.groups = obj.groups.map((group) => new InterestGroup(group));
  }
}
