<button class="close-button" mat-icon-button (click)="onClose()">
  <mat-icon>close</mat-icon>
</button>
<mat-card
  class="h-100 d-flex flex-column list-background ql-editor scrollbar p-0"
>
  <mat-card-content class="p-0 h-100">
    <div
      class="w-100 h-100 d-flex flex-column position-relative min-height-0 min-width-0"
    >
      <div
        class="d-flex flex-column w-100 p-3 flex-shrink-0 min-height-0 min-width-0"
        *ngIf="metadataParameters$ | async as params"
      >
        <div
          class="d-flex flex-row justify-content-between align-items-center w-100"
        >
          <div class="d-flex flex-row align-items-center">
            <button mat-icon-button (click)="toggleFiltering()">
              <mat-icon class="material-icons-outlined">
                filter_alt{{ filtering ? "_off" : "" }}
              </mat-icon>
            </button>
            <ng-container *ngIf="filtering">
              <mat-form-field
                appearance="outline"
                class="ms-2 me-2 no-subscript-wrapper"
              >
                <mat-label>{{ getTranslation$("filter") | async }}</mat-label>
                <mat-select
                  (openedChange)="$event ? focusInput(searchInput) : undefined"
                  panelClass="p-0"
                  [formControl]="filterParamControl"
                >
                  <div class="d-flex flex-column h-100">
                    <mat-form-field class="no-subscript-wrapper">
                      <mat-label
                        >{{ getTranslation$("search") | async }}...</mat-label
                      >
                      <input matInput type="text" #searchInput />
                    </mat-form-field>
                    <div class="overflow-auto scrollbar">
                      <mat-option
                        *ngFor="
                          let param of getFilteredParams(params);
                          trackBy: paramTrackBy
                        "
                        [value]="param"
                        [hidden]="
                          param.title
                            .toLowerCase()
                            .indexOf(searchInput.value) === -1
                        "
                      >
                        {{ param.title }}
                      </mat-option>
                    </div>
                  </div>
                </mat-select>
              </mat-form-field>
              <ng-container *ngIf="filterParamControl.value">
                <app-adaptable-input
                  class="no-subscript-wrapper"
                  [param]="filterParamControl.value"
                  [params]="params"
                  [library]="library"
                  [filter]="true"
                  [formControl]="filterValueControl"
                  #filterValueElement
                ></app-adaptable-input>
                <button
                  mat-mini-fab
                  color="primary"
                  class="ms-2"
                  *ngIf="
                    filterValue[filterParamControl.value.id] === undefined;
                    else filterExists
                  "
                  (click)="addFilter()"
                  [disabled]="
                    filterValueControl.invalid || filterValueControl.pristine
                  "
                >
                  <mat-icon>search</mat-icon>
                </button>
                <ng-template #filterExists>
                  <button
                    mat-mini-fab
                    color="primary"
                    class="ms-2"
                    (click)="addFilter()"
                  >
                    <mat-icon>search</mat-icon>
                  </button>
                </ng-template>
              </ng-container>
            </ng-container>
          </div>
          <mat-spinner *ngIf="loading" diameter="40" class="ms-2"></mat-spinner>
        </div>
        <mat-chip-listbox>
          <mat-chip
            *ngFor="let filter of filterValueArray; trackBy: filterTrackBy"
            (click)="selectFilter(params, filter.paramId, filter.value)"
          >
            {{ getParamById(params, filter.paramId)?.title }}:
            {{ getParamValue(params, filter.paramId, filter.value) }}
            <button matChipRemove (click)="removeFilter(filter.paramId)">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
        </mat-chip-listbox>
      </div>
      <div class="h-100 min-height-0 flex-grow-1 pt-2 text-center">
        <processdelight-dashboard
          class="w-100 h-100"
          [data]="pagedItems"
          [maxSizeItems]="totalRecordCount"
          [pageSize]="pageSize"
          [possibleColumns]="possibleColumns"
          [selectedColumns]="selectedColumns"
          [showExpandedDetails]="false"
          [sortColumn]="orderBy"
          [sortDirection]="orderByDirection ?? ''"
          (pageChange)="pageChange($event)"
          (sortChange)="sortChange($event)"
          (rowExpanded)="!appLibrary ? openPreview($event) : undefined"
          (columnChange)="columnChange($event)"
          [emptyTableText]="noItems"
          [itemTrackByFn]="itemTrackByFn"
          [selectedItems]="selectedItems"
          [columnBreakpointObserver]="false"
          [highlightOnHover]="true"
          [horizontalScroll]="true"
          (rowDblClicked)="!appLibrary ? openDocument($event, true) : undefined"
          [columnsResizable]="true"
          (selectionChanged)="selectedItems = $event"
          (columnWidthChanged)="columnWidthChange($event)"
          [multiSelect]="false"
          [multiSelectActions]="multiSelectActions"
          [highlightSelected]="true"
          [arrowKeySelectionChange]="true"
          [extraButtonsTemplate]="buttonsTemplateRef"
          [customContextMenu]="true"
          [contextMenuActionsFn]="itemContextMenuFn"
        >
        </processdelight-dashboard>
        <ng-template #noItems>
          <div
            class="w-100 h-100 d-flex justify-content-center align-items-center"
          >
            <h1 *ngIf="!loading">
              {{ getTranslation$("noResults") | async }}
            </h1>
          </div>
        </ng-template>
      </div>
    </div>
  </mat-card-content>
</mat-card>
