<div class="h-100 d-flex flex-column">
  <processdelight-action-bar
    [navColor]="navColor"
    [navContrast]="navContrast"
    [title]="title"
    [iconActions]="iconActions"
    [searchEnabled]="searchEnabled"
    [sideBarButtonEnabled]="sideBarButtonEnabled"
    [buttonActions]="buttonActions"
    class="flex-shrink-0"
  ></processdelight-action-bar>
  <div
    class="flex-grow-1 d-flex flex-row justify-content-between align-items-center mt-2 mb-2"
  >
    <div class="ms-2 d-flex flex-column">
      <div>Client reference:</div>
      <div>{{ quoteDetailsData.quotation.agentRef }}</div>
    </div>
    <mat-form-field appearance="outline" [style.max-width.rem]="14">
      <mat-label>Desired delivery date</mat-label>
      <input
        matInput
        [matDatepicker]="picker"
        [min]="earliestDeliveryDate"
        [(ngModel)]="quoteDetailsData.quotation.deliveryDate"
        required
        processdelightDatePickerFormat
        datePickerFormat="dd/MM/yyyy"
        (ngModelChange)="validate()"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <div class="d-flex flex-column align-items-end me-2">
      <div>
        <strong>Total Price All: </strong>
      </div>
      <div>€ {{ getTotalPrice() }}</div>
    </div>
  </div>
  <app-verhofste-product-table
    [showTotalPrice]="true"
    [data]="data"
    [displayedColumns]="displayedColumns"
    [possibleColumns]="possibleColumns"
    [actualDisplayedColumns]="displayedColumns"
    [filtersEnabled]="filtersEnabled"
    [sortSettings]="sortSettings"
  ></app-verhofste-product-table>
</div>
