import { DateTime } from 'luxon';
import { VerhofsteAgent } from './agent.model';
import { VerhofsteOfferLine } from './offer-line.model';

export class VerhofsteOffer {
  id!: string;
  agentRef?: string;
  description?: string;
  agentId!: number;
  quoteNumber!: string;
  quoteSequenceNumber!: number;
  createdOn!: DateTime;
  validityDate!: DateTime;
  orderDate?: DateTime;
  deliveryDate?: DateTime;
  offerLines!: VerhofsteOfferLine[];

  agent!: VerhofsteAgent;

  constructor(init: Partial<VerhofsteOffer>) {
    Object.assign(this, init);
    if (init.createdOn && typeof init.createdOn === 'string') {
      this.createdOn = DateTime.fromISO(init.createdOn, {
        zone: 'utc',
      }).toLocal();
    }
    if (init.validityDate && typeof init.validityDate === 'string') {
      this.validityDate = DateTime.fromISO(init.validityDate, {
        zone: 'utc',
      }).toLocal();
    }
    if (init.orderDate && typeof init.orderDate === 'string') {
      this.orderDate = DateTime.fromISO(init.orderDate, {
        zone: 'utc',
      }).toLocal();
    }
    if (init.deliveryDate && typeof init.deliveryDate === 'string') {
      this.deliveryDate = DateTime.fromISO(init.deliveryDate, {
        zone: 'utc',
      }).toLocal();
    }
    if (init.offerLines) {
      this.offerLines = init.offerLines.map(
        (line) => new VerhofsteOfferLine(line)
      );
    }
  }
}
