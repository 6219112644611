import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of, switchMap, tap } from 'rxjs';
import {
  getInterestGroups,
  getInterestGroupsForUser,
  addPortalUserInterestGroup,
  deletePortalUserInterestGroup,
  getInterestGroupsResolved,
  getInterestGroupsForUserResolved,
  addPortalUserInterestGroupResolved,
  deletePortalUserInterestGroupResolved,
} from './interestGroup.actions';
import { PortalService } from '../../services/portal.service';
import { interestGroupJoinRequests$ } from 'src/app/app.observables';

@Injectable({ providedIn: 'root' })
export class InterestGroupEffects {
  constructor(private actions$: Actions, private api: PortalService) {}

  getInterestGroups = createEffect(() =>
    this.actions$.pipe(
      ofType(getInterestGroups),
      switchMap(({ callback, error }) =>
        this.api.getInterestGroups().pipe(
          tap((interestGroups) =>
            callback ? callback(interestGroups) : undefined
          ),
          switchMap((interestGroups) =>
            of(getInterestGroupsResolved({ interestGroups }))
          ),
          catchError((e) => {
            if (error) error(e);

            return [];
          })
        )
      )
    )
  );
  getInterestGroupsForUser = createEffect(() =>
    this.actions$.pipe(
      ofType(getInterestGroupsForUser),
      switchMap(({ portalUserId, callback, error }) =>
        this.api.getInterestGroupsForUser(portalUserId).pipe(
          tap((interestGroups) =>
            callback ? callback(interestGroups) : undefined
          ),
          switchMap((interestGroups) =>
            of(getInterestGroupsForUserResolved({ interestGroups }))
          ),
          catchError((e) => {
            if (error) error(e);

            return [];
          })
        )
      )
    )
  );
  addPortalUserInterestGroup = createEffect(() =>
    this.actions$.pipe(
      ofType(addPortalUserInterestGroup),
      switchMap(({ portalUserId, interestGroupId, callback, error }) =>
        this.api.addPortalUserInterestGroup(portalUserId, interestGroupId).pipe(
          tap((response) => {
            if (callback) callback(response);
            if (response.joinRequest)
              interestGroupJoinRequests$.next([
                ...interestGroupJoinRequests$.value,
                response.joinRequest,
              ]);
          }),
          switchMap((response) =>
            of(addPortalUserInterestGroupResolved({ response }))
          ),
          catchError((e) => {
            if (error) error(e);

            return [];
          })
        )
      )
    )
  );
  deletePortalUserInterestGroup = createEffect(() =>
    this.actions$.pipe(
      ofType(deletePortalUserInterestGroup),
      switchMap(({ portalUserId, interestGroup, callback, error }) =>
        this.api
          .deletePortalUserInterestGroup(portalUserId, interestGroup)
          .pipe(
            tap((deletedInterestGroups) =>
              callback ? callback(deletedInterestGroups) : undefined
            ),
            switchMap((deletedInterestGroup) =>
              of(
                deletePortalUserInterestGroupResolved({ deletedInterestGroup })
              )
            ),
            catchError((e) => {
              if (error) error(e);

              return [];
            })
          )
      )
    )
  );
}
