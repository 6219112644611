import { Injectable } from '@angular/core';
import { MicrosoftAuthenticationService } from 'processdelight-angular-components';
import { OrganizationFacade } from 'src/app/core/store/organization/organization.facade';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FunctionsService {
  apiBase = `${environment.ishtarFunctions}/api`;
  tenantId?: string;
  sharepointUrl?: string;
  constructor(
    private msal: MicrosoftAuthenticationService,
    private organizationFacade: OrganizationFacade
  ) {
    // Can't really do takeuntil destroy, since services don't have a destroy lifecycle, but since it is root provided we know this will just live as long as the app
    this.organizationFacade.organizationInfo$.subscribe((o) => {
      this.tenantId = o?.tenantId;
      this.sharepointUrl = o?.sharepointUrl;
    });
  }
  private createApiEndpointUrl(path: string) {
    const url = new URL(`${this.apiBase}/${path}`);
    if (environment.ishtarFunctionsKey.trim() !== '')
      url.searchParams.append('code', environment.ishtarFunctionsKey);
    return url.toString();
  }

  getFileLocation(relativeUrl: string) {
    const sharepointUrl = this.sharepointUrl;
    if (!sharepointUrl) throw new Error('Sharepoint url not set');
    const sp = 'sharepoint.com/';
    const index = sharepointUrl.indexOf(sp) + sp.length - 1;
    return `${sharepointUrl.substring(0, index)}${relativeUrl}`;
  }

  getSPValueUrl(relativeUrl: string, preview = false) {
    const split = relativeUrl.split('/').filter((s) => s);
    let filename = split.pop()!;
    const filenameSplit = filename.split('.');
    const extension = filenameSplit.pop()!;
    filename = filenameSplit.join('.');
    const library = split.join('/');
    return this.createApiEndpointUrl(
      `sharepoint/${this.tenantId}/libraries/${encodeURIComponent(
        encodeURIComponent(library)
      )}/${encodeURIComponent(filename)}.${extension}?sharepointUrl=${
        this.sharepointUrl
      }&preview=${preview}`
    );
  }
  getSPValueUrlById(sharepointId: string, preview = false) {
    return this.createApiEndpointUrl(
      `sharepoint/${this.tenantId}/libraries/files/value?sharepointUrl=${this.sharepointUrl}&fileId=${sharepointId}&preview=${preview}`
    );
  }
}
