import { Injectable } from '@angular/core';
import { select } from '@ngrx/store';
import { BaseFacade } from '../base.facade';
import { getMetadataParams, resetSlice } from './metadata.actions';
import { metadataSelectors } from './metadata.selectors';
import { map } from 'rxjs';
import { MetadataParam } from 'src/app/webparts/dms-component/domain/models/metadata-param.model';

@Injectable({
  providedIn: 'root',
})
export class MetadataFacade extends BaseFacade {
  metadataParams$ = this._store.pipe(
    select(metadataSelectors.getMetadataParams),
    map((params) =>
      params.map(
        (param) =>
          new MetadataParam({
            ...param,
            choices: param.choices ? [...param.choices] : [],
          })
      )
    )
  );
  fixedParams$ = this._store.pipe(select(metadataSelectors.getFixedParams));
  fileNameParam$ = this._store.pipe(select(metadataSelectors.getFileNameParam));

  getMetadataParams$() {
    return this.dispatchAction$(getMetadataParams);
  }

  resetSlice() {
    this._store.dispatch(resetSlice());
  }
}
