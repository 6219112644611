import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/core/store/app.reducer';

const pageState = (state: AppState) => state.portalFeature.page;
const loadingPages = createSelector(pageState, (state) => state.loadingPages);
const loadingWebPartTypes = createSelector(
  pageState,
  (state) => state.loadingWebPartTypes
);
const getPages = createSelector(pageState, (state) => state.pages);
const getWebPartTypes = createSelector(
  pageState,
  (state) => state.webPartTypes
);
export const pageSelectors = {
  loadingPages,
  getPages,
  loadingWebPartTypes,
  getWebPartTypes,
};
