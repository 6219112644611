import { Action, createReducer, on } from '@ngrx/store';
import { UserSettings } from '../../domain/models/user-settings.model';
import {
  addClientUserRequest,
  createPortalClientUserGroupResolved,
  deletePortalClientUserGroupResolved,
  getExternalUserInfoResolved,
  getLicensesForUserResolved,
  getUserSettingsResolved,
  resetSlice,
  updateUserSettingsResolved,
} from './user.actions';
import { UserLicenseInfo } from '../../domain/models/user-license-info.model';
import { ExternalUserInfo } from '../../domain/models/external-user-info.model';
import { ClientUserInfo } from '../../domain/models/client-user-info.model';
import { Form } from 'processdelight-angular-components';
import { PortalAppConfig } from '../../domain/models/portal-app-config';

export const featureSlice = 'user';

export interface State {
  licenseInfo?: UserLicenseInfo;
  externalUserInfo?: ExternalUserInfo;
  settings: UserSettings;
  appSettings?: PortalAppConfig;
  forms?: Form[];
}

const defaultState: State = {
  licenseInfo: undefined,
  externalUserInfo: undefined,
  settings: new UserSettings(),
  forms: undefined,
};

export function Reducer(state: State | undefined, action: Action) {
  return userReducer(state, action);
}

export const initialState: State = defaultState;
export const userReducer = createReducer(
  initialState,
  on(getLicensesForUserResolved, (state, { licenseInfo }) => ({
    ...state,
    licenseInfo,
  })),
  on(getExternalUserInfoResolved, (state, { externalUserInfo }) => ({
    ...state,
    externalUserInfo,
  })),
  on(getUserSettingsResolved, (state, { settings }) => ({
    ...state,
    settings: settings,
  })),
  on(updateUserSettingsResolved, (state, { settings }) => ({
    ...state,
    settings: settings,
  })),
  on(addClientUserRequest, (state, { request }) => ({
    ...state,
    externalUserInfo: state.externalUserInfo
      ? new ExternalUserInfo({
          ...state.externalUserInfo,
          clientUserRequests:
            state.externalUserInfo.clientUserRequests.concat(request),
        })
      : new ExternalUserInfo({ clientUserRequests: [request] }),
  })),

  on(
    deletePortalClientUserGroupResolved,
    (state, { portalClientUserGroup }) => ({
      ...state,
      externalUserInfo: state.externalUserInfo
        ? {
            ...state.externalUserInfo,
            clientUserInfo:
              state.externalUserInfo?.clientUserInfo.map((u) =>
                u.id === portalClientUserGroup.portalClientUserId
                  ? new ClientUserInfo({
                      ...u,
                      groups: u.groups.filter(
                        (g) => g.id !== portalClientUserGroup.portalGroupId
                      ),
                    })
                  : u
              ) ?? [],
          }
        : undefined,
    })
  ),
  on(
    createPortalClientUserGroupResolved,
    (state, { portalClientUserGroup, groups }) => ({
      ...state,
      externalUserInfo: state.externalUserInfo
        ? {
            ...state.externalUserInfo,
            clientUserInfo:
              state.externalUserInfo?.clientUserInfo.map((u) =>
                u.id === portalClientUserGroup.portalClientUserId
                  ? new ClientUserInfo({
                      ...u,
                      groups: u.groups.concat(
                        groups.filter(
                          (g) => g.id === portalClientUserGroup.portalGroupId
                        )
                      ),
                    })
                  : u
              ) ?? [],
          }
        : undefined,
    })
  ),

  on(resetSlice, () => initialState)
);
