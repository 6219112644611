import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { UserFacade } from 'src/app/core/store/user/user.facade';

@Component({
  selector: 'app-wait-for-approval',
  standalone: true,
  imports: [CommonModule, MatCardModule],
  templateUrl: './wait-for-approval.component.html',
  styleUrls: ['./wait-for-approval.component.scss'],
})
export class WaitForApprovalComponent {
  constructor(private userFac: UserFacade) {}

  userInfo$ = this.userFac.externalUserInfo$;
}
