import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { PortalService } from '../../services/portal.service';
import { BaseEffects } from '../base.effects';
import { PortalAppConfig } from '../../domain/models/portal-app-config';
import {
  getAppConfig,
  getAppConfigResolved,
  getForms,
  getFormsResolved,
  updateAppConfig,
  updateAppConfigResolved,
} from './app.actions';

@Injectable({ providedIn: 'root' })
export class AppEffects extends BaseEffects {
  constructor(private portalService: PortalService, actions$: Actions) {
    super(actions$);
  }

  getAppSettings = this.createDefaultEffect(
    getAppConfig,
    () =>
      this.portalService.getAppConfig<PortalAppConfig>(
        'Ishtar.Portal',
        PortalAppConfig
      ),
    {
      valueMapper: (v) => ({ config: v.result }),
      action: getAppConfigResolved,
    }
  );

  updateAppSettings = this.createDefaultEffect(
    updateAppConfig,
    ({ config }) =>
      this.portalService.updateAppConfig<PortalAppConfig>(
        'Ishtar.Portal',
        PortalAppConfig,
        config
      ),
    {
      valueMapper: (v) => ({ config: v.result }),
      action: updateAppConfigResolved,
    }
  );

  getforms = this.createDefaultEffect(
    getForms,
    () => this.portalService.getForms(),
    {
      valueMapper: (v) => ({ forms: v.result }),
      action: getFormsResolved,
    }
  );
}
