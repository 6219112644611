const originSubDomainRegex =
  /https:\/\/((?<domain>[a-z0-9-]+)\.)?portal(\.(dev|test|uat|prod|demo))?\.ishtar365\.com/i;

export function getSubDomain() {
  const subDomainMatch = location.origin.match(originSubDomainRegex);
  return subDomainMatch?.groups?.domain;
}
export function isB2C() {
  const subDomain = getSubDomain();
  return !!subDomain && subDomain != 'internal';
}
export function isB2B() {
  const subDomain = getSubDomain();
  return !subDomain || subDomain == 'internal';
}
