<div class="dialogContainer h-100 w-100">
  <h5 class="property-header">
    <div class="formType">[{{ data.entityObject?.form?.name }}]</div>
    {{ data.entityObject?.entity?.title }}
  </h5>
  <div class="align-items-center d-flex justify-content-end buttons-header p-2 w-100">
    <mat-icon class="closeButtonIcon" (click)="onCloseDialog()">close</mat-icon>
  </div>
  <div class="w-100 h-100 client-dialog-container scrollbar min-height-0">
    <form [formGroup]="entityFormGroup" class="h-100 min-height-0">
      <div class="d-flex flex-column h-100">
        <processdelight-form-builder
          #formBuilder
          *ngIf="fieldConfigurations"
          [formgroup]="entityFormGroup"
          [debug]="true"
          [isNewForm]="false"
          [disabled]="true"
          [objService]="dataObjectService"
        ></processdelight-form-builder>

        <ng-template #number let-element="config">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{ translations.taskNr }}</mat-label>
            <input
              type="text"
              formControlName="number"
              matInput
              readonly="readonly"
            />
          </mat-form-field>
        </ng-template>
        <ng-template #title let-element="config">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{ translations.taskName }}</mat-label>
            <input
              formControlName="title"
              matInput
            />
          </mat-form-field>
        </ng-template>
        <ng-template #description let-element="config">
          <div class="d-flex flex-column w-100" style="height: 300px; padding: 5px">
            <quill-view-html class="w-100 h-100" [content]="entityFormGroup.get('description')?.value"></quill-view-html>
          </div>
        </ng-template>
        <ng-template #taskType let-element="config">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{ translations.type }}</mat-label>
            <input
              formControlName="taskType"
              matInput
            />
          </mat-form-field>
        </ng-template>
        <ng-template #status let-element="config">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{ translations.status }}</mat-label>
            <input
              formControlName="status"
              matInput
            />
          </mat-form-field>
        </ng-template>
        <ng-template #taskSkill let-element="config">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{ translations.skill }}</mat-label>
            <input
              formControlName="skill"
              matInput
              placeholder="{{ translations.skill }}"
            />
          </mat-form-field>
        </ng-template>
        <ng-template #project let-element="config">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{ translations.project }}</mat-label>
            <input
              formControlName="project"
              matInput
              placeholder="{{ translations.project }}"
            />
          </mat-form-field>
        </ng-template>
        <ng-template #startTime let-element="config">
          <div class="d-flex align-items-center w-100">
            <div class="col-md-5 flex-grow-1">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>{{ translations.startDate }}*</mat-label>
                <input
                  formControlName="startDate"
                  matInput
                />
              </mat-form-field>
            </div>
            <div class="col-md-5 flex-grow-1">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>{{ translations.startTime }}*</mat-label>
                <input
                  formControlName="startTime"
                  matInput
                />
              </mat-form-field>
            </div>
          </div>
        </ng-template>
        <ng-template #deadline let-element="config">
          <div class="d-flex align-items-center w-100">
            <div class="col-md-5 flex-grow-1">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>{{ translations.deadlineDate }}*</mat-label>
                <input
                  formControlName="deadlineDate"
                  matInput
                />
              </mat-form-field>
            </div>
            <div class="col-md-5 flex-grow-1">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>{{ translations.deadlineTime }}*</mat-label>
                <input
                  formControlName="deadlineTime"
                  matInput
                />
              </mat-form-field>
            </div>
          </div>
        </ng-template>
        <ng-template #endTime let-element="config">
          <div class="d-flex align-items-center w-100" *ngIf="data.entityObject?.entity?.endTime">
            <div class="col-md-5 flex-grow-1">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>{{ translations.endDate }}</mat-label>
                <input
                  formControlName="endDate"
                  matInput
                />
              </mat-form-field>
            </div>
            <div class="col-md-5 flex-grow-1">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>{{ translations.endTime }}</mat-label>
                <input
                  formControlName="endTime"
                  matInput
                />
              </mat-form-field>
            </div>
          </div>
        </ng-template>
        <ng-template #resourceUsers let-element="config">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>Assign people</mat-label>
            <input
              formControlName="resourceUsers"
              matInput
            />
          </mat-form-field>
        </ng-template>
        <ng-template #resourceFunctions let-element="config">
          <ng-container>
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>Assign machines</mat-label>
              <input
                formControlName="resourceFunctions"
                matInput
              />
            </mat-form-field>
          </ng-container>
        </ng-template>
        <ng-template #parentTask let-element="config">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{ translations.parentTask }}</mat-label>
            <input
              formControlName="parentTask"
              matInput
            />
          </mat-form-field>
        </ng-template>
        <!-- <ng-template #closeTaskWhenSubtasksDone let-element="config">
          <mat-checkbox
            formControlName="closeTaskWhenSubtasksDone"
          ></mat-checkbox>
        </ng-template> -->
      </div>
    </form>
  </div>
</div>