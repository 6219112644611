import { DateTime } from 'luxon';
import { BaseEntity } from 'processdelight-angular-components';
import { LibraryMetadataChoice } from './library-metadata-choice.model';
import { MetadataFilterType } from './metadata.functions';

export class LibraryParamConfig extends BaseEntity {
  title?: string;
  paramId!: string;
  libraryId?: string;
  defaultValue?: MetadataFilterType;
  choices: LibraryMetadataChoice[] = [];
  position!: number;
  size!: number;

  constructor(obj: Partial<LibraryParamConfig>) {
    super(obj);
    Object.assign(this, obj);
    if (obj.defaultValue)
      try {
        this.defaultValue = JSON.parse(obj.defaultValue as any);
      } catch {
        if (typeof obj.defaultValue == 'string') {
          const date = DateTime.fromFormat(
            obj.defaultValue as any,
            'dd/MM/yyyy Hh:mm'
          );
          if (date.invalidReason == null) {
            this.defaultValue = date.toJSDate();
          } else this.defaultValue = obj.defaultValue as any;
        } else this.defaultValue = obj.defaultValue as any;
      }
  }
}
