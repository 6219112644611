import { Entity, InterestGroup } from 'processdelight-angular-components';
import { PortalUser } from './portal-user.model';

export class PortalUserInterestGroupJoinRequest extends Entity {
  interestGroupId!: string;
  interestGroup!: InterestGroup;
  userId!: string;
  user!: PortalUser;

  requestStatus: 'Pending' | 'Accepted' | 'Rejected' = 'Pending';
  requestDate!: Date;

  constructor(obj: Partial<PortalUserInterestGroupJoinRequest>) {
    super(obj);
    if (obj.interestGroup)
      this.interestGroup = new InterestGroup(obj.interestGroup);
    if (obj.user) this.user = new PortalUser(obj.user);
  }
}
