import { Injectable } from '@angular/core';
import { PortalService } from '../../services/portal.service';
import { BaseEffects } from '../base.effects';
import { getClientsResolved } from '../client/client.actions';
import {
  getDefaultRoles,
  getDefaultRolesResolved,
  getRoles,
  getRolesResolved,
  updateRoleConfiguration,
} from './role.actions';
import { Actions } from '@ngrx/effects';

@Injectable({ providedIn: 'root' })
export class RoleEffects extends BaseEffects {
  constructor(private portalService: PortalService, actions$: Actions) {
    super(actions$);
  }

  getRoles = this.createDefaultEffect(
    getRoles,
    () => this.portalService.getRoles(),
    { valueMapper: (v) => ({ roles: v.result }), action: getRolesResolved }
  );

  getDefaultRoles = this.createDefaultEffect(
    getDefaultRoles,
    () => this.portalService.getDefaultRoles(),
    { valueMapper: (v) => ({ defaultRoles: v.result }), action: getDefaultRolesResolved }
  );

  updateRoleConfiguration = this.createDefaultEffect(
    updateRoleConfiguration,
    ({ roleConfiguration }) =>
      this.portalService.updateRoleConfiguration(roleConfiguration),
    [
      {
        valueMapper: (v) => ({ roles: v.result.roles }),
        action: getRolesResolved,
      },
      {
        valueMapper: (v) => ({
          clients: v.result.clientConfigs.sort(
            (c1, c2) => c1.client.name?.localeCompare(c2.client.name ?? '') ?? 1
          ),
        }),
        action: getClientsResolved,
      },
    ]
  );
}
