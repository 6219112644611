import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../app.reducer';
import {
  getAppConfig,
  getForms,
  updateAppConfig,
  updateAppConfigResolved,
} from './app.actions';
import { first, Observable, of, Subject } from 'rxjs';
import { PortalAppConfig } from '../../domain/models/portal-app-config';
import { appSelectors } from './app.selectors';
import { Form } from 'processdelight-angular-components';

@Injectable({
  providedIn: 'root',
})
export class AppFacade {
  appConfig$ = this._store.pipe(select(appSelectors.getAppConfig));
  forms$ = this._store.pipe(select(appSelectors.getForms));

  constructor(private _store: Store<AppState>) {}

  getAppConfig$() {
    const subject = new Subject<void>();
    this._store.dispatch(
      getAppConfig({
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  updateAppConfig$(config: PortalAppConfig) {
    const subject = new Subject<void>();
    this._store.dispatch(
      updateAppConfig({
        config,
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  getForms$() {
    let value: Form[] | undefined = undefined;
    this.forms$.pipe(first()).subscribe((f) => {
      value = f;
    });
    if (value) return of(value) as unknown as Observable<Form[]>;
    const subject = new Subject<Form[]>();
    this._store.dispatch(
      getForms({
        callback: (f) => {
          subject.next(f);
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }
}
