export class ClientData {
  propertyName!: string;
  propertyLabel!: string;
  value?: string;
  dataType!: string;

  constructor(obj: Partial<ClientData>) {
    Object.assign(this, obj);
  }
}
