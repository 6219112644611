import { NestedTreeControl } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTreeModule, MatTreeNestedDataSource } from '@angular/material/tree';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  AbstractWebPartComponent,
  AbstractWebPartConfigComponent,
  DataSourceService,
  TranslationService,
  VIEW,
  WebPartConfig,
  WebpartViewBox,
  hexToRgb,
} from 'processdelight-angular-components';
import { Subject, combineLatest, first, forkJoin, takeUntil } from 'rxjs';
import { InterestGroupFacade } from 'src/app/core/store/interestGroup/interestGroup.facade';
import { CoiFilterPopupComponent } from './coi-filter-popup/coi-filter-popup.component';
import { CommunitiesOfInterestConfigComponent } from './communities-of-interest-config/communities-of-interest-config.component';
import {
  InterestGroupWebpartUserSettings,
  InterestGroupWebpartUserSettingsService,
} from './services/user-settings.service';
import { InterestGroupVM as InterestGroup } from './interest-group.model';
import { Memoize } from 'typescript-memoize';

interface Groups {
  id: string;
  title: string;
  color: string;
  description?: string;
  icon?: string;
  isSelected: boolean;
  children?: Groups[];
}

@Component({
  standalone: true,
  selector: 'app-communities-of-interest',
  templateUrl: './communities-of-interest.component.html',
  styleUrls: ['./communities-of-interest.component.scss'],
  imports: [
    CommonModule,
    MatCardModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    MatChipsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
  ],
})
export class CommunitiesOfInterestComponent
  extends AbstractWebPartComponent
  implements OnInit
{
  static override TYPE = 'CommunitiesOfInterest';
  static override FEATURES = [VIEW];
  static override CONFIG_COMPONENT: new () => AbstractWebPartConfigComponent =
    CommunitiesOfInterestConfigComponent;

  isB2B = false;
  destroy$ = new Subject<void>();

  // Master lists (all interest groups):
  interestGroups: InterestGroup[] = [];
  interestGroupsForUser: InterestGroup[] = [];

  // Filtered lists used for display:
  private filteredInterestGroups: InterestGroup[] = [];
  private filteredInterestGroupsForUser: InterestGroup[] = [];

  forcedGroup?: InterestGroup;
  showAllGroups = true;
  treeControl = new NestedTreeControl<Groups>((node) => node.children ?? []);
  dataSource = new MatTreeNestedDataSource<Groups>();

  private searchText = '';

  hasChild = (_: number, node: Groups) =>
    !!node.children && node.children.length > 0;

  override set configurations(value: WebPartConfig[]) {
    this._configurations = value;
  }

  override get configurations(): WebPartConfig[] {
    return this._configurations ?? [];
  }

  override set webpartViewbox(value: WebpartViewBox | undefined) {
    this._webpartViewbox = value;
  }

  get filteredGroups() {
    const groups = this.showAllGroups
      ? this.filteredInterestGroups
      : this.filteredInterestGroupsForUser;
    return [...groups.filter((g) => g.isSelected)].sort((a, b) =>
      a.title.localeCompare(b.title)
    );
  }

  getTranslation$(key: string) {
    return this.translationService.getTranslation$(key);
  }

  constructor(
    private translationService: TranslationService,
    private interestGroupFacade: InterestGroupFacade,
    private readonly sanitizer: DomSanitizer,
    private filterDialog: MatDialog,
    private dataService: DataSourceService,
    private readonly userSettingsService: InterestGroupWebpartUserSettingsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.dataService.updateData('filteredInterestGroups', []);
    forkJoin([
      this.interestGroupFacade.interestGroups$.pipe(first()),
      this.interestGroupFacade.interestGroupsForUser$.pipe(first()),
    ])
      .pipe(first())
      .subscribe(([interestGroups, interestGroupsForUser]) => {
        this.dataSource.data = [];
        if (interestGroups) {
          this.interestGroups = interestGroups.map(
            (g) => new InterestGroup({ ...g })
          );
          this.filteredInterestGroups = [...this.interestGroups];
        }
        if (interestGroupsForUser) {
          this.interestGroupsForUser = interestGroupsForUser.map(
            (g) => new InterestGroup({ ...g })
          );
          this.filteredInterestGroupsForUser = [...this.interestGroupsForUser];
        }
        const configuration = this.configurations?.find(
          (c) => c.name === 'communityGroupIds'
        );
        if (configuration) {
          const parsedConfiguration = this.getParsedConfiguration(
            configuration.configuration
          );
          this.showAllGroups = parsedConfiguration.showAllGroups;
          this.forcedGroup = this.interestGroups.find(
            (g) => g.id === parsedConfiguration.oneGroup
          );
          if (this.forcedGroup)
            this.dataService.updateData('filteredInterestGroups', [
              this.forcedGroup.id,
            ]);
        }

        if (!this.forcedGroup) {
          const localUserSettings = this.userSettingsService.getSettings(
            this.id ?? ''
          );
          if (localUserSettings.selectedInterestGroupIds.length > 0) {
            (this.showAllGroups
              ? this.filteredInterestGroups
              : this.filteredInterestGroupsForUser
            )
              .filter((g) =>
                localUserSettings.selectedInterestGroupIds.includes(g.id)
              )
              .forEach((g) => (g.isSelected = true));
          } else if (this.showAllGroups) {
            this.filteredInterestGroups
              .filter((g) =>
                this.filteredInterestGroupsForUser
                  .map((gu) => gu.id)
                  .includes(g.id)
              )
              .forEach((g) => (g.isSelected = true));
          } else {
            this.filteredInterestGroupsForUser.forEach(
              (g) => (g.isSelected = true)
            );
          }

          const sourceArray = this.forcedGroup
            ? [this.forcedGroup]
            : this.showAllGroups
            ? this.filteredInterestGroups
            : this.filteredInterestGroupsForUser;

          this.dataSource.data = this.buildTree(sourceArray);

          this.userSettingsService.setSettings(
            this.id ?? '',
            new InterestGroupWebpartUserSettings({
              ...localUserSettings,
              selectedInterestGroupIds: this.filteredGroups.map((g) => g.id),
            })
          );
          this.dataService.updateData(
            'filteredInterestGroups',
            this.filteredGroups.map((g) => g.id)
          );
        }

        combineLatest([
          this.interestGroupFacade.interestGroups$,
          this.interestGroupFacade.interestGroupsForUser$,
        ])
          .pipe(takeUntil(this.destroy$))
          .subscribe(([interestGroups, interestGroupsForUser]) => {
            this.dataSource.data = [];
            if (interestGroups) {
              this.interestGroups = interestGroups.map((g) => {
                const matched = this.filteredInterestGroups.find(
                  (ig) => ig.id === g.id
                );
                return new InterestGroup({
                  ...g,
                  isSelected: matched?.isSelected ?? false,
                });
              });
            }
            if (interestGroupsForUser) {
              this.interestGroupsForUser = interestGroupsForUser.map((g) => {
                const matched = this.filteredInterestGroupsForUser.find(
                  (ig) => ig.id === g.id
                );
                return new InterestGroup({
                  ...g,
                  isSelected: matched?.isSelected ?? false,
                });
              });
            }

            this.applySearchFilter();
            const sourceArray = this.forcedGroup
              ? [this.forcedGroup]
              : this.showAllGroups
              ? this.filteredInterestGroups
              : this.filteredInterestGroupsForUser;
            this.dataSource.data = this.buildTree(sourceArray);
          });
      });
  }

  private applySearchFilter(): void {
    if (!this.interestGroups || !this.interestGroupsForUser) return;

    const text = this.searchText.toLowerCase();
    // Filter all
    this.filteredInterestGroups = this.interestGroups.filter(
      (g) =>
        g.title?.toLowerCase().includes(text) ||
        g.description?.toLowerCase().includes(text)
    );
    // Filter user
    this.filteredInterestGroupsForUser = this.interestGroupsForUser.filter(
      (g) =>
        g.title?.toLowerCase().includes(text) ||
        g.description?.toLowerCase().includes(text)
    );
  }

  protected filterGroups(event: Event): void {
    const value = (event.target as HTMLInputElement).value || '';
    this.searchText = value;
    this.applySearchFilter();

    const sourceArray = this.forcedGroup
      ? [this.forcedGroup]
      : this.showAllGroups
      ? this.filteredInterestGroups
      : this.filteredInterestGroupsForUser;

    this.dataSource.data = this.buildTree(sourceArray);
  }

  buildTree(interestGroups: InterestGroup[]): Groups[] {
    if (!interestGroups) {
      return [];
    }
    const parentGroups = interestGroups.filter(
      (g) => g.subGroups && g.subGroups.length > 0
    );
    const standaloneGroups = interestGroups.filter(
      // groups without a parent and not a subgroup of a group
      (g) =>
        !parentGroups.some((p) =>
          p.subGroups?.some((sg) => sg.subGroupId === g.id)
        ) && !parentGroups.map((p) => p.id).includes(g.id)
    );
    const parentGroupsThatAreNotSubGroups = parentGroups.filter(
      (pg) =>
        !parentGroups.some((p) =>
          p.subGroups?.some((sg) => sg.subGroupId === pg.id)
        )
    );

    const groupsToShow =
      parentGroupsThatAreNotSubGroups.concat(standaloneGroups);
    const r = groupsToShow.map((g) => ({
      id: g.id,
      title: g.title,
      color: g.color,
      description: g.description,
      icon: g.icon,
      isSelected: g.isSelected,
      children: this.getConvertedNodes(g),
    }));
    return this.sortGroups(r);
  }

  getConvertedNodes(interestGroup: InterestGroup): Groups[] {
    if (interestGroup.subGroups && interestGroup.subGroups.length === 0) {
      return [];
    }
    const r = interestGroup.subGroups!.flatMap((group) => {
      const subGroup = this.interestGroups?.find(
        (g) => g.id === group.subGroupId
      );
      if (!subGroup) return [];
      return [
        {
          id: subGroup.id,
          title: subGroup.title,
          color: subGroup.color,
          description: subGroup.description,
          icon: subGroup.icon,
          isSelected: subGroup.isSelected,
          children: this.getConvertedNodes(subGroup),
        },
      ];
    });
    return this.sortGroups(r);
  }

  sortGroups(groups: Groups[]): Groups[] {
    return groups.sort((a, b) => {
      if (
        a.children &&
        a.children.length > 0 &&
        b.children &&
        b.children.length > 0
      ) {
        return a.title.localeCompare(b.title);
      } else if (a.children && a.children.length > 0) {
        return -1;
      } else if (b.children && b.children.length > 0) {
        return 1;
      } else {
        return a.title.localeCompare(b.title);
      }
    });
  }

  checkBoxChange(event: MatCheckboxChange, node: Groups) {
    let groupsToCheck = this.showAllGroups
      ? this.filteredInterestGroups
      : this.filteredInterestGroupsForUser;
    let allGroupsToJoin = [node.id];
    if (event.checked) {
      let newSubGroups = allGroupsToJoin;
      do {
        newSubGroups = groupsToCheck
          .filter(
            (g) =>
              !allGroupsToJoin.includes(g.id) &&
              g.subGroups?.some((s) => newSubGroups.includes(s.subGroupId))
          )
          .map((g) => g.id);
        allGroupsToJoin = allGroupsToJoin.concat(newSubGroups);
      } while (newSubGroups.length != 0);
    }
    groupsToCheck = groupsToCheck.map((g) => {
      if (allGroupsToJoin.includes(g.id)) {
        g.isSelected = event.checked;
      }
      return g;
    });
    this.dataSource.data = this.buildTree(groupsToCheck);
    this.dataService.updateData(
      'filteredInterestGroups',
      this.filteredGroups.map((g) => g.id)
    );
    this.userSettingsService.setSettings(
      this.id ?? '',
      new InterestGroupWebpartUserSettings({
        ...this.userSettingsService.getSettings(this.id ?? ''),
        selectedInterestGroupIds: this.filteredGroups.map((g) => g.id),
      })
    );
  }

  getParsedConfiguration(value: string) {
    return {
      showAllGroups: value ? JSON.parse(value).showAllGroups : true,
      oneGroup: value ? JSON.parse(value).oneGroup : '',
    };
  }

  @Memoize()
  transform(value: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }

  getTextColor(color: string) {
    const rgb = hexToRgb(color);
    if (rgb) {
      return rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114 <= 186
        ? '#fff'
        : '#000';
    } else {
      return '#fff';
    }
  }

  openFilterDialog() {
    this.filterDialog
      .open(CoiFilterPopupComponent, {
        width: '50%',
        height: '70%',
        data: {
          interestGroups: this.showAllGroups
            ? this.filteredInterestGroups
            : this.filteredInterestGroupsForUser,
          dataSource: this.dataSource.data,
        },
      })
      .afterClosed()
      .subscribe((result: InterestGroup[]) => {
        if (result) {
          const groupsToCheck = this.showAllGroups
            ? this.filteredInterestGroups
            : this.filteredInterestGroupsForUser;
          groupsToCheck.forEach(
            (g) =>
              (g.isSelected = result.some((r) => r.id === g.id && r.isSelected))
          );
          this.dataSource.data = this.buildTree(groupsToCheck);
          this.dataService.updateData(
            'filteredInterestGroups',
            this.filteredGroups.map((g) => g.id)
          );
          this.userSettingsService.setSettings(
            this.id ?? '',
            new InterestGroupWebpartUserSettings({
              ...this.userSettingsService.getSettings(this.id ?? ''),
              selectedInterestGroupIds: this.filteredGroups.map((g) => g.id),
            })
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.dataService.updateData('filteredInterestGroups', undefined);
    this.destroy$.next();
    this.destroy$.complete();
  }
}
