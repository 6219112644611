import { HttpClient } from '@angular/common/http';
import { Injectable, Type } from '@angular/core';
import { DateTime } from 'luxon';
import {
  AADUser,
  Application,
  CMSPage,
  Country,
  CRMEntity,
  DataProperty,
  DataRecord,
  dateTimesToISO,
  Day,
  DMSDataRecordUpload,
  EntityAppData,
  EntityObject,
  FieldConfigurationsRequest,
  Form,
  GroupUser,
  IDatacomponentService,
  ILibraryService,
  IMetadataService,
  IRelationRetrieverService,
  IUploadResult,
  Library,
  LibraryItem,
  LibraryTrigger,
  Metadata,
  MetadataChoice,
  MetadataChoiceTranslation,
  MetadataParam,
  MetadataPermission,
  MetadataPermissionConfiguration,
  Permission,
  PropertyRight,
  PropertyType,
  RelationFieldTable,
  RelationObjectValue,
  Task,
  WebPartType,
} from 'processdelight-angular-components';
import { Entity } from 'processdelight-angular-components/lib/domain/models/entity';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MicrosoftAuthenticationService } from 'src/app/msal/injectables/microsoft-authentication.service';
import { PortalClientUserGroup } from 'src/app/webparts/domain/models/portal-client-user-group.model';
import { GroupConfigurationDTO } from '../domain/dto/group-configuration.dto';
import { PageConfigurationDTO } from '../domain/dto/page-configuration.dto';
import { RoleConfigurationDTO } from '../domain/dto/role-configuration.dto';
import { UserConfigurationDTO } from '../domain/dto/user-configuration.dto';
import { ClientUserInfo } from '../domain/models/client-user-info.model';
import { ClientUserRequest } from '../domain/models/client-user-request.model';
import { ClientWithConfig } from '../domain/models/client-with-config.model';
import { ExternalUserInfo } from '../domain/models/external-user-info.model';
import { Group } from '../domain/models/group.model';
import { InterestGroup } from '../domain/models/interestGroup';
import { IshtarOrganization } from '../domain/models/ishtar-organization.model';
import { NotificationUserSetting } from '../domain/models/notification-user-setting.model';
import { PortalUserVM } from '../domain/models/portal-user.model';
import { Role } from '../domain/models/role.model';
import { TaskCalendarItem } from '../domain/models/task-calendarItem.model';
import { UserLicenseInfo } from '../domain/models/user-license-info.model';
import { UserSettings } from '../domain/models/user-settings.model';
import { isB2C } from '../extensions/b2x.extensions';
import { camelcaseKeys } from '../extensions/object.extensions';
import { SendNotification } from '../domain/models/send-notification.model';

@Injectable({
  providedIn: 'root',
})
export class PortalService
  implements IRelationRetrieverService, IDatacomponentService, IMetadataService
{
  apiBase = `${location.origin}/web`;
  constructor(
    private httpClient: HttpClient,
    private msal: MicrosoftAuthenticationService
  ) {}

  GetLinkedObjectsNotVisible(recordId: string): Observable<DataRecord[]> {
    return this.httpClient
      .get<DataRecord[]>(
        `${
          this.apiBase
        }/${this.b2x()}/dc/linked-objects-not-visible/${recordId}`
      )
      .pipe(map((objects) => objects.map((o) => new DataRecord(o))));
  }
  HasEntity(tableName: string, filter: string): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
  updateDataProperty(prop: DataProperty): Observable<DataProperty> {
    throw new Error('Method not implemented.');
  }
  deleteDataProperty(
    propId: string,
    deleteObjects: boolean
  ): Observable<boolean> {
    throw new Error('Method not implemented.');
  }

  getEntities(
    pageSize: number,
    page: number,
    sortedColumn: string,
    sortDirection: string,
    internalSort: boolean,
    internalFilterString: string,
    dataFilterString: string
  ): Observable<{
    result: EntityObject<Entity>[];
    pagingCookie: string;
    totalRecordCount: number;
  }> {
    throw new Error('Method not implemented.');
  }
  getEntity(entityId: string): Observable<EntityObject<Entity>> {
    throw new Error('Method not implemented.');
  }
  getFormTemplates<T extends Entity>(): Observable<EntityObject<T>[]> {
    return this.httpClient.get<EntityObject<T>[]>(`${this.apiBase}/${this.b2x()}/dc/formTemplates?applicationName=IshtarTasks`)
      .pipe(
        map((forms) =>
          forms.map((f) => new EntityObject<T>(camelcaseKeys(f)))
        )
      );
  }
  removeEntity(id: string): Observable<string[]> {
    throw new Error('Method not implemented.');
  }
  updateEntity(entity: Entity): Observable<Entity> {
    throw new Error('Method not implemented.');
  }
  addEntity(
    entity: Entity,
    formId: string,
    createTeam?: boolean
  ): Observable<Entity> {
    throw new Error('Method not implemented.');
  }
  getPropertyTypes(): Observable<PropertyType[]> {
    throw new Error('Method not implemented.');
  }
  getApplicationForms(): Observable<Form[]> {
    throw new Error('Method not implemented.');
  }
  getCurrentApplication(): Observable<Application> {
    throw new Error('Method not implemented.');
  }
  findForm(name: string): Observable<Form[]> {
    throw new Error('Method not implemented.');
  }
  getPropertyRights(): Observable<PropertyRight[]> {
    throw new Error('Method not implemented.');
  }
  addFieldConfigurations(
    request: FieldConfigurationsRequest
  ): Observable<DataRecord> {
    throw new Error('Method not implemented.');
  }
  getFieldConfigurations(recordId?: string): Observable<DataRecord> {
    throw new Error('Method not implemented.');
  }
  getTemplateFieldsConfigurations(formId: string): Observable<DataRecord> {
    throw new Error('Method not implemented.');
  }
  getDataProperties(): Observable<DataProperty[]> {
    return this.httpClient
      .get<DataProperty[]>(`${this.apiBase}/${this.b2x()}/dc/dataProperties`)
      .pipe(
        map((dataProperties) =>
          dataProperties.map((dp) => new DataProperty(camelcaseKeys(dp)))
        )
      );
  }
  updateApplicationData<T extends Entity>(
    tableName: string,
    applicationData: EntityAppData<T>
  ): Observable<EntityAppData<T>> {
    throw new Error('Method not implemented.');
  }
  addForm(form: Form): Observable<DataRecord> {
    throw new Error('Method not implemented.');
  }
  updateForm(form: Form): Observable<Form> {
    throw new Error('Method not implemented.');
  }
  deleteForm(form: Form): Observable<Form> {
    throw new Error('Method not implemented.');
  }
  isPropertyUsed(propertyId: string): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
  getRelationFieldTables() {
    return this.httpClient
      .get<RelationFieldTable[]>(
        `${this.apiBase}/${this.b2x()}/dc/relationFieldTables`,
        {}
      )
      .pipe(map((forms) => forms.map((f) => new RelationFieldTable(f))));
  }
  getLinkedObjects(dataObjectId: string): Observable<RelationObjectValue[]> {
    return this.httpClient
      .get<RelationObjectValue[]>(
        `${this.apiBase}/${this.b2x()}/dc/linkedObjects/${dataObjectId}`
      )
      .pipe(map((objects) => objects.map((o) => new RelationObjectValue(o))));
  }
  getLinkedObjectsByDataPropertyId(
    dataPropertyId: string,
    recordId: string
  ): Observable<RelationObjectValue[]> {
    return this.httpClient
      .get<RelationObjectValue[]>(
        `${
          this.apiBase
        }/${this.b2x()}/dc/linkedObjects/${dataPropertyId}/${recordId}`
      )
      .pipe(map((objects) => objects.map((o) => new RelationObjectValue(o))));
  }
  getPossibleRelations(
    dataPropertyId: string,
    relationFieldTableIds: string[],
    page: number,
    pageSize: number,
    filter?: string
  ): Observable<RelationObjectValue[]> {
    return this.httpClient
      .post<RelationObjectValue[]>(
        `${
          this.apiBase
        }/dc/possibleRelations/${dataPropertyId}?page=${page}&pageSize=${pageSize}${
          filter ? `&filter=${filter}` : ''
        }`,
        relationFieldTableIds
      )
      .pipe(map((objects) => objects.map((o) => new RelationObjectValue(o))));
  }
  getTypeRelations(
    relationFieldTableId: string
  ): Observable<RelationObjectValue[]> {
    return this.httpClient
      .get<RelationObjectValue[]>(
        `${this.apiBase}/${this.b2x()}/dc/typeRelations/${relationFieldTableId}`
      )
      .pipe(map((objects) => objects.map((o) => new RelationObjectValue(o))));
  }

  private b2x() {
    return isB2C() ? 'b2c' : 'b2b';
  }

  registerSession(tenantId: string) {
    return this.httpClient
      .post<UserLicenseInfo>(
        `${this.apiBase}/${this.b2x()}/session/register?tenantId=${tenantId}`,
        {}
      )
      .pipe(map((c) => new UserLicenseInfo(c)));
  }
  registerExternalSession(
    domain: string
  ): Observable<ExternalUserInfo | undefined> {
    return this.httpClient
      .post<ExternalUserInfo>(
        `${
          this.apiBase
        }/${this.b2x()}/session/register-external?domain=${domain}`,
        {}
      )
      .pipe(map((c) => (c ? new ExternalUserInfo(c) : c)));
  }

  sessionKeepAlive() {
    return this.httpClient.post(
      `${this.apiBase}/${this.b2x()}/session/keepalive`,
      {}
    );
  }

  getLicensesForUser(email: string) {
    return this.httpClient
      .get<UserLicenseInfo>(
        `${this.apiBase}/${this.b2x()}/users/licenses/${email}`
      )
      .pipe(map((c) => new UserLicenseInfo(c)));
  }

  getUserSettings() {
    return this.httpClient
      .get(`${this.apiBase}/${this.b2x()}/users/app/Ishtar.365/settings`)
      .pipe(map((c) => new UserSettings(c)));
  }
  updateUserSettings(settings: UserSettings) {
    return this.httpClient
      .post(
        `${this.apiBase}/${this.b2x()}/users/app/Ishtar.365/settings`,
        settings
      )
      .pipe(map((c) => new UserSettings(c)));
  }

  deletePortalClientUserGroup(portalClientUserGroup: PortalClientUserGroup) {
    return this.httpClient.delete(
      `${this.apiBase}/${this.b2x()}/clients/clientUserGroup`,
      {
        body: portalClientUserGroup,
      }
    );
  }

  createPortalClientUserGroup(portalClientUserGroup: PortalClientUserGroup) {
    return this.httpClient.post(
      `${this.apiBase}/${this.b2x()}/clients/clientUserGroup`,
      portalClientUserGroup
    );
  }

  getGraphUsers(filter?: string) {
    return this.httpClient
      .get<AADUser[]>(
        `${this.apiBase}/${this.b2x()}/graphusers${
          filter ? '?filter=' + filter : ''
        }`
      )
      .pipe(map((users) => users.map((u) => new AADUser(camelcaseKeys(u)))));
  }

  getGraphUser(id: string) {
    return this.httpClient
      .get<AADUser>(`${this.apiBase}/${this.b2x()}/graphusers/${id}`)
      .pipe(map((user) => new AADUser(camelcaseKeys(user))));
  }

  getGraphUsersById(ids: string[]) {
    return this.httpClient
      .post<AADUser[]>(`${this.apiBase}/${this.b2x()}/graphusersbyid`, ids)
      .pipe(map((users) => users.map((u) => new AADUser(camelcaseKeys(u)))));
  }

  getIshtarUsers() {
    return this.httpClient
      .get<GroupUser[]>(`${this.apiBase}/${this.b2x()}/organizations/users`)
      .pipe(map((user) => user.map((u) => new GroupUser(camelcaseKeys(u)))));
  }
  getIshtarGroups() {
    return this.httpClient
      .get<GroupUser[]>(`${this.apiBase}/${this.b2x()}/organizations/groups`)
      .pipe(map((user) => user.map((u) => new GroupUser(camelcaseKeys(u)))));
  }

  getGroups(clientId?: string) {
    return this.httpClient
      .get<Group[]>(
        `${this.apiBase}/${this.b2x()}/groups${
          clientId ? '?clientId=' + clientId : ''
        }`
      )
      .pipe(map((group) => group.map((g) => new Group(camelcaseKeys(g)))));
  }

  getGraphGroups(filter?: string) {
    return this.httpClient
      .get<Group[]>(
        `${this.apiBase}/${this.b2x()}/graphgroups${
          filter ? '?filter=' + filter : ''
        }`
      )
      .pipe(map((users) => users.map((u) => new Group(camelcaseKeys(u)))));
  }

  getTranslations(lang: string) {
    return this.httpClient.get<{ [key: string]: string }>(
      `${this.apiBase}/${this.b2x()}/users/translations?lang=${lang}`
    );
  }

  getAppConfig<T>(appName: string, constructor: new (obj: Partial<T>) => T) {
    return this.httpClient
      .get<T>(`${this.apiBase}/${this.b2x()}/app/appconfig/${appName}`)
      .pipe(map((c) => new constructor(camelcaseKeys(c))));
  }

  updateAppConfig<T>(
    appName: string,
    constructor: new (obj: Partial<T>) => T,
    config: T
  ) {
    return this.httpClient
      .post<T>(`${this.apiBase}/${this.b2x()}/app/appconfig/${appName}`, config)
      .pipe(map((c) => new constructor(camelcaseKeys(c))));
  }

  getClients() {
    return this.httpClient
      .get<ClientWithConfig[]>(`${this.apiBase}/${this.b2x()}/clients`)
      .pipe(
        map((clients) =>
          clients.map((c) => new ClientWithConfig(camelcaseKeys(c)))
        )
      );
  }
  getCountries() {
    return this.httpClient.get<Country[]>(
      `${this.apiBase}/${this.b2x()}/app/countries`
    );
  }

  getRoles() {
    return this.httpClient
      .get<Role[]>(`${this.apiBase}/${this.b2x()}/roles`)
      .pipe(
        map((roles) =>
          roles.map((r) => {
            return new Role(camelcaseKeys(r));
          })
        )
      );
  }

  getDefaultRoles() {
    return this.httpClient
      .get<Role[]>(`${this.apiBase}/${this.b2x()}/roles/default`)
      .pipe(
        map((roles) =>
          roles.map((r) => {
            return new Role(camelcaseKeys(r));
          })
        )
      );
  }

  updateRoleConfiguration(roleConfiguration: RoleConfigurationDTO[]) {
    return this.httpClient
      .post<{
        roles: Role[];
        clientConfigs: ClientWithConfig[];
      }>(`${this.apiBase}/${this.b2x()}/roles/configuration`, roleConfiguration)
      .pipe(
        map(({ roles, clientConfigs }) => ({
          roles: roles.map((r) => new Role(camelcaseKeys(r))),
          clientConfigs: clientConfigs.map(
            (c) => new ClientWithConfig(camelcaseKeys(c))
          ),
        }))
      );
  }
  updateUserConfiguration(userConfiguration: UserConfigurationDTO[]) {
    return this.httpClient
      .post<{
        users: ClientUserInfo[];
        clientConfigs: ClientWithConfig[];
      }>(
        `${this.apiBase}/${this.b2x()}/clients/userConfiguration`,
        userConfiguration
      )
      .pipe(
        map(({ users, clientConfigs }) => ({
          users: users.map((r) => new ClientUserInfo(camelcaseKeys(r))),
          clientConfigs: clientConfigs.map(
            (c) => new ClientWithConfig(camelcaseKeys(c))
          ),
        }))
      );
  }

  updateGroupConfiguration(groupConfiguration: GroupConfigurationDTO[]) {
    return this.httpClient
      .post<{
        groups: Group[];
        clientConfigs: ClientWithConfig[];
        users: ClientUserInfo[];
      }>(
        `${this.apiBase}/${this.b2x()}/groups/configuration`,
        groupConfiguration
      )
      .pipe(
        map(({ groups, clientConfigs, users }) => ({
          groups: groups.map((r) => new Group(camelcaseKeys(r))),
          clientConfigs: clientConfigs.map(
            (c) => new ClientWithConfig(camelcaseKeys(c))
          ),
          users: users.map((u) => new ClientUserInfo(camelcaseKeys(u))),
        }))
      );
  }

  getOrganization(tenantId: string) {
    return this.httpClient.get<IshtarOrganization>(
      `${this.apiBase}/${this.b2x()}/organizations/${tenantId}`
    );
  }

  getOrganizationByDomain(domain: string) {
    return this.httpClient.get<IshtarOrganization>(
      `${this.apiBase}/${this.b2x()}/organizations/domain/${domain}`
    );
  }

  getPortalUsers() {
    return this.httpClient
      .get<PortalUserVM[]>(
        `${this.apiBase}/${this.b2x()}/organizations/portalusers`
      )
      .pipe(
        map((users) => users.map((u) => new PortalUserVM(camelcaseKeys(u))))
      );
  }
  getClientUsers(clientId?: string) {
    return this.httpClient
      .get<ClientUserInfo[]>(
        `${this.apiBase}/${this.b2x()}/clients${
          clientId ? '/' + clientId : ''
        }/users`
      )
      .pipe(
        map((users) => users.map((u) => new ClientUserInfo(camelcaseKeys(u))))
      );
  }
  getClientUserRequests(clientId?: string) {
    return this.httpClient
      .get<ClientUserRequest[]>(
        `${this.apiBase}/${this.b2x()}/clients${
          clientId ? '/' + clientId : ''
        }/requests`
      )
      .pipe(
        map((requests) =>
          requests.map((u) => new ClientUserRequest(camelcaseKeys(u)))
        )
      );
  }

  acceptRequest(requestId: string) {
    return this.httpClient.post<ClientUserInfo | undefined>(
      `${this.apiBase}/${this.b2x()}/clients/requests/${requestId}/accept`,
      {}
    );
  }
  rejectRequest(requestId: string) {
    return this.httpClient.post<ClientUserInfo | undefined>(
      `${this.apiBase}/${this.b2x()}/clients/requests/${requestId}/reject`,
      {}
    );
  }

  newRequest(request: CRMEntity) {
    return this.httpClient.post<ClientUserRequest>(
      `${this.apiBase}/${this.b2x()}/clients/newrequest`,
      request
    );
  }

  getPages() {
    return this.httpClient.get<CMSPage[]>(
      `${this.apiBase}/${this.b2x()}/pages`
    );
  }
  updatePageConfiguration(configuration: PageConfigurationDTO) {
    return this.httpClient.post<CMSPage[]>(
      `${this.apiBase}/${this.b2x()}/pages/configuration`,
      configuration
    );
  }
  getWebPartTypes() {
    return this.httpClient.get<WebPartType[]>(
      `${this.apiBase}/${this.b2x()}/pages/webparttypes`
    );
  }

  getBlob(url: string) {
    return this.httpClient.get<Blob>(url, { responseType: 'blob' as 'json' });
  }

  getForms() {
    return this.httpClient.get<Form[]>(
      `${this.apiBase}/${this.b2x()}/clients/forms`
    );
  }

  getCRMEntities(filter?: string) {
    return this.httpClient
      .get<CRMEntity[]>(
        `${this.apiBase}/${this.b2x()}/clients/entities${
          filter ? '?filter=' + filter : ''
        }`
      )
      .pipe(
        map((entities) => entities.map((e) => new CRMEntity(camelcaseKeys(e))))
      );
  }

  linkPortalUserEntity(portalUserId: string, entityId: string) {
    return this.httpClient.post<boolean>(
      `${
        this.apiBase
      }/${this.b2x()}/clients/linkUserEntity?portalUserId=${portalUserId}&entityId=${entityId}`,
      {}
    );
  }

  getLinkedUserEntity(portalUserId: string) {
    return this.httpClient
      .get<CRMEntity>(
        `${
          this.apiBase
        }/${this.b2x()}/clients/getLinkedEntity?portalUserId=${portalUserId}`
      )
      .pipe(
        map((entity) => (entity ? new CRMEntity(camelcaseKeys(entity)) : null))
      );
  }

  DeleteLinkedUserEntity(portalUserId: string, entityId: string) {
    return this.httpClient.delete<boolean>(
      `${
        this.apiBase
      }/${this.b2x()}/clients/deleteLinkedEntity?portalUserId=${portalUserId}&entityId=${entityId}`
    );
  }

  getProfileData(entityId?: string) {
    return this.httpClient
      .get<EntityObject<CRMEntity>>(
        `${this.apiBase}/${this.b2x()}/clients/getProfileData/${entityId}`
      )
      .pipe(
        map((entity) =>
          entity ? new EntityObject<CRMEntity>(camelcaseKeys(entity)) : null
        )
      );
  }

  requestUpdateProfileData(entity: CRMEntity, email: string) {
    return this.httpClient.post<boolean>(
      `${this.apiBase}/${this.b2x()}/clients/requestUpdateProfileData`,
      { entity: entity, email: email }
    );
  }

  getInterestGroups() {
    return this.httpClient
      .get<InterestGroup[]>(
        `${this.apiBase}/${this.b2x()}/interestGroups/getAllInterestGroups`
      )
      .pipe(
        map((groups) => groups.map((g) => new InterestGroup(camelcaseKeys(g))))
      );
  }

  getInterestGroupsForUser(portalUserId: string) {
    return this.httpClient
      .get<InterestGroup[]>(
        `${
          this.apiBase
        }/${this.b2x()}/interestGroups/getInterestGroupsForUser?portalUserId=${portalUserId}`
      )
      .pipe(
        map((groups) => groups.map((g) => new InterestGroup(camelcaseKeys(g))))
      );
  }
  addPortalUserInterestGroup(
    portalUserId: string,
    interestGroup: InterestGroup
  ) {
    return this.httpClient
      .post<InterestGroup[]>(
        `${
          this.apiBase
        }/${this.b2x()}/interestGroups/addPortalUserInterestGroup?portalUserId=${portalUserId}`,
        interestGroup
      )
      .pipe(
        map((groups) => groups.map((g) => new InterestGroup(camelcaseKeys(g))))
      );
  }
  deletePortalUserInterestGroup(
    portalUserId: string,
    interestGroup: InterestGroup
  ) {
    return this.httpClient
      .patch<InterestGroup>(
        `${
          this.apiBase
        }/${this.b2x()}/interestGroups/deletePortalUserInterestGroup?portalUserId=${portalUserId}`,
        interestGroup
      )
      .pipe(map((group) => new InterestGroup(camelcaseKeys(group))));
  }

  getTaskCalendarItems(startDate: DateTime, endDate: DateTime) {
    return this.httpClient
      .get<TaskCalendarItem[]>(
        `${
          this.apiBase
        }/${this.b2x()}/task-calendar?startDate=${startDate.toISO()}&endDate=${endDate.toISO()}`
      )
      .pipe(
        map((tasks) => tasks.map((t) => new TaskCalendarItem(camelcaseKeys(t))))
      );
  }

  getDays() {
    return this.httpClient
      .get<Day[]>(`${this.apiBase}/notifications/days`)
      .pipe(map((days) => days.map((d) => new Day(d))));
  }

  getNotifications(portalUserId: string) {
    return this.httpClient
      .get<SendNotification[]>(
        `${this.apiBase}/notifications/notifications?PortalUserId=${portalUserId}`
      )
      .pipe(map((params) => params.map((p) => new SendNotification(p))));
  }

  markNotificationsAsRead(notificationIds: string[]) {
    return this.httpClient.post<string[]>(
      `${this.apiBase}/notifications/read`,
      notificationIds
    );
  }

  getNotificationConfig(portalUserId: string) {
    return this.httpClient
      .get<NotificationUserSetting>(
        `${this.apiBase}/notifications/config?portalUserId=${portalUserId}`
      )
      .pipe(map((config) => new NotificationUserSetting(config)));
  }

  saveNotificationConfig(config: NotificationUserSetting) {
    return this.httpClient
      .post<NotificationUserSetting>(
        `${this.apiBase}/notifications/config`,
        config
      )
      .pipe(map((config) => new NotificationUserSetting(config)));
  }

  getMetadataParams(getAll: boolean): Observable<MetadataParam[]> {
    return this.httpClient
      .get<MetadataParam[]>(
        `${this.apiBase}/${this.b2x()}/metadata/parameters?getAll=${getAll}`
      )
      .pipe(map((params) => params.map((p) => new MetadataParam(p))));
  }
  createMetadataParam(metadataParam: MetadataParam): Observable<MetadataParam> {
    throw new Error('Method not implemented.');
  }
  updateMetadataParam(metadataParam: MetadataParam): Observable<MetadataParam> {
    throw new Error('Method not implemented.');
  }
  deleteMetadataParam(metadataParamId: string): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
  getMetadataPermissionConfigurations(): Observable<
    MetadataPermissionConfiguration[]
  > {
    throw new Error('Method not implemented.');
  }
  updateMetadataPermissions(
    permissions: MetadataPermission[]
  ): Observable<MetadataPermission[]> {
    throw new Error('Method not implemented.');
  }
  updateMetadataPermissionConfiguration(
    permissionConfig: MetadataPermissionConfiguration
  ): Observable<MetadataPermissionConfiguration> {
    throw new Error('Method not implemented.');
  }
  createMetadataChoice(choice: MetadataChoice): Observable<MetadataChoice> {
    throw new Error('Method not implemented.');
  }
  updateMetadataChoice(choice: MetadataChoice): Observable<MetadataChoice> {
    throw new Error('Method not implemented.');
  }
  updateMetadataChoices(
    choices: MetadataChoice[]
  ): Observable<MetadataChoice[]> {
    throw new Error('Method not implemented.');
  }
  deleteMetadataChoice(paramId: string, choiceId: string): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
  createMetadataChoiceTranslations(
    translations: MetadataChoiceTranslation[]
  ): Observable<MetadataChoiceTranslation[]> {
    throw new Error('Method not implemented.');
  }
  checkExistingMetadata(
    metadata: Metadata[],
    fileExtension: string,
    excludedId?: string,
    libraryId?: string
  ): Observable<string | undefined> {
    return this.httpClient.post<string | undefined>(
      `${this.apiBase}/${this.apiBase}/metadata/checkExisting${
        libraryId ? `?libraryId=${libraryId}` : ''
      }`,
      {
        metadata: dateTimesToISO(metadata),
        fileExtension,
        excludedId,
      },
      { responseType: 'text' as 'json' }
    );
  }
  getDataRecordUploads(
    dataRecordId: string
  ): Observable<DMSDataRecordUpload[]> {
    throw new Error('Method not implemented.');
  }
}
