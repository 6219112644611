import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'appSort',
  standalone: true,
})
export class SortPipe implements PipeTransform {
  transform(value: any[] | null, ...args: string[]): any[] | null {
    if (!value) {
      return value;
    }
    const newArray = [...value];
    newArray.sort((a, b) => {
      let aVal = a;
      let bVal = b;
      args.forEach((arg) => {
        if (aVal) aVal = aVal[arg];
        if (bVal) bVal = bVal[arg];
      });
      if (!aVal && !bVal) {
        return 0;
      }
      if (!aVal) {
        return -1;
      }
      if (!bVal) {
        return 1;
      }
      if (typeof aVal === 'string' && typeof bVal === 'string') {
        return aVal.localeCompare(bVal);
      }
      return aVal - bVal;
    });
    return newArray;
  }
}
