import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ActionBarComponent,
  ElementRefDirective,
  LoaderComponent,
  TopLayoutComponent,
} from 'processdelight-angular-components';

import { map, Subject, takeUntil } from 'rxjs';
import { CoreModule } from '../core/core.module';
import { isB2B, isB2C } from '../core/extensions/b2x.extensions';
import { ApplicationService } from '../core/services/application.service';
import { PortalActionsService } from '../core/services/portal-actions.service';
import { OrganizationFacade } from '../core/store/organization/organization.facade';
import { UserFacade } from '../core/store/user/user.facade';
import { TopBarComponent } from './top-bar/top-bar.component';
import {
  navColor$,
  navContrast$,
  orgLogo$,
  userName$,
} from '../app.observables';
@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    CommonModule,
    LoaderComponent,
    TopBarComponent,
    ActionBarComponent,
    TopLayoutComponent,
    ElementRefDirective,
  ],
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  search = new Subject<string>();

  appName = 'Ishtar.Portal!';

  userLang = '';
  showExtended = false;

  orgLogo$ = orgLogo$;
  userName$ = userName$;
  navColor$ = navColor$;
  navContrast$ = navContrast$;

  get sideBarActions() {
    return this.portalActions.sideBarActions;
  }
  get buttonActions() {
    return this.portalActions.buttonActions;
  }
  get iconActions() {
    return this.portalActions.iconActions;
  }
  signedIn$ = this.applicationService.signedIn.asObservable();

  get isB2B() {
    return isB2B();
  }

  get isB2C() {
    return isB2C();
  }

  constructor(
    private readonly organizationFacade: OrganizationFacade,
    private readonly userFacade: UserFacade,
    private readonly portalActions: PortalActionsService,
    private readonly applicationService: ApplicationService
  ) {}

  ngOnInit(): void {
    this.search.pipe(takeUntil(this.destroy$)).subscribe((search) => {
      this.portalActions.changeSearch(search);
    });
  }

  destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
