import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RefreshService {
  private refreshPageSubject = new Subject<void>();
  refreshPage$ = this.refreshPageSubject.asObservable();

  triggerRefreshPage() {
    this.refreshPageSubject.next();
  }
}
