import { CommonModule, KeyValue } from '@angular/common';
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormControl, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { BehaviorSubject, Subject, combineLatest, map, takeUntil } from 'rxjs';

import { MatOptionModule } from '@angular/material/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { DateTime } from 'luxon';
import { ClientFacade } from 'src/app/core/store/client/client.facade';
import { Memoize } from 'typescript-memoize';
import * as uuid from 'uuid';
import { VerhofsteAgent } from '../domain/models/agent.model';
import { VerhofsteOfferLine } from '../domain/models/offer-line.model';
import { VerhofsteOffer } from '../domain/models/offer.model';
import { VerhofsteProduct } from '../domain/models/product.model';
import { VerhofsteService } from '../services/verhofste.service';
import { UpdateMetadataDialogComponent } from '../update-metadata-dialog/update-metadata-dialog.component';
import {
  VerhofsteProductLineVM,
  VerhofsteProductTableComponent,
} from '../verhofste-product-table/verhofste-product-table.component';
import { VerhofsteQuoteSummaryDialogComponent } from '../verhofste-quote-summary-dialog/verhofste-quote-summary-dialog.component';
import { SortPipe } from './sort.pipe';
import { ClientWithConfig } from 'src/app/core/domain/models/client-with-config.model';

export const possibleColumns: {
  [id: string]: {
    internalName: string;
    displayName: string;
    sortable: boolean;
    fixed: boolean;
    filterValue: string;
    valueAccessor: (item: VerhofsteProductLineVM) => any;
    sortValueAccessor: (item: VerhofsteProductLineVM) => string | number;
  };
} = {
  ItemCode: {
    internalName: 'ItemCode',
    displayName: 'Code',
    sortable: true,
    fixed: false,
    filterValue: '',
    valueAccessor: (item: VerhofsteProductLineVM) => item.product.itemCode,
    sortValueAccessor: (item: VerhofsteProductLineVM) => item.product.itemCode,
  },
  ItemName: {
    internalName: 'ItemName',
    displayName: 'Name',
    sortable: true,
    fixed: false,
    filterValue: '',
    valueAccessor: (item: VerhofsteProductLineVM) => item.product.itemName,
    sortValueAccessor: (item: VerhofsteProductLineVM) => item.product.itemName,
  },
  PriceUnitPrice: {
    internalName: 'PriceUnitPrice',
    displayName: 'Price',
    sortable: true,
    fixed: false,
    filterValue: '',
    valueAccessor: (item: VerhofsteProductLineVM) =>
      '€ ' + Math.round(+item.product.priceUnitPrice * 100) / 100,
    sortValueAccessor: (item: VerhofsteProductLineVM) =>
      +item.product.priceUnitPrice,
  },
  PriceUserDiscount: {
    internalName: 'PriceUserDiscount',
    displayName: 'Discount',
    sortable: true,
    fixed: false,
    filterValue: '',
    valueAccessor: (item: VerhofsteProductLineVM) =>
      item.product.priceUnitDiscount
        ? '€ ' + item.product.priceUnitDiscount
        : '€ 0',
    sortValueAccessor: (item: VerhofsteProductLineVM) =>
      item.product.priceUnitDiscount ? +item.product.priceUnitDiscount : 0,
  },
  ItemSubGroepName: {
    internalName: 'ItemSubGroepName',
    displayName: 'Subgroup Name',
    sortable: true,
    fixed: false,
    filterValue: '',
    valueAccessor: (item: VerhofsteProductLineVM) =>
      item.product.itemSubGroepName,
    sortValueAccessor: (item: VerhofsteProductLineVM) =>
      item.product.itemSubGroepName,
  },
  ItemSubGroepCode: {
    internalName: 'ItemSubGroepCode',
    displayName: 'Subgroup Code',
    sortable: true,
    fixed: false,
    filterValue: '',
    valueAccessor: (item: VerhofsteProductLineVM) =>
      item.product.itemSubGroepCode,
    sortValueAccessor: (item: VerhofsteProductLineVM) =>
      item.product.itemSubGroepCode,
  },
  ItemSubGroep2Name: {
    internalName: 'ItemSubGroep2Name',
    displayName: 'Subgroup Name 2',
    sortable: true,
    fixed: false,
    filterValue: '',
    valueAccessor: (item: VerhofsteProductLineVM) =>
      item.product.itemSubGroep2Name,
    sortValueAccessor: (item: VerhofsteProductLineVM) =>
      item.product.itemSubGroep2Name,
  },
  ItemSubGroep2Code: {
    internalName: 'ItemSubGroep2Code',
    displayName: 'Subgroup Code 2',
    sortable: true,
    fixed: false,
    filterValue: '',
    valueAccessor: (item: VerhofsteProductLineVM) =>
      item.product.itemSubGroep2Code,
    sortValueAccessor: (item: VerhofsteProductLineVM) =>
      item.product.itemSubGroep2Code,
  },
  Weight: {
    internalName: 'Weight',
    displayName: 'Weight',
    sortable: true,
    fixed: false,
    filterValue: '',
    valueAccessor: (item: VerhofsteProductLineVM) => item.product.weight,
    sortValueAccessor: (item: VerhofsteProductLineVM) =>
      item.product.weight ?? '',
  },
};

@Component({
  selector: 'app-verhofste-product-list-component',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatButtonModule,
    MatMenuModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatSnackBarModule,
    MatDialogModule,
    VerhofsteProductTableComponent,
    SortPipe,
  ],
  templateUrl: './verhofste-product-list.component.html',
  styleUrls: ['./verhofste-product-list.component.scss'],
})
export class VerhofsteProductListComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() navColor = 'steelblue';
  @Input() navContrast = 'white';
  @Input() canAssignMetadata = false;
  @Input() filtersEnabled = false;
  @Input() disabled = false;
  @Input() ralLabel?: string;

  @Input() businessPartner?: VerhofsteAgent;
  @Input() validityDateOffset!: number;

  clientReference = '';

  data: VerhofsteProductLineVM[] = [];

  amountTableDef = {
    internalName: 'Amount',
    displayName: 'Amount',
    sortable: true,
    fixed: false,
    filterValue: '',
    valueAccessor: (item: VerhofsteProductLineVM) => item.amount,
    sortValueAccessor: (item: VerhofsteProductLineVM) => item.amount ?? 0,
  };
  ralTableDef = {
    internalName: 'RAL',
    displayName: 'RAL',
    sortable: true,
    fixed: false,
    filterValue: '',
    valueAccessor: (item: VerhofsteProductLineVM) => item.ral,
    sortValueAccessor: (item: VerhofsteProductLineVM) => item.ral ?? '',
  };

  sortSettings = {
    active: 'ItemCode',
    direction: 'asc',
  };

  selectedItems: VerhofsteProductLineVM[] = [];

  resetSelectedItems = false;
  filterVal = '';

  columnTrackByFn = (
    _index: number,
    item: KeyValue<
      string,
      {
        internalName: string;
        displayName: string;
        sortable: boolean;
        fixed: boolean;
        filterValue: string;
        valueAccessor: (item: VerhofsteProductLineVM) => any;
      }
    >
  ) => item;

  businessPartnerSubject = new BehaviorSubject<VerhofsteAgent | undefined>(
    undefined
  );

  productMetadata$ = combineLatest([
    this.verhofsteService.products$,
    this.verhofsteService.productMetadata$,
  ]).pipe(
    map(([products, metadata]) => {
      const productIds = products.map((p) => p.itemId);
      return metadata.filter((m) =>
        productIds.includes(m.productId.toString())
      );
    })
  );

  displayedColumnsSubject = new BehaviorSubject<string[]>([
    'ItemCode',
    'ItemName',
    'PriceUnitPrice',
  ]);

  possibleColumns$ = combineLatest([
    this.productMetadata$,
    this.businessPartnerSubject.asObservable(),
  ]).pipe(
    map(([metadata, agent]) => {
      const client = this.clients.find((c) =>
        c.data?.some(
          (d) =>
            d.propertyName == 'AgentNumber' &&
            +(d.value ?? 0) === +(agent?.businessPartnerCode ?? -1)
        )
      );
      const showDiscount =
        client?.data?.some(
          (d) =>
            d.propertyName === 'ShowDiscounts' &&
            d.value &&
            JSON.parse(d.value).selectedValues.includes('Yes')
        ) ?? false;
      const columns = Object.fromEntries(
        Object.entries(possibleColumns).map((c) => ({ ...c }))
      );
      if (!showDiscount) {
        delete columns.PriceUserDiscount;
        columns.PriceUnitPrice.valueAccessor = (item: VerhofsteProductLineVM) =>
          '€ ' +
          Math.round(
            (+item.product.priceUnitPrice -
              +(item.product.priceUnitDiscount ?? 0)) *
              100
          ) /
            100;
      }
      const params = metadata
        .map((m) => ({
          paramId: m.paramId,
          paramName: m.param.title,
          paramType: m.param.type,
        }))
        .filter((m, i, a) => a.indexOf(m) === i);
      params.forEach((p) => {
        columns[p.paramId] = {
          internalName: p.paramId,
          displayName: p.paramName,
          sortable: true,
          fixed: false,
          filterValue: '',
          valueAccessor: (item: VerhofsteProductLineVM) => {
            const itemMetadata = metadata.find(
              (md) =>
                md.productId.toString() === item.product.itemId &&
                md.paramId === p.paramId
            );
            if (itemMetadata?.param.type === 'DateTime')
              return DateTime.fromFormat(
                itemMetadata?.value,
                'dd/MM/yyyy HH:mm',
                { zone: 'utc' }
              )
                .toLocal()
                .toFormat('dd/MM/yyyy HH:mm');
            return itemMetadata?.value ?? '';
          },
          sortValueAccessor: (item: VerhofsteProductLineVM) => {
            const itemMetadata = metadata.find(
              (md) =>
                md.productId.toString() === item.product.itemId &&
                md.paramId === p.paramId
            );
            if (itemMetadata?.param.type === 'DateTime')
              return DateTime.fromFormat(
                itemMetadata?.value,
                'dd/MM/yyyy HH:mm',
                { zone: 'utc' }
              ).valueOf();
            return itemMetadata?.value ?? '';
          },
        };
      });
      return columns;
    })
  );

  displayedColumns$ = combineLatest([
    this.displayedColumnsSubject.asObservable(),
    this.possibleColumns$,
  ]).pipe(
    map(([displayedColumns, possibleColumns]) =>
      displayedColumns.filter((c) => possibleColumns[c])
    )
  );

  needsRALColumn$ = new BehaviorSubject<boolean>(false);

  actualDisplayedColumns$ = combineLatest([
    this.displayedColumnsSubject,
    this.possibleColumns$,
    this.needsRALColumn$,
  ]).pipe(
    map(([displayedColumns, possibleColumns, needsRal]) => [
      'ItemSelected',
      ...displayedColumns.filter((c) => possibleColumns[c]),
      'ItemAmount',
      ...(needsRal ? ['RAL'] : []),
    ])
  );

  private wholeNumberRegex = new RegExp('^[0-9]*$');

  get disableGenerateQuoteButton() {
    return (
      this.disabled ||
      this.clientReference === '' ||
      this.selectedItems.length <= 0 ||
      this.selectedItems.some(
        (item) =>
          !item.amount ||
          !this.wholeNumberRegex.test(item.amount.toString()) ||
          (this.needsRal(item) && !item.ral)
      )
    );
  }

  clients!: ClientWithConfig[];

  destroy$ = new Subject<void>();

  constructor(
    private verhofsteService: VerhofsteService,
    private clientFacade: ClientFacade,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.verhofsteService.products$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.data = data.map(
          (product) => new VerhofsteProductLineVM(product, undefined, undefined)
        );
        this.selectedItems = [];
        this.needsRALColumn$.next(
          this.data.some((item) => this.needsRal(item))
        );
      });
    this.clientFacade.clients$
      .pipe(takeUntil(this.destroy$))
      .subscribe((clients) => (this.clients = clients));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.businessPartner && changes.businessPartner.currentValue) {
      this.clientReference =
        changes.businessPartner.currentValue.businessPartnerName;
      this.businessPartnerSubject.next(changes.businessPartner.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSelectedItemsChange(selectedItems: VerhofsteProductLineVM[]): void {
    this.selectedItems = selectedItems;
  }

  validatePrice() {
    return (control: FormControl) => {
      return control.value && control.value.length > 2
        ? undefined
        : { error: 'The title must be 3 character at least' };
    };
  }

  @Memoize({
    tags: ['ral'],
    hashFunction: (item: VerhofsteProductLineVM) => item.product.itemId,
  })
  needsRal(item: VerhofsteProductLineVM): boolean {
    return (
      !!this.ralLabel &&
      item.product.itemName.toLowerCase().includes(this.ralLabel.toLowerCase())
    );
  }

  updateMetadata() {
    this.dialog.open(UpdateMetadataDialogComponent, {
      minWidth: '200px',
      maxWidth: '600px',
      width: '100%',
      data: {
        productIds: this.selectedItems.map((i) => i.product.itemId),
      },
    });
  }

  generateQuote(): void {
    const dialogRef = this.dialog.open(VerhofsteQuoteSummaryDialogComponent, {
      maxWidth: '1100px',
      height: '90%',
      data: {
        filtersEnabled: this.filtersEnabled,
        ralLabel: this.ralLabel,
        clientReference: this.clientReference,
        items: this.selectedItems.map(
          (item) =>
            new VerhofsteProductLineVM(
              new VerhofsteProduct(item.product),
              item.amount,
              item.ral
            )
        ),
        updateAmount: (item: VerhofsteProductLineVM, amount: number) => {
          item.amount = amount;
        },
      },
    });

    dialogRef.afterClosed().subscribe(
      (
        result:
          | {
              action: string;
              items: VerhofsteProductLineVM[];
              description: string;
            }
          | undefined
      ) => {
        if (result?.action === 'confirm') {
          const offerId = uuid.v4();
          const offer = new VerhofsteOffer({
            id: offerId,
            validityDate: this.getValidityDate(
              this.businessPartner!.businessPartnerId
            ),
            agentId: +this.businessPartner!.businessPartnerId,
            agentRef: this.clientReference,
            description: result.description,
            agent: this.businessPartner!,
            offerLines: result.items
              .filter((i) => i.amount)
              .map(
                (i: VerhofsteProductLineVM) =>
                  new VerhofsteOfferLine({
                    id: uuid.v4(),
                    name: i.product.itemName,
                    price: +i.product.priceUnitPrice,
                    discount: i.product.priceUnitDiscount
                      ? +i.product.priceUnitDiscount
                      : 0,
                    productId: +i.product.itemId,
                    order: i.amount,
                    offerId: offerId,
                    product: i.product,
                    ral: i.ral,
                  })
              ),
          });
          this.verhofsteService.createOffer(offer).subscribe(() => {
            this._snackBar.open('Quotation created', 'X', {
              duration: 3000,
            });
          });
          this.selectedItems = [];
          this.resetSelectedItems = true;
          setTimeout(() => {
            this.resetSelectedItems = false;
          }, 0);
        }
      }
    );
  }

  getValidityDate(agentERPId: string): DateTime {
    const client = this.clients.find((c) =>
      c.data?.some(
        (d) =>
          d.propertyName == 'AgentNumber' && +(d.value ?? 0) === +agentERPId
      )
    );
    const offset = +(
      client?.data?.find((d) => d.propertyName === 'QuoteValidityOffset')
        ?.value ?? 0
    );
    return DateTime.now().plus({ days: this.validityDateOffset + offset });
  }

  addOrRemoveColumn(column: {
    internalName: string;
    displayName: string;
    sortable: boolean;
    fixed: boolean;
    filterValue: string;
    valueAccessor: (item: VerhofsteProductLineVM) => any;
  }) {
    if (this.displayedColumnsSubject.value.includes(column.internalName)) {
      this.displayedColumnsSubject.next(
        this.displayedColumnsSubject.value.filter(
          (c) => c !== column.internalName
        )
      );
    } else {
      this.displayedColumnsSubject.next([
        ...this.displayedColumnsSubject.value,
        column.internalName,
      ]);
    }
  }
}
