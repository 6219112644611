import { NestedTreeControl } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTreeModule, MatTreeNestedDataSource } from '@angular/material/tree';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  InterestGroup,
  TranslationService,
} from 'processdelight-angular-components';
import { catchError, combineLatest, first, Subject, takeUntil, tap } from 'rxjs';
import { interestGroupJoinRequests$ } from 'src/app/app.observables';
import { ExternalUserInfo } from 'src/app/core/domain/models/external-user-info.model';
import { ClientFacade } from 'src/app/core/store/client/client.facade';
import { InterestGroupFacade } from 'src/app/core/store/interestGroup/interestGroup.facade';
import { UserFacade } from 'src/app/core/store/user/user.facade';
import { Memoize } from 'typescript-memoize';

interface Groups {
  id: string;
  name: string;
  description?: string;
  icon?: SafeHtml;
  color: string;
  status: 'joined' | 'requestSent' | 'hidden' | 'none';
  children?: Groups[];
}

@Component({
  selector: 'app-group-settings',
  templateUrl: './group-settings.component.html',
  styleUrls: ['./group-settings.component.scss'],
  standalone: true,
  imports: [
    MatCardModule,
    CommonModule,
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule,
    MatTreeModule,
    MatCardModule,
    MatChipsModule,
  ],
})
export class GroupSettingsComponent implements OnInit, OnDestroy {
  interestGroups: InterestGroup[] = [];
  joinedGroups: InterestGroup[] = [];
  externalUser: ExternalUserInfo | undefined;
  canJoinCommunityGroups = true;

  treeControl = new NestedTreeControl<Groups>((node) => node.children ?? []);
  dataSource = new MatTreeNestedDataSource<Groups>();

  hasChild = (_: number, node: Groups) =>
    !!node.children && node.children.length > 0;

  getTranslation$(key: string) {
    return this.translationService.getTranslation$(key);
  }
  getTranslation(label: string): string {
    return this.translationService.getTranslation(label);
  }

  constructor(
    private translationService: TranslationService,
    private interestGroupFacade: InterestGroupFacade,
    private userFacade: UserFacade,
    private clientFacade: ClientFacade,
    private snackbar: MatSnackBar,
    private readonly sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.userFacade.externalUserInfo$
      .pipe(takeUntil(this.destroy$))
      .subscribe((external) => {
        if (external)
          {
            this.externalUser = external;

            this.clientFacade.currentClient$.pipe(first()).subscribe((client) => {
              if (client == null){
                this.canJoinCommunityGroups = this.externalUser?.clientUserInfo.every(x=>x.canJoinCommunityGroups == true) ?? true;
              } else {
                this.canJoinCommunityGroups = this.externalUser?.clientUserInfo.filter(x=>x.clientId == client.id).every(x=>x.canJoinCommunityGroups == true) ?? true;
              }
            });
          }
      });

    combineLatest([
      this.interestGroupFacade.interestGroups$,
      this.interestGroupFacade.interestGroupsForUser$,
      interestGroupJoinRequests$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([interestGroups, joinedGroups]) => {
        if (joinedGroups) this.joinedGroups = joinedGroups;
        if (interestGroups) {
          this.interestGroups = interestGroups;
          const parentGroups = this.interestGroups.filter(
            (g) => g.subGroups && g.subGroups.length > 0
          );
          const standaloneGroups = this.interestGroups.filter(
            // groups without a parent and not a subgroup of a group
            (g) =>
              !parentGroups.some((p) =>
                p.subGroups?.some((sg) => sg.subGroupId === g.id)
              ) && !parentGroups.map((p) => p.id).includes(g.id)
          );
          const parentGroupsThatAreNotSubGroups = parentGroups.filter(
            (pg) =>
              !parentGroups.some((p) =>
                p.subGroups?.some((sg) => sg.subGroupId === pg.id)
              )
          );

          const groupsToShow =
            parentGroupsThatAreNotSubGroups.concat(standaloneGroups);
          this.dataSource.data = this.sortNodes(
            groupsToShow.map((g) => ({
              id: g.id,
              name: g.title,
              description: g.description,
              icon: g.icon ? this.transform(g.icon) : undefined,
              color: g.color,
              status: this.getGroupStatus(g),
              children: this.sortNodes(this.getConvertedNodes(g)),
            }))
          );
        }
      });
  }

  getGroupStatus(
    group: InterestGroup
  ): 'joined' | 'requestSent' | 'hidden' | 'none' {
    if (group.isHidden) return 'hidden';
    if (this.joinedGroups.some((jg) => jg.id === group.id)) return 'joined';
    if (
      interestGroupJoinRequests$.value.some(
        (jg) => jg.interestGroupId === group.id
      )
    )
      return 'requestSent';
    return 'none';
  }

  getConvertedNodes(interestGroup: InterestGroup): Groups[] {
    if (interestGroup.subGroups && interestGroup.subGroups.length === 0) {
      return [];
    }
    const r = interestGroup.subGroups!.flatMap((group) => {
      const subGroup = this.interestGroups?.find(
        (g) => g.id === group.subGroupId
      );
      if (!subGroup) return [];
      return [
        {
          id: subGroup.id,
          name: subGroup.title,
          description: subGroup.description,
          icon: subGroup.icon ? this.transform(subGroup.icon) : undefined,
          color: subGroup.color,
          status: this.getGroupStatus(subGroup),
          children: this.sortNodes(this.getConvertedNodes(subGroup)),
        },
      ];
    });
    return this.sortNodes(r);
  }

  sortNodes(groups: Groups[]): Groups[] {
    return groups.sort((a, b) => {
      if (
        a.children &&
        a.children.length > 0 &&
        b.children &&
        b.children.length == 0
      ) {
        return -1;
      }
      if (
        a.children &&
        a.children.length == 0 &&
        b.children &&
        b.children.length > 0
      ) {
        return 1;
      }
      return a.name.localeCompare(b.name);
    });
  }

  joinGroup(groupId: string) {
    const portalUserId = this.externalUser?.portalUserId;
    if (!portalUserId) {
      this.snackbar.open('No portalUserId. Contact administrator', 'X', {
        duration: 3000,
        panelClass: 'app-notification-error',
      });
      return;
    }
    this.interestGroupFacade
      .addPortalUserInterestGroup$(portalUserId, groupId)
      .pipe(
        tap((x) => {
          if (x.joinRequest) {
            this.snackbar.open(this.getTranslation('joinRequestSent'), 'X', {
              duration: 3000,
              panelClass: 'app-notification-success',
            });
          } else {
            this.snackbar.open(this.getTranslation('joinGroup'), 'X', {
              duration: 3000,
              panelClass: 'app-notification-success',
            });
          }
        }),
        catchError((err) => {
          this.snackbar.open(this.getTranslation('savingError'), 'X', {
            duration: 3000,
            panelClass: 'app-notification-error',
          });
          return err;
        })
      )
      .subscribe();
  }

  leaveGroup(groupId: string) {
    const interestGroup = this.interestGroups.find((g) => g.id === groupId);
    const portalUserId = this.externalUser?.portalUserId;
    if (!portalUserId) {
      this.snackbar.open('No portalUserId. Contact administrator', 'X', {
        duration: 3000,
        panelClass: 'app-notification-error',
      });
      return;
    }
    this.interestGroupFacade
      .deletePortalUserInterestGroup$(portalUserId, interestGroup!)
      .pipe(
        tap((x) => {
          this.snackbar.open(this.getTranslation('leftGroup'), 'X', {
            duration: 3000,
            panelClass: 'app-notification-success',
          });
        }),
        catchError((err) => {
          this.snackbar.open(this.getTranslation('savingError'), 'X', {
            duration: 3000,
            panelClass: 'app-notification-error',
          });
          return err;
        })
      )
      .subscribe();
  }
  @Memoize()
  transform(value: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }

  destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
