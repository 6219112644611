export class AppItemInfo {
  id!: string;
  tableName!: string;
  value!: string;
  name!: string;

  constructor(obj: Partial<AppItemInfo>) {
    Object.assign(this, obj);
  }
}
