<div class="h-100 d-flex flex-column list-background">
  <div class="d-flex flex-row mt-2 ms-2 mb-2 align-items-center">
    <mat-form-field appearance="outline" [style.max-width.rem]="14">
      <mat-label>Client reference</mat-label>
      <input matInput [(ngModel)]="clientReference" required />
    </mat-form-field>
    <button
      mat-raised-button
      color="primary"
      class="ms-3"
      (click)="updateMetadata()"
      *ngIf="canAssignMetadata"
      [disabled]="!selectedItems.length"
    >
      UPDATE METADATA
    </button>
    <button
      mat-raised-button
      color="primary"
      class="ms-3"
      (click)="generateQuote()"
      [disabled]="disableGenerateQuoteButton"
    >
      CREATE QUOTATION
    </button>
    <span *ngIf="disabled" class="ms-2">
      Creating quotations is temporarily disabled
    </span>

    <span
      class="text-uppercase"
      *ngIf="selectedItems.length > 1"
      style="margin-left: 10px; font-size: 12px; font-weight: 700"
    >
      {{ selectedItems.length ? selectedItems.length + " Selected" : "" }}
    </span>

    <div class="flex-grow-1"></div>
    <button
      [matMenuTriggerFor]="menu"
      mat-mini-fab
      class="btn"
      style="right: 0px"
      [style.background]="navColor"
      [style.color]="navContrast"
    >
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu" xPosition="before" class="scrollbar">
      <div
        class="pe-2"
        (click)="$event.stopPropagation()"
        *ngFor="
          let column of possibleColumns$
            | async
            | keyvalue
            | appSort : 'value' : 'displayName';
          trackBy: columnTrackByFn
        "
      >
        <mat-checkbox
          (change)="addOrRemoveColumn(column.value)"
          [checked]="
            displayedColumnsSubject.value.includes(column.value.internalName)
          "
        >
          {{ column.value.displayName }}</mat-checkbox
        >
      </div>
    </mat-menu>
  </div>

  <app-verhofste-product-table
    [data]="data"
    [displayedColumns]="(displayedColumns$ | async) ?? []"
    [possibleColumns]="(possibleColumns$ | async) ?? {}"
    [actualDisplayedColumns]="(actualDisplayedColumns$ | async) ?? []"
    [amountTableDef]="amountTableDef"
    [ralTableDef]="ralTableDef"
    [ralLabel]="ralLabel"
    [filtersEnabled]="filtersEnabled"
    [sortSettings]="sortSettings"
    [resetSelectedItems]="resetSelectedItems"
    (selectedItemsChange)="onSelectedItemsChange($event)"
  ></app-verhofste-product-table>
</div>
