import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { CRMEntity } from 'processdelight-angular-components';
import { PortalService } from 'src/app/core/services/portal.service';
import { TranslationService } from 'processdelight-angular-components';

@Component({
  selector: 'app-change-request-popup',
  templateUrl: './change-request-popup.component.html',
  styleUrls: ['./change-request-popup.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    MatSnackBarModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    CommonModule,
  ],
})
export class ChangeRequestPopupComponent {
  emailTobeSent = '';

  getTranslation$(label: string) {
    return this.translationService.getTranslation$(label);
  }

  constructor(
    private translationService: TranslationService,
    private portalService: PortalService,
    private snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private data: { entity: CRMEntity }
  ) {}

  sendEmail() {
    this.portalService
      .requestUpdateProfileData(this.data.entity, this.emailTobeSent)
      .subscribe((x) => {
        this.snackbar.open('Mail sent succesfully.', 'X', {
          duration: 3000,
          panelClass: 'app-notification-success',
        });
      });
  }
}
