import { createAction, props } from '@ngrx/store';
import { Group } from '../../domain/models/group.model';
import { ActionBase } from '../action-base.interface';
import { GroupConfigurationDTO } from '../../domain/dto/group-configuration.dto';
import { ClientWithConfig } from '../../domain/models/client-with-config.model';
import { ClientUserInfo } from '../../domain/models/client-user-info.model';

export const getGroups = createAction(
  '[Group] Get groups',
  props<{ clientId: string | undefined } & ActionBase<Group[]>>()
);
export const getGroupsResolved = createAction(
  '[Group] Get groups resolved',
  props<{ groups: Group[] }>()
);

export const updateGroupConfiguration = createAction(
  '[Group] Update group configuration',
  props<
    { groupConfiguration: GroupConfigurationDTO[] } & ActionBase<{
      groups: Group[];
      clientConfigs: ClientWithConfig[];
      users: ClientUserInfo[];
    }>
  >()
);

export const resetSlice = createAction('[Group] Reset slice');
