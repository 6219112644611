import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalPostLike } from 'src/app/core/domain/models/portal-post-like.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { PortalUserPipe } from '../../portal.user.pipe';
import { ColumnDef, DashboardComponent, TranslationService } from 'processdelight-angular-components';

@Component({
  selector: 'app-like-list-popup',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    DashboardComponent
  ],
  templateUrl: './like-list-popup.component.html',
  styleUrls: ['./like-list-popup.component.scss'],
  providers: [PortalUserPipe]
})
export class LikeListPopupComponent implements AfterViewInit {
  likes: PortalPostLike[] = [];
  possibleColumns: ColumnDef<PortalPostLike>[] = [];
  selectedColumns = ['userName', 'dateTime']

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: { likes: PortalPostLike[] },
    public dialogRef: MatDialogRef<LikeListPopupComponent>,
    private readonly translations: TranslationService,
    private readonly portalUserPipe: PortalUserPipe // I

  ) {
    this.likes =dialogData.likes && dialogData.likes.length > 0 ?[...dialogData.likes].sort((a, b) => b.createdOn!.toMillis() - a.createdOn!.toMillis()):dialogData.likes;
    this.possibleColumns = [
      new ColumnDef<PortalPostLike>({
        internalName: 'userName',
        displayName:this.getTranslation('userName'),
        sortable: false,
        filterable: false,
        valueAccessor: (item: PortalPostLike) => this.portalUserPipe.transform(item),
        width: undefined,
        cellClass: 'ellipsis',
      }),
      new ColumnDef<PortalPostLike>({
        internalName: 'dateTime',
        displayName: this.getTranslation('Date & Time'),
        sortable: false,
        filterable: false,
        valueAccessor: (item: PortalPostLike) => item.createdOn?.toFormat('dd/MM/yyyy HH:mm'),
        width: undefined,
        cellClass: 'ellipsis',
      })
    ]

  }
  // datetime elave et
  @ViewChild(DashboardComponent) dashboard!: DashboardComponent<PortalPostLike>;
  ngAfterViewInit(): void {
   if(this.dashboard)
    this.dashboard.paginator.disabled = true;
  }

  close() {
    this.dialogRef.close();
  }

  getTranslation = (label: string) => this.translations.getTranslation(label)
}
