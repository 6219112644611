import { Lookup } from 'processdelight-angular-components';

export class ClientConfig {
  id?: string;
  name!: string;
  blockAllRequests = false;
  allowRequests = false;
  allowRoleCreation = false;
  useGeneralRoles = true;
  defaultRole?: Lookup;
  defaultGroup?: Lookup;

  constructor(obj?: Partial<ClientConfig>) {
    Object.assign(this, obj);
  }
}
