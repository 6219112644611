// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  instance: 'https://login.microsoftonline.com/',
  clientId: 'a5341d23-1ecf-48e9-9fc6-55fae87ed91e',
  tenantId: 'organizations',
  authority: 'https://login.microsoftonline.com/organizations',
  ishtarFunctions: 'https://functions.dev.ishtar365.com/',
  ishtarFunctionsScope:
    'api://a5341d23-1ecf-48e9-9fc6-55fae87ed91e/user_impersonation',
  b2cTenantName: 'ishtar365',
  b2cClientId: '336b7fdf-4c34-4f47-b202-e6c9dddf23a5',
  b2cScope:
    'https://ishtar365.onmicrosoft.com/daf6f0f0-4ced-4778-bb06-d986660dd078/web_api',
  ishtarFunctionsKey:
    'Rl1x2qHDmM4-SvoNyzQDb-SRDTv-qXStKozJkQlMzF9lAzFu6Qk4VA==',
  ishtar365: 'https://portal.dev.ishtar365.com',
  cdnUrl: 'https://ishtar365storage.blob.core.windows.net/',
  cdnIconsPath: 'icons/',
  cdnImageNotFound:
    'https://ishtar365storage.blob.core.windows.net/icons/not_found.svg',
  ishtar_word_icon: 'https://ishtar.azureedge.net/user/assets/word_48x1.svg',
  ishtar_powerpoint_icon:
    'https://ishtar.azureedge.net/user/assets/powerpoint_48x1.svg',
  ishtar_excel_icon: 'https://ishtar.azureedge.net/user/assets/excel_48x1.svg',
  ishtar_pdf_icon: 'https://ishtar.azureedge.net/user/assets/pdf.svg',
  ishtar_picture_icon: 'https://ishtar.azureedge.net/user/assets/jpg-png.svg',
  ishtar_movie_icon: 'https://ishtar.azureedge.net/user/assets/Movie.svg',
  ishtar_email_icon: 'https://ishtar.azureedge.net/user/assets/email.svg',
  ishtar_default_icon:
    'https://ishtar.azureedge.net/user/assets/Default_file.svg',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
