<div class="d-flex flex-column h-100 w-100">
  <h1 mat-dialog-title class="dialogTitle">
    {{ getTranslations("Calendar") }}
  </h1>
  <mat-icon class="closeButtonIcon" (click)="closeDialog()">close</mat-icon>
  <processdelight-calendar
    [calenderItems]="data.calendarItems"
    (dateChanged)="onDateChange($event)"
    [selectedDate]="selectedDate"
    [calendarHeight]="calendarHeight"
    [hourBlocks]="hourBlocks"
    [currentView]="'week'"
    [splitMultipleDayEventsWeekView]="false"
  >
  </processdelight-calendar>
</div>
