import { createAction, props } from '@ngrx/store';
import { Form } from 'processdelight-angular-components';
import { PortalAppConfig } from '../../domain/models/portal-app-config';
import { ActionBase } from '../action-base.interface';

export const getAppConfig = createAction(
  '[AppConfig] Get AppConfig',
  props<{
    callback?: () => void;
  }>()
);
export const getAppConfigResolved = createAction(
  '[AppConfig] Get AppConfig resolved',
  props<{ config: PortalAppConfig }>()
);

export const updateAppConfig = createAction(
  '[AppConfig] Update AppConfig',
  props<{
    config: PortalAppConfig;
    callback?: () => void;
  }>()
);
export const updateAppConfigResolved = createAction(
  '[AppConfig] Update AppConfig resolved',
  props<{ config: PortalAppConfig }>()
);

export const getForms = createAction(
  '[Forms] Get Forms',
  props<ActionBase<Form[]>>()
);
export const getFormsResolved = createAction(
  '[Forms] Get Forms resolved',
  props<{ forms: Form[] }>()
);
export const resetSlice = createAction('[User] Reset slice');
