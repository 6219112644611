import { BaseEntity, Lookup } from 'processdelight-angular-components';
import { Metadata } from './metadata.model';
import { Library } from './library.model';
import { AppItemInfo } from './app-item-info.model';

export class LibraryItem extends BaseEntity {
  title!: string;
  sharepointId!: string;
  fileLocation!: string;
  metadata: Metadata[] = [];
  linkedItems: Lookup[] = [];
  library?: Library;
  appItems: AppItemInfo[] = [];

  // added for mock data json respone.
  libraryId!: string;

  constructor(obj: Partial<LibraryItem>) {
    super(obj);
    Object.assign(this, obj);
    if (obj.metadata) this.metadata = obj.metadata.map((m) => new Metadata(m));
    if (obj.appItems)
      this.appItems = obj.appItems.map((m) => new AppItemInfo(m));
  }
}
