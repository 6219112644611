<div class="assignedToMeSwitcher">
  <mat-button-toggle-group [formControl]="control">
    <mat-button-toggle [value]="true"
      >My tasks
      <div [@selected]="control.value" class="indicator">
        &nbsp;
      </div></mat-button-toggle
    >
    <mat-button-toggle [value]="false"
      >All tasks
      <div [@selected]="!control.value" class="indicator">
        &nbsp;
      </div></mat-button-toggle
    >
  </mat-button-toggle-group>
</div>
