import { Action, createReducer, on } from '@ngrx/store';
import { Role } from '../../domain/models/role.model';
import { getDefaultRoles, getDefaultRolesResolved, getRoles, getRolesResolved, resetSlice } from './role.actions';

export const featureSlice = 'role';

export interface State {
  loadingRoles: boolean;
  loadingDefaultRoles: boolean;
  roles: Role[];
  defaultRoles: Role[];
}

const defaultState: State = {
  loadingRoles: false,
  loadingDefaultRoles: false,
  roles: [],
  defaultRoles: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return roleReducer(state, action);
}

export const initialState: State = defaultState;
export const roleReducer = createReducer(
  initialState,
  on(getRoles, (state) => ({ ...state, loadingRoles: true })),
  on(getDefaultRoles, (state) => ({ ...state, loadingDefaultRoles: true })),
  on(getRolesResolved, (state, { roles }) => ({
    ...state,
    roles,
    loadingRoles: false,
  })),
  on(getDefaultRolesResolved, (state, { defaultRoles }) => ({
    ...state,
    defaultRoles,
    loadingDefaultRoles: false,
  })),
  on(resetSlice, () => initialState)
);
