import { BaseEntity, Lookup } from 'processdelight-angular-components';

export class MetadataChoiceTranslation extends BaseEntity {
  translation!: string;
  parentItemId!: string;
  parentItem!: Lookup;
  languageId!: string;
  language!: Lookup;

  constructor(obj: Partial<MetadataChoiceTranslation>) {
    super(obj);
    Object.assign(this, obj);
  }
}
