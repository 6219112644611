import { createAction, props } from '@ngrx/store';
import { CMSPage, WebPartType } from 'processdelight-angular-components';
import { ActionBase } from '../action-base.interface';
import { PageConfigurationDTO } from '../../domain/dto/page-configuration.dto';

export const getPages = createAction(
  '[Page] Get pages',
  props<ActionBase<CMSPage[]>>()
);
export const getPagesResolved = createAction(
  '[Page] Get pages resolved',
  props<{ pages: CMSPage[] }>()
);
export const updatePageConfiguration = createAction(
  '[Page] Update page configuration',
  props<{ configuration: PageConfigurationDTO } & ActionBase<CMSPage[]>>()
);
export const getWebPartTypes = createAction(
  '[Page] Get web part types',
  props<ActionBase<WebPartType[]>>()
);
export const getWebPartTypesResolved = createAction(
  '[Page] Get web part types resolved',
  props<{ webPartTypes: WebPartType[] }>()
);

export const resetSlice = createAction('[Page] Reset slice');
