import { Injectable } from '@angular/core';
import {
  ContextMenuAction,
  SideBarAction,
} from 'processdelight-angular-components';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PortalActionsService {
  sideBarActions: SideBarAction[] = [];
  buttonActions: ContextMenuAction<unknown>[] = [];
  iconActions: ContextMenuAction<unknown>[] = [];

  reset() {
    this.sideBarActions = [];
    this.buttonActions = [];
    this.iconActions = [];
  }

  private search = new Subject<string>();
  searchData = this.search.asObservable();
  changeSearch(search: string) {
    this.search.next(search);
  }
}
