import { Injectable } from '@angular/core';
import { PortalService } from '../../services/portal.service';
import { BaseEffects } from '../base.effects';
import {
  getClientUsersResolved,
  getClientsResolved,
} from '../client/client.actions';
import {
  getGroups,
  getGroupsResolved,
  updateGroupConfiguration,
} from './group.actions';
import { Actions } from '@ngrx/effects';

@Injectable({ providedIn: 'root' })
export class GroupEffects extends BaseEffects {
  constructor(private portalService: PortalService, actions$: Actions) {
    super(actions$);
  }

  getGroups = this.createDefaultEffect(
    getGroups,
    ({ clientId }) => this.portalService.getGroups(clientId),
    { valueMapper: (v) => ({ groups: v.result }), action: getGroupsResolved }
  );

  updateGroupConfiguration = this.createDefaultEffect(
    updateGroupConfiguration,
    ({ groupConfiguration }) =>
      this.portalService.updateGroupConfiguration(groupConfiguration),
    [
      {
        valueMapper: (v) => ({ groups: v.result.groups }),
        action: getGroupsResolved,
      },
      {
        valueMapper: (v) => ({
          clients: v.result.clientConfigs,
        }),
        action: getClientsResolved,
      },
      {
        valueMapper: (v) => ({ clientUsers: v.result.users }),
        action: getClientUsersResolved,
      },
    ]
  );
}
