import { Directive } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
} from '@angular/forms';

@Directive({
  selector: '[appWholeNumbers]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: WholeNumbersValidatorDirective,
      multi: true,
    },
  ],
  standalone: true,
})
export class WholeNumbersValidatorDirective implements Validator {
  private regex = new RegExp('^[0-9]*$');
  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value && !this.regex.test(control.value)) {
      return { wholeNumber: true };
    }
    return null;
  }
}
