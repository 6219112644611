<div class="w-100 h-100 mh-100 min-height-0" style="padding: 0px">
  <div
    class="h-100 w-100 d-flex flex-column justify-content-center align-items-center"
    *ngIf="noProfile; else profile"
  >
    <span
      class="bold"
      *ngIf="getTranslation$('noProfileFound') | async as noProfileLabel"
      >{{ noProfileLabel }}</span
    >
  </div>
  <ng-template #profile>
    <div
      *ngIf="loadingProfileData; else dataContainer"
      class="h-100 w-100 d-flex flex-column justify-content-center align-items-center"
    >
      <span
        class="title"
        *ngIf="getTranslation$('loadingProfile') | async as loadingProfileLabel"
        >{{ loadingProfileLabel }}</span
      >
      <mat-spinner style="width: 60px"></mat-spinner>
    </div>
    <ng-template #dataContainer>
      <!-- <div class="w-100 h-100 container-fluid p-0 d-flex flex-column">
      <div class="dataContainer scrollbar">
        <div class="column">
          <span *ngIf="getTranslation$('name') | async as nameLabel">
            {{ nameLabel }}
          </span>
          <div class="bold">{{ profileData?.entity?.name }}</div>
        </div>
        <ng-container
          *ngFor="let dataObject of getDataObjects(); let i = index"
        >
          <div class="column">
            <span>{{ dataObject.dataProperty?.name }}: </span>
            <div
              class="bold"
              *ngIf="
                dataObject.dataProperty?.propertyType?.type !== 'Rich text'
              "
            >
              {{ GetValueOfObject(dataObject) }}
            </div>
          </div>
          <div
            *ngIf="dataObject.dataProperty?.propertyType?.type === 'Rich text'"
            [innerHTML]="GetValueOfObject(dataObject)"
          ></div>
        </ng-container>
      </div>

    </div> -->
      <div class="dataContainer h-100 w-100 d-flex flex-column">
        <processdelight-form-builder
          class="formBuilder"
          #formBuilder
          [formgroup]="clientForm"
          [debug]="true"
          [disabled]="true"
          [objService]="dataObjectService"
        ></processdelight-form-builder>
      </div>
    </ng-template>
    <ng-template #clientTeams let-element="config">
      <mat-checkbox
        class="w-100 no-subscript-wrapper"
        formControlName="teams"
        class="example-margin"
        >Teams channel</mat-checkbox
      >
    </ng-template>
  </ng-template>
</div>
