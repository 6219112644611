import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { VerhofsteService } from '../services/verhofste.service';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MetadataParameter } from '../domain/models/metadata-parameter.model';
import { Observable, Subject, first, forkJoin, takeUntil } from 'rxjs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  DatePickerFormatDirective,
  MY_FORMATS,
} from 'processdelight-angular-components';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { MatButtonModule } from '@angular/material/button';
import { Memoize, clear as MemoizeClear } from 'typescript-memoize';
import { VerhofsteProductMetadata } from '../domain/models/product-metadata.model';
import { DateTime } from 'luxon';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';

@Component({
  selector: 'app-update-metadata-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatButtonModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    DatePickerFormatDirective,
  ],
  templateUrl: './update-metadata-dialog.component.html',
  styleUrls: ['./update-metadata-dialog.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS,
    },
  ],
})
export class UpdateMetadataDialogComponent implements OnInit, OnDestroy {
  metadataParams$ = this.verhofsteService.metadataParameters$;
  metadata$ = this.verhofsteService.productMetadata$;

  metadataParameters: MetadataParameter[] = [];

  paramSelectorControl = new FormControl<string | null>(
    null,
    Validators.required
  );
  valueSelectorControl = new FormControl<string | DateTime | null>(null);

  get productIds() {
    return this.data.productIds;
  }

  @Memoize({ tags: ['selectedParam'] })
  get selectedParam() {
    return this.metadataParameters.find(
      (param) => param.id === this.paramSelectorControl.value
    );
  }

  destroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      productIds: string[];
    },
    private readonly verhofsteService: VerhofsteService,
    private readonly snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.metadataParams$.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.metadataParameters = params.map(
        (p) =>
          new MetadataParameter({
            ...p,
            choices:
              p.choices?.sort(
                (a, b) => a.value?.localeCompare(b.value ?? '') ?? 0
              ) || [],
          })
      );
      this.metadataParameters.sort((a, b) => a.title.localeCompare(b.title));
    });
    this.paramSelectorControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.valueSelectorControl.setValue(null);
        MemoizeClear(['selectedParam']);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  focusInput(input: HTMLInputElement) {
    setTimeout(() => {
      input.focus();
    }, 0);
  }

  update() {
    const param = this.metadataParameters.find(
      (param) => param.id === this.paramSelectorControl.value
    );
    if (!param) return;
    const valueMetadata = new VerhofsteProductMetadata({
      paramId: param.id,
    });
    if (!this.valueSelectorControl.value) {
      this.metadata$.pipe(first()).subscribe((metadata) => {
        const existing = metadata.filter(
          (m) =>
            m.paramId === param.id &&
            this.productIds.includes(m.productId.toString())
        );
        if (existing.length)
          this.verhofsteService
            .deleteProductMetadata([...new Set(existing.map((m) => m.id))])
            .subscribe(() => {
              this.snackbar.open('Metadata updated', 'Close', {
                duration: 3000,
              });
            });
      });
      return;
    }
    switch (param.type) {
      case 'Choice': {
        const choice = param.choices.find(
          (choice) => choice.id === this.valueSelectorControl.value
        );
        valueMetadata.value = choice?.value ?? '';
        break;
      }
      case 'DateTime':
        valueMetadata.value = (this.valueSelectorControl.value as DateTime)
          .setZone('UTC')
          .toFormat('dd/MM/yyyy HH:mm');
        break;
      case 'Number':
        valueMetadata.value = this.valueSelectorControl.value.toString();
        break;
      default:
        valueMetadata.value = this.valueSelectorControl.value as string;
        break;
    }
    this.metadata$.pipe(first()).subscribe((metadata) => {
      const existing = metadata.filter(
        (m) =>
          m.paramId === param.id &&
          this.productIds.includes(m.productId.toString())
      );
      const newMetadata = this.productIds
        .filter((id) => !existing.some((m) => m.productId.toString() === id))
        .map(
          (id) =>
            new VerhofsteProductMetadata({
              ...valueMetadata,
              productId: id,
              param: undefined,
            })
        );
      const actions: Observable<VerhofsteProductMetadata[]>[] = [];
      if (newMetadata.length)
        actions.push(this.verhofsteService.createProductMetadata(newMetadata));
      if (existing.length)
        actions.push(
          this.verhofsteService.updateProductMetadata(
            existing.map(
              (m) =>
                new VerhofsteProductMetadata({
                  ...m,
                  ...valueMetadata,
                  param: undefined,
                })
            )
          )
        );
      if (actions.length)
        forkJoin(actions).subscribe(() => {
          this.snackbar.open('Metadata updated', 'Close', {
            duration: 3000,
          });
        });
    });
  }
}
