import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isB2C } from '../../../core/extensions/b2x.extensions';
import { VerhofsteProduct } from '../domain/models/product.model';
import { VerhofsteAgent } from '../domain/models/agent.model';
import { VerhofsteOffer } from '../domain/models/offer.model';
import { BehaviorSubject, map, tap } from 'rxjs';
import { VerhofsteProductMetadata } from '../domain/models/product-metadata.model';
import { MetadataParameter } from '../domain/models/metadata-parameter.model';
import { MicrosoftAuthenticationService } from 'src/app/msal/injectables/microsoft-authentication.service';

@Injectable({
  providedIn: 'root',
})
export class VerhofsteService {
  products$ = new BehaviorSubject<VerhofsteProduct[]>([]);
  offers$ = new BehaviorSubject<VerhofsteOffer[]>([]);
  productMetadata$ = new BehaviorSubject<VerhofsteProductMetadata[]>([]);
  metadataParameters$ = new BehaviorSubject<MetadataParameter[]>([]);

  apiBase = `${location.origin}/web`;
  constructor(
    private httpClient: HttpClient,
    private msal: MicrosoftAuthenticationService
  ) {}

  private b2x() {
    return isB2C() ? 'b2c' : 'b2b';
  }

  getCustomerList() {
    return this.httpClient.get<VerhofsteAgent[]>(
      `${this.apiBase}/${this.b2x()}/verhofste/clients`
    );
  }

  getProductList(id: string) {
    return this.httpClient
      .get<VerhofsteProduct[]>(
        `${this.apiBase}/${this.b2x()}/verhofste/products?CustomerId=${id}`
      )
      .pipe(
        map((products) => products.map((p) => new VerhofsteProduct(p))),
        tap((products) => this.products$.next(products))
      );
  }

  getOffers(agentId?: number) {
    return this.httpClient
      .get<VerhofsteOffer[]>(
        `${this.apiBase}/${this.b2x()}/verhofste/offers${
          agentId ? '?agentId=' + agentId : ''
        }`
      )
      .pipe(
        map((offers) => offers.map((o) => new VerhofsteOffer(o))),
        tap((offers) => this.offers$.next(offers))
      );
  }

  createOffer(offer: VerhofsteOffer) {
    return this.httpClient
      .post(`${this.apiBase}/${this.b2x()}/verhofste/offers`, offer)
      .pipe(
        map((o) => new VerhofsteOffer(o)),
        tap((o) => this.offers$.next([...this.offers$.value, o]))
      );
  }

  updateOffer(offer: VerhofsteOffer) {
    return this.httpClient
      .patch(`${this.apiBase}/${this.b2x()}/verhofste/offers`, offer)
      .pipe(
        map((o) => new VerhofsteOffer(o)),
        tap((o) =>
          this.offers$.next(
            this.offers$.value.map((offer) => (offer.id === o.id ? o : offer))
          )
        )
      );
  }

  createOrderXml(offerId: string, webpartId: string, agentName: string) {
    return this.httpClient.post(
      `${
        this.apiBase
      }/${this.b2x()}/verhofste/offers/${offerId}/orderXml?webpartId=${webpartId}&agentName=${agentName}`,
      undefined
    );
  }

  downloadQuoteUrl(
    offerId: string,
    customerName: string,
    showDiscounts?: boolean
  ) {
    return this.httpClient
      .get<{ downloadUrl: string }>(
        `${this.apiBase}/${this.b2x()}/verhofste/offers/${offerId}/downloadLink`
      )
      .pipe(
        map(({ downloadUrl }) => ({
          downloadUrl:
            downloadUrl +
            `&customerName=${customerName}&userName=${
              this.msal.name
            }&showDiscounts=${showDiscounts ? 'true' : 'false'}`,
        }))
      );
  }

  getMetadataParameters() {
    return this.httpClient
      .get<MetadataParameter[]>(
        `${this.apiBase}/${this.b2x()}/verhofste/metadata-parameters`
      )
      .pipe(
        tap((metadataParameters) =>
          this.metadataParameters$.next(metadataParameters)
        )
      );
  }

  getProductMetadata() {
    return this.httpClient
      .get<VerhofsteProductMetadata[]>(
        `${this.apiBase}/${this.b2x()}/verhofste/product-metadata`
      )
      .pipe(
        map((metadata) => metadata.map((m) => new VerhofsteProductMetadata(m))),
        tap((metadata) => this.productMetadata$.next(metadata))
      );
  }

  createProductMetadata(metadata: VerhofsteProductMetadata[]) {
    const productIds = [
      ...new Set(metadata.map((m) => m.productId.toString())),
    ];
    return this.httpClient
      .post<VerhofsteProductMetadata[]>(
        `${this.apiBase}/${this.b2x()}/verhofste/product-metadata`,
        metadata
      )
      .pipe(
        map((metadata) => metadata.map((m) => new VerhofsteProductMetadata(m))),
        tap((metadata) =>
          this.productMetadata$.next([
            ...this.productMetadata$.value.filter(
              (m) => !productIds.includes(m.productId.toString())
            ),
            ...metadata,
          ])
        )
      );
  }

  updateProductMetadata(metadata: VerhofsteProductMetadata[]) {
    const productIds = [
      ...new Set(metadata.map((m) => m.productId.toString())),
    ];
    return this.httpClient
      .patch<VerhofsteProductMetadata[]>(
        `${this.apiBase}/${this.b2x()}/verhofste/product-metadata`,
        metadata
      )
      .pipe(
        map((metadata) => metadata.map((m) => new VerhofsteProductMetadata(m))),
        tap((metadata) =>
          this.productMetadata$.next([
            ...this.productMetadata$.value.filter(
              (m) => !productIds.includes(m.productId.toString())
            ),
            ...metadata,
          ])
        )
      );
  }

  deleteProductMetadata(ids: string[]) {
    return this.httpClient
      .delete(`${this.apiBase}/${this.b2x()}/verhofste/product-metadata`, {
        body: ids,
      })
      .pipe(
        tap(() =>
          this.productMetadata$.next(
            this.productMetadata$.value.filter((m) => !ids.includes(m.id))
          )
        )
      );
  }
}
