import { Action, createReducer, on } from '@ngrx/store';

import {
  getLibrariesResolved,
  getLibraryItemsResolved,
  getSharedItemsResolved,
  resetSlice,
} from './library.actions';
import { Library } from 'src/app/webparts/dms-component/domain/models/library.model';
import { LibraryItem } from 'src/app/webparts/dms-component/domain/models/item.model';

export const featureSlice = 'library';

export interface State {
  libraries: Library[];
  items: LibraryItem[];
  totalRecordCount: number;
  sharedItems: LibraryItem[];
  sharedRecordCount: number;
}

const defaultState: State = {
  libraries: [],
  items: [],
  totalRecordCount: 0,
  sharedItems: [],
  sharedRecordCount: 0,
};

export function Reducer(state: State | undefined, action: Action) {
  return libraryReducer(state, action);
}

export const initialState: State = defaultState;
export const libraryReducer = createReducer(
  initialState,
  on(getLibrariesResolved, (state, { libraries }) => ({
    ...state,
    libraries,
  })),
  on(getLibraryItemsResolved, (state, { result, totalRecordCount }) => {
    return {
      ...state,
      items: result,
      totalRecordCount,
    };
  }),
  on(getSharedItemsResolved, (state, { result, totalRecordCount }) => ({
    ...state,
    sharedItems: result,
    sharedRecordCount: totalRecordCount,
  })),

  on(resetSlice, () => initialState)
);
