<div class="h-100 w-100 d-flex flex-column pt-2">
  <div class="h-100 ms-2 me-2 d-flex flex-column">
    <mat-card>
      <mat-card-header class="d-flex flex-row justify-content-between">
        <mat-card-title
          *ngIf="
            getTranslation$('interestGroups') | async as interestGroupsLabel
          "
        >
          {{ interestGroupsLabel }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="form" class="h-100 w-100 d-flex flex-column">
          <mat-checkbox #allGroupSelector
                        [disabled]="disableAll && !showAllGroups"
                        [checked]="showAllGroups"
                        (change)="onCheckboxChange('allGroups', $event.checked)"
                        *ngIf="
    getTranslation$('showAllGroups') | async as showAllGroupsLabel
  ">
            {{ showAllGroupsLabel }}
          </mat-checkbox>

          <mat-checkbox #subScribedGroupSelector
                        [disabled]="disableAll && !oneGroup"
                        [checked]="oneGroup"
                        (change)="onCheckboxChange('subscribedGroups', $event.checked)"
                        *ngIf="
    getTranslation$('showSubscribedGroups') | async as showSubScribedGroupsLabel
  ">
            {{ showSubScribedGroupsLabel }}
          </mat-checkbox>

          <mat-checkbox #fixedGroupSelector
                        [disabled]="disableAll && !showFixedGroup"
                        [checked]="showFixedGroup"
                        (change)="onCheckboxChange('fixedGroup', $event.checked)"
                        *ngIf="
    getTranslation$('showOnlyFixedGroup') | async as showOnlyFixedGroupLabel
  ">
            {{ showOnlyFixedGroupLabel }}
          </mat-checkbox>

          <mat-form-field appearance="outline" *ngIf="showFixedGroup">
            <mat-label *ngIf="getTranslation$('fixedGroup') | async as fixedGroupLabel">{{ fixedGroupLabel }}</mat-label>
            <mat-select formControlName="oneGroup">
              <mat-option *ngIf="getTranslation$('none') | async as noneLabel">{{ noneLabel }}</mat-option>
              <mat-option *ngFor="let group of interestGroups"
                          [value]="group.id">
                {{ group.title }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
