<div class="w-100 h-100">
  <processdelight-calendar-webpart-view
    *ngIf="
      _webpartViewbox &&
        (_webpartViewbox.width < 6 || _webpartViewbox.height < 5);
      else fullCalendar
    "
    [calendarItems]="calendarItems"
    [webpartViewBox]="_webpartViewbox"
    [isLoading]="isLoading"
    (dateChanged)="onDateChange($event)"
    (openCalendarPopUp)="openCalendarPopUp($event)"
  ></processdelight-calendar-webpart-view>
  <ng-template #fullCalendar>
    <processdelight-calendar
      [calenderItems]="calendarItems"
      (dateChanged)="onDateChange($event)"
      [selectedDate]="selectedDate"
      [calendarHeight]="calendarHeight"
      [hourBlocks]="hourBlocks"
      [currentView]="'week'"
      [splitMultipleDayEventsWeekView]="false"
    >
    </processdelight-calendar>
  </ng-template>
</div>
