import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractWebPartConfigComponent } from 'processdelight-angular-components';

@Component({
  selector: 'app-feed-config',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './feed-config.component.html',
  styleUrls: ['./feed-config.component.scss'],
})
export class FeedConfigComponent extends AbstractWebPartConfigComponent {}
