import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import {
  ChoiceFilterOption,
  DashboardComponent,
  DataComponentFacade,
  DataProperty,
  DataSourceService,
  EntityChoiceFilterComponent,
  EntityColumnDef,
  EntityDashboardComponent,
  EntityDateFilterComponent,
  EntityDefaultCellsComponent,
  EntityDefaultFiltersComponent,
  EntityFacade,
  EntityFilter,
  EntityFilterValue,
  EntityFormData,
  EntityNumberFilterComponent,
  EntityObject,
  EntityStringFilterComponent,
  GroupUser,
  InterestGroup,
  ObjectRelationPropertyConfiguration,
  Project,
  RelationFieldTable,
  RelationObjectValue,
  Skill,
  StateEntity,
  Status,
  TaskType,
  TranslationService,
} from 'processdelight-angular-components';
import {
  Subject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  takeUntil,
} from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { Memoize, clear as memoizeClear } from 'typescript-memoize';
import { FunctionsService } from '../services/functions.service';
import { UserSettingsService } from '../services/user-settings.service';
import { Task } from '../domain/models/task.model';
import { TasksWebpartUserSettings } from '../domain/models/tasks-webpart-user-settings.model';
import { TaskService } from '../services/task.service';
import { TasksSwitcherComponent } from '../tasks-switcher/tasks-switcher.component';
import { FormControl } from '@angular/forms';
import { ResourceThing } from '../domain/models/resource-thing.model';
import { ResourceUser } from '../domain/models/resource-user.model';
import { OrganizationFacade } from 'src/app/core/store/organization/organization.facade';
import { PortalService } from 'src/app/core/services/portal.service';
import { TaskInfoComponent } from '../tasks-detail/task-info/task-info.component';
import { MeetingTaskInfoComponent } from '../tasks-detail/meeting-task-info/meeting-task-info.component';

@Component({
  selector: 'app-tasks-list-dialog',
  templateUrl: './tasks-list-dialog.component.html',
  styleUrls: ['./tasks-list-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    EntityDashboardComponent,
    EntityDefaultCellsComponent,
    EntityDefaultFiltersComponent,
    TasksSwitcherComponent,
    MatIconModule,
    MatDialogModule,
    MatSnackBarModule,
    RouterModule,
    MatCardModule,
    MatButtonModule,
    MatProgressSpinnerModule,
  ],
})
export class TasksListDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  getInterestGroupFilterActive() {
    return (
      this.dataSourceService.getData('filteredInterestGroups') != undefined
    );
  }

  focusInput(input: HTMLInputElement) {
    setTimeout(() => {
      input.focus();
    }, 0);
  }

  @Output() taskClicked = new EventEmitter<Task>();

  orderBy!: string;
  orderByDirection: 'asc' | 'desc' | '' = 'desc';
  pageSize = 100;
  page = 0;
  highestPage = 0;
  entities: EntityObject<Task>[] = [];
  totalRecordCount = 0;
  filters: EntityFilter<EntityObject<Task>>[] = [];
  pagedEntities: EntityObject<Task>[] = [];
  peopleAndGroups: GroupUser[] = [];
  interestGroups: InterestGroup[] = [];
  resourceThings: ResourceThing[] = [];
  resourceUsers: ResourceUser[] = [];
  groupUsers: GroupUser[] = [];
  taskTypes: TaskType[] = [];
  statusses: Status[] = [];
  skills: Skill[] = [];
  projects: Project[] = [];
  allTasks: Task[] = [];
  relationFieldTables: RelationFieldTable[] = [];
  formTemplates: { [formId: string]: EntityObject<Task> } = {};
  firstSortChange = true;

  possibleColumns: EntityColumnDef<EntityObject<Task>>[] = [];
  selectedColumns: string[] = [];
  view?: string[];
  loading = false;
  initialized = false;
  destroy$ = new Subject<void>();
  reset$ = new Subject<void>();

  pagingCalls = 0;
  assignedToMe: FormControl<boolean | null> = new FormControl(null);
  lastPageCall?: () => void;
  itemTrackByFn: (index: number, item: EntityObject<Task>) => string = (_, item) => item.id;

  @ViewChild(DashboardComponent) dashboard!: DashboardComponent<Task>;
  @ViewChild(EntityDefaultFiltersComponent, { static: true }) defaultFilters!: EntityDefaultFiltersComponent;
  @ViewChild(EntityDefaultCellsComponent, { static: true }) defaultCells!: EntityDefaultCellsComponent<EntityObject<Task>>;

  userSettingsUpdateSubject = new Subject<void>();

  constructor(
    private readonly functions: FunctionsService,
    private readonly taskService: TaskService,
    private readonly portalService: PortalService,
    private dataPropertyFacade: DataComponentFacade,
    private entityFacade: EntityFacade,
    private readonly orgFacade: OrganizationFacade,
    private readonly matDialog: MatDialog,
    private readonly snackbar: MatSnackBar,
    private readonly translationService: TranslationService,
    private readonly userSettingsService: UserSettingsService,
    private readonly dataSourceService: DataSourceService,
    public dialogRef: MatDialogRef<TasksListDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      entities: EntityObject<Task>[];
      totalRecordCount: number;
      filters: EntityFilter<EntityObject<Task>>[];
      webpartId?: string;
      filteredInterestGroupIds?: string[];
    }
  ) {}

  getTranslation(label: string): string {
    return this.translationService.getTranslation(label);
  }
  getTranslation$(label: string) {
    return this.translationService.getTranslation$(label);
  }

  ngOnInit(): void {
    this.filters = this.data.filters;
    this.entities = this.data.entities;
    this.pagedEntities = this.data.entities;
    this.totalRecordCount = this.data.totalRecordCount;
    this.assignedToMe.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.initialized) {
          this.userSettingsUpdateSubject.next();
        }

        this.page = 0;
        this.highestPage = 0;
        this.getTaskEntities(true);
      });

    this.dataSourceService.getData$<RelationFieldTable[]>('relationFieldTables')
      .pipe(takeUntil(this.destroy$))
      .subscribe((relationFieldTables) => {
        this.relationFieldTables = relationFieldTables ?? [];
      });
    this.dataSourceService.getData$<GroupUser[]>('peopleAndGroups')
      .pipe(takeUntil(this.destroy$))
      .subscribe((peopleAndGroups) => {
        this.peopleAndGroups = peopleAndGroups ?? [];
      });
    this.dataSourceService.getData$<InterestGroup[]>('interestGroups')
      .pipe(takeUntil(this.destroy$))
      .subscribe((interestGroups) => {
        this.interestGroups = interestGroups ?? [];
      });

    this.taskService.getSkills()
      .pipe(takeUntil(this.destroy$))
      .subscribe((skills) => {
        this.skills = skills ?? [];
      });
    this.taskService.getProjects()
      .pipe(takeUntil(this.destroy$))
      .subscribe((projects) => {
        this.projects = projects ?? [];
      });
    this.taskService.getAllTasks()
      .pipe(takeUntil(this.destroy$))
      .subscribe((tasks) => {
        this.allTasks = tasks ?? [];
      });
    this.taskService.getStatusses()
      .pipe(takeUntil(this.destroy$))
      .subscribe((statusses) => {
        this.statusses = statusses ?? [];
      });
    this.taskService.getTaskTypes()
      .pipe(takeUntil(this.destroy$))
      .subscribe((taskTypes) => {
        this.taskTypes = taskTypes ?? [];
      });
    this.taskService.getResourceThings()
      .pipe(takeUntil(this.destroy$))
      .subscribe((resourceThings) => {
        this.resourceThings = resourceThings ?? [];
      });
    this.taskService.getResourceUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((resourceUsers) => {
        this.resourceUsers = resourceUsers ?? [];
      });
    this.orgFacade.getGroupUsers$()
      .pipe(takeUntil(this.destroy$))
      .subscribe();
    this.dataPropertyFacade.getDataProperties$()
      .pipe(takeUntil(this.destroy$))
      .subscribe();
    this.entityFacade.getFormTemplates$(StateEntity.Task)
      .pipe(takeUntil(this.destroy$))
      .subscribe();

    this.userSettingsUpdateSubject
      .asObservable()
      .pipe(takeUntil(this.destroy$), debounceTime(500))
      .subscribe(() => this.updateUserSettings());
  }

  ngAfterViewInit() {
    const userSettings = this.data.webpartId
      ? this.userSettingsService.getSettings(this.data.webpartId)
      : new TasksWebpartUserSettings();

    this.pageSize = userSettings.pageSize ?? 100;
    this.orderBy = userSettings.sortColumn ?? 'Number';
    this.orderByDirection = userSettings.sortDirection ?? 'desc';  

    combineLatest([
      this.entityFacade.formTemplates$<Task>(StateEntity.Task)
        .pipe(distinctUntilChanged()),
      this.orgFacade.users$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([templates, users]) => {
        this.possibleColumns = [
          new EntityColumnDef({
            internalName: 'Number',
            displayName: 'Task number',
            sortable: true,
            width: userSettings.columnSizes['Number'],
            cellClass: 'ellipsis',
            cellTemplate: this.defaultCells.valueCell,
            templateData: {
              valueAccessor: (entityObject: EntityObject<Task>) =>
                entityObject.entity?.number,
            },
            internal: true,
            filter: {
              filterTemplate: this.defaultFilters.stringFilter,
              filterChipAccessor: (value) =>
                EntityStringFilterComponent.buildChipString(value),
              filterQueryAccessor: (value) =>
                EntityStringFilterComponent.buildFilterString('Number', value),
            },
          }),
          new EntityColumnDef({
            internalName: 'Title',
            displayName: 'Task name',
            sortable: true,
            width: userSettings.columnSizes['Title'],
            cellClass: 'ellipsis',
            cellTemplate: this.defaultCells.valueCell,
            templateData: {
              valueAccessor: (entityObject: EntityObject<Task>) =>
                entityObject.entity?.title,
            },
            internal: true,
            filter: {
              filterTemplate: this.defaultFilters.stringFilter,
              filterChipAccessor: (value) =>
                EntityStringFilterComponent.buildChipString(value),
              filterQueryAccessor: (value) =>
                EntityStringFilterComponent.buildFilterString('Title', value),
            },
          }),
          new EntityColumnDef({
            internalName: 'Description',
            displayName: 'Description',
            sortable: true,
            width: userSettings.columnSizes['Decription'],
            cellClass: 'ellipsis',
            cellTemplate: this.defaultCells.valueCell,
            templateData: {
              valueAccessor: (entityObject: EntityObject<Task>) =>
                entityObject.entity?.description,
            },
            internal: true,
            filter: {
              filterTemplate: this.defaultFilters.stringFilter,
              filterChipAccessor: (value) =>
                EntityStringFilterComponent.buildChipString(value),
              filterQueryAccessor: (value) =>
                EntityStringFilterComponent.buildFilterString(
                  'Description',
                  value
                ),
            },
          }),
          new EntityColumnDef({
            internalName: 'StartTime',
            displayName: 'Start Date',
            sortable: true,
            width: userSettings.columnSizes['StartTime'],
            cellClass: 'ellipsis',
            cellTemplate: this.defaultCells.valueCell,
            templateData: {
              valueAccessor: (entityObject: EntityObject<Task>) =>
                entityObject.entity?.startTime?.toFormat('dd/MM/yyyy HH:mm') ??
                '',
            },
            internal: true,
            filter: {
              filterTemplate: this.defaultFilters.dateFilter,
              filterChipAccessor: (value) =>
                EntityDateFilterComponent.buildChipString(
                  value,
                  'dd/MM/yyyy HH:mm'
                ),
              filterQueryAccessor: (value) =>
                EntityDateFilterComponent.buildFilterString('StartTime', value),
              templateData: {
                dateFormat: 'dd/MM/yyyy HH:mm',
              },
            },
          }),
          new EntityColumnDef({
            internalName: 'Deadline',
            displayName: 'Deadline',
            sortable: true,
            width: userSettings.columnSizes['Deadline'],
            cellClass: 'ellipsis',
            cellTemplate: this.defaultCells.valueCell,
            templateData: {
              valueAccessor: (entityObject: EntityObject<Task>) =>
                entityObject.entity?.deadline?.toFormat('dd/MM/yyyy HH:mm') ??
                '',
            },
            internal: true,
            filter: {
              filterTemplate: this.defaultFilters.dateFilter,
              filterChipAccessor: (value) =>
                EntityDateFilterComponent.buildChipString(
                  value,
                  'dd/MM/yyyy HH:mm'
                ),
              filterQueryAccessor: (value) =>
                EntityDateFilterComponent.buildFilterString('Deadline', value),
              templateData: {
                dateFormat: 'dd/MM/yyyy HH:mm',
              },
            },
          }),
          new EntityColumnDef({
            internalName: 'EndTime',
            displayName: 'End Date',
            sortable: true,
            width: userSettings.columnSizes['EndTime'],
            cellClass: 'ellipsis',
            cellTemplate: this.defaultCells.valueCell,
            templateData: {
              valueAccessor: (entityObject: EntityObject<Task>) =>
                entityObject.entity?.endTime?.toFormat('dd/MM/yyyy HH:mm') ??
                '',
            },
            internal: true,
            filter: {
              filterTemplate: this.defaultFilters.dateFilter,
              filterChipAccessor: (value) =>
                EntityDateFilterComponent.buildChipString(
                  value,
                  'dd/MM/yyyy HH:mm'
                ),
              filterQueryAccessor: (value) =>
                EntityDateFilterComponent.buildFilterString('EndTime', value),
              templateData: {
                dateFormat: 'dd/MM/yyyy HH:mm',
              },
            },
          }),
          new EntityColumnDef({
            internalName: 'IsTeamsMeeting',
            displayName: 'Is teams meeting?',
            sortable: true,
            width: userSettings.columnSizes['IsTeamsMeeting'],
            cellClass: 'ellipsis',
            cellTemplate: this.defaultCells.valueCell,
            templateData: {
              valueAccessor: (entityObject: EntityObject<Task>) =>
                entityObject.entity?.isTeamsMeeting,
            },
            internal: true,
            filter: {
              filterTemplate: this.defaultFilters.choiceFilter,
              filterChipAccessor: (value) =>
                EntityChoiceFilterComponent.buildChipString(value),
              filterQueryAccessor: (value) =>
                EntityChoiceFilterComponent.buildFilterString(
                  'IsTeamsMeeting',
                  value
                ),
              templateData: {
                options: [
                  { internalValue: 'true', displayValue: 'Yes' },
                  { internalValue: 'false', displayValue: 'No' },
                ],
              },
            },
          }),
          new EntityColumnDef({
            internalName: 'Location',
            displayName: 'Location',
            sortable: true,
            width: userSettings.columnSizes['Location'],
            cellClass: 'ellipsis',
            cellTemplate: this.defaultCells.valueCell,
            templateData: {
              valueAccessor: (entityObject: EntityObject<Task>) =>
                entityObject.entity?.location,
            },
            internal: true,
            filter: {
              filterTemplate: this.defaultFilters.stringFilter,
              filterChipAccessor: (value) =>
                EntityStringFilterComponent.buildChipString(value),
              filterQueryAccessor: (value) =>
                EntityStringFilterComponent.buildFilterString(
                  'Location',
                  value
                ),
            },
          }),
          new EntityColumnDef({
            internalName: 'FormId',
            displayName: 'Form template',
            sortable: true,
            width: userSettings.columnSizes['FormId'],
            cellClass: 'ellipsis',
            cellTemplate: this.defaultCells.valueCell,
            templateData: {
              valueAccessor: (entityObject: EntityObject<Task>) =>
                this.formTemplates[entityObject.formId].form?.name ?? '',
            },
            internal: true,
            filter: {
              filterTemplate: this.defaultFilters.choiceFilter,
              filterChipAccessor: (value) =>
                EntityChoiceFilterComponent.buildChipString(value),
              filterQueryAccessor: (value) =>
                EntityChoiceFilterComponent.buildFilterString('FormId', value),
              templateData: {
                options: Object.values(this.formTemplates)
                  .map((record) => ({
                    internalValue: record.formId,
                    displayValue: record.form?.name,
                  })),
              },
            },
          }),
          new EntityColumnDef({
            internalName: 'TypeId',
            displayName: 'Type',
            sortable: true,
            width: userSettings.columnSizes['TypeId'],
            cellClass: 'ellipsis',
            cellTemplate: this.defaultCells.valueCell,
            templateData: {
              valueAccessor: (entityObject: EntityObject<Task>) =>
                entityObject.entity?.type?.type,
            },
            internal: true,
            filter: {
              filterTemplate: this.defaultFilters.choiceFilter,
              filterChipAccessor: (value) =>
                EntityChoiceFilterComponent.buildChipString(value),
              filterQueryAccessor: (value) =>
                EntityChoiceFilterComponent.buildFilterString('TypeId', value),
              templateData: {
                options: this.taskTypes
                  .map((tt) => ({
                    internalValue: tt.id,
                    displayValue: tt.type,
                  })),
              },
            },
          }),
          new EntityColumnDef({
            internalName: 'StatusId',
            displayName: 'Status',
            sortable: true,
            width: userSettings.columnSizes['StatusId'],
            cellClass: 'ellipsis',
            cellTemplate: this.defaultCells.valueCell,
            templateData: {
              valueAccessor: (entityObject: EntityObject<Task>) =>
                entityObject.entity?.status?.status,
            },
            internal: true,
            filter: {
              filterTemplate: this.defaultFilters.choiceFilter,
              filterChipAccessor: (value) =>
                EntityChoiceFilterComponent.buildChipString(value),
              filterQueryAccessor: (value) =>
                EntityChoiceFilterComponent.buildFilterString(
                  'StatusId',
                  value
                ),
              templateData: {
                options: this.statusses
                  .map((s) => ({
                    internalValue: s.id,
                    displayValue: s.status,
                  })),
              },
            },
          }),
          new EntityColumnDef({
            internalName: 'SkillId',
            displayName: 'Skill',
            sortable: true,
            width: userSettings.columnSizes['SkillId'],
            cellClass: 'ellipsis',
            cellTemplate: this.defaultCells.valueCell,
            templateData: {
              valueAccessor: (entityObject: EntityObject<Task>) =>
                entityObject.entity?.skill?.title,
            },
            internal: true,
            filter: {
              filterTemplate: this.defaultFilters.choiceFilter,
              filterChipAccessor: (value) =>
                EntityChoiceFilterComponent.buildChipString(value),
              filterQueryAccessor: (value) =>
                EntityChoiceFilterComponent.buildFilterString('SkillId', value),
              templateData: {
                options: this.skills
                  .map((s) => ({
                    internalValue: s.id,
                    displayValue: s.title,
                  })),
              },
            },
          }),
          new EntityColumnDef({
            internalName: 'ProjectId',
            displayName: 'Project',
            sortable: true,
            width: userSettings.columnSizes['ProjectId'],
            cellClass: 'ellipsis',
            cellTemplate: this.defaultCells.valueCell,
            templateData: {
              valueAccessor: (entityObject: EntityObject<Task>) =>
                entityObject.entity?.project?.projectName,
            },
            internal: true,
            filter: {
              filterTemplate: this.defaultFilters.choiceFilter,
              filterChipAccessor: (value) =>
                EntityChoiceFilterComponent.buildChipString(value),
              filterQueryAccessor: (value) =>
                EntityChoiceFilterComponent.buildFilterString(
                  'ProjectId',
                  value
                ),
              templateData: {
                options: this.projects
                  .map((p) => ({
                    internalValue: p.id,
                    displayValue: p.projectName,
                  })),
              },
            },
          }),
          new EntityColumnDef({
            internalName: 'ParentTaskId',
            displayName: 'ParentTask',
            sortable: true,
            width: userSettings.columnSizes['ParentTaskId'],
            cellClass: 'ellipsis',
            cellTemplate: this.defaultCells.valueCell,
            templateData: {
              valueAccessor: (entityObject: EntityObject<Task>) =>
                entityObject.entity?.parentTask?.title,
            },
            internal: true,
            filter: {
              filterTemplate: this.defaultFilters.choiceFilter,
              filterChipAccessor: (value) =>
                EntityChoiceFilterComponent.buildChipString(value),
              filterQueryAccessor: (value) =>
                EntityChoiceFilterComponent.buildFilterString(
                  'ParentTaskId',
                  value
                ),
              templateData: {
                options: this.allTasks
                  .map((t) => ({
                    internalValue: t.id,
                    displayValue: t.title,
                  })),
              },
            },
          }),
          new EntityColumnDef({
            internalName: 'Attendees',
            displayName: 'Attendees',
            sortable: true,
            width: userSettings.columnSizes['Attendees'],
            cellClass: 'ellipsis',
            cellTemplate: this.defaultCells.valueCell,
            templateData: {
              valueAccessor: (entityObject: EntityObject<Task>) =>
                entityObject.entity?.attendees?.map((a) => {
                  const user = users?.find(
                    (u) => u.user?.id === a.userId
                  );
                  return user?.user?.displayName ?? '';
                }).join('; '),
            },
            internal: true,
            filter: {
              filterTemplate: this.defaultFilters.choiceFilter,
              filterChipAccessor: (value) =>
                EntityChoiceFilterComponent.buildChipString(value),
              filterQueryAccessor: (value) =>
                EntityChoiceFilterComponent.buildFilterString(
                  'Attendees',
                  value
                ),
              templateData: {
                options: users?.filter((u) => u.user)
                  .map((u) => ({
                    internalValue: u.user ? u.user.id : '',
                    displayValue: u.user ? u.user.displayName : '',
                  })),
              },
            },
          }),
          new EntityColumnDef({
            width: userSettings.columnSizes['ResourceUsers'],
            cellClass: 'ellipsis',
            internalName: 'ResourceUsers',
            displayName: 'Assigned to',
            sortable: true,
            cellTemplate: this.defaultCells.valueCell,
            templateData: {
              valueAccessor: (entityObject: EntityObject<Task>) => {
                const names: string[] = [];
                const resourceUserIds = entityObject.entity?.resources?.map((r) => r.resourceUserId);

                if (resourceUserIds) {
                  resourceUserIds.forEach((resourceUserId) => {
                    const resourceUser = this.resourceUsers
                      .find((ru) => ru.id == resourceUserId);
                    const user = users?.find(
                      (u) => u.user?.id === resourceUser?.userId
                    );
                    names.push(user?.user?.displayName ?? '');
                  });
                  return names.join('; ');
                } else {
                  return '';
                }
              },
            },
            internal: true,
            filter: {
              filterTemplate: this.defaultFilters.choiceFilter,
              filterChipAccessor: (value) =>
                EntityChoiceFilterComponent.buildChipString(value),
              filterQueryAccessor: (value) =>
                EntityChoiceFilterComponent.buildFilterString(
                  'ResourceUsers',
                  value
                ),
              templateData: {
                options: this.resourceUsers
                  .map((ru) => {
                    const user = users?.find(
                      (u) => u.user?.id === ru.userId
                    );
                    return {
                      internalValue: ru.id,
                      displayValue: user?.user?.displayName ?? '',
                    };
                  }),
              },
            },
          }),
          new EntityColumnDef({
            internalName: 'ResourceFunctions',
            displayName: 'Assigned machines',
            sortable: true,
            width: userSettings.columnSizes['ResourceFunctions'],
            cellClass: 'ellipsis',
            cellTemplate: this.defaultCells.valueCell,
            templateData: {
              valueAccessor: (entityObject: EntityObject<Task>) => {
                const functions: string[] = [];
                const functionIds = entityObject.entity?.resources?.map((r) => r.functionId ?? '');

                if (functionIds) {
                  functionIds.forEach((functionId) => {
                    const resourceThing = this.resourceThings
                      .find((rt) =>
                        rt.resourceFunctions?.map((rf) => rf.id).includes(functionId)
                      );
                    const resourceFunction = resourceThing?.resourceFunctions?.find((rf) => rf.id === functionId);
                    functions.push(resourceFunction?.name ?? '');
                  });

                  return functions.join('; ');
                } else {
                  return '';
                }
              },
            },
            internal: true,
            filter: {
              filterTemplate: this.defaultFilters.choiceFilter,
              filterChipAccessor: (value) =>
                EntityChoiceFilterComponent.buildChipString(value),
              filterQueryAccessor: (value) =>
                EntityChoiceFilterComponent.buildFilterString(
                  'ResourceFunctions',
                  value
                ),
              templateData: {
                options: this.resourceThings
                  .map((rt) => ({
                    internalValue: rt.id,
                    displayValue: rt.name,
                  })),
              },
            },
          }),
          new EntityColumnDef({
            internalName: 'CreatedOn',
            displayName: 'Created on',
            sortable: true,
            width: userSettings.columnSizes['CreatedOn'],
            cellClass: 'ellipsis',
            cellTemplate: this.defaultCells.valueCell,
            templateData: {
              valueAccessor: (entityObject: EntityObject<Task>) =>
                entityObject.entity?.createdOn?.toFormat('dd/MM/yyyy HH:mm') ?? '',
            },
            internal: true,
            filter: {
              filterTemplate: this.defaultFilters.dateFilter,
              filterChipAccessor: (value) =>
                EntityDateFilterComponent.buildChipString(
                  value,
                  'dd/MM/yyyy HH:mm'
                ),
              filterQueryAccessor: (value) =>
                EntityDateFilterComponent.buildFilterString('CreatedOn', value),
              templateData: {
                dateFormat: 'dd/MM/yyyy HH:mm',
              },
            },
          }),
          new EntityColumnDef({
            internalName: 'CreatedBy',
            displayName: 'Created by',
            sortable: true,
            width: userSettings.columnSizes['CreatedBy'],
            cellClass: 'ellipsis',
            cellTemplate: this.defaultCells.valueCell,
            templateData: {
              valueAccessor: (entityObject: EntityObject<Task>) =>
                entityObject.entity?.createdBy?.displayName,
            },
            internal: true,
            filter: {
              filterTemplate: this.defaultFilters.choiceFilter,
              filterChipAccessor: (value) =>
                EntityChoiceFilterComponent.buildChipString(value),
              filterQueryAccessor: (value) =>
                EntityChoiceFilterComponent.buildFilterString(
                  'CreatedById',
                  value
                ),
              templateData: {
                options: users?.map((u) => ({
                  internalValue: u.id,
                  displayValue: u.displayName,
                })),
              },
            },
          }),
          new EntityColumnDef({
            internalName: 'ModifiedOn',
            displayName: 'Modified on',
            sortable: true,
            width: userSettings.columnSizes['ModifiedOn'],
            cellClass: 'ellipsis',
            cellTemplate: this.defaultCells.valueCell,
            templateData: {
              valueAccessor: (entityObject: EntityObject<Task>) =>
                entityObject.entity?.modifiedOn?.toFormat('dd/MM/yyyy HH:mm') ??
                '',
            },
            internal: true,
            filter: {
              filterTemplate: this.defaultFilters.dateFilter,
              filterChipAccessor: (value) =>
                EntityDateFilterComponent.buildChipString(
                  value,
                  'dd/MM/yyyy HH:mm'
                ),
              filterQueryAccessor: (value) =>
                EntityDateFilterComponent.buildFilterString(
                  'ModifiedOn',
                  value
                ),
              templateData: {
                dateFormat: 'dd/MM/yyyy HH:mm',
              },
            },
          }),
          new EntityColumnDef({
            internalName: 'ModifiedBy',
            displayName: 'Modified by',
            sortable: true,
            width: userSettings.columnSizes['ModifiedBy'],
            cellClass: 'ellipsis',
            cellTemplate: this.defaultCells.valueCell,
            templateData: {
              valueAccessor: (entityObject: EntityObject<Task>) =>
                entityObject.entity?.modifiedBy?.displayName,
            },
            internal: true,
            filter: {
              filterTemplate: this.defaultFilters.choiceFilter,
              filterChipAccessor: (value) =>
                EntityChoiceFilterComponent.buildChipString(value),
              filterQueryAccessor: (value) =>
                EntityChoiceFilterComponent.buildFilterString(
                  'ModifiedById',
                  value
                ),
              templateData: {
                options: users?.map((u) => ({
                  internalValue: u.id,
                  displayValue: u.displayName,
                })),
              },
            },
          }),
        ];

        if (templates) {
          this.formTemplates = templates;
          const dataProperties: DataProperty[] = Object.keys(templates)
            .flatMap((id) =>
              templates[id].dataObjects?.flatMap((dataObject) =>
                dataObject.dataPropertyId
                  ? [
                    this.dataPropertyFacade.getPropertyById(
                      dataObject.dataPropertyId
                    )!,
                  ]
                  : []
              )
            )
            .filter((v, i, a) => a.findIndex((t) => t?.id === v?.id) === i)
            .filter((t) => !!t) as DataProperty[];
          dataProperties.map((dataProperty) => {
            const filterTemplate = this.mapDataPropertyToFilterTemplate(dataProperty);
            this.possibleColumns.push(
              new EntityColumnDef({
                internalName: dataProperty?.id,
                displayName: dataProperty?.name,
                sortable: this.isDataPropertySortable(dataProperty),
                cellTemplate: this.mapDataPropertyToCellTemplate(dataProperty),
                templateData: this.mapDataPropertyToTemplateData(dataProperty),
                filter: filterTemplate
                  ? {
                    filterTemplate: filterTemplate,
                    filterChipAccessor: (value) =>
                      this.mapDataPropertyToChipString(dataProperty, value),
                    filterQueryAccessor: (value) =>
                      this.mapDataPropertyToFilterString(
                        dataProperty,
                        dataProperty.id,
                        value
                      ),
                    templateData: {
                      options: this.getDataPropertyChoices(dataProperty),
                    },
                  }
                  : undefined,
                internal: false,
              })
            );
          });
          this.possibleColumns = this.possibleColumns.sort((a, b) =>
            a.displayName?.localeCompare(b.displayName)
          );
          this.view = userSettings.columnOrder.filter((c) =>
            this.possibleColumns.some((p) => p.internalName == c)
          );
  
          if (!this.view.length) {
            this.selectedColumns = ['Number', 'Title'];
            this.view = this.selectedColumns;
  
            if (this.data.webpartId) {
              this.userSettingsService.setSettings(
                this.data.webpartId,
                new TasksWebpartUserSettings({
                  columnOrder: this.view,
                  sortColumn: this.orderBy,
                  sortDirection: this.orderByDirection,
                  pageSize: this.pageSize,
                  columnSizes: this.possibleColumns
                    .filter((c) => c.width)
                    .reduce(
                      (acc, c) => ({ ...acc, [c.internalName]: c.width }),
                      {}
                    ),
                })
              );
            }
          } else {
            this.selectedColumns = [...this.possibleColumns.map((c) => c.internalName)]
              .filter((c) => this.view!.includes(c))
              .sort((a, b) => this.view!.indexOf(a) - this.view!.indexOf(b));
          }
        }

        if (!this.initialized) {
          this.initialized = true;
          this.assignedToMe.patchValue(userSettings.assignedToMe);
        }

        memoizeClear([EntityDashboardComponent.DASHBOARD_CELL_MEMOIZE_TAG]);
      });
  }

  ngOnDestroy(): void {
    this.reset$.next();
    this.reset$.complete();
    this.destroy$.next();
    this.destroy$.complete();
    this.userSettingsUpdateSubject.complete();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  openDetailDialog(entityObject?: EntityObject<Task>) {
    if (entityObject?.entity?.type?.type === "Meeting") {
      this.matDialog.open(MeetingTaskInfoComponent, {
        panelClass: 'client-form-dialog',
        data: new EntityFormData<Task>({
          entityObject: entityObject,
          disabled: true,
          new: false,
        }),
        autoFocus: false,
        width: '70%',
        disableClose: true,
      });
    } else {
      this.matDialog.open(TaskInfoComponent, {
        panelClass: 'client-form-dialog',
        data: new EntityFormData<Task>({
          entityObject: entityObject,
          disabled: true,
          new: false,
        }),
        autoFocus: false,
        width: '70%',
        disableClose: true,
      });
    }
  }

  getTaskEntities(resetPaging?: boolean, callback?: () => void) {
    this.loading = true;
    const col = this.possibleColumns.find(
      (c) => c.internalName === this.orderBy
    );

    if (resetPaging) {
      this.totalRecordCount = 0;
      this.reset$.next();
      this.pagingCalls = 1;
    } else {
      this.pagingCalls++;
    }

    this.lastPageCall = callback;
    this.taskService.getTaskEntities(
      this.assignedToMe.value ?? false,
      col?.internal ?? false,
      this.orderBy,
      this.orderByDirection,
      this.filters,
      this.pageSize,
      this.page,
      this.getInterestGroupFilterActive(),
      this.data.filteredInterestGroupIds
    )
      .subscribe((entities) => {
        this.reset$.asObservable();
        this.pagingCalls--;
        this.entities = resetPaging
          ? [...entities.result]
          : [...this.entities, ...entities.result];
        this.pagedEntities = this.entities
          .filter(
            (_, index) => index >= this.page * this.pageSize 
              && index < (this.page + 1) * this.pageSize
          );

        if (this.pagingCalls == 0) {
          this.loading = false;
          if (this.lastPageCall) this.lastPageCall();
        }

        this.totalRecordCount = entities.totalRecordCount;
        this.selectedColumns = [...this.possibleColumns.map((c) => c.internalName),]
          .filter((c) => this.view?.includes(c))
          .sort((a, b) => this.view!.indexOf(a) - this.view!.indexOf(b));
      });
  }

  filterChanged(event: EntityFilter<EntityObject<Task>>[]) {
    if ((this.filters.length != 0 || event.length != 0) 
      && this.filters != event) {
      this.page = 0;
      this.highestPage = 0;
      this.filters = [...event];
      this.getTaskEntities(true);
    }
  }

  pageChanged(event: { pagesize: number; page: number }) {
    if (this.pageSize != event.pagesize) {
      const needNewTasks = event.pagesize > this.pageSize * (this.highestPage + 1);
      this.highestPage = Math.max(
        0,
        Math.floor((this.pageSize * (this.highestPage + 1)) / event.pagesize) - 1
      );
      this.pageSize = event.pagesize;
      this.userSettingsUpdateSubject.next();
      this.page = 0;

      if (needNewTasks) {
        this.getTaskEntities(true);
      } else {
        this.pagedEntities = this.entities
          .filter(
            (_, index) => index >= this.page * this.pageSize 
              && index < (this.page + 1) * this.pageSize
          );
      }
    } else {
      if (event.page > this.highestPage) {
        this.getTaskEntities(false, () => (this.highestPage = event.page));
      } else {
        this.pagedEntities = this.entities
          .filter(
            (_, index) => index >= event.page * this.pageSize 
              && index < (event.page + 1) * this.pageSize
          );
      }

      this.page = event.page;
    }
  }

  sortChanged(event: {
    sortedColumn: string;
    sortDirection: '' | 'asc' | 'desc';
  }) {
    if (this.orderBy != event.sortedColumn 
      || this.orderByDirection != event.sortDirection) {
      this.orderBy = event.sortedColumn;
      this.orderByDirection = event.sortDirection;

      if (this.initialized) {
        this.userSettingsUpdateSubject.next();
      }

      this.page = 0;
      this.highestPage = 0;
      this.getTaskEntities(true);
    }
  }

  columnChanged(columns: string[]) {
    // this.items = [...this.items];
    // this.pagedItems = this.items
    //   .filter(
    //     (_, index) =>
    //       index >= this.page * this.pageSize &&
    //       index < (this.page + 1) * this.pageSize
    //   )
    //   .slice(0, this.pageSize);
    this.view = columns;

    if (this.initialized) {
      this.userSettingsUpdateSubject.next();
    }
  }

  columnWidthChange(change: { column: string; width: number }) {
    this.possibleColumns.find((c) => c.internalName == change.column)!.width = change.width;

    if (this.initialized) {
      this.userSettingsUpdateSubject.next();
    }
  }
  
  onClose(): void {
    this.dialogRef.close();
  }

  updateUserSettings() {
    if (!this.data.webpartId) return;
    this.userSettingsService.setSettings(
      this.data.webpartId,
      new TasksWebpartUserSettings({
        columnOrder: this.view,
        sortColumn: this.orderBy,
        sortDirection: this.orderByDirection,
        pageSize: this.pageSize,
        columnSizes: this.possibleColumns
          .filter((c) => c.width)
          .reduce((acc, c) => ({ ...acc, [c.internalName]: c.width }), {}),
        assignedToMe: this.assignedToMe.value ?? false
      })
    );
  }

  @Memoize()
  getRelationFieldTableName(relatonFieldTableId: string) {
    return this.relationFieldTables
      .find(
        (rft) => rft.id === relatonFieldTableId
      )?.displayName;
  }

  private mapDataPropertyToFilterTemplate(dataProperty: DataProperty) {
    switch (dataProperty.propertyType.type) {
      case 'Object relation':
      case 'Text field':
      case 'Rich text':
      case 'Type relation':
      case 'Phonenumber':
        return this.defaultFilters.stringFilter;
      case 'Number':
        return this.defaultFilters.numberFilter;
      case 'Date':
        return this.defaultFilters.dateFilter;
      case 'Choices':
      case 'People':
        return this.defaultFilters.choiceFilter;
      default:
        return undefined;
    }
  }
  private mapDataPropertyToChipString(
    dataProperty: DataProperty,
    value: EntityFilterValue
  ) {
    switch (dataProperty.propertyType.type) {
      case 'Text field':
      case 'Rich text':
      case 'Object relation':
      case 'Type relation':
      case 'Phonenumber':
        return EntityStringFilterComponent.buildChipString(value);
      case 'Number':
        return EntityNumberFilterComponent.buildChipString(value);
      case 'Date':
        return EntityDateFilterComponent.buildChipString(
          value,
          dataProperty.format
        );
      case 'Choices':
      case 'People':
        return EntityChoiceFilterComponent.buildChipString(value);
      default:
        return '';
    }
  }
  private mapDataPropertyToFilterString(
    dataProperty: DataProperty,
    columnName: string,
    value: EntityFilterValue
  ) {
    switch (dataProperty.propertyType.type) {
      case 'Text field':
      case 'Rich text':
      case 'Object relation':
      case 'Type relation':
      case 'Phonenumber':
        return EntityStringFilterComponent.buildFilterString(columnName, value);
      case 'Number':
        return EntityNumberFilterComponent.buildFilterString(columnName, value);
      case 'Date':
        return EntityDateFilterComponent.buildFilterString(columnName, value);
      case 'Choices':
      case 'People':
        return EntityChoiceFilterComponent.buildFilterString(columnName, value);
      default:
        return '';
    }
  }

  private mapDataPropertyToCellTemplate(dataProperty: DataProperty) {
    if (dataProperty.propertyType.type === 'Object relation') {
      return this.defaultCells.lazyLoadedCell;
    }

    return this.defaultCells.valueCell;
  }
  
  private mapDataPropertyToTemplateData(dataProperty: DataProperty) {
    if (dataProperty.propertyType.type === 'Object relation') {
      const config = dataProperty.configuration as ObjectRelationPropertyConfiguration;
      const types = config.types;

      return {
        lazyLoadingKeyFn: (entityObject: EntityObject<Task>) => `object-relation-${dataProperty.id}-${entityObject.entity?.id}`,
        lazyLoaderFn: (entityObject: EntityObject<Task>) =>
          this.portalService.getLinkedObjectsByDataPropertyId(
            dataProperty.id,
            entityObject.entity?.id ?? ''
          ),
        valueAccessor: (relations?: RelationObjectValue[]) => {
          if (!relations) return '';
          return relations
            .map((relation) =>
              `${
                relation.type
                  ? `${types.find((t) => t.id == relation.type)?.name} - `
                  : ''
              }(${this.getRelationFieldTableName(
                relation.relationFieldTableId
              )}) ${relation.name}`
            )
            .join('; ');
        },
        trackByFn: (entityObject: EntityObject<Task>) => entityObject.entity?.id ?? '',
        //refreshData: this.refreshDataSubject.asObservable(),
      };
    }

    return {
      valueAccessor: (entityObject: EntityObject<Task>) => {
        const obj = entityObject.dataObjects?.find(
          (dataobj) => dataobj.dataPropertyId === dataProperty.id
        );
        return obj
          ? this.dataSourceService.GetValueOfObject(obj, dataProperty, '; ')
          : '';
      },
    };
  }

  private isDataPropertySortable(dataProperty: DataProperty): boolean {
    switch (dataProperty.propertyType.type) {
      case 'Object relation':
      case 'Type relation':
      case 'People':
        return false;
      default:
        return true;
    }
  }

  private getDataPropertyChoices(
    dataProperty: DataProperty
  ): ChoiceFilterOption[] {
    if (dataProperty.propertyType.type === 'Choices') {
      return dataProperty.choiceOptions.map((choice) => {
        return {
          internalValue: choice.id,
          displayValue: choice.name,
        };
      });
    } else if (dataProperty.propertyType.type === 'InterestGroups') {
      return this.interestGroups.map((ig) => {
        return {
          internalValue: ig.id,
          displayValue: ig.title,
        };
      });
    } else if (dataProperty.configuration) {
      switch (dataProperty.configuration.type) {
        case 'People':
          return this.peopleAndGroups
            .filter((pg) => pg.user)
            .map((pg) => {
              return {
                internalValue: pg.user ? pg.user.id : '',
                displayValue: pg.user ? pg.user.displayName : '',
              };
            });
        case 'Groups':
          return this.peopleAndGroups
            .filter((pg) => pg.group)
            .map((pg) => {
              return {
                internalValue: pg.group ? pg.group.groupId : '',
                displayValue: pg.group ? pg.group.displayName : '',
              };
            });
        default:
          return this.peopleAndGroups.map((pg) => {
            return {
              internalValue: pg.user
                ? pg.user.id
                : pg.group
                ? pg.group.groupId
                : '',
              displayValue: pg.user
                ? pg.user.displayName
                : pg.group
                ? pg.group.displayName
                : '',
            };
          });
      }
    } else return [];
  }

  private apps: { [key: string]: string } = {
    IshtarTask: AppComponent.IshtarTasksAppName,
  };
}
