import { CRMEntity } from 'processdelight-angular-components';
import { Group } from './group.model';
import { PortalUserVM } from './portal-user.model';
import { Role } from './role.model';

export class ClientUserInfo {
  id?: string;
  userId!: string;
  user!: PortalUserVM;
  clientId!: string;
  client!: CRMEntity;
  roles!: Role[];
  groups!: Group[];
  disabled = false;
  canJoinCommunityGroups = true;

  constructor(obj: Partial<ClientUserInfo>) {
    Object.assign(this, obj);
    if (obj?.user) this.user = new PortalUserVM(obj.user);
    if (obj?.client) this.client = new CRMEntity(obj.client);
    if (obj?.roles) this.roles = obj.roles.map((r) => new Role(r));
    if (obj?.groups) this.groups = obj.groups.map((g) => new Group(g));
  }
}
