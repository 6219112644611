<mat-tree
  [dataSource]="dataSource"
  [treeControl]="treeControl"
  class="tree scrollbar h-100 w-100 min-height-0 overflow-auto"
>
  <!-- This is the tree node template for leaf nodes -->
  <!-- There is inline padding applied to this node using styles.
      This padding value depends on the mat-icon-button width. -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
    <mat-card class="position-relative">
      <div
        class="colorStripe position-absolute start-0 top-0 h-100"
        style="width: 5px"
        [style.backgroundColor]="node.color"
      ></div>
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center w-100">
          <div class="d-flex flex-column ps-3">
            <div class="bold">{{ node.name }}</div>
            <div class="italic">{{ node.description }}</div>
          </div>
          <ng-container *ngIf="node.icon">
            <img
              class="list-icon m-2"
              [src]="transform(node.icon)"
              alt="Interest group icon"
            />
          </ng-container>
        </div>

        <button
          class="buttons flex-shrink-0"
          mat-raised-button
          [color]="node.joinable ? 'primary' : 'warn'"
          (click)="node.joinable ? joinGroup(node.id) : leaveGroup(node.id)"
        >
          {{ node.joinable ? getTranslation("join") : getTranslation("leave") }}
          <mat-icon class="material-icons-outlined">{{
            node.joinable ? "add" : "delete"
          }}</mat-icon>
        </button>
      </div>
    </mat-card>
  </mat-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
    <div class="mat-tree-node">
      <div class="d-flex align-items-center w-100">
        <button
          mat-icon-button
          matTreeNodeToggle
          [attr.aria-label]="'Toggle ' + node.name"
        >
          <mat-icon class="mat-icon-rtl-mirror">
            {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
          </mat-icon>
        </button>
        <mat-card class="position-relative">
          <div
            class="colorStripe position-absolute start-0 top-0 h-100"
            style="width: 5px"
            [style.backgroundColor]="node.color"
          ></div>
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center w-100">
              <div class="d-flex flex-column ps-3">
                <div class="bold">{{ node.name }}</div>
                <div class="italic">{{ node.description }}</div>
              </div>
              <ng-container *ngIf="node.icon">
                <img
                  class="list-icon m-2"
                  [src]="transform(node.icon)"
                  alt="Interest group icon"
                />
              </ng-container>
            </div>
            <button
              mat-raised-button
              class="buttons flex-shrink-0"
              [color]="node.joinable ? 'primary' : 'warn'"
              (click)="node.joinable ? joinGroup(node.id) : leaveGroup(node.id)"
            >
              {{
                node.joinable ? getTranslation("join") : getTranslation("leave")
              }}
              <mat-icon class="material-icons-outlined">{{
                node.joinable ? "add" : "logout"
              }}</mat-icon>
            </button>
          </div>
        </mat-card>
      </div>
    </div>
    <!-- There is inline padding applied to this div using styles.
            This padding value depends on the mat-icon-button width.  -->
    <div [class.tree-invisible]="!treeControl.isExpanded(node)" role="group">
      <ng-container matTreeNodeOutlet></ng-container>
    </div>
  </mat-nested-tree-node>
</mat-tree>
