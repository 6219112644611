<div class="d-flex flex-column m-2">
  <h2>Update metadata</h2>
  <div class="d-flex mt-2 flex-md-row flex-sm-column">
    <div class="h-100 w-100">
      <mat-form-field>
        <mat-label>Metadata parameter</mat-label>
        <mat-select
          [formControl]="paramSelectorControl"
          (opened)="focusInput(search)"
        >
          <div class="h-100 d-flex flex-column">
            <mat-form-field class="flex-shrink-0 no-subscript-wrapper">
              <mat-label>Search...</mat-label>
              <input type="text" matInput #search />
            </mat-form-field>
            <div class="d-flex flex-column scrollbar overflow-auto">
              <mat-option
                *ngFor="let param of metadataParameters"
                [value]="param.id"
                [hidden]="
                  !param.title
                    .toLowerCase()
                    .includes(search.value.toLowerCase())
                "
              >
                {{ param.title }}
              </mat-option>
            </div>
          </div>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="h-100 w-100 ms-sm-0 ms-md-2 mt-sm-2 mt-md-0">
      <ng-container
        *ngIf="paramSelectorControl.value"
        [ngSwitch]="selectedParam?.type"
      >
        <ng-container *ngSwitchCase="'Number'">
          <mat-form-field>
            <mat-label>Value</mat-label>
            <input
              matInput
              [formControl]="valueSelectorControl"
              type="number"
            />
          </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="'Text'">
          <mat-form-field>
            <mat-label>Value</mat-label>
            <input matInput [formControl]="valueSelectorControl" />
          </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="'DateTime'">
          <mat-form-field>
            <mat-label>Value</mat-label>
            <input
              matInput
              processdelightDatePickerFormat
              [datePickerFormat]="selectedParam?.format ?? 'dd/MM/yyyy'"
              [formControl]="valueSelectorControl"
              [matDatepicker]="picker"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="'Choice'">
          <mat-form-field>
            <mat-label>Value</mat-label>
            <mat-select
              [formControl]="valueSelectorControl"
              (opened)="focusInput(choiceSearch)"
            >
              <div class="h-100 d-flex flex-column">
                <mat-form-field class="flex-shrink-0 no-subscript-wrapper">
                  <mat-label>Search...</mat-label>
                  <input type="text" matInput #choiceSearch />
                </mat-form-field>
                <div class="d-flex flex-column scrollbar overflow-auto">
                  <mat-option>(Empty)</mat-option>
                  <mat-option
                    *ngFor="let choice of selectedParam?.choices"
                    [value]="choice.id"
                    [hidden]="
                      !choice.value
                        .toLowerCase()
                        .includes(choiceSearch.value.toLowerCase())
                    "
                  >
                    {{ choice.value }}
                  </mat-option>
                </div>
              </div>
            </mat-select>
          </mat-form-field>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div class="d-flex flex-row mt-2">
    <button
      mat-raised-button
      color="primary"
      mat-dialog-close
      (click)="update()"
    >
      Update
    </button>
    <button mat-raised-button class="ms-2" mat-dialog-close>Cancel</button>
  </div>
</div>
