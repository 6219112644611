import { createAction, props } from '@ngrx/store';
import { UserSettings } from '../../domain/models/user-settings.model';
import { UserLicenseInfo } from '../../domain/models/user-license-info.model';
import { ExternalUserInfo } from '../../domain/models/external-user-info.model';
import { PortalClientUserGroup } from 'src/app/webparts/domain/models/portal-client-user-group.model';
import { Group } from '../../domain/models/group.model';
import { PortalAppConfig } from '../../domain/models/portal-app-config';
import { Form } from 'processdelight-angular-components';
import { ClientUserRequest } from '../../domain/models/client-user-request.model';

export const getLicensesForUser = createAction(
  '[User] Get licenses for user',
  props<{
    email: string;
    callback?: () => void;
  }>()
);
export const getLicensesForUserResolved = createAction(
  '[User] Get licenses for user resolved',
  props<{ licenseInfo: UserLicenseInfo }>()
);
export const getExternalUserInfoResolved = createAction(
  '[User] Get external user info resolved',
  props<{ externalUserInfo: ExternalUserInfo }>()
);
export const updateUserLanguage = createAction(
  '[User] Update user language',
  props<{ lang: string; callback?: () => void }>()
);
export const getUserSettings = createAction(
  '[User] Get user settings',
  props<{ callback?: () => void }>()
);
export const getUserSettingsResolved = createAction(
  '[User] Get user settings resolved',
  props<{ settings: UserSettings }>()
);
export const updateUserSettings = createAction(
  '[User] Update user settings',
  props<{ settings: UserSettings; callback?: () => void }>()
);
export const updateUserSettingsResolved = createAction(
  '[User] Update user settings resolved',
  props<{ settings: UserSettings }>()
);
export const deletePortalClientUserGroup = createAction(
  '[User] delete user clientusergroup',
  props<{
    portalClientUserGroup: PortalClientUserGroup;
    callback?: () => void;
  }>()
);
export const deletePortalClientUserGroupResolved = createAction(
  '[User] delete user clientusergroup resolved',
  props<{ portalClientUserGroup: PortalClientUserGroup }>()
);
export const createPortalClientUserGroup = createAction(
  '[User] create user clientusergroup',
  props<{
    portalClientUserGroup: PortalClientUserGroup;
    callback?: () => void;
  }>()
);
export const createPortalClientUserGroupResolved = createAction(
  '[User] create user clientusergroup resolved',
  props<{ portalClientUserGroup: PortalClientUserGroup; groups: Group[] }>()
);
export const addClientUserRequest = createAction(
  '[ClientRequest] create user client request',
  props<{ request: ClientUserRequest }>()
);
export const resetSlice = createAction('[User] Reset slice');
