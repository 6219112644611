import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslationService } from 'processdelight-angular-components';
import { Subject, takeUntil } from 'rxjs';
import { PortalPostComment } from 'src/app/core/domain/models/portal-post-comment.model';
import { PortalPostLike } from 'src/app/core/domain/models/portal-post-like.model';
import { PortalPost } from 'src/app/core/domain/models/portal-post.model';
import { PortalUserVM } from 'src/app/core/domain/models/portal-user.model';
import { FeedFacade } from 'src/app/core/store/custom/feed/feed.facade';
import { UserFacade } from 'src/app/core/store/user/user.facade';
import { clear, Memoize } from 'typescript-memoize';
import { v4 } from 'uuid';
import { PortalUserPipe } from '../portal.user.pipe';
import { PostPopupComponent } from '../post-popup/post-popup.component';
import { LikeListPopupComponent } from './like-list-popup/like-list-popup.component';

@Component({
  selector: 'app-feed-post',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatDialogModule,
    MatFormFieldModule,
    MatMenuModule,
    PortalUserPipe,
    CdkMenuModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
  ],
  templateUrl: './feed-post.component.html',
  styleUrls: ['./feed-post.component.scss'],
})
export class FeedPostComponent implements OnInit, OnDestroy {
  @Input() post!: PortalPost;
  @Input() groupUserId!: string | undefined;
  @Input() portalUserId!: string | undefined;

  @Input() isSmallView!: boolean;
  @Input() canCreatePosts!: boolean;
  @Input() canCreateComment!: boolean;
  @Input() canCreateLike!: boolean;
  @Input() canDeleteAnything!: boolean;
  @Input() canEditAllPosts!: boolean;

  savingLike = false;
  savingComment = false;
  updatingComment = false;
  deletingPost = false;

  showComment = false;

  newCommentText = new FormControl('');

  users: PortalUserVM[] = [];

  constructor(
    private readonly matDialog: MatDialog,
    private readonly feedFac: FeedFacade,
    private readonly userFacade: UserFacade,
    private readonly snackBar: MatSnackBar,
    public readonly translations: TranslationService
  ) {}

  @ViewChild('commentEditInput') commentEditInput?: ElementRef;

  ngOnInit(): void {
    this.feedFac.posts$.pipe(takeUntil(this.destroy$)).subscribe((posts) => {
      const thispost = posts?.find((p) => p.id === this.post.id);
      if (!thispost) return;
      this.post = thispost;
      clear(['post']);
    });
  }

  toggleLikePost() {
    this.savingLike = true;
    if (!this.isLikedByMe(this.post))
      this.feedFac
        .createLike(
          new PortalPostLike({
            id: v4(),
            postId: this.post.id,
            portalUserId: this.portalUserId,
            groupUserId: this.groupUserId,
          })
        )
        .subscribe(() => {
          this.savingLike = false;
        });
    else {
      this.removeLike(this.post);
    }
  }

  deletePost() {
    this.deletingPost = true;
    this.feedFac.removePost(this.post).subscribe(() => {
      this.snackBar.open(
        `${this.translations.getTranslation('post')} ${this.translations
          .getTranslation('deleted')
          .toLowerCase()}`,
        'Dismiss',
        { duration: 2000 }
      );
      this.deletingPost = false;
    });
  }

  toggleLikeComment(comment: PortalPostComment) {
    this.savingLike = true;
    if (!this.isLikedByMe(comment))
      this.feedFac
        .createLike(
          new PortalPostLike({
            id: v4(),
            commentId: comment.id,
            portalUserId: this.portalUserId,
            groupUserId: this.groupUserId,
          })
        )
        .subscribe(() => {
          this.savingLike = false;
        });
    else {
      this.removeLike(comment);
    }
  }
  removeLike(post: PortalPost | PortalPostComment) {
    const mylike = post.likes.find(
      (l) =>
        l.portalUserId === this.portalUserId ||
        l.groupUserId === this.groupUserId
    );
    if (!mylike) {
      console.error('Could not find like to remove');
      return;
    }
    this.feedFac.removeLike(mylike).subscribe(() => {
      this.savingLike = false;
    });
  }

  createComment() {
    if (this.savingComment || !this.newCommentText.value) return;
    this.savingComment = true;
    this.feedFac
      .createComment(
        new PortalPostComment({
          postId: this.post.id,
          portalUserId: this.portalUserId,
          groupUserId: this.groupUserId,
          message: this.newCommentText.value,
        })
      )
      .subscribe((d) => {
        this.newCommentText.setValue('');
        this.savingComment = false;
      });
  }

  openLikeList(post: PortalPost | PortalPostComment) {
    this.matDialog.open(LikeListPopupComponent, {
      data: { likes: post.likes },
      maxHeight: '70%',
      width: '50%',
    });
  }

  @Memoize({ tags: ['post'] })
  isLikedByMe(post: PortalPost | PortalPostComment) {
    return post.likes.some(
      (like) =>
        like.portalUserId === this.portalUserId ||
        like.groupUserId === this.groupUserId
    );
  }

  deleteComment(comment: PortalPostComment) {
    this.savingComment = true;
    this.feedFac.removeComment(comment).subscribe(() => {
      this.savingComment = false;
    });
  }

  @Memoize({ tags: ['post'], hashFunction: (i) => i })
  getLastLikeComment(post: PortalPost | PortalPostComment) {
    const getUser = (like: PortalPostLike) =>
      like.portalUser?.displayName ?? like.groupUser?.displayName;
    if (post.likes.length === 0)
      return this.translations.getTranslation('noLikes');
    if (post.likes.length === 1)
      return (
        getUser(post.likes[0]) +
        ' ' +
        this.translations.getTranslation('likesThis')
      );
    const lastLike = post.likes[post.likes.length - 1];
    return (
      getUser(lastLike) +
      ` ${this.translations.getTranslation('and').toLowerCase()} ` +
      (post.likes.length - 1) +
      ' ' +
      this.translations.getTranslation('others')
    );
  }

  editPost() {
    this.matDialog.open(PostPopupComponent, {
      data: { post: this.post },
      width: '100%',
      maxWidth: '750px',
      height: '100%',
      panelClass: 'p-4',
    });
  }

  editingComment: PortalPostComment | null = null;
  editComment(comment: PortalPostComment) {
    this.editingComment = new PortalPostComment({ ...comment });
    setTimeout(() => {
      this.commentEditInput?.nativeElement.focus();
    }, 0);
  }

  saveEditComment() {
    this.updatingComment = true;
    this.feedFac
      .editComment(
        new PortalPostComment({
          id: this.editingComment!.id,
          message: this.editingComment!.message,
          postId: this.editingComment!.postId,
          likes: this.editingComment!.likes,
          groupUserId: this.editingComment!.groupUserId,
          portalUserId: this.editingComment!.portalUserId,
        })
      )
      .subscribe(() => {
        this.editingComment = null;
        this.updatingComment = false;
        this.snackBar.open(
          this.translations.getTranslation('commentEdited') ?? 'Comment Edited',
          'Dismiss',
          {
            duration: 2000,
          }
        );
      });
  }

  cancelEditComment() {
    this.editingComment = null;
  }

  destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
