import { InterestGroup } from 'processdelight-angular-components';
import { PortalUserInterestGroupJoinRequest } from '../models/portal-user-interest-group-join-request.model';

export class UserJoinInterestGroupDTO {
  joinedGroups?: InterestGroup[];
  joinRequest?: PortalUserInterestGroupJoinRequest;

  constructor(obj: Partial<UserJoinInterestGroupDTO>) {
    Object.assign(this, obj);
    if (obj.joinedGroups)
      this.joinedGroups = obj.joinedGroups.map(
        (group) => new InterestGroup(group)
      );
    if (obj.joinRequest)
      this.joinRequest = new PortalUserInterestGroupJoinRequest(
        obj.joinRequest
      );
  }
}
