<div class="top-bar-wrapper">
  <!-- <div class="horizontal-scrollbar h-100 overflow-x-auto overflow-y-hidden">
    <mat-button-toggle-group
      *ngIf="{
        activeApp: activeApp$ | async
      } as appData"
      [value]="appData.activeApp?.appName ?? 'Ishtar.365'"
      style="transform: translateY(-3px)"
    >
      <mat-button-toggle
        *ngFor="let app of openApps"
        [value]="app.appName"
        class="ishtar-tabs"
        (click)="handleTabChange(app.appName)"
      >
        <img
          width="32"
          height="32"
          [src]="app.appIconUrl"
          alt="{{ app.appName }} Logo"
        />
        <div
          class="title"
          *ngIf="{
            isTabExpanded: isTabExpanded(
              app.appName,
              appData.activeApp ?? undefined
            )
          } as tabData"
          [style.width]="tabData.isTabExpanded ? 'auto' : '0'"
          [style.margin-left]="tabData.isTabExpanded ? '1rem' : '0'"
          [style.opacity]="tabData.isTabExpanded ? '1' : '0'"
        >
          {{ app.appName }}
          <button
            *ngIf="app.appName !== 'Ishtar.365'"
            mat-icon-button
            (click)="closeApp(app.appName); $event.stopPropagation()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <button
    *ngIf="closedApps.length"
    mat-icon-button
    [matMenuTriggerFor]="openNewTabMenu"
  >
    <mat-icon>add</mat-icon>
  </button>
  <mat-menu #openNewTabMenu>
    <mat-action-list>
      <mat-list-item
        *ngFor="let app of closedApps"
        (click)="openApp(app.appName)"
      >
        <div class="d-flex flex-row">
          <img
            width="32"
            height="32"
            [src]="app.appIconUrl"
            alt="{{ app.appName }} Logo"
          />
          <div class="d-flex align-items-center ps-2">
            {{ app.appName }}
          </div>
        </div>
      </mat-list-item>
    </mat-action-list>
  </mat-menu> -->
  <div class="spacer"></div>
  <div class="account">
    <ng-container *ngIf="signedIn; else signInButton">
      <processdelight-notification-button
        [resizeBarColor]="navColor"
        [customWidth]="300"
        [hidePin]="true"
        [hideDockSwitcher]="true"
        [title]="getTranslation$('notifications') | async"
        [parent]="notifPanelContent?.nativeElement"
      ></processdelight-notification-button>
      <div
        class="h-100 ps-2 d-flex flex-row align-items-center clickable account-menu-button"
        (click)="accountMenuTrigger.toggleMenu()"
      >
        <ng-container *ngIf="{ isMobile: isMobile$ | async } as data">
          <div class="name" *ngIf="username && !data.isMobile">
            {{ username }}
          </div>
        </ng-container>
        <div class="photo" *ngIf="userPhoto; else initials">
          <img [src]="userPhoto" width="32" height="32" alt="user image" />
        </div>
        <ng-template #initials>
          <processdelight-user-initials
            *ngIf="username"
            [userName]="username"
            class="mx-2"
            (click)="accountMenuTrigger.toggleMenu()"
          ></processdelight-user-initials>
        </ng-template>
      </div>
      <div
        #accountMenuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="accountMenu"
        class="align-self-end"
      ></div>
      <mat-menu #accountMenu class="overflow-hidden account-panel">
        <div class="d-flex flex-column mt-2 mb-1 ms-3 me-3">
          <span class="bold">{{ username }}</span>
          <span class="mb-2">{{ userMail }}</span>
          <!-- <div class="d-flex flex-column">
            <div
              class="bold"
              *ngIf="
                getTranslation$('environments') | async as environmentsLabel
              "
            >
              {{ environmentsLabel }}:
            </div>
            <mat-action-list class="tenant-picker">
              <mat-list-item
                *ngFor="let tenant of tenants"
                (click)="selectTenant(tenant, true)"
                [class.active]="tenant.tenantId === selectedTenant?.tenantId"
                [style.height.rem]="2"
              >
                {{ tenant.displayName }}
              </mat-list-item>
            </mat-action-list>
          </div> -->
          <div
            class="mb-2 d-flex flex-row align-items-center"
            *ngIf="userLang"
            (click)="$event.stopPropagation()"
          >
            <div
              class="me-2 bold"
              *ngIf="getTranslation$('language') | async as languageLabel"
            >
              {{ languageLabel }}:
            </div>
            <mat-select
              color="primary"
              [value]="userLang"
              (selectionChange)="languageChange($event)"
            >
              <mat-option value="en">English</mat-option>
              <mat-option value="nl">Nederlands</mat-option>
              <mat-option value="fr">Français</mat-option>
            </mat-select>
          </div>
          <hr />
          <div class="d-flex flex-row justify-content-between">
            <button
              *ngIf="loggedInUser"
              mat-button
              (click)="openAccountSettings()"
            >
              <span
                *ngIf="
                  getTranslation$('settings') | async as accountSettingsLabel
                "
              >
                {{ accountSettingsLabel }}
              </span>
              <mat-icon matIconSuffix color="primary">settings</mat-icon>
            </button>
            <div></div>
            <button mat-button (click)="signOut()">
              <span *ngIf="getTranslation$('signOut') | async as signOutLabel">
                {{ signOutLabel }}
              </span>
              <mat-icon matIconSuffix color="warn">logout</mat-icon>
            </button>
          </div>
        </div>
      </mat-menu>
    </ng-container>
    <ng-template #signInButton>
      <button mat-button (click)="signIn()">
        <mat-icon>login</mat-icon>
        Sign in
      </button>
    </ng-template>
  </div>
  <div class="tenant-logo" *ngIf="orgLogo">
    <div class="vertical-ruler"></div>
    <img [src]="orgLogo" alt="organization logo" />
  </div>
</div>
