export class TasksWebpartUserSettings {
  columnOrder: string[] = [];
  columnSizes: { [key: string]: number } = {};
  sortColumn?: string;
  sortDirection: 'asc' | 'desc' | '' = '';
  pageSize = 100;
  assignedToMe = false;

  constructor(init?: Partial<TasksWebpartUserSettings>) {
    Object.assign(this, init);
  }
}
