import { createAction, props } from '@ngrx/store';
import { ClientWithConfig } from '../../domain/models/client-with-config.model';
import { ActionBase } from '../action-base.interface';
import { ClientUserInfo } from '../../domain/models/client-user-info.model';
import { ClientUserRequest } from '../../domain/models/client-user-request.model';
import { UserConfigurationDTO } from '../../domain/dto/user-configuration.dto';
import { CRMEntity } from 'processdelight-angular-components';

export const getClients = createAction(
  '[Client] Get clients',
  props<ActionBase<ClientWithConfig[]>>()
);
export const getClientsResolved = createAction(
  '[Client] Get clients resolved',
  props<{ clients: ClientWithConfig[] }>()
);
export const getClientUsers = createAction(
  '[Client] Get client users',
  props<{ clientId: string | undefined } & ActionBase<ClientUserInfo[]>>()
);
export const getClientUsersResolved = createAction(
  '[Client] Get client users resolved',
  props<{ clientUsers: ClientUserInfo[] }>()
);
export const getClientUserRequests = createAction(
  '[Client] Get client user requests',
  props<{ clientId: string | undefined } & ActionBase<ClientUserRequest[]>>()
);
export const getClientUserRequestsResolved = createAction(
  '[Client] Get client user requests resolved',
  props<{ requests: ClientUserRequest[] }>()
);
export const acceptRequest = createAction(
  '[Client] Accept request',
  props<{ requestId: string } & ActionBase<ClientUserInfo | undefined>>()
);
export const acceptRequestResolved = createAction(
  '[Client] Accept request resolved',
  props<{ requestId: string; clientUserInfo: ClientUserInfo | undefined }>()
);
export const rejectRequest = createAction(
  '[Client] Reject request',
  props<{ requestId: string } & ActionBase<ClientUserInfo | undefined>>()
);
export const rejectRequestResolved = createAction(
  '[Client] Reject request resolved',
  props<{ requestId: string; clientUserInfo: ClientUserInfo | undefined }>()
);
export const newRequest = createAction(
  '[Client] New request',
  props<{ client: CRMEntity } & ActionBase<ClientUserRequest | undefined>>()
);
export const newRequestResolved = createAction(
  '[Client] New request resolved',
  props<{
    clientUserInfo: ClientUserRequest;
  }>()
);
export const updateUserConfiguration = createAction(
  '[Client] Update user configuration',
  props<
    { userConfiguration: UserConfigurationDTO[] } & ActionBase<{
      users: ClientUserInfo[];
      clientConfigs: ClientWithConfig[];
    }>
  >()
);

export const setCurrentClient = createAction(
  '[Client] Set current client',
  props<{ client: CRMEntity }>()
);

export const resetSlice = createAction('[Client] Reset slice');
