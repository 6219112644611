import { createSelector } from '@ngrx/store';
import { AppState } from '../app.reducer';

const interestGroupState = (state: AppState) =>
  state.portalFeature.interestGroup;
const getInterestGroups = createSelector(
  interestGroupState,
  (state) => state.interestGroups
);
const getInterestGroupsForUser = createSelector(
  interestGroupState,
  (state) => state.interestGroupsForUser
);
export const interestGroupSelectors = {
  getInterestGroups,
  getInterestGroupsForUser,
};
