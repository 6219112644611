import { Action, createReducer, on } from '@ngrx/store';
import { IshtarOrganization } from '../../domain/models/ishtar-organization.model';
import {
  getGroupUsersResolved,
  getOrganizationInfoResolved,
  getPortalUsersResolved,
  resetSlice,
} from './organization.actions';
import { GroupUser } from 'processdelight-angular-components';
import { PortalUserVM } from '../../domain/models/portal-user.model';

export const featureSlice = 'organization';

export interface State {
  organizationInfo?: IshtarOrganization;
  groupUsers: GroupUser[];
  portalUsers: PortalUserVM[];
}

const defaultState: State = {
  organizationInfo: undefined,
  groupUsers: [],
  portalUsers: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return organizationReducer(state, action);
}

export const initialState: State = defaultState;
export const organizationReducer = createReducer(
  initialState,
  on(getOrganizationInfoResolved, (state, { organizationInfo }) => ({
    ...state,
    organizationInfo,
  })),
  on(getGroupUsersResolved, (state, { groupUsers }) => ({
    ...state,
    groupUsers,
  })),
  on(getPortalUsersResolved, (state, { portalUsers }) => ({
    ...state,
    portalUsers,
  })),
  on(resetSlice, () => initialState)
);
