import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { DateTime } from 'luxon';
import {
  ColumnDef,
  ContextMenuAction,
  DashboardComponent,
  DataSourceService,
  GroupUser,
  Library,
  LibraryFacade,
  LibraryItem,
  MetadataFacade,
  MetadataParam,
} from 'processdelight-angular-components';
import {
  Subject,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  takeUntil,
  withLatestFrom,
} from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { TranslationService } from 'processdelight-angular-components';
import { AdaptableInputComponent } from '../adaptable-input/adaptable-input.component';
import { DmsPreviewDialogComponent } from '../dms-preview-dialog/dms-preview-dialog.component';
import { ColumnType } from '../domain/enums/column-type.enum';
import { DMSWebpartUserSettings } from '../domain/models/dms-webpart-user-settings.model';
import {
  DateStartEnd,
  MetadataFilterType,
} from '../domain/models/metadata.functions';
import { FunctionsService } from '../services/functions.service';
import { LibraryIconService } from '../services/library-icon.service';
import { UserSettingsService } from '../services/user-settings.service';
import { DataService } from '../../../core/services/data.service';

@Component({
  selector: 'app-dms-list-dialog',
  templateUrl: './dms-list-dialog.component.html',
  styleUrls: ['./dms-list-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    DashboardComponent,
    MatIconModule,
    MatDialogModule,
    MatSnackBarModule,
    RouterModule,
    MatCardModule,
    MatButtonModule,
    MatProgressSpinnerModule,

    // filter
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatInputModule,
    MatButtonToggleModule,
    AdaptableInputComponent,
  ],
})
export class DmsListDialogComponent implements OnInit, OnDestroy, OnChanges {
  // filter
  @ViewChild('filterValueElement') filterValueElement?: AdaptableInputComponent;
  filterParamControl = new FormControl<MetadataParam | undefined>(undefined);
  filterValueControl = new FormControl<MetadataFilterType | undefined>(
    undefined
  );
  filtering = false;

  selectedView?: string;

  getInterestGroupFilterActive() {
    return (
      this.dataSourceService.getData('filteredInterestGroups') != undefined
    );
  }

  paramTrackBy = (_i: number, param: MetadataParam) => param.id;

  filterTrackBy = (_i: number, param: { paramId: string }) => param.paramId;

  getParamValue(
    params: MetadataParam[],
    id: string,
    value: MetadataFilterType
  ) {
    const param = this.getParamById(params, id);
    if (param?.type == ColumnType.Choice)
      return Array.isArray(value)
        ? value
            .map((v) => param.choices?.find((c) => c.id == v)?.value)
            .join(', ')
        : param.choices?.find((c) => c.id == value)?.value;
    else if (param?.type == ColumnType.ConsolidatedChoice) {
      if (Array.isArray(value)) {
        return value
          .map((v) => {
            const [pId, cId] = (v as string).split('.');
            const p = this.getParamById(params, pId);
            return p?.choices?.find((c) => c.id == cId)?.value;
          })
          .join(', ');
      } else {
        const [pId, cId] = (value as string).split('.');
        const p = this.getParamById(params, pId);
        return p?.choices?.find((c) => c.id == cId)?.value;
      }
    } else if (param?.type == ColumnType.GroupUser)
      return Array.isArray(value)
        ? value.map((v) => (v as GroupUser).displayName).join(', ')
        : (value as GroupUser).displayName;
    else if (param?.type == ColumnType.DateTime) {
      if (typeof value == 'string') return value;
      else
        return `${
          this.getDateStartEndStart(value as DateStartEnd)?.toFormat(
            param.format ?? 'dd/MM/yyyy'
          ) ?? '...'
        } - ${
          this.getDateStartEndEnd(value as DateStartEnd)?.toFormat(
            param.format ?? 'dd/MM/yyyy'
          ) ?? '...'
        }`;
    } else if (Array.isArray(value)) return value.join(', ');
    else return value;
  }

  getFilteredParams(params: MetadataParam[]) {
    return this.library
      ? params
          .filter(
            (p) =>
              p.createdByParam ||
              p.createdOnParam ||
              p.modifiedByParam ||
              p.modifiedOnParam ||
              p.fileNameParam ||
              this.library?.configuredParams.some((c) => c.paramId == p.id)
          )
          .sort(
            (a, b) =>
              (this.library?.configuredParams.find((c) => c.paramId == a.id)
                ?.position ?? 0) -
              (this.library?.configuredParams.find((c) => c.paramId == b.id)
                ?.position ?? 0)
          )
      : params.sort((a, b) => (a.title < b.title ? -1 : 1));
  }

  toggleFiltering() {
    if (this.filtering) {
      this.filterValue = {};
      if (this.library) {
        this.filterValue = {
          ...this.filterValue,
        };
      }

      this.filtering = false;
      this.filterParamControl.patchValue(undefined);
      this.filterValueControl.patchValue(undefined);
    } else {
      this.filtering = !this.filtering;
    }
  }

  getDateStartEndStart(value: DateStartEnd) {
    if (!value.start) return undefined;
    return value.start instanceof DateTime
      ? value.start
      : DateTime.fromJSDate(value.start);
  }
  getDateStartEndEnd(value: DateStartEnd) {
    if (!value.end) return undefined;
    return value.end instanceof DateTime
      ? value.end
      : DateTime.fromJSDate(value.end);
  }

  addFilter() {
    const param = this.filterParamControl.value;
    if (!param) return;
    if (this.filterValueControl.value)
      this.updateFilter({
        ...this.filterValue,
        [param.id]: this.filterValueControl.value,
      });
    else if (this.filterValue[param.id]) {
      delete this.filterValue[param.id];
      this.updateFilter({ ...this.filterValue });
    }
  }

  removeFilter(paramId: string) {
    delete this.filterValue[paramId];
    this.updateFilter({ ...this.filterValue });
  }

  selectFilter(
    params: MetadataParam[],
    paramId: string,
    value: MetadataFilterType
  ) {
    const param = this.getParamById(params, paramId);
    if (!param) return;
    this.filterParamControl.patchValue(param);
    setTimeout(() => {
      this.filterValueControl.patchValue(value);
    }, 0);
  }

  getParamById(params: MetadataParam[], id: string) {
    return params.find((p) => p.id == id);
  }

  updateFilter(value: { [key: string]: any }) {
    this.filterValue = value;
    this.page = 0;
    this.getItems(
      this.orderBy,
      this.orderByDirection,
      { ...this.filterValue, ...this.data.prefilterValue },
      this.pageSize,
      this.page,
      this.getInterestGroupFilterActive(),
      true,
      this.data.filteredInterestGroupIds
    );
  }

  refreshFilter() {
    this.updateFilter({ ...this.filterValue });
  }

  get filterValueArray() {
    return Object.entries(this.filterValue).map(([key, value]) => ({
      paramId: key,
      value,
    }));
  }

  focusInput(input: HTMLInputElement) {
    setTimeout(() => {
      input.focus();
    }, 0);
  }
  // filter end

  @Input() library?: Library;
  @Input() filterValue: { [key: string]: string } = {};
  @Input() buttonsTemplateRef?: TemplateRef<void>;

  @Output() itemClicked = new EventEmitter<LibraryItem>();

  orderBy!: string;
  orderByDirection?: 'asc' | 'desc' = 'desc';
  pageSize = 100;
  page = 0;
  highestPage = 0;

  items: LibraryItem[] = [];
  totalRecordCount = 0;

  pagedItems: LibraryItem[] = [];

  metadataParameters$ = this.metadataFacade.metadataParams$;
  firstSortChange = true;

  private imgColumn = new ColumnDef<LibraryItem>({
    internalName: 'img',
    displayName: '',
    sortable: false,
    filterable: false,
    fixed: true,
    valueHtml: true,
    valueAccessor: (item) =>
      `<img class="ms-2" src="${this.libraryIconService.provideIcon(
        item.fileLocation
      )}" width="24" height="24" alt="file icon" />`,
    canChangeWidth: false,
    width: 50,
  });

  private libraryColumn = new ColumnDef<LibraryItem>({
    internalName: 'library',
    displayName: 'Library',
    sortable: false,
    filterable: false,
    valueAccessor: (item) =>
      item.library?.title ?? item.appItems?.find((i) => !!i)?.tableName,
    cellClass: 'ellipsis',
  });

  private appItemColumn = new ColumnDef<LibraryItem>({
    internalName: 'appItem',
    displayName: 'Linked to',
    sortable: false,
    filterable: false,
    valueAccessor: (item) =>
      item.appItems
        .filter((i) => i.tableName == this.library?.id)
        .map((i) => i.name)
        .join(', '),
    cellClass: 'ellipsis',
  });

  possibleColumns: ColumnDef<LibraryItem>[] = [];

  selectedColumns: string[] = [];
  view?: string[];

  previewItem?: LibraryItem;
  selectedItems: LibraryItem[] = [];

  metadataParams: MetadataParam[] = [];

  contextMenuActions: ContextMenuAction<LibraryItem>[] = [];

  loading = false;

  initialized = false;

  destroy$ = new Subject<void>();

  reset$ = new Subject<void>();

  pagingCalls = 0;
  lastPageCall?: () => void;

  itemTrackByFn: (index: number, item: LibraryItem) => string = (_, item) =>
    item.id;

  appLibrary = false;

  multiSelectActions: ContextMenuAction<LibraryItem[]>[] = [];

  @ViewChild(DashboardComponent) dashboard!: DashboardComponent<LibraryItem>;

  userSettingsUpdateSubject = new Subject<void>();

  constructor(
    private readonly libraryFacade: LibraryFacade,
    private readonly metadataFacade: MetadataFacade,
    private readonly libraryIconService: LibraryIconService,
    private readonly functions: FunctionsService,
    private readonly dataService: DataService,
    private readonly matDialog: MatDialog,
    private readonly snackbar: MatSnackBar,
    private readonly translationService: TranslationService,
    private readonly userSettingsService: UserSettingsService,
    private readonly dataSourceService: DataSourceService,
    public dialogRef: MatDialogRef<DmsListDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      items: LibraryItem[];
      totalRecordCount: number;
      prefilterValue: { [key: string]: string };
      filterValue: { [key: string]: string };
      library?: Library;
      webpartId?: string;
      filteredInterestGroupIds?: string[];
    }
  ) {}

  getTranslation(label: string): string {
    return this.translationService.getTranslation(label);
  }
  getTranslation$(label: string) {
    return this.translationService.getTranslation$(label);
  }

  ngOnInit(): void {
    this.items = this.data.items;
    this.pagedItems = this.data.items;
    this.filterValue = { ...this.data.filterValue };
    this.library = this.data.library;
    if (Object.keys(this.filterValue).length) this.filtering = true;
    this.totalRecordCount = this.data.totalRecordCount;
    this.selectedView = 'tileView';

    this.metadataFacade.getMetadataParams$().subscribe();

    this.appLibrary =
      !!this.library &&
      !/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i.test(
        this.library.id!
      );
    this.metadataParameters$
      .pipe(
        filter((x) => x.length > 0),
        first()
      )
      .subscribe((params) => {
        const userSettings = this.data.webpartId
          ? this.userSettingsService.getSettings(this.data.webpartId)
          : new DMSWebpartUserSettings();
        this.appItemColumn.width =
          userSettings.columnSizes[this.appItemColumn.internalName];
        this.libraryColumn.width =
          userSettings.columnSizes[this.libraryColumn.internalName];

        this.metadataParams = params;

        this.pageSize = userSettings.pageSize ?? 100;
        this.orderBy =
          userSettings.sortColumn ?? params.find((p) => p.modifiedOnParam)!.id;
        this.orderByDirection = userSettings.sortDirection ?? 'desc';
        const fixedColumns = [this.imgColumn];
        if (!this.library) fixedColumns.push(this.libraryColumn);
        if (this.appLibrary) fixedColumns.push(this.appItemColumn);

        this.possibleColumns = [...fixedColumns].concat(
          params
            ?.filter(
              (p) =>
                p.fileNameParam ||
                p.createdByParam ||
                p.createdOnParam ||
                p.modifiedByParam ||
                p.modifiedOnParam ||
                !this.library ||
                this.library.configuredParams.some((c) => c.paramId == p.id!)
            )
            .map(
              (p) =>
                new ColumnDef<LibraryItem>({
                  internalName: p.id!,
                  displayName: p.title,
                  sortable: p.type != ColumnType.GroupUser,
                  filterable: false,
                  valueAccessor: this.itemValueAccessor(p.id!, params),
                  width: userSettings.columnSizes[p.id!],
                  cellClass: 'ellipsis',
                })
            ) ?? []
        );
        this.possibleColumns.sort((a, b) =>
          a.displayName.localeCompare(b.displayName)
        );

        this.view = userSettings.columnOrder.filter((c) =>
          this.possibleColumns.some((p) => p.internalName == c)
        );
        if (!this.view) {
          const special = params.filter(
            (p) =>
              p.fileNameParam ||
              p.createdByParam ||
              p.createdOnParam ||
              p.modifiedByParam ||
              p.modifiedOnParam
          );
          this.selectedColumns = [
            ...fixedColumns.map((c) => c.internalName),
            ...special.map((s) => s.id!),
            ...(this.library?.configuredParams.map((p) => p.paramId) ?? []),
          ];
          this.view = this.selectedColumns;
          if (this.data.webpartId)
            this.userSettingsService.setSettings(
              this.data.webpartId,
              new DMSWebpartUserSettings({
                columnOrder: this.view,
                sortColumn: this.orderBy,
                sortDirection: this.orderByDirection,
                pageSize: this.pageSize,
                columnSizes: this.possibleColumns
                  .filter((c) => c.width)
                  .reduce(
                    (acc, c) => ({ ...acc, [c.internalName]: c.width }),
                    {}
                  ),
              })
            );
        } else {
          this.selectedColumns = [
            ...fixedColumns.map((c) => c.internalName),
            ...params.map((m) => m.id!),
          ]
            .filter((c) => this.view!.includes(c))
            .sort((a, b) => this.view!.indexOf(a) - this.view!.indexOf(b));
        }
        this.initialized = true;
      });
    this.metadataParameters$
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged((a, b) => {
          if (a.length != b.length) return false;
          const aSorted = [...a].sort((a, b) => a.id!.localeCompare(b.id!));
          const bSorted = [...b].sort((a, b) => a.id!.localeCompare(b.id!));
          for (let i = 0; i < a?.length; i++)
            if (
              aSorted[i].id! != bSorted[i].id! ||
              aSorted[i].choices.length != bSorted[i].choices.length ||
              aSorted[i].format != bSorted[i].format ||
              aSorted[i].consolidatedChoices.length !=
                bSorted[i].consolidatedChoices.length ||
              aSorted[i].title != bSorted[i].title ||
              aSorted[i].hasTranslations != bSorted[i].hasTranslations ||
              aSorted[i].languages.length != bSorted[i].languages.length
            )
              return false;

          return true;
        })
      )
      .subscribe((params) => {
        this.possibleColumns.forEach((column) => {
          const param = params.find((p) => p.id! == column.internalName);
          if (param) {
            column.displayName = param.title;

            column.valueAccessor = this.itemValueAccessor(param.id!, params);
          }
        });
      });

    this.filterParamControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.filterValueControl.patchValue(undefined);
        setTimeout(() => {
          this.filterValueElement?.focus();
        }, 0);
      });

    this.userSettingsUpdateSubject
      .asObservable()
      .pipe(takeUntil(this.destroy$), debounceTime(500))
      .subscribe(() => this.updateUserSettings());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.filterValue &&
      !changes.filterValue.firstChange &&
      changes.filterValue.currentValue != changes.filterValue.previousValue
    ) {
      this.page = 0;
      this.highestPage = 0;
      this.dashboard.paginator.firstPage();
      this.getItems(
        this.orderBy,
        this.orderByDirection,
        { ...this.filterValue, ...this.data.prefilterValue },
        this.pageSize,
        this.page,
        this.getInterestGroupFilterActive(),
        true,
        this.data.filteredInterestGroupIds
      );
    }
  }

  ngOnDestroy(): void {
    this.reset$.next();
    this.reset$.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  itemContextMenuFn = this.itemContextMenu.bind(this);
  itemContextMenu(item: LibraryItem) {
    return [
      new ContextMenuAction<LibraryItem>({
        action: () => {
          this.snackbar.open(this.getTranslation('downloadStart'), 'OK', {
            duration: 3000,
          });
          this.downloadDocument(item);
        },
        icon: 'download',
        label: this.getTranslation('download'),
      }),
    ];
  }

  downloadDocument(item: LibraryItem) {
    let relativeUrl = item.fileLocation.substring(
      item.fileLocation.indexOf('sharepoint.com/') + 14
    );
    if (relativeUrl.startsWith('//')) relativeUrl = relativeUrl.substring(1);

    const iframe = document.createElement('iframe');
    iframe.style.visibility = 'collapse';
    document.body.append(iframe);
    const url = this.functions.getSPValueUrl(relativeUrl);
    const queryParams = new URLSearchParams(url.split('?').pop());
    let form = `<form action="${url.replace(/"/g, '"')}" method="GET">`;
    queryParams.forEach(
      (val, key) => (form += `<input hidden name="${key}" value="${val}">`)
    );
    form += '</form>';

    iframe.contentDocument?.write(form);
    iframe.contentDocument?.forms[0].submit();

    setTimeout(() => iframe.remove(), 2000);
  }

  getItems(
    orderBy: string,
    orderByDirection: 'asc' | 'desc' | undefined,
    filter: { [key: string]: string },
    pageSize: number,
    page: number,
    filterActive: boolean,
    resetPaging?: boolean,
    filteredInterestGroupIds?: string[],
    callback?: () => void
  ) {
    this.loading = true;
    if (resetPaging) {
      // this.totalRecordCount = 0;
      this.reset$.next();
      this.pagingCalls = 1;
    } else {
      this.pagingCalls++;
    }
    this.lastPageCall = callback;

    this.dataService
      .getLibraryItems(
        orderBy,
        orderByDirection,
        { ...filter },
        pageSize,
        page,
        this.library?.id,
        filterActive,
        filteredInterestGroupIds
      )
      .pipe(first(), withLatestFrom(this.metadataFacade.metadataParams$))
      .subscribe(([items, params]) => {
        this.reset$.asObservable();
        this.pagingCalls--;
        this.items = resetPaging
          ? [...items.result]
          : [...this.items, ...items.result];
        this.pagedItems = this.items.filter(
          (_, index) =>
            index >= this.page * this.pageSize &&
            index < (this.page + 1) * this.pageSize
        );
        if (this.pagingCalls == 0) {
          this.loading = false;
          if (this.lastPageCall) this.lastPageCall();
        }
        this.totalRecordCount = items.totalRecordCount;
        if (this.items?.length == 0) return;
        const fixedColumns = [this.imgColumn];
        if (!this.library) fixedColumns.push(this.libraryColumn);
        if (this.appLibrary) fixedColumns.push(this.appItemColumn);
        this.selectedColumns = [
          ...fixedColumns.map((c) => c.internalName),
          ...params.map((p) => p.id!),
        ]
          .filter((c) => this.view?.includes(c))
          .sort((a, b) => this.view!.indexOf(a) - this.view!.indexOf(b));

        const missing = this.selectedColumns.filter(
          (c) => !this.possibleColumns.some((p) => p.internalName == c)
        );
        if (missing?.length > 0) {
          const userSettings = this.data.webpartId
            ? this.userSettingsService.getSettings(this.data.webpartId)
            : new DMSWebpartUserSettings();
          this.possibleColumns = [
            ...this.possibleColumns,
            ...missing.map((m) => {
              const param = this.metadataParams.find((p) => p.id == m)!;
              return new ColumnDef<LibraryItem>({
                internalName: param.id,
                displayName: param.title,
                sortable: param.type != ColumnType.GroupUser,
                filterable: false,
                valueAccessor: this.itemValueAccessor(
                  param.id!,
                  this.metadataParams
                ),
                width: userSettings.columnSizes[param.id!],
                cellClass: 'ellipsis',
              });
            }),
          ];
        }
      });
  }

  itemValueAccessor(metadataParamId: string, params: MetadataParam[]) {
    let resultFn: (item: LibraryItem) => any = () => undefined;
    this.metadataParameters$
      .pipe(
        first(),
        map((params) => params.find((p) => p.id == metadataParamId))
      )
      .subscribe((param) => {
        switch (param?.type) {
          case ColumnType.Choice:
            resultFn = (item: LibraryItem) =>
              item.metadata
                .filter((m) => m.metadataParameter!.id == metadataParamId)
                .map((m) => param.choices.find((c) => c.id == m.value)?.value)
                .join(', ');
            break;
          case ColumnType.ConsolidatedChoice:
            resultFn = (item: LibraryItem) =>
              item.metadata
                .filter(
                  (m) => m.metadataParameter!.id == metadataParamId && m.value
                )
                .map((m) => {
                  if (m.value) {
                    const [paramId, choiceId] = m.value.split('.');
                    const choiceParam = params.find((p) => p.id == paramId);
                    return `(${choiceParam?.title}) ${
                      choiceParam?.choices.find((c) => c.id == choiceId)?.value
                    }`;
                  }
                  return undefined;
                })
                .join(', ');
            break;
          case ColumnType.DateTime:
            resultFn = (item: LibraryItem) => {
              const val = item.metadata.find(
                (m) => m.metadataParameter!.id == metadataParamId
              )?.dateTimeValue;
              return val
                ? val.toFormat(param.format ?? 'dd/MM/yyyy HH:mm')
                : undefined;
            };
            break;
          case ColumnType.Number:
            resultFn = (item: LibraryItem) =>
              item.metadata.find(
                (m) => m.metadataParameter!.id == metadataParamId
              )?.numberValue;
            break;
          case ColumnType.GroupUser:
            resultFn = (item: LibraryItem) =>
              item.metadata
                .filter((m) => m.metadataParameter!.id == metadataParamId)
                .map((m) => m.groupUserValue?.displayName)
                .join(', ');
            break;
          default:
            resultFn = (item: LibraryItem) =>
              item.metadata.find(
                (m) => m.metadataParameter!.id == metadataParamId
              )?.value;
        }
      });
    return resultFn;
  }

  pageChange(event: { pagesize: number; page: number }) {
    if (this.pageSize != event.pagesize) {
      const needNewItems =
        event.pagesize > this.pageSize * (this.highestPage + 1);
      this.highestPage = Math.max(
        0,
        Math.floor((this.pageSize * (this.highestPage + 1)) / event.pagesize) -
          1
      );
      this.pageSize = event.pagesize;
      this.userSettingsUpdateSubject.next();
      this.page = 0;
      if (needNewItems)
        this.getItems(
          this.orderBy,
          this.orderByDirection,
          { ...this.filterValue, ...this.data.prefilterValue },
          this.pageSize,
          this.page,
          this.getInterestGroupFilterActive(),
          true,
          this.data.filteredInterestGroupIds
        );
      else
        this.pagedItems = this.items.filter(
          (_, index) =>
            index >= this.page * this.pageSize &&
            index < (this.page + 1) * this.pageSize
        );
    } else {
      if (event.page > this.highestPage)
        this.getItems(
          this.orderBy,
          this.orderByDirection,
          { ...this.filterValue, ...this.data.prefilterValue },
          this.pageSize,
          event.page,
          this.getInterestGroupFilterActive(),
          false,
          this.data.filteredInterestGroupIds,
          () => (this.highestPage = event.page)
        );
      else
        this.pagedItems = this.items.filter(
          (_, index) =>
            index >= event.page * this.pageSize &&
            index < (event.page + 1) * this.pageSize
        );
      this.page = event.page;
    }
  }

  sortChange(event: {
    sortedColumn: string;
    sortDirection: '' | 'asc' | 'desc';
  }) {
    if (this.firstSortChange) {
      this.firstSortChange = false;
      return;
    }
    this.orderBy = event.sortedColumn;
    this.orderByDirection =
      event.sortDirection == ''
        ? undefined
        : (event.sortDirection as 'asc' | 'desc');
    if (this.initialized) {
      this.userSettingsUpdateSubject.next();
    }

    this.page = 0;
    this.highestPage = 0;
    this.getItems(
      this.orderBy,
      this.orderByDirection,
      { ...this.filterValue, ...this.data.prefilterValue },
      this.pageSize,
      this.page,
      this.getInterestGroupFilterActive(),
      true,
      this.data.filteredInterestGroupIds
    );
  }

  columnChange(columns: string[]) {
    this.view = columns;
    if (this.initialized) {
      this.userSettingsUpdateSubject.next();
    }
  }

  columnWidthChange(change: { column: string; width: number }) {
    if (change.column == 'library') this.libraryColumn.width = change.width;
    else if (change.column == 'appItem')
      this.appItemColumn.width = change.width;
    else
      this.possibleColumns.find((c) => c.internalName == change.column)!.width =
        change.width;
    if (this.initialized) {
      this.userSettingsUpdateSubject.next();
    }
  }

  openPreview(item: LibraryItem) {
    this.previewItem = item;
    this.selectedItems = [item];

    this.matDialog.open(DmsPreviewDialogComponent, {
      width: '100%',
      height: '100%',
      panelClass: 'p-4',
      data: { item },
    });
  }

  openDocument(item: LibraryItem, app = false) {
    // const url = this.functions.getSPValueUrlById(item.sharepointId, true);
    // const extension = item.fileLocation.split('.').pop()!;
    // if (app) {
    //   forkJoin([
    //     this.libraryFacade.libraries$.pipe(first()),
    //     this.tilePageFacade.homePage$.pipe(first()),
    //   ])
    //     .pipe(
    //       switchMap(([libs, homepage]) => {
    //         let permissions: Permission[] = [];
    //         if (this.library) permissions = this.library.permissions;
    //         else if (item.library)
    //           permissions =
    //             libs.find((l) => l.id == item.library?.id)
    //               ?.permissions ?? [];
    //         else permissions = homepage?.permissions ?? [];
    //         const canEdit =
    //           !permissions.length ||
    //           permissions.some(
    //             (p) =>
    //               (p.groupUser.user?.id == this.msal.userId ||
    //                 p.groupUser.group?.members?.some(
    //                   (m) => m.id == this.msal.userId
    //                 )) &&
    //               p.permissionType != PermissionType.Read
    //           );
    //         return this.libDocumentService.provideOfficeScheme(
    //           item.sharepointId,
    //           extension,
    //           canEdit
    //         );
    //       })
    //     )
    //     .subscribe((appUrl) => {
    //       window.open(appUrl ? appUrl : url, '_blank');
    //     });
    // } else {
    //   this.libDocumentService
    //     .providePreviewUrl(item.sharepointId, extension)
    //     .subscribe(({ data, type }) => {
    //       if (type == PreviewType.Iframe) {
    //         const url = new URL(data);
    //         url.searchParams.delete('embedded');
    //         window.open(url.toString(), '_blank');
    //       } else window.open(url, '_blank');
    //     });
    // }
  }

  onClose(): void {
    this.dialogRef.close();
  }

  openDocuments(items: LibraryItem[], app = false) {
    items.forEach((item) => this.openDocument(item, app));
  }

  updateUserSettings() {
    if (!this.data.webpartId) return;
    this.userSettingsService.setSettings(
      this.data.webpartId,
      new DMSWebpartUserSettings({
        columnOrder: this.view,
        sortColumn: this.orderBy,
        sortDirection: this.orderByDirection,
        pageSize: this.pageSize,
        columnSizes: this.possibleColumns
          .filter((c) => c.width)
          .reduce((acc, c) => ({ ...acc, [c.internalName]: c.width }), {}),
      })
    );
  }

  private apps: { [key: string]: string } = {
    IshtarTask: AppComponent.IshtarTasksAppName,
  };
}
